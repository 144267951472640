import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { Router, NavigationEnd } from '@angular/router';
import { BLHeader, jasperModel } from '../shared/index';
import { Promise } from 'q';
declare  var $:any;

export class SelectModel{
  code:String='';
  name:String='';

  constructor(){}
}
export class Kolom {
  header:string = '';
  field:string = '';
  sort:string = 'false';
  ds:SelectModel[] = []

  constructor(){}
}

export class BLDraft {
  blNo:string = '';
  columns:Kolom[] = [];
  id:string = '';
  no:number = 0;
  select:string = '';
  locationName:string ='';

  constructor(){}
}


@Component({
  selector: 'app-report-bl-print-sin-page',
  templateUrl: './report-bl-print-sin-page.component.html',
  styleUrls: ['./report-bl-print-sin-page.component.css']
})
export class ReportBlPrintSinPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;


  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cbReceipt') cbReceipt:ComboPluginComponent;
  @ViewChild('cbDelivery') cbDelivery:ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort:ComboPluginComponent;
  @ViewChild('cbDischargePort') cbDischargePort:ComboPluginComponent;
  @ViewChild('cbPOR') cbPOR:ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD:ComboPluginComponent;
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;
  @ViewChild('gridPDFPrev') gridPDFPrev:GridPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;
//isLoadPort=false;

  /* Parameter settings */
  settingToolbar;
  settingDelivery;
  settingReceipt;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingPOR;
  settingLoadPort;
  settingDischargePort;
  settingGrid;
  settingPDF;
  settingGridPDFPrev;
  newDialogOpt;

  // lock
  cButtonLock = true;
  dButtonLock = true;

 isCOC:Boolean = true;
 isSOC:Boolean = true;
 isSLOT:Boolean = true;
 isSOCTS:Boolean = true;
 isCOCTS:Boolean = true;
 isNonNego:Boolean = true;
 isSilent:Boolean = false;
 isSortPrint : Boolean = false;
 isWithTemplate: Boolean = false;
 isIna: Boolean = false;
 disableNN: Boolean = false;

 IsContOwn : String;
 isSort : String;
 isSelection : String;
 isWeight : String;
 isPrint : String;
 isExch : String;
 isSurcharge : String;
 isPackage : String;
 cCOC : String;
 cSOC : String;
 cCOCTs : String;
 cSOCTs : String;
 cSlot : String;
 config:any = require('config.json');

 isError : Boolean = false;
 lock: Boolean = false;
 lockHeader:boolean = false;
 lockButtonRetrieve : Boolean = true;

 addRemoveButton : string  ="none";
 lengthArray : number=0;
 blFcPop : string ="";
 locationName : string="";
 payableAt : string = "";

 i : number=1;


 printCopy:number=3;
 printOnlyOnceList = [];
 printFrom : string = "";

  officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
  officeId = (this.cookieService.getDefaultLocationId() == null) ?'*': this.cookieService.getDefaultLocationId();
  userId:string = "";
  userEmail:string = "";
  userIdCode:string = "";
  currentMenu:string = "";

  model = new BLHeader();

  blOkToPrint = [];
  labelMenu : string; 
  validatorRules = {};
  reprintMode:Boolean = false;

  jasper:jasperModel;
  jasperPath = [];
  disableOpt:Boolean = true;

  constructor(private cdr: ChangeDetectorRef, private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.router.events.subscribe((event) => {
      //console.log(event);
      if (event instanceof NavigationEnd) {
        // do some logic again when I click same url
        this.router.navigated = false;
        this.ngAfterViewInit();
      }
    });

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Reprint', event: 'reprint', icon: 'print'},
        {name: 'OK', event: 'ok', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.cekLabel()
    }

     this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingReceipt = {
      id          : 'cbReceipt',
      type        : 'search enter', // search | select | select input
      url        : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlReceiptType/receiptTypeCode={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'RECEIPT TYPE',
      title       : 'receiptTypeCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Receipt Type Code', field: 'receiptTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'receiptTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingDelivery = {
      id          : 'cbDelivery',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findByComboBoxControlDeliveryType/deliveryTypeCode={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'DELIVERY TYPE',
      title       : 'deliveryTypeCode',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Receipt Type Code', field: 'deliveryTypeCode', width: 100},
        {header: 'Receipt Type Name', field: 'deliveryTypeName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }


    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        // {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingDischargePort = {
      id          : 'cbDiscPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        //{header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
       // {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

     this.settingDischargePort = {
      id          : 'cbDiscPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        //{header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

     this.settingPOR = {
      id          : 'cbPOR',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        //{header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

     this.settingPOD = {
      id          : 'cbPOD',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
       // {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingGrid = {
      id : 'grid',
      url:'',//this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: '10',
      columns: [
        {header: 'B/L No', field: 'blNo', width: 200, editType: 'text'},
        {header: 'Surcharge Place Of Payment', field: 'locationName', width: 200, editType:'select', 
          maxCharsCombo:0,
          titleSelect:'locationName',
          codeSelect:'code',
          maxChars:0, 
        }
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      enableSelectedRows: false,
      sortingColumns:'blNo',
      sortingDirection: 'ASC',
      editable : true,
      height:500,
      minHeight: 500,
      autoSize:'auto'
    }

    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : false,
    };

    this.settingGridPDFPrev = {
      id : 'gridPDFPrev',
      url:'',//this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: '10',
      columns: [
        {header: 'B/L No', field: 'blNo', width: 200, editType: 'text'},
      ],
      buttons: [
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'blNo',
      sortingDirection: 'ASC',
      editable : false,
      height:500,
      minHeight: 500,
      autoSize:'auto'
    }
  }

  ngOnInit() {
  }

  cekLabel(){
    this.labelMenu = '';
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("Re-Print")){
      this.labelMenu ='Re-Print B/L Original';   
    }else{
      this.labelMenu ='SSS B/L Original Print';   
    }

    return this.labelMenu;

  }

  ngAfterViewInit() {
    this.officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
    this.officeId = (this.cookieService.getDefaultLocationId() == null) ?'*': this.cookieService.getDefaultLocationId();
    this.jasper = new jasperModel(this.genericService, this.configService);

     
    this.afterViewMode();
    console.log ("Nilai is cont own --> "+this.IsContOwn);
    this.cdr.detectChanges();    
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    this.showReprint();
    this.printOnlyOnceList = ["MY","IN","ID","SG"];
    if(this.officeCode == 'SGSIN'){
      this.grid.setHiddenColumn(1,false);
    }else{
      this.grid.setHiddenColumn(1,true);
    }
    $('.menu .item').tab();

    //this.jasper.getJasperPath(this.officeCode,"BLOriginalNS");
    //this.getJasperPath();
  }

  getJasperPath(){
    var hitUrl = this.configService.config.BASE_API.toString() + "/MasterJasperReport/getPath/"+this.officeCode+"/BLOriginalNS";
    this.genericService.GET(hitUrl).subscribe((resp)=>{
      if(resp.json()["message"] == "ok"){
        var rList = resp.json()["data"];

        rList.forEach(rL=>{
          this.jasperPath.push(rL);
        });

        console.log(this.jasperPath);
      }
    });
  }

  toolbarEvent(event) {
    //console.log('event : ' + event);
    switch (event) {     
      case 'retrieve' :
        break;
      case 'cancel' :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case 'ok' :
      this. printFrom = 'ok1';
        if(this.grid.listStore.store.length > 0){
          if(this.isSilent){
            this.loading = true;
            var hitUrl = this.configService.config.BASE_API.toString()  + "/ContainerLoadList/UserSpooler/check";
            var pData = {};
            pData["userEmail"] = this.userEmail;
            pData["userLocationId"] = this.officeId;
            pData["printerList"] = [
              "ORIGINALBL",
              "DRAFTBL"
            ]

            this.genericService.POST(hitUrl, pData).subscribe((resp)=>{
              if(resp.ok){
                if(resp.json()["message"] == "ok"){
                  this.printTheBL();
                  //this.printBLOriginalReport();
                } else {
                  this.loading = false;
                  this.message("information","Information","Please Ask Administrator to Register Printer Spooler For Your User Id","okonly",{ok:""});
                }
              }
            }, error=>{
              this.loading = false;
              this.message("information","Information","Error ocurred when trying to print the document, please contact your administrator!","okonly",{ok:""});
            });

          } else {
            this.printTheBL();
            //this.printBLOriginalReport();
          }
          //this.printBLOriginalReport();
        } else {
          if(this.isSelection == "BL"){
            this.message("information","Information","Please insert bl to print","okonly",{ok:""});
          } else {
            this.message("information","Information","Please retrieve the data","okonly",{ok:""});
          }
        }
        //console.log(this.grid.listStore.store)
        //this.printBLOriginalReport();
        break;
        case 'ok2' :
        this. printFrom = 'ok2';
        if(this.grid.listStore.store.length > 0){
          if(this.isSilent){
            this.loading = true;
            var hitUrl = this.configService.config.BASE_API.toString()  + "/ContainerLoadList/UserSpooler/check";
            var pData = {};
            pData["userEmail"] = this.userEmail;
            pData["userLocationId"] = this.officeId;
            pData["printerList"] = [
              "ORIGINALBL",
              "DRAFTBL"
            ]

            this.genericService.POST(hitUrl, pData).subscribe((resp)=>{
              if(resp.ok){
                if(resp.json()["message"] == "ok"){
                  this.printTheBL();
                  //this.printBLOriginalReport();
                } else {
                  this.loading = false;
                  this.message("information","Information","Please Ask Administrator to Register Printer Spooler For Your User Id","okonly",{ok:""});
                }
              }
            }, error=>{
              this.loading = false;
              this.message("information","Information","Error ocurred when trying to print the document, please contact your administrator!","okonly",{ok:""});
            });

          } else {
            this.printTheBL();
            //this.printBLOriginalReport();
          }
          //this.printBLOriginalReport();
        } else {
          if(this.isSelection == "BL"){
            this.message("information","Information","Please insert bl to print","okonly",{ok:""});
          } else {
            this.message("information","Information","Please retrieve the data","okonly",{ok:""});
          }
        }
        //console.log(this.grid.listStore.store)
        //this.printBLOriginalReport();
        break;
        case 'reprint':
        if(this.grid.listStore.store.length > 0){
          if(this.isSilent){
            this.loading = true;
            var hitUrl = this.configService.config.BASE_API.toString()  + "/ContainerLoadList/UserSpooler/check";
            var pData = {};
            pData["userEmail"] = this.userEmail;
            pData["userLocationId"] = this.officeId;
            pData["printerList"] = [
              "ORIGINALBL",
              "DRAFTBL"
            ]
  
  
            this.genericService.POST(hitUrl, pData).subscribe((resp)=>{
              if(resp.ok){
                if(resp.json()["message"] == "ok"){
                  this.reprintTheBL();
                  //this.printBLOriginalReport();
                } else {
                  this.loading = false;
                  this.message("information","Information","Please Ask Administrator to Register Printer Spooler For Your User Id","okonly",{ok:""});
                }
              }
            }, error=>{
              this.loading = false;
              this.message("information","Information","Test!","okonly",{ok:""});
            });
          } else {
            this.reprintTheBL();
          }
        } else {
          if(this.isSelection == "BL"){
            this.message("information","Information","Please insert bl to print","okonly",{ok:""});
          } else {
            this.message("information","Information","Please retrieve the data","okonly",{ok:""});
          }
        }
        break;
    }
  }

  printTheBL(){
    this.loading = true;
    this.blOkToPrint = [];
    var uriUpdateGrid = this.configService.config.BASE_API.toString() + "/BLReport/checkSSSBLNo";
    var blnos = [];
    var popIsEmpty:boolean = false;
    var popEmptyList = [];
    this.grid.listStore.store.forEach(fe => {
      if(fe.select == 'checked'){
        blnos.push(fe.blNo.toUpperCase().trim());
        if(this.isSurcharge == "Y"){
          if(fe.locationName != undefined){
            if(fe.locationName != ""){
              this.payableAt =fe.locationName.toUpperCase().trim();
            } else {
              popIsEmpty = true;
              popEmptyList.push(fe.blNo.toUpperCase().trim());
            }
          } else {
            popIsEmpty = true;
            popEmptyList.push(fe.blNo.toUpperCase().trim());
          } 
        } else {
          this.payableAt = "";
        }        
      }
    });
    var postMap = {};
    postMap["officeCode"] = this.officeCode;
    postMap["blNos"] = blnos;

    if(postMap["blNos"] != ''){
      if(this.isSurcharge == "Y"){
        if(popIsEmpty && this.officeCode.startsWith("SG")){
          this.loading = false;
          var blList = "";
          popEmptyList.forEach(fe=>{
            blList = blList + fe + ",";
          });
          blList = blList.substring(0, blList.length-1);
          this.message("information","Information","Please insert place of payment for BL No :<br>"+blList,"okonly",{ok:""});
        } else {
          this.genericService.POST(uriUpdateGrid, postMap).subscribe((resp)=>{
            if(resp.ok){
              var hitRes = resp.json()["content"];
              var hitRes2 = resp.json()["content-OBL"];
              var hitRes3 = resp.json()["contentPrint"];
              var hitRes4 = resp.json()["contentPrintBy"];
              var bLAlreadyPrinted = [];          
              
              
              //console.log("nojan effect");
              //console.log(hitRes);        
              this.grid.listStore.store.forEach(fe => {
                if(fe.select == 'checked'){
                  fe.hasAttachment = hitRes[fe.blNo.toUpperCase().trim()];
                  fe["blNoOfObl"] = hitRes2[fe.blNo.toUpperCase().trim()];
                  fe["isPrinted"] = hitRes3[fe.blNo.toUpperCase().trim()];
  
                  if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
                    if(hitRes3[fe.blNo.toUpperCase().trim()] == "Y"){
                      bLAlreadyPrinted.push(fe.blNo.toUpperCase().trim());
                    } else {
                      this.blOkToPrint.push(fe);
                    }
                  } else {
                    this.blOkToPrint.push(fe);
                  }
                }
              });
  
              //console.log("print the BL")  ;
              //this.printBLOriginalReport();
              if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
                var alrPrintedMsg = "";
                if(bLAlreadyPrinted.length > 0){
                  bLAlreadyPrinted.forEach(fe=>{
                    alrPrintedMsg = alrPrintedMsg + fe + ",";
                  });
                  alrPrintedMsg = alrPrintedMsg.substring(0, alrPrintedMsg.length-1);
                }
  
                if(this.blOkToPrint.length > 0){
                  if(bLAlreadyPrinted.length > 0){
                    this.message("information","Information","Below BL has been printed. This BL will not be printed.<br>"+alrPrintedMsg+"<br><br> continue to Print the rest of BL?","yesno",{yes:"this.printBLOriginalReport();",no:""});
                    this.loading = false;
                  } else {
                    this.printBLOriginalReport();
                  }
                } else {
                  if(bLAlreadyPrinted.length > 0){
                    if(bLAlreadyPrinted.length == 1){
                      this.message("information","Information","BL has been printed by "+hitRes4[alrPrintedMsg].toUpperCase()+".","okonly",{ok:""});
                    } else {
                      this.message("infomation","Information","BL(s) have been printed"+".","okonly",{ok:""});
                    }
                  }
                  this.loading = false;
                }
              } else {
                console.log("print the BL")  ;
                this.printBLOriginalReport();
              }
  
  
              /*this.grid.listStore.store.forEach(ele=>{
                if(this.officeCode.startsWith("MY")){
                  if(ele["isPrinted"] != "Y"){
                    console.log("print the bl");
                    this.printBLOriginalReport();
                  }else{
                    this.loading = false;
                    this.message("information","Information","BL already printed!","okonly",{ok:""});                  
                  }
                }else{
                  console.log("print the bl");
                  this.printBLOriginalReport();
                }
              })*/
            }
          }, error=>{
            this.loading = false;
            this.message("information","Information","Error ocurred when trying to print the document, please contact your administrator!","okonly",{ok:""});
          });
        }
      } else {
        this.genericService.POST(uriUpdateGrid, postMap).subscribe((resp)=>{
          if(resp.ok){
            var hitRes = resp.json()["content"];
            var hitRes2 = resp.json()["content-OBL"];
            var hitRes3 = resp.json()["contentPrint"];
            var hitRes4 = resp.json()["contentPrintBy"];
            var bLAlreadyPrinted = [];          
            
            //console.log("nojan effect");
            //console.log(hitRes);        
            this.grid.listStore.store.forEach(fe => {
              if(fe.select == 'checked'){
                fe.hasAttachment = hitRes[fe.blNo.toUpperCase().trim()];
                fe["blNoOfObl"] = hitRes2[fe.blNo.toUpperCase().trim()];
                fe["isPrinted"] = hitRes3[fe.blNo.toUpperCase().trim()];

                if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
                  if(hitRes3[fe.blNo.toUpperCase().trim()] == "Y"){
                    bLAlreadyPrinted.push(fe.blNo.toUpperCase().trim());
                  } else {
                    this.blOkToPrint.push(fe);
                  }
                } else {
                  this.blOkToPrint.push(fe);
                }
              }
            });

            //console.log("print the BL")  ;
            //this.printBLOriginalReport();
            if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
              var alrPrintedMsg = "";
              if(bLAlreadyPrinted.length > 0){
                bLAlreadyPrinted.forEach(fe=>{
                  alrPrintedMsg = alrPrintedMsg + fe + ",";
                });
                alrPrintedMsg = alrPrintedMsg.substring(0, alrPrintedMsg.length-1);
              }

              if(this.blOkToPrint.length > 0){
                if(bLAlreadyPrinted.length > 0){
                  this.message("information","Information","Below BL has been printed. This BL will not be printed.<br>"+alrPrintedMsg+"<br><br> continue to Print the rest of BL?","yesno",{yes:"this.printBLOriginalReport();",no:""});
                  this.loading = false;
                } else {
                  this.printBLOriginalReport();
                }
              } else {
                if(bLAlreadyPrinted.length > 0){
                  if(bLAlreadyPrinted.length == 1){
                    this.message("information","Information","BL has been printed by "+hitRes4[alrPrintedMsg].toUpperCase()+".","okonly",{ok:""});
                  } else {
                    this.message("infomation","Information","BL(s) have been printed"+".","okonly",{ok:""});
                  }
                }
                this.loading = false;
              }
            } else {
              console.log("print the BL")  ;
              this.printBLOriginalReport();
            }


            /*this.grid.listStore.store.forEach(ele=>{
              if(this.officeCode.startsWith("MY")){
                if(ele["isPrinted"] != "Y"){
                  console.log("print the bl");
                  this.printBLOriginalReport();
                }else{
                  this.loading = false;
                  this.message("information","Information","BL already printed!","okonly",{ok:""});                  
                }
              }else{
                console.log("print the bl");
                this.printBLOriginalReport();
              }
            })*/
          }
        }, error=>{
          this.loading = false;
          this.message("information","Information","Error ocurred when trying to print the document, please contact your administrator!","okonly",{ok:""});
        });
      }
    }else{
      this.loading = false;
      this.message("information","Information","Please select bl to print","okonly",{ok:""});
    }
  }



  eventMessage(event){
    if(event != ''){
      eval(event);
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

 setValidatorVessel(){
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      blOceanVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      blOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
    }
  }

  setValidatorLoadPort(){
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      blOceanVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      blOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
      blLoadPortCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Load Port.'
        }]
      },
    }
  }
  setValidatorDischargePort(){
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      blOceanVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      blOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
      blDischargePortCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Discharge Port.'
        }]
      },
    }
  }
  setValidatorPOR(){
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      blOceanVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      blOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
      blPlaceOfReceiptCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Place Of Receipt Code.'
        }]
      },
    }
  }
  setValidatorPOD(){
    this.clearValidatorError();
    this.validatorRules = {
      blOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      blOceanVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      blOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
      blPlaceOfDeliveryCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Place of Delivery Code.'
        }]
      },
    }
  }

  noNegativeWithoutDecimal(event){
    // console.log("fired from no Negative => " + event.keyCode + " --- " + event.target.value);

    if(event.keyCode < 49 || event.keyCode > 57 || Number.parseInt(event.target.value) < 0){
      event.preventDefault();
    }
  }

  TempOnly(event:any){
    var regexPattern = new RegExp("[0-9+-.]");
    if(regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }


  changeEventVessel(event){
    console.log("EVENTnya :"+event.vesselCode);
      if(event.vesselId == null || event.vesselId == "" || event.vesselId == undefined){
        console.log("Vessel kosong");
        this.model.blOceanVesselId = '';
        this.model.blOceanVesselCode = '';

      }else{
        this.model.blOceanVesselId = event.vesselId;
        this.model.blOceanVesselCode = event.vesselCode;

        this.cbVoyage.disableCombo = false;
        //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
        this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + event.vesselId + "/" + this.model.blOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
        this.cbVoyage.setValue("");
      }

      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
  }

  changeEventReceipt(event){
    if(event.receiptTypeCode == null || event.receiptTypeCode == "" || event.receiptTypeCode == undefined){
      this.model.blReceiptTypeCode = '';

    }else{
      this.model.blReceiptTypeCode = event.receiptTypeCode;
    }

  }

  changeExch(event){

    if(event.target.value == "WithoutExch"){
      this.isExch= "WithoutExch";

    } else if(event.target.value == "WithExch"){
       this.isExch= "WithExch";
    }

  }

  changeEventDelivery(event){
    if(event.deliveryTypeCode == null || event.deliveryTypeCode == "" || event.deliveryTypeCode == undefined){
      this.model.blDeliveryTypeCode = '';

    }else{
      this.model.blDeliveryTypeCode = event.deliveryTypeCode;
    }
  }


  blMode(){
    this.setBL();
    this.isSelection = "BL";
    this.addRemoveButton="block";
    this.grid.onClear();
  }
  vesselMode(){
    console.log('event vessel : ' + event);
    this.isSelection = "Vessel";
    this.addRemoveButton="none";
    this.lockButtonRetrieve = false;
    this.disableLoadPort();
    this.disableDiscPort();
    this.disablePOD();
    this.disablePOR();
    this.grid.onClear();
    this.lock=true;
    this.lockHeader = true;
    this.clearValidatorError();
  }

  changeSelection(event){

    if(event.target.value == "Vessel"){
      this.vesselMode();
   } else if( event.target.value == "BL"){
       this.blMode();
   } else if( event.target.value == "LP"){
       this.isSelection = "LP";
       this.addRemoveButton="none";
       this.lockButtonRetrieve = false;
       this.cbLoadPort.disableCombo = false;
       this.disableDiscPort();
       this.disablePOD();
       this.disablePOR();
       this.grid.onClear();
       this.lock=true;
       this.lockHeader = true;
   } else if( event.target.value == "DP"){
       this.clearValidatorError();
       this.isSelection = "DP";
       this.addRemoveButton="none";
       this.lockButtonRetrieve = false;
       this.disableLoadPort();
       this.cbDischargePort.disableCombo = false;
       this.disablePOD();
       this.disablePOR();
       this.grid.onClear();
       this.lock=true;
       this.lockHeader = true;
   } else if( event.target.value == "POD"){
       this.clearValidatorError();
       this.isSelection = "POD";
       this.addRemoveButton="none";
       this.lockButtonRetrieve = false;
       this.disableLoadPort();
       this.disableDiscPort();
       this.disablePOR();
       this.cbPOD.disableCombo = false;
       this.grid.onClear();
       this.lock=true;
       this.lockHeader = true;
   } else if( event.target.value == "POR"){
       this.clearValidatorError();
       this.isSelection = "POR";
       this.addRemoveButton="none";
       this.lockButtonRetrieve = false;
       this.disableLoadPort();
       this.disableDiscPort();
       this.disablePOD();
       this.cbPOR.disableCombo = false;
       this.grid.onClear();
       this.lock=true;
       this.lockHeader = true;
   }
}

  changePrint(event){

      if(event.target.value == "Without"){
        this.isPrint= "Without";
        this.isSurcharge = 'N';

      } else if(event.target.value == "With"){
         this.isPrint= "With";
         this.isSurcharge = 'Y';
      }
  }

  setBL(){
    this.clearValidatorError();
    this.isSelection = "BL";
    this.addRemoveButton="block";
    this.lockButtonRetrieve = true;
    this.disableLoadPort();
    this.disableDiscPort();
    this.disablePOD();
    this.disablePOR();
    this.grid.editable=true;
    this.cbVessel.clearSelect;
    this.cbVessel.setValue("");
    this.cbVoyage.clearSelect;
    this.cbVoyage.setValue("");
    this.model.blOceanVesselBound ="O";
    this.lock = true;
    this.lockHeader = false;

  }


  disableLoadPort(){
    this.cbLoadPort.disableCombo = true;
    this.cbLoadPort.clearSelect;
    this.cbLoadPort.setValue("");
  }

  disableDiscPort(){
    this.cbDischargePort.disableCombo = true;
    this.cbDischargePort.clearSelect;
    this.cbDischargePort.setValue("");
  }

  disablePOR(){
    this.cbPOR.disableCombo = true;
    this.cbPOR.clearSelect;
    this.cbPOR.setValue("");
  }

   disablePOD(){
    this.cbPOD.disableCombo = true;
    this.cbPOD.clearSelect;
    this.cbPOD.setValue("");
  }

  onRetrieve(){
    console.log("tekan retrieve : "+this.configService.config.BASE_API.toString());
    this.IsContOwn = this.cCOC+"-"+this.cSOC+"-"+this.cCOCTs+"-"+this.cSOCTs+"-"+this.cSlot;

    if (this.isSelection == "Vessel"){
      this.setValidatorVessel();
      this.isError = this.onValidate(this.model);
      console.log("VALIDASINYA -- > "+this.onValidate(this.model));
      if(!this.isError){

        console.log("VALIDASINYA 2 -- > "+this.onValidate(this.model));
        this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        this.grid.search = this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"==Y";
        //this.grid.loadData();

        /*
        this.genericService.GET(this.configService.config.BASE_API.toString() + "/BLReport/findBLByVessel/"+ this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=").subscribe((resp) =>{

          let dArr =[];
          dArr= resp.json()['content'];
          let lengthArray : Number=0;
          lengthArray = dArr.length;

          console.log("RESP NYA : "+resp.json().length+" >>> "+dArr.length);
          if(lengthArray > 0){
            this.grid.listStore.store = resp.json()['content'];
            this.grid.loadData();
          }else{
            this.grid.listStore.store=[];
            this.grid.loadData();
            this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=false;'});
          }
        })
        */

      }
    }else if(this.isSelection == "LP"){
      this.setValidatorVessel();
      this.setValidatorLoadPort();

      this.isError = this.onValidate(this.model);
      if(!this.isError){
        this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
        this.grid.search = this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=POL-"+this.model.blLoadPortCode+"=Y";
        //this.grid.loadData();
      }
    }else if(this.isSelection == "DP"){
        this.setValidatorVessel();
        this.setValidatorDischargePort();
        this.isError = this.onValidate(this.model);
          if(!this.isError){
            this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
            this.grid.search =  this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=POD-"+this.model.blDischargePortCode+"=Y";
            //this.grid.loadData();
          }
    }else if(this.isSelection == "POR"){
        this.setValidatorVessel();
        this.setValidatorPOR();
        this.isError = this.onValidate(this.model);
          if(!this.isError){
            this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
            this.grid.search =  this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=POR-"+this.model.blPlaceOfReceiptCode+"=Y";
            //this.grid.loadData();
          }
    }else if(this.isSelection == "POD"){

        this.setValidatorVessel();
        this.setValidatorPOD();
        this.isError = this.onValidate(this.model);
          if(!this.isError){
            this.grid.url = this.configService.config.BASE_API.toString() + "/BLReport/findBLByVesselNoPaging";
            this.grid.search =  this.officeCode +"="+this.model.blOceanVesselId+"="+this.model.blOceanVesselVoyage+"="+this.model.blOceanVesselBound+"="+this.isSort+"="+this.IsContOwn+"="+this.model.blDeliveryTypeCode+"="+this.model.blReceiptTypeCode+"=PD-"+this.model.blPlaceOfDeliveryCode+"=Y";
            //this.grid.loadData();
          }
    }

    if(this.reprintMode){
      this.grid.search = this.grid.search + "=RM";      
    } else {
      this.grid.search = this.grid.search + "=";      
    }

    this.grid.loadData();

  }

  changeWeight(event){

      if(event.target.value == "KG"){
        this.isWeight= "KG";

      } else if(event.target.value == "MT"){
         this.isWeight= "MT";
      }
  }

  changePackage(event){

     if(event.target.value == "Cargo"){
        this.isPackage= "Cargo";

      } else if(event.target.value == "Cont"){
         this.isPackage= "Cont";
      }
  }

  changeEventLoadPort(event){

    console.log('event Load Port : ' + event);


    if(event.locationCode == null || event.locationCode == "" || event.locationCode == undefined){
        this.model.blLoadPortCode = '';

    }else{
       this.model.blLoadPortCode = event.locationCode;
    }
  }


  changeEventDischargePort(event){
    if(event.locationCode == null || event.locationCode == "" || event.locationCode == undefined){
        this.model.blDischargePortCode = '';

    }else{
       this.model.blDischargePortCode = event.locationCode;
    }
  }
  changeEventPOR(event){
    if(event.locationCode == null || event.locationCode == "" || event.locationCode == undefined){
        this.model.blPlaceOfReceiptCode = '';

    }else{
       this.model.blPlaceOfReceiptCode = event.locationCode;
    }
  }
  changeEventPOD(event){
    if(event.locationCode == null || event.locationCode == "" || event.locationCode == undefined){
        this.model.blPlaceOfDeliveryCode = '';

    }else{
       this.model.blPlaceOfDeliveryCode = event.locationCode;
    }
  }

  changeEventVoyage(event){
    if(event.voyage == null || event.voyage == "" || event.voyage == undefined){
        this.model.blOceanVesselVoyage = '';

      }else{
       this.model.blOceanVesselVoyage = event.voyage;
      }
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
    testObject = {};

inputArray.map(function(item) {

  var itemPropertyName = (item[propertyName].trim()).toUpperCase();

  if(item[propertyName]!=''){
    if (itemPropertyName in testObject) {
      testObject[itemPropertyName].duplicate = true;
      item.duplicate = true;
      seenDuplicate = true;
    }
    else {
      testObject[itemPropertyName] = item;
      delete item.duplicate;
    }
  }
  });

  return seenDuplicate;
}



  gridEvent(event){
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log('eventgrid'+event);
    switch (event.split(".")[0]) {
      case 'afterLoad':      
      /*this.grid.onSelectAllByValue(true);
      this.i =1;  
      this.grid.listStore.store.forEach(yr=>{
        var xx :any[] = [];     
        
        
        yr['pop'].forEach(dt => {
          console.log("CEK LOAD 1: "+ dt.blFcPop);
          this.blFcPop = dt.blFcPop;
          this.locationName = dt.locationName;
         
          var md = new SelectModel()
          md['code'] = this.blFcPop;
          md['name'] = this.locationName;
          xx.push(md);                      
        });
                        
        
                    
        this.grid.setDsCombo(xx,this.i,"locationName");
        this.i++;
        
      });  */
      break;
      case 'selected':

        this.grid.getSelectedValues().forEach(element => {
          if(element.select == 'checked'){
            flgTool = 1;
          }
        });
        if(flgTool > 0)
          this.disableToolbarButtons = "";
          //console.log(flgTool);
        else
         // this.disableToolbarButtons = "unlock";
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case 'click' :
        this.grid.listStore.store.forEach(yr=>{
          console.log("KLIK GRID "+yr);
          if(yr.select == 'checked'){
            flgTool = 1;
          }
        });

        if(flgTool > 0)
        console.log(flgTool)
         // this.disableToolbarButtons = "retrieve";
        else
          //this.disableToolbarButtons = "retrieve,unlock";
        break;

        case 'afterLoad' :
        this.lengthArray = this.grid.listStore.store.length;

        /*if(this.lengthArray == 0){
          console.log("LENGTH GRID --> "+this.grid.listStore.store.length);
          this.grid.listStore.store=[];
          this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=true;'});
        }*/


        break;
      default:


        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split(';');
          console.log("before split4 -> " + strEvent);
          console.log("arr3 -> " + arr);
          console.log("arr-split1 -> " + arr[0]);
          console.log("arr-split2 -> " + arr[1]);
          switch(arr[0].split('.')[0] ){
            case 'AfterEdit':
              if(strEvent.split(';')[1]=='blNo'){
                this.getBLStatus2(strEvent.split(';')[2],Number(strEvent.split(';')[0].split(".")[1]));
              }


              this.grid.listStore.store.forEach(yr=>{
                console.log(yr);

                if(yr.select == 'checked'){
                  flgTool = 1;
                  this.model.blNo = (yr['blNo'].trim()).toUpperCase();
                  this.model.blOfficeCode = this.officeCode;
                }else{
                  this.model.blNo = '';
                  this.model.blOfficeCode ='';
                }
                soso.push(yr);

              });


              if(flgTool > 0)
              //this.disableToolbarButtons = "retrieve";
                console.log(flgTool)
              else
                this.disableToolbarButtons = "unlock";
                dblFlag = this.checkDuplicateInObject('blNo',soso);
              if(dblFlag == true){
                this.message('information','Information','Duplicate BL Reference(s) detected.','okonly',{ok: 'this.loading=false;'});
                this.grid.listStore.store.splice(parseInt(arr[0].split('.')[1])-1,1);
                this.grid.loadData();
              }



              break;
            case 'dblClick':
              // this.modeForm == 'sailingScheduleMaintenance';
              //   this.handleDoubleClickedSailingSchedule();
              break;
            default:
              break;
          }

        }

        break;

    }
  }

  infoGrid(event){}
  changeSortBy(event){}


  changeSort(event){
     if (event.target.value == "LP") {
       this.isSort="LP";

       console.log('event Sort Load Port : ' + this.isSort);
     }else  if (event.target.value == "DP") {
       this.isSort="DP";
       console.log('event Sort Load Port : ' + this.isSort);
     }else  if (event.target.value == "POD") {
       this.isSort="POD";
       console.log('event Sort Load Port : ' + this.isSort);
     }else  if (event.target.value == "POR") {
       this.isSort="POR";
       console.log('event Sort Load Port : ' + this.isSort);
     }else  if (event.target.value == "BL") {
       this.isSort="BL";
       console.log('event Sort Load Port : ' + this.isSort);
     }
  }


  changeContainerCOC(event){
     if(event.target.checked){
      this.isCOC = true;
      this.cCOC = "COC";
    }else{
      this.isCOC = false;
      this.cCOC='';
    }
  }

  changeContainerSOC(event){
     if(event.target.checked){
      this.isSOC = true;
      this.cSOC ="SOC";
    }else{
      this.isSOC = false;
      this.cSOC = '';
    }
  }

  changeContainerCOCTS(event){
     if(event.target.checked){
      this.isCOCTS = true;
      this.cCOCTs = "COCTS";
    }else{
      this.isCOCTS = false;
      this.cCOCTs = '';
    }
  }

  changeContainerSOCTS(event){
     if(event.target.checked){
      this.isSOCTS = true;
      this.cSOCTs = "SOCTS";

    }else{
      this.isSOCTS = false;
      this.cSOCTs ='';
    }
  }

  changeContainerSLOT(event){
     if(event.target.checked){
      this.isSLOT = true;
      this.cSlot = "SLOT";
    }else{
      this.isSLOT = false;
      this.cSlot = '';
    }
  }

  changeNonNego(event){
     if(event.target.checked){
      this.isNonNego = true;
    }else{
      this.isNonNego = false;
    }
  }

  changeSortPrint(event){
     if(event.target.checked){
      this.isSortPrint = true;
    }else{
      this.isSortPrint = false;
    }
  }

  afterViewMode(){
    this.userId = this.cookieService.getName();
    this.userEmail = localStorage.getItem("logged").split("|")[1];
    this.userIdCode = localStorage.getItem("logged").split("|")[3];
    this.lockHeader = false;
    this.lock = true;
    this.model.blOceanVesselBound ='O';
    this.isSort = 'LP';
    this.isSelection='BL';
    this.addRemoveButton="block";
    //this.isPrint = 'Without';
    if(this.officeCode.toUpperCase().startsWith("TH")){
      this.isPrint = 'With';
      this.isSurcharge = 'Y';
    } else if(this.officeCode.toUpperCase() == "MYPEN"){
      this.disableNN = true;
      this.isNonNego = false;
    } else {
      this.isPrint = 'Without';
      this.isSurcharge = 'N';
    }
    this.isExch = 'WithExch';
    //this.isSurcharge = 'N';
    this.isWeight = 'KG';
    this.isPackage = 'Cont';
    this.cbDelivery.setValue('CY');
    this.cbReceipt.setValue('CY');
    this.cCOC = 'COC';
    this.cSOC = 'SOC';
    this.cCOCTs = 'COCTS';
    this.cSOCTs = 'SOCTS';
    this.cSlot = 'SLOT';
    this.IsContOwn = this.cCOC+"-"+this.cSOC+"-"+this.cCOCTs+"-"+this.cSOCTs+"-"+this.cSlot;

    // if(this.officeCode.toUpperCase().startsWith("ID")){
    //   if(this.officeCode.toUpperCase() != "IDJKT"){
    //     this.isIna = true;
    //   }
    // }

  }

  getBLStatus(blNo:String){
    var BLPrint: String;
    var BLFinish: String;
    var hasAttch: boolean;
    var boxCheck : number;
    var blPOL: String;
    if(blNo.trim() == '' || blNo.trim() == undefined){
      blNo = "blNo";
    }

    this.genericService.GET(this.configService.config.BASE_API.toString() + "/BLReport/findSSSBLNo/"+blNo.toUpperCase()).subscribe((resp) => {
      console.log("Masuk getBLStatus");
      if(resp.ok){
        if(Object.keys(resp.json()['content']).length > 0){
          BLPrint = resp.json()['content'][0].blIsBlPrinted;
          hasAttch = resp.json()['content'][0].hasAttachment;
          blPOL = resp.json()['content'][0].blPOL;
          BLFinish = resp.json()['content'][0].blIsFinished;
            console.info('FINISH NYEt 1: '+BLFinish);

          console.log("Status BL --> "+resp.json()['content'][0].blStatus);
          switch(resp.json()['content'][0].blStatus){
            case 'D':

              console.log("bl delete");
              this.grid.listStore.store.forEach(data=>{
                if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                  this.message('information','Information','This BL has been deleted.','okonly',{ok: 'this.loading=false;'});

                   boxCheck = this.grid.listStore.store.indexOf(data);
                   console.log("BOXCEK --> "+boxCheck);
                   this.grid.listStore.store.splice(boxCheck,1);
                   this.grid.loadData();

                }
              });
            break;

            default:
            console.info('FINISH NYEt 2: '+BLFinish);

            BLFinish = resp.json()['content'][0].blIsFinished;

            this.grid.listStore.store.forEach(data=>{
              if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                data["hasAttachment"] = hasAttch;
                data["blPOL"] = blPOL;
                data.select = 'checked';
                var xx :any[] = [];  
                let pop: any[] = resp.json()['content'][0]["pop"];
                
                if(pop != undefined){
                  pop.forEach(dt => {
                    this.blFcPop = dt.blFcPop;
                    this.locationName = dt.locationName;
                    var md = new SelectModel()
                    md['code'] = this.blFcPop;
                    md['name'] = this.locationName;
                    xx.push(md);                      
                  });
                }else{
                  var md = new SelectModel()
                  md['code'] = "";
                  md['name'] = "";
                  xx.push(md);
                }
                  
                  this.grid.setDsCombo(xx,this.i,"locationName");
                  this.i++;
                
                BLFinish = resp.json()['content'][0].blIsFinished;
            
                
                if(BLFinish == "N"){
                  this.message('information','Information','BL has not been finished.','okonly',{ok: 'this.loading=false;'});

                  boxCheck = this.grid.listStore.store.indexOf(data);
                  console.log("BOXCEK --> "+boxCheck);
                  this.grid.listStore.store.splice(boxCheck,1);
                  this.grid.loadData();
                }

              }


            });

            /*if(BLPrint != 'Y'){
              console.log("bisa di print");
              this.grid.listStore.store.forEach(data=>{
                if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                  data["hasAttachment"] = hasAttch;
                }
              });
            }else{
              console.log("Maaf udah pernah di print");
              this.grid.listStore.store.forEach(data=>{
                if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                  this.message('information','Information','This BL already printed.','okonly',{ok: 'this.loading=false;'});

                   boxCheck = this.grid.listStore.store.indexOf(data);
                   console.log("BOXCEK --> "+boxCheck);
                   this.grid.listStore.store.splice(boxCheck,1);

                }
              });
            }*/

            break;

          }
        }else{
          //console.log("NETI: "+this.grid.listStore.store.length);
          if(this.grid.listStore.store.length > 0){
            //console.log("satu: "+Object.keys(resp.json()['content']).length );

            this.grid.listStore.store.forEach(data=>{
              if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                this.message('information','Information','BL Not Found.','okonly',{ok: 'this.loading=false;'});

                boxCheck = this.grid.listStore.store.indexOf(data);
                console.log("BOXCEK --> "+boxCheck);
                this.grid.listStore.store.splice(boxCheck,1);
                this.grid.loadData();


              }
            });

            /*if(Object.keys(resp.json()['content']).length == 0){
              this.message('information','Information','BL Not Found.','okonly',{ok: 'this.loading=false;'});
              this.grid.onClear();
              this.grid.loadData();
            }*/

          }
        }
      }
    })
  }


  getBLStatus2(blNo:String, idx:number){
    var BLPrint: String;
    var hasAttch: boolean;
    var boxCheck : number;
    var xx : any[];
    var blPOL: String;    
    var BLFinish: String;
    
    if(blNo.trim() == '' || blNo.trim() == undefined){
      blNo = "blNo";
    }

    this.genericService.GET(this.configService.config.BASE_API.toString() + "/BLReport/findSSSBlNo/"+blNo.toUpperCase()).subscribe((resp) => {
      console.log("Masuk getBLStatus");
      if(resp.ok){
        if(Object.keys(resp.json()['content']).length > 0){
          BLPrint = resp.json()['content'][0].blIsBlPrinted;
          hasAttch = resp.json()['content'][0].hasAttachment;
          blPOL = resp.json()['content'][0].blPOL;
                   
          console.log("Status BL --> "+resp.json()['content'][0].blStatus);
          switch(resp.json()['content'][0].blStatus){
            case 'D':
              console.log("bl delete");
              this.grid.listStore.store.forEach(data=>{
                if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                  this.message('information','Information','This BL has been deleted.','okonly',{ok: 'this.loading=false;'});

                   boxCheck = this.grid.listStore.store.indexOf(data);
                   console.log("BOXCEK --> "+boxCheck);
                   this.grid.listStore.store.splice(boxCheck,1);
                   this.grid.loadData();

                }
              });
              break;
            default:          

            
              /*
              this.i=1;
              this.grid.listStore.store.forEach(data=>{
               
                if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                  console.log(data);
                  data["hasAttachment"] = hasAttch;
                  data["blPOL"] = blPOL;  
                  data.select = 'checked';
                  var xx :any[] = [];          
                  
                  console.log("NETI: KUKURUYUK2"+this.grid.listStore.store.length);
                  let pop: any[] = resp.json()['content'][0]["pop"];
                
                if(pop != undefined){
                  pop.forEach(dt => {
                    this.blFcPop = dt.blFcPop;
                    this.locationName = dt.locationName;
                    var md = new SelectModel()
                    md['code'] = this.blFcPop;
                    md['name'] = this.locationName;
                    xx.push(md);                      
                  });
                }
                 
                  console.log("this.i == " + this.i)
                  console.log("xx = " + xx)
                  this.grid.setDsCombo(xx,this.i,"locationName");
                  this.i++;
                }
              });
              */

             var xx :any[] = [];     
             let pop: any[] = resp.json()['content'][0]["pop"];
             if(pop != undefined){
                pop.forEach(dt => {
                  this.blFcPop = dt.blFcPop;
                  this.locationName = dt.locationName;
                  var md = new SelectModel()
                  md['code'] = this.blFcPop;
                  md['name'] = this.locationName;
                  xx.push(md);                      
                });
              }else{
                var md = new SelectModel()
                  md['code'] = "";
                  md['name'] = "";
                  xx.push(md);
              }

              this.grid.setDsCombo(xx,idx,"locationName");

              BLFinish = resp.json()['content'][0].blIsFinished;    
              this.grid.listStore.store.forEach(data=>{            
                if(BLFinish == "N"){
                  this.message('information','Information','BL has not been finished.','okonly',{ok: 'this.loading=false;'});
                  boxCheck = this.grid.listStore.store.indexOf(data);
                    console.log("BOXCEK --> "+idx);
                    this.grid.listStore.store.splice(idx-1,1);
                    this.grid.loadData();
                }
              });

              console.log(this.grid.listStore.store)
            break;
          }
        }else{

          if(this.grid.listStore.store.length > 0){
            //console.log("satu: "+Object.keys(resp.json()['content']).length );

            this.grid.listStore.store.forEach(data=>{
              if(data.blNo.toUpperCase() == blNo.toUpperCase()){
                this.message('information','Information','BL Not Found.','okonly',{ok: 'this.loading=false;'});

                boxCheck = this.grid.listStore.store.indexOf(data);
                //xx=data.blFcPop,data.locationName;                
                console.log("BOXCEK --> "+boxCheck);
                this.grid.listStore.store.splice(boxCheck,1);
                
                this.grid.loadData();


              }
            });

            /*if(Object.keys(resp.json()['content']).length == 0){
              this.message('information','Information','BL Not Found.','okonly',{ok: 'this.loading=false;'});
              this.grid.onClear();
              this.grid.loadData();
            }*/

          }
        }
      }
    })
  }
  

  rowEvent(act:string){
    var tableStore = [];
    this.grid.url = '';
     if(act == 'add'){

      console.log(this.grid.listStore.store)
      if(this.grid.listStore.store.length == 0){
        this.grid.listStore.store.forEach(element => {
          tableStore.push(element)
        });
        tableStore.push({id:(tableStore.length+1).toString(), blNo : '',no:(tableStore.length+1), select:''})
        console.log(tableStore);
        this.grid.listStore.store = [];
        tableStore.forEach(element => {
          this.grid.listStore.store.push(element)
        });

        this.grid.loadData(0, $('#grid_dataTables_scrollBody').prop('scrollHeight'));
      }else{
        this.grid.listStore.store.forEach(element => {
          tableStore.push(element)
        });
        console.log("#1")
        console.log(tableStore)
        //tableStore.push({id:(tableStore.length+1).toString(), blNo : '',no:(tableStore.length+1), select:'', columns: this.grid.getColumns()})
        tableStore.push({id:(tableStore.length+1).toString(), blNo : '',no:(tableStore.length+1), select:'', locationName:''})
        
        
        console.log("#2")
        console.log(tableStore);

        console.log("** this.grid.getColumns() **")
        //console.log(this.grid.getColumns());
     

        this.grid.listStore.store = [];
        tableStore.forEach(element => {
          console.log("#3")
          console.log(element)
          
          var bld =  new BLDraft();
          bld.blNo = element['blNo']
          bld.id = element['id']
          bld.no = element['no']
          bld.select = element['select']
          bld.locationName = element['locationName']
          bld.columns = []
          

          if(element['columns'] != undefined){

            for (const key in element['columns']) {
              var col:Kolom = element['columns'][key]
              
              var x = new Kolom();
              x['field'] = col.field;
              x['header'] = col.header;
              x['index'] = col['index'];
              x['width'] = col['width'];
              x['sort'] = col.sort;

              for (const key2 in col.ds) {
                var ds:SelectModel = col.ds[key2]
                var ds2 = new SelectModel();
                ds2.code = ds.code;
                ds2.name = ds.name;
                x.ds.push(ds2)
              }    

              if(col['align'] != undefined){
                x['align'] = col['align'];
              }
        
              if(col['valign'] != undefined){
                x['valign'] = col['valign'];
              }
        
              if(col['hide'] != undefined){
                x['hide'] = col['hide'];
              }
        
              if(col['type'] != undefined){
                x['type'] = col['type'];
              }
        
              if(col['editType'] != undefined){
                x['editType'] = col['editType'];
              }
        
              if(col['maxChars'] != undefined){
                x['maxChars'] = col['maxChars'];
              }
        
              if(col['maxCharsCombo'] != undefined){
                x['maxCharsCombo'] = col['maxCharsCombo'];
              }
        
              if(col['titleCombo'] != undefined){
                x['titleCombo'] = col['titleCombo'];
              }
        
              if(col['codeCombo'] != undefined){
                x['codeCombo'] = col['codeCombo'];
              }
        
              if(col['urlCombo'] != undefined){
                x['urlCombo'] = col['urlCombo'];
              }
        
              if(col['columnsCombo'] != undefined){
                x['columnsCombo'] = col['columnsCombo'];
              }
        
              if(col['calendarType'] != undefined){
                x['calendarType'] = col['calendarType'];
              }
        
              if(col['calendarformat'] != undefined){
                x['calendarformat'] = col['calendarformat'];
              }
        
              if(col['customTimeFormat'] != undefined){
                x['customTimeFormat'] = col['customTimeFormat'];
              }
        
              if(col['labelButton'] != undefined){
                x['labelButton'] = col['labelButton'];
              }
              
              if(col['urlCombo'] != undefined){
                x['urlCombo'] = col['urlCombo'];
              }
                      
              if(col['titleSelect'] != undefined){
                x['titleSelect'] = col['titleSelect'];
              }
        
              if(col['codeSelect'] != undefined){
                x['codeSelect'] = col['codeSelect'];
              }
        
              if(col['disableButton'] != undefined){
                x['disableButton'] = col['disableButton'];
              }

              console.log(x)
              bld.columns.push(x)
              
            }
          }
          //console.log(bld)
          this.grid.listStore.store.push(bld)
        });
        console.log(this.grid.listStore.store)
        this.grid.loadData(0, $('#grid_dataTables_scrollBody').prop('scrollHeight'))
      }

     }else if(act == 'del'){
      console.log("PANJANG GRIDNYA: "+this.grid.onSelect.length);

      console.log("masuk remove");
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck : number;
      var storeTemp = new ListStore;

      this.grid.listStore.store.forEach(xy=>{
        console.log("xy "+xy);
        storeTemp.addData(xy);
      })


      storeTemp.store.forEach(el=>{
        if(el['select'] == 'checked') {
          boxCheck = this.grid.listStore.store.indexOf(el);
          this.grid.listStore.store.splice(boxCheck,1);

          $('input[name="gridCount"]').val(parseInt($('input[name="gridCount"]').val())-1);
        }else{
        }
      });


      this.grid.listStore.store.forEach(col=>{
        seqNo = seqNo + 1;
        col.seqNo=seqNo;
      });

      var yScroll = this.grid.getScrollY();

      this.grid.loadData(0, yScroll);
      this.grid.clearSelectedValues();

     }
  }

   changeBound(event){
    this.model.blOceanVesselBound = event.target.value ;
    this.cbVoyage.disableCombo = false;
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + this.model.blOceanVesselId + "/" + this.model.blOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
    this.cbVoyage.setValue("");

    this.model.blOceanVesselVoyage= '';
  }

  handleCancel(){
    this.clearMode();
  }

  clearValidatorError(){
    this.model['error-blOceanVesselCode'] = '';
    this.model['error-blOceanVesselVoyage'] = '';
    this.model['error-blOceanVesselBound'] = '';
    this.model['error-blLoadPortCode'] = '';
    this.model['error-blPlaceOfDeliveryCode'] = '';
    this.model['error-blPlaceOfReceiptCode']= '';
    this.model['error-blDischargePortCode'] = '';
    this.model['error-blReceiptTypeCode'] = '';
  }

  clearMode(){
    this.cbVessel.setValue ('');
    this.cbVessel.clearSelect();
    this.cbVoyage.setValue('');
    this.cbVoyage.clearSelect();
    this.cbReceipt.setValue('');
    this.cbReceipt.clearSelect();
    this.cbReceipt.setValue("CY");
    this.cbDelivery.setValue('');
    this.cbDelivery.clearSelect();
    this.cbDelivery.setValue("CY");
    this.cbLoadPort.setValue('');
    this.cbLoadPort.clearSelect();
    this.cbDischargePort.setValue('');
    this.cbDischargePort.clearSelect();
    this.cbPOD.setValue('');
    this.cbPOD.clearSelect();
    this.cbPOR.setValue('');
    this.cbPOR.clearSelect();
    this.printCopy=3;
    this.isSilent= false;
    this.isWithTemplate=true;
    this.isSortPrint=false;


    this.isSOC = true;
    this.isSOCTS = true;
    this.isCOC = true;
    this.isCOCTS = true;
    this.isSLOT = true;
    this.isNonNego = true;
    this.cSOC = 'SOC';
    this.cSOCTs = 'SOCTS';
    this.cCOC = 'COC';
    this.cCOCTs = 'COCTS';
    this.cSlot = 'SLOT';


    this.isSort = 'LP';
    this.blMode();
    this.IsContOwn = this.cCOC+"-"+this.cSOC+"-"+this.cCOCTs+"-"+this.cSOCTs+"-"+this.cSlot;
    this.lockButtonRetrieve = true;
    this.cbLoadPort.disableCombo = true;
    this.cbDischargePort.disableCombo = true;
    this.cbPOD.disableCombo = true;
    this.cbPOR.disableCombo = true;

    if(this.officeCode.toUpperCase().startsWith("TH")){
      this.isPrint = 'With';
      this.isSurcharge = 'Y';
    } else {
      this.isPrint = 'Without';
      this.isSurcharge = 'N';
    }
    // this.isPrint = 'Without';
    // this.isSurcharge = 'N';
    this.isWeight = 'KG';
    this.isPackage = 'Cont';
    this.isSortPrint = false;
    this.lock = true;
    this.lockHeader = false;

    this.grid.listStore.store.splice(0,this.grid.listStore.store.length);
    this.grid.url = '';


    this.model.blOceanVesselBound = 'O';



  }


  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      this.clearPDFPlugin();
    }
  }


  changeWithTemplate(event) {
    if (event.target.checked) {
      this.isWithTemplate = true;
    } else {
      this.isWithTemplate = false;
    }
  }

  getOblValue(blNo){
    var result = 0;
    if(this.grid.listStore.store.length > 0){
      this.grid.listStore.store.forEach(fe=>{
        if(fe["blNo"].toUpperCase().trim() == blNo){
          if(fe["blNoOfObl"] == ""){
            result = 0;
          } else {
            result = parseInt(fe["blNoOfObl"]);
          }
        }
      });
    }
    return result;
  }

  printBLOriginalReport(){
    this.loading = true;
    var postData = {};
    postData["userid"] = this.userId;
    postData["processData"] = this.genericUtil.getPSIDate();
    postData["officeCode"] = this.officeCode;
    postData["documentType"] = "OriginalBL";
    postData["re-Print"] = "N";
    postData["silent-mode"] = (this.isSilent ? "Y" : "N");
    postData["summaryUrl"] = this.configService.config.jasperUrl.toString() + 
    "/jasperserver/rest_v2/reports/reports/GLOBAL_SHIPPING/BL_Summary.pdf?BLNo=xblno&BLPlaceOfCreation=SGSIN&type=ORIGINAL&decorate=no&output=pdf&NoOriginalword=THREE&page=1";
  
    var hitUrl = this.configService.config.BASE_API.toString() + "/edi-util/getFile/blNew";

    var jasperDefaultPath = this.configService.config.jasperUrl.toString() + 
    "/jasperserver/rest_v2/reports/reports/GLOBAL_SHIPPING/"+this.officeCode+"/BL_Original_Singapore_NS.pdf";

    //if(this.grid.listStore.store.length > 0){
    if(this.blOkToPrint.length > 0){
      var eDataList = [];
      this.blOkToPrint.forEach(fe=>{
        var eData = {};
        var jasperUrlPath = "";
        if(fe.select == 'checked'){
          if(fe.blNo != ""){
            eData["blNo"] = fe.blNo.toUpperCase().trim();
            eData["fileName"] = "OriginalBL-"+fe.blNo.toUpperCase().trim()+"-"+this.userId+".pdf";
            eData["attach"] = "N";
            if(fe.hasAttachment){
              eData["attach"] = "Y";
            }

            if(fe.blPOL != ""){
              if(this.jasper.jasperPathList.length > 0){
                this.jasper.jasperPathList.forEach(xe=>{
                  if(xe["reportPolCode"] == "*"){
                    if(xe["reportUid"] == this.userIdCode){
                      jasperUrlPath = this.configService.config.jasperUrl + "/jasperserver" + xe["reportJasperPath"] + xe["reportName"] + "." + xe["reportType"] ;
                    }
                  } else {
                    if(xe["reportPolCode"] == fe.blPOL){
                      jasperUrlPath = this.configService.config.jasperUrl + "/jasperserver" + xe["reportJasperPath"] + xe["reportName"] + "." + xe["reportType"] ;                  
                    }
                  }
                });
  
                if(jasperUrlPath == ""){
                  jasperUrlPath = jasperDefaultPath;
                }
  
              } else {
                jasperUrlPath = jasperDefaultPath;
              }
            } else {
              jasperUrlPath = jasperDefaultPath;
            }

            var addUrl = "";
            if(this.isWeight == "KG"){
              addUrl = addUrl + "&wm=KGS";
            } else {
              addUrl = addUrl + "&wm=MT";
            }

            if(this.isPackage == "Cont"){
              addUrl = addUrl + "&pm=UNT";
            } else {
              addUrl = addUrl + "&pm=CRG";
            }

            if(this.isPrint == "With"){
              addUrl = addUrl + "&sc=WITH";
            } else {
              addUrl = addUrl + "&sc=WITHOUT";
            }

            eData["url"]= jasperUrlPath + "?BLNo=" + fe.blNo.toUpperCase().trim() + "&BLPlaceOfCreation=SGSIN&type=ORIGINAL&decorate=no&output=pdf&NoOriginalword=THREE&page=1"+addUrl;   

            eDataList.push(eData);

            if(this.isSilent){
              if(this.isNonNego){
                eData = {};
                eData["blNo"] = fe.blNo.toUpperCase().trim();
                eData["fileName"] = "CopyBL-"+fe.blNo.toUpperCase().trim()+"-"+this.userId+".pdf";
                eData["attach"] = "N";
                if(fe.hasAttachment){
                  eData["attach"] = "Y";
                }
                eData["url"]= jasperUrlPath.replace("BL_Original_Singapore_NS","BL_Copy_Singapore_NS") + "?BLNo=" + fe.blNo.toUpperCase().trim() + "&BLPlaceOfCreation=SGSIN&type=ORIGINAL&decorate=no&output=pdf&NoOriginalword=THREE&page=1"+addUrl;
                eDataList.push(eData);
              }
            }
          }
        }
      });

      postData["blData"] = eDataList;
    }
    //console.log(postData);

    this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
      if(resp.ok){
        var result:any = resp.json()["content"];
        var store = [];

        if(this.isSilent){
          var prinDataList = [];
          result.forEach(fe=>{
            if(fe["message"] == "success" && fe["filePath"].includes("OriginalBL")){
              var blData = {};
              var printDoc = [];
              var printDocDetail = {}
              blData["docNo"] = fe["blNo"];
              blData["printDoc"] = [];

              if(this.configService.config.server == "PRODUCTION"){
                printDocDetail["docName"] = fe["filePath"].replace("/silentPrinting/","");
              } else {
                printDocDetail["docName"] = fe["filePath"].replace("/download/silentPrinting/","");
              }
              printDocDetail["docAttachName"] = "";
              printDocDetail["spoolerDoc"] = "ORIGINALBL";
              printDocDetail["spoolerDocAttach"] = "";
              printDocDetail["totalPrint"] = this.getOblValue(fe["blNo"]);
              printDocDetail["hasAttach"] = "N";
              if(fe["filePath-attached"] != undefined){
                if(fe["filePath-attached"] != ""){
                  if(this.configService.config.server == "PRODUCTION"){
                    printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/silentPrinting/","");
                  } else {
                    printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/download/silentPrinting/","");
                  }
                  printDocDetail["spoolerDocAttach"] = "DRAFTBL";
                  printDocDetail["hasAttach"] = "Y";
                }
              }
              blData["printDoc"].push(printDocDetail);

              if(this.isNonNego){
                if(this.printCopy > 0){
                  printDocDetail = {};
                  if(this.configService.config.server == "PRODUCTION"){
                    printDocDetail["docName"] = fe["filePath"].replace("/silentPrinting/","").replace("OriginalBL","CopyBL");
                  } else {
                    printDocDetail["docName"] = fe["filePath"].replace("/download/silentPrinting/","").replace("OriginalBL","CopyBL");
                  }
                  printDocDetail["docAttachName"] = "";
                  printDocDetail["spoolerDoc"] = "COPYBL";
                  printDocDetail["spoolerDocAttach"] = "";
                  printDocDetail["totalPrint"] = this.printCopy;
                  printDocDetail["hasAttach"] = "N";
                  if(fe["filePath-attached"] != undefined){
                    if(fe["filePath-attached"] != ""){
                      if(this.configService.config.server == "PRODUCTION"){
                        printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/silentPrinting/","").replace("OriginalBL","CopyBL");
                      } else {
                        printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/silentPrinting/","").replace("OriginalBL","CopyBL");
                      }
                      printDocDetail["spoolerDocAttach"] = "DRAFTBL";
                      printDocDetail["hasAttach"] = "Y";
                    }
                  }
                  blData["printDoc"].push(printDocDetail);
                }
              }

              prinDataList.push(blData);
            }
            //this.message("information","Information","BL(s) Has been sent to printer, please check nearest printer to collect BL(s)","okonly",{ok:""});
          });

          console.log("horee");

          if(prinDataList.length > 0){
            postData = {};
            postData["userId"] = this.userId;
            postData["userEmail"] = this.userEmail;
            postData["userLocationId"] = this.officeId;
            postData["printList"] = prinDataList;
            postData["server"] = this.configService.config.server;
          }

          console.log(postData);

          console.log("sent to printer");
          //this.message("information","Information","Document has been sent for printing.","okonly",{ok:""});
          //this.loading = false;
          hitUrl = this.configService.config.BASE_API.toString() + "/?q=/PrintService/print";
          this.genericService.post(hitUrl, postData).subscribe((resp)=>{
            if(resp.ok){
              this.message("information","Information","Document has been sent for printing.","okonly",{ok:""});
              this.loading = false;
            }
          }, error=>{
            this.message("Information","information","An Error Occured while printing document, please contact your administrator!","okonly",{ok:""});
            this.loading = false;
          });

        } else {
          this.gridPDFPrev.listStore.store = [];
          result.forEach(fe=>{
            if(fe["message"] == "success"){
              var arr = {};
              arr["blNo"] = fe["blNo"];
              arr["pdfUrl"] = this.configService.config.pdfUrl + fe["filePath"]+"#view=FitH";

              this.gridPDFPrev.listStore.store.push(arr);
            }
          });

          console.log("yeye " + this.gridPDFPrev.listStore.store.length);
          if(this.gridPDFPrev.listStore.store.length > 1){
            //this.setPDF(store);
            this.showPDF();
            this.gridPDFPrev.loadData();
            this.gridPDFPrev.clearSelectedValues();
            this.gridPDFPrev.onSelect(true, 1);
          } else if (this.gridPDFPrev.listStore.store.length == 1) {
            this.showPDF();
            this.gridPDFPrev.loadData();
            this.gridPDFPrev.clearSelectedValues();
            this.gridPDFPrev.onSelect(true, 1);
            $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-label").hide();
            $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-grid-container").hide();
            /*this.gridPDFPrev.listStore.store.forEach(fe=>{
              this.pdfContainer.setPDFURL(fe["pdfUrl"]);
              this.pdfContainer.showPDF();
            });*/
          } else {
            this.message("information","Information","Failed to load PDF. Please contact your administrator.","okonly",{ok:""})
          }

          this.loading = false;
        }

      }
    });

  }

  checkBoxEvent(event, mode){
    if(mode == "silent-mode"){
      this.isSilent = event.srcElement.checked;
    }
  }

  closePdfPlugin(){
    $("#pdfContainerNewBlOriginal").find("#pdfContainerNewBlOriginal-tab-header").empty();
    $("#pdfContainerNewBlOriginal").find(".xtab").remove();
  }


  setPDF(someArr:any){
    $("#pdfContainerNewBlOriginal").find("#pdfContainerNewBlOriginal-tab-header").empty();
    $("#pdfContainerNewBlOriginal").find(".xtab").remove();
    var activeIndicator:boolean = true
    someArr.forEach(fe=>{
      if(activeIndicator){
        $("#pdfContainerNewBlOriginal").find("#pdfContainerNewBlOriginal-tab-header").append(
          "<a class='item active' data-tab='"+fe["fileName"].replace(".pdf","")+"'>"+fe["fileName"].replace(".pdf","")+"</a>"
        );

        $("#pdfContainerNewBlOriginal").append(
          "<div class='ui bottom attached active tab segment xtab' data-tab='"+fe["fileName"].replace(".pdf","")+"' style='padding-top:50px;height:100%'></div>"
        );
        activeIndicator = false;
      } else {
        $("#pdfContainerNewBlOriginal").find("#pdfContainerNewBlOriginal-tab-header").append(
          "<a class='item' data-tab='"+fe["fileName"].replace(".pdf","")+"'>"+fe["fileName"].replace(".pdf","")+"</a>"
        );

        $("#pdfContainerNewBlOriginal").append(
          "<div class='ui bottom attached tab segment xtab' data-tab='"+fe["fileName"].replace(".pdf","")+"' style='padding-top:50px;height:100%'></div>"
        );
      }

      $("#pdfContainerNewBlOriginal").find("div[data-tab='"+fe["fileName"].replace(".pdf","")+"']").append('<object id="o" data="'+ fe["pdfUrl"] +'" type="application/pdf" width="100%" height="100%"></object>');
    });
  }

  showPDF(){
    var self = this;
    /*$("#pdfContainerNewBlOriginal2")
    .modal('setting', {
      onShow : function () {
        $(this).css({
            'margin' : '5px',
            'position' : 'fixed',
            'top' : '0',
            'bottom' : '0',
            'left' : '0',
            'right' : '0',
            'width' : 'auto',
            'height' : '100%'
        });
        $("body").first().css("overflow","hidden");
      },
      'closable' : false,
      onHide : function(){
        self.clearPDFPlugin();
        $("body").first().css("overflow","auto");
        //self.eventPdf.emit("close-pdf");
      }
    }).modal("show");

    $('.menu .item').tab();*/

    this.newDialogOpt = {
      title:"PDF Preview",
      modal:true,
      closeText: "hide",
      closeOnEscape: false,
      width:1020,
      height:690,
      open: function(event, ui) {
          //hide close button.
          //// console.log($(this).css("z-index","102"));
          $('.ui-dialog').css('z-index',103);
          $('.ui-widget-overlay').css('z-index',102);
          //$(this).parent().children().children('.ui-dialog-titlebar-close').hide();
          $("body").first().css("overflow","hidden");
      },
      close: function(event, ui){
        $("body").first().css("overflow"," auto");
        self.clearPDFPlugin();
      }
    };

    this.showDialog("pdfContainerNewBlOriginal2", this.newDialogOpt);

  }

  showDialog(id:string, options:any){
    $("#"+id).dialog(options).dialog("open");
  }

  closeDialog(id:string){
    $("#"+id).dialog("close");
    $("#"+id).dialog("destroy");
  }

  clearPDFPlugin(){
    this.gridPDFPrev.listStore.store.splice(0, this.gridPDFPrev.listStore.store.length);
    $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-viewer-container").empty();
    $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-label").show();
    $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-grid-container").show();
    this.closeDialog("pdfContainerNewBlOriginal2");
  }

  clearPDFPlugin2(){
    this.gridPDFPrev.listStore.store.splice(0, this.gridPDFPrev.listStore.store.length);
    $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-viewer-container").empty();
    $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-label").show();
    $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-grid-container").show();
  }

  gridEventPDFPrev(event){
    console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        this.selectedEvent(event);
        break;
      case 'click' :
        this.selectedEvent(event);
        break;
      default:
        break;
    }
  }

  selectedEvent(event){
    //// // // console.log(event);
    var no:number = 0;
    var opt = (event.split(".").length > 1 ? event.split(".")[1].split("-")[0] : event );
    var opt2 = (event.split(".").length > 1 ? event.split(".")[1].split("-")[1] : event );
    switch(opt){
      case 'checked':
        no = opt2;
        this.selectedListMod(no, this.gridPDFPrev, 'no');
        this.gridPDFPrev.listStore.store.forEach(fe=>{
          if(fe["no"] == no){
            //console.log(fe);
            $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-viewer-container").empty();
            $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-viewer-container").append('<object id="o" data="'+ fe["pdfUrl"] +'" type="application/pdf" width="100%" height="100%"></object>');
          }
        });

        break;
      case 'unchecked':
        no = opt2;
        //$("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-viewer-container").empty();
        break;
    }
  }

  selectedListMod(value, store:GridPluginComponent, key){
    if(store.getSelectedValues().length > 1){
      store.getSelectedValues().forEach(ff=>{
        if(ff[key] != value){
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0,1);
    }
  }

  showReprint(){

    if(this.currentMenu.includes("Re-Print")){
      this.invisibleToolbarButtons = "ok";   
      this.reprintMode = true;
    } else {
      this.invisibleToolbarButtons = "reprint";      
    }

    /*var userID = this.cookieService.getUserId()!
  
      console.log(userID)
      if(userID == "US0000001" || userID == "US0000002" || userID == "US0000004"
      || userID == "US0000005" || userID == "US0000006" || userID == "US0000007"
      || userID == "US0000008" || userID == "US0000009" || userID == "US0000012"
      || userID == "US0000049"){
        console.log("ok")
      }else{
        this.invisibleToolbarButtons = "reprint";      
      }*/
  }

  reprintBLOriginalReport(){
    this.loading = true;
    var postData = {};
    postData["userid"] = this.userId;
    postData["processData"] = this.genericUtil.getPSIDate();
    postData["officeCode"] = this.officeCode;
    postData["documentType"] = "OriginalBL";
    postData["re-Print"] = "Y";
    postData["silent-mode"] = (this.isSilent ? "Y" : "N");
    postData["summaryUrl"] = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/BL/BLSummary.pdf?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo=xblno";
    //var hitUrl = this.configService.config.BASE_API.toString()  + "/edi-util/getFile/blNew";
    // var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile/blNew";
    var hitUrl = this.configService.config.BASE_API.toString() + "/edi-util/getFile/blNew";

    var jasperDefaultPath = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/"+this.officeCode+"/BL/BLOriginalNS.pdf";

    //if(this.grid.listStore.store.length > 0){
    if(this.blOkToPrint.length > 0){
      var eDataList = [];
      this.blOkToPrint.forEach(fe=>{
        var eData = {};
        var jasperUrlPath = "";
        if(fe.select == 'checked'){
          if(fe.blNo != ""){
            eData["blNo"] = fe.blNo.toUpperCase().trim();
            eData["fileName"] = "OriginalBL-"+fe.blNo.toUpperCase().trim()+"-"+this.userId+".pdf";
            eData["attach"] = "N";
            if(fe.hasAttachment){
              eData["attach"] = "Y";
            }

            if(fe.blPOL != ""){
              if(this.jasper.jasperPathList.length > 0){
                this.jasper.jasperPathList.forEach(xe=>{
                  if(xe["reportPolCode"] == "*"){
                    if(xe["reportUid"] == this.userIdCode){
                      jasperUrlPath = this.configService.config.jasperUrl + "/jasperserver" + xe["reportJasperPath"] + xe["reportName"] + "." + xe["reportType"] ;
                    }
                  } else {
                    if(xe["reportPolCode"] == fe.blPOL){
                      jasperUrlPath = this.configService.config.jasperUrl + "/jasperserver" + xe["reportJasperPath"] + xe["reportName"] + "." + xe["reportType"] ;                  
                    }
                  }
                });
  
                if(jasperUrlPath == ""){
                  jasperUrlPath = jasperDefaultPath;
                }
  
              } else {
                jasperUrlPath = jasperDefaultPath;
              }
            } else {
              jasperUrlPath = jasperDefaultPath;
            }
            if(this.officeCode == 'SGSIN'){
              eData["url"]= jasperUrlPath + "?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo="+fe.blNo.toUpperCase().trim()+"&isPackage="+this.isPackage+"&surcharge="+this.isSurcharge+"&weight="+this.isWeight+"&isExchRate="+this.isExch+"&page=1&locationName="+this.payableAt+"&printFrom="+this.printFrom;   
            }else{
              eData["url"]= jasperUrlPath + "?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo="+fe.blNo.toUpperCase().trim()+"&isPackage="+this.isPackage+"&surcharge="+this.isSurcharge+"&weight="+this.isWeight+"&isExchRate="+this.isExch+"&page=1";   
            
            }

            
            eDataList.push(eData);

            if(this.isSilent){
              if(this.isNonNego){
                eData = {};
                eData["blNo"] = fe.blNo.toUpperCase().trim();
                eData["fileName"] = "CopyBL-"+fe.blNo.toUpperCase().trim()+"-"+this.userId+".pdf";
                eData["attach"] = "N";
                if(fe.hasAttachment){
                  eData["attach"] = "Y";
                }
                eData["url"]= jasperUrlPath.replace("BLOriginalNS","BLCopyNS") + "?userCreated="+this.userId+"&officeCode="+this.officeCode+"&blNo="+fe.blNo.toUpperCase().trim()+"&isPackage="+this.isPackage+"&surcharge="+this.isSurcharge+"&weight="+this.isWeight+"&isExchRate="+this.isExch+"&page=1&locationName="+this.payableAt;   
                eDataList.push(eData);
              }
            }
          }
        }
      });

      postData["blData"] = eDataList;
    }
    //console.log(postData);

    this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
      if(resp.ok){
        var result:any = resp.json()["content"];
        var store = [];

        if(this.isSilent){
          var prinDataList = [];
          result.forEach(fe=>{
            if(fe["message"] == "success" && fe["filePath"].includes("OriginalBL")){
              var blData = {};
              var printDoc = [];
              var printDocDetail = {}
              blData["docNo"] = fe["blNo"];
              blData["printDoc"] = [];

              if(this.configService.config.server == "PRODUCTION"){
                printDocDetail["docName"] = fe["filePath"].replace("/silentPrinting/","");
              } else {
                printDocDetail["docName"] = fe["filePath"].replace("/download/silentPrinting/","");
              }
              printDocDetail["docAttachName"] = "";
              printDocDetail["spoolerDoc"] = "ORIGINALBL";
              printDocDetail["spoolerDocAttach"] = "";
              printDocDetail["totalPrint"] = this.getOblValue(fe["blNo"]);
              printDocDetail["hasAttach"] = "N";
              if(fe["filePath-attached"] != undefined){
                if(fe["filePath-attached"] != ""){
                  if(this.configService.config.server == "PRODUCTION"){
                    printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/silentPrinting/","");
                  } else {
                    printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/download/silentPrinting/","");
                  }
                  printDocDetail["spoolerDocAttach"] = "DRAFTBL";
                  printDocDetail["hasAttach"] = "Y";
                }
              }

              blData["printDoc"].push(printDocDetail);

              if(this.isNonNego){
                if(this.printCopy > 0){
                  printDocDetail = {};
                  if(this.configService.config.server == "PRODUCTION"){
                    printDocDetail["docName"] = fe["filePath"].replace("/silentPrinting/","").replace("OriginalBL","CopyBL");
                  } else {
                    printDocDetail["docName"] = fe["filePath"].replace("/download/silentPrinting/","").replace("OriginalBL","CopyBL");
                  }
                  printDocDetail["docAttachName"] = "";
                  printDocDetail["spoolerDoc"] = "COPYBL";
                  printDocDetail["spoolerDocAttach"] = "";
                  printDocDetail["totalPrint"] = this.printCopy;
                  printDocDetail["hasAttach"] = "N";
                  if(fe["filePath-attached"] != undefined){
                    if(fe["filePath-attached"] != ""){
                      if(this.configService.config.server == "PRODUCTION"){
                        printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/silentPrinting/","").replace("OriginalBL","CopyBL");
                      } else {
                        printDocDetail["docAttachName"] = fe["filePath-attached"].replace("/silentPrinting/","").replace("OriginalBL","CopyBL");
                      }
                      printDocDetail["spoolerDocAttach"] = "DRAFTBL";
                      printDocDetail["hasAttach"] = "Y";
                    }
                  }
                  blData["printDoc"].push(printDocDetail);
                }
              }

              prinDataList.push(blData);
            }
            //this.message("information","Information","BL(s) Has been sent to printer, please check nearest printer to collect BL(s)","okonly",{ok:""});
          });

          console.log("horee");

          if(prinDataList.length > 0){
            postData = {};
            postData["userId"] = this.userId;
            postData["userEmail"] = this.userEmail;
            postData["userLocationId"] = this.officeId;
            postData["printList"] = prinDataList;
            postData["server"] = this.configService.config.server;
          }

          console.log(postData);
          hitUrl = this.configService.config.BASE_API.toString() + "/?q=/PrintService/print";
          this.genericService.post(hitUrl, postData).subscribe((resp)=>{
            if(resp.ok){
              this.message("information","Information","Document has been sent for printing.","okonly",{ok:""});
              this.loading = false;
            }
          }, error=>{
            this.message("Information","information","An Error Occured while printing document, please contact your administrator!","okonly",{ok:""});
            this.loading = false;
          });

        } else {
          this.gridPDFPrev.listStore.store = [];
          result.forEach(fe=>{
            if(fe["message"] == "success"){
              var arr = {};
              arr["blNo"] = fe["blNo"];
              arr["pdfUrl"] = this.configService.config.pdfUrl + fe["filePath"]+"#view=FitH";

              this.gridPDFPrev.listStore.store.push(arr);
            }
          });

          console.log("yeye " + this.gridPDFPrev.listStore.store.length);
          if(this.gridPDFPrev.listStore.store.length > 1){
            //this.setPDF(store);
            this.showPDF();
            this.gridPDFPrev.loadData();
            this.gridPDFPrev.clearSelectedValues();
            this.gridPDFPrev.onSelect(true, 1);
          } else if (this.gridPDFPrev.listStore.store.length == 1) {
            this.showPDF();
            this.gridPDFPrev.loadData();
            this.gridPDFPrev.clearSelectedValues();
            this.gridPDFPrev.onSelect(true, 1);
            $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-label").hide();
            $("#pdfContainerNewBlOriginal2").find("#pdfContainerNewBlOriginal2-grid-container").hide();
            /*this.gridPDFPrev.listStore.store.forEach(fe=>{
              this.pdfContainer.setPDFURL(fe["pdfUrl"]);
              this.pdfContainer.showPDF();
            });*/
          } else {
            this.message("information","Information","Failed to load PDF. Please contact your administrator.","okonly",{ok:""})
          }

          this.loading = false;
        }
      }
    });

  }

  reprintTheBL(){
    this.loading = true;
    this.blOkToPrint = [];
    var uriUpdateGrid = this.configService.config.BASE_API.toString() + "/BLReport/checkBLNo";
    var blnos = [];
    var popIsEmpty:boolean = false;
    var popEmptyList = [];
    this.grid.listStore.store.forEach(fe => {
      if(fe.select == 'checked'){
        blnos.push(fe.blNo.toUpperCase().trim());
        if(this.isSurcharge == "Y"){
          if(fe.locationName != undefined){
            if(fe.locationName != ""){
              this.payableAt =fe.locationName.toUpperCase().trim();
            } else {
              popIsEmpty = true;
              popEmptyList.push(fe.blNo.toUpperCase().trim());
            }
          } else {
            popIsEmpty = true;
            popEmptyList.push(fe.blNo.toUpperCase().trim());
          } 
        } else {
          this.payableAt = "";
        }   
      }
    });
    var postMap = {};
    postMap["officeCode"] = this.officeCode;
    postMap["blNos"] = blnos;

    if(postMap["blNos"] != ''){
      if(this.isSurcharge == "Y"){
        if(popIsEmpty && this.officeCode.startsWith("SG")){
          this.loading = false;
          var blList = "";
          popEmptyList.forEach(fe=>{
            blList = blList + fe + ",";
          });
          blList = blList.substring(0, blList.length-1);
          this.message("information","Information","Please insert place of payment for BL No :<br>"+blList,"okonly",{ok:""});
        } else {
          this.genericService.POST(uriUpdateGrid, postMap).subscribe((resp)=>{
            if(resp.ok){
              var hitRes = resp.json()["content"];
              var hitRes2 = resp.json()["content-OBL"];
              var hitRes3 = resp.json()["contentPrint"];
              var blIsNotPrintedYet = [];
              
              // console.log("nojan effect");
              // console.log(hitRes3);
              this.grid.listStore.store.forEach(fe => {
                if(fe.select == 'checked'){
                  fe.hasAttachment = hitRes[fe.blNo.toUpperCase().trim()];
                  fe["blNoOfObl"] = hitRes2[fe.blNo.toUpperCase().trim()];
                  fe["isPrinted"] = hitRes3[fe.blNo.toUpperCase().trim()];
      
                  if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
                    if(hitRes3[fe.blNo.toUpperCase().trim()] == "Y"){
                      this.blOkToPrint.push(fe);
                    } else {
                      blIsNotPrintedYet.push(fe.blNo.toUpperCase().trim());
                    }
                  } else {
                    this.blOkToPrint.push(fe);
                  }
      
                }
              });        
      
              if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
                var isNotPrintedYetMsg = "";
                if(blIsNotPrintedYet.length > 0){
                  blIsNotPrintedYet.forEach(fe=>{
                    isNotPrintedYetMsg = isNotPrintedYetMsg + fe + ",";
                  });
                  isNotPrintedYetMsg = isNotPrintedYetMsg.substring(0, isNotPrintedYetMsg.length-1);
                }
      
                if(this.blOkToPrint.length > 0){
                  if(blIsNotPrintedYet.length > 0){
                    this.message("information","Information","Below BL has not been printed yet. This BL will not be printed.<br>"+isNotPrintedYetMsg+"<br><br> continue to Reprint the rest of BL?","yesno",{yes:"this.reprintBLOriginalReport();",no:""});
                    this.loading = false;
                  } else {
                    this.reprintBLOriginalReport();
                  }
                } else {
                  if(blIsNotPrintedYet.length > 0){
                    this.message("infomation","Information","BL(s) has not been printed yet. Please use BL Original menu to print the BL(s)","okonly",{ok:""});
                  }
                  this.loading = false;
                }
      
              } else {
                this.message("information","Information","Please use BL Original to print","okonly",{ok:""});
                this.loading = false;
              }
              
            }
          }, error=>{
            this.loading = false;
            this.message("information","Information","Error ocurred when trying to print the document, please contact your administrator!","okonly",{ok:""});
          });
        }
      } else{
    this.genericService.POST(uriUpdateGrid, postMap).subscribe((resp)=>{
      if(resp.ok){
        var hitRes = resp.json()["content"];
        var hitRes2 = resp.json()["content-OBL"];
        var hitRes3 = resp.json()["contentPrint"];
        var blIsNotPrintedYet = [];
        
        // console.log("nojan effect");
        // console.log(hitRes3);
        this.grid.listStore.store.forEach(fe => {
          if(fe.select == 'checked'){
            fe.hasAttachment = hitRes[fe.blNo.toUpperCase().trim()];
            fe["blNoOfObl"] = hitRes2[fe.blNo.toUpperCase().trim()];
            fe["isPrinted"] = hitRes3[fe.blNo.toUpperCase().trim()];

            if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
              if(hitRes3[fe.blNo.toUpperCase().trim()] == "Y"){
                this.blOkToPrint.push(fe);
              } else {
                blIsNotPrintedYet.push(fe.blNo.toUpperCase().trim());
              }
            } else {
              this.blOkToPrint.push(fe);
            }

          }
        });        

        if(this.printOnlyOnceList.includes(this.officeCode.substring(0,2))){
          var isNotPrintedYetMsg = "";
          if(blIsNotPrintedYet.length > 0){
            blIsNotPrintedYet.forEach(fe=>{
              isNotPrintedYetMsg = isNotPrintedYetMsg + fe + ",";
            });
            isNotPrintedYetMsg = isNotPrintedYetMsg.substring(0, isNotPrintedYetMsg.length-1);
          }

          if(this.blOkToPrint.length > 0){
            if(blIsNotPrintedYet.length > 0){
              this.message("information","Information","Below BL has not been printed yet. This BL will not be printed.<br>"+isNotPrintedYetMsg+"<br><br> continue to Reprint the rest of BL?","yesno",{yes:"this.reprintBLOriginalReport();",no:""});
              this.loading = false;
            } else {
              this.reprintBLOriginalReport();
            }
          } else {
            if(blIsNotPrintedYet.length > 0){
              this.message("infomation","Information","BL(s) has not been printed yet. Please use BL Original menu to print the BL(s)","okonly",{ok:""});
            }
            this.loading = false;
          }

        } else {
          this.message("information","Information","Please use BL Original to print","okonly",{ok:""});
          this.loading = false;
        }
        
      }
    }, error=>{
      this.loading = false;
      this.message("information","Information","Error ocurred when trying to print the document, please contact your administrator!","okonly",{ok:""});
    });
  }
  }else{
    this.loading = false;
    this.message("information","Information","Please select bl to print","okonly",{ok:""});
    }
  }
}
