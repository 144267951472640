import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Authorize, Validator, ComboPluginComponent, GenericService,GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { officeModel, specialCharUtil, } from '../shared/index';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $: any;


export class Parameter {
  vesselId = '';
  vesselName = '';
  voyage = '';
  bound = 'O';
  cOwner = 'SOC';

  constructor() { }
}


@Component({
  selector: 'app-transaction-auto-creation-bl-via-cll-page',
  templateUrl: './transaction-auto-creation-bl-via-cll-page.component.html',
  styleUrls: ['./transaction-auto-creation-bl-via-cll-page.component.css']
})
export class TransactionAutoCreationBLViaCLLPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;

  @ViewChild('rSoc') radioSoc:ElementRef;
  @ViewChild('rCoc') radioCoc:ElementRef;
  @ViewChild('rSlot') radioSlot:ElementRef;
  @ViewChild('rAll') radioAll:ElementRef;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';
  autoCreationResultMesssage = '';

  modeToolbar = false;

  labelToolbar = "AUTO CREATION OF BL (VIA CLL)";
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPDF;

  // types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';
  sCharUtil = new specialCharUtil();


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new Parameter();
  office = new officeModel;
  userId:string = "";
  postData:any = {};
  hitUrl:string = this.configService.config.BASE_API.toString();

  printButtonIsClicked = true;

  validatorRules = {};


  constructor(private auth: Authorize,
              private genericService: GenericService,
              private genericUtil: GenericUtil,
              private router: Router,
              private configService: ConfigService,
              private cookieService: CookieService,
              private _http: HttpClient) {
    super();
    var officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
    if(officeCode == "SGSIN"){
      this.labelToolbar = "AUTO CREATION OF BL (OBOP)";
    }

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Create', event: 'create', icon: 'upload'},
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar
    };

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 130},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    };

    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : true,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInit();
  }

  firstInit(){
    this.office.officeCode = this.cookieService.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cookieService.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cookieService.getCookie("defaultLocation").split("|")[0];
    this.userId = this.cookieService.getName();
    this.disableToolbarButtons = 'print';
  }

  toolbarEvent(event) {
    switch (event) {
      case 'create' :
        if(this.model.vesselId != "" && this.model.voyage != "" && this.model.cOwner != ""){
          this.printButtonIsClicked = true;
          this.disableToolbarButtons = 'print';
          this.postData = {};
          this.postData["officeCode"] = this.office.officeCode;
          this.postData["userId"] = this.userId;
          this.postData["dateCreated"] = this.genericUtil.getPSIDate();
          this.postData["vesselId"] = this.model.vesselId;
          this.postData["voyage"] = this.model.voyage;
          this.postData["cOwnerShip"] = this.model.cOwner;
          this.postData["bound"] = "O";
          this.loading = true;

          //console.log(this.postData);
          this.autoCreationProcess();
        } else {
          this.message("information","Information","please insert vessel & voyage","okonly",{ok:""});
        }
        break;
      case 'print':
        this.printDiscrepancyReport();

        break;
      case 'cancel' :
        this.model = new Parameter;
        this.cbVessel.setForceValue("");
        this.cbVoyage.setForceValue("");
        this.radioAll.nativeElement.checked = "";
        this.radioSoc.nativeElement.checked = "checked";
        this.radioCoc.nativeElement.checked = "";
        this.radioSlot.nativeElement.checked = "";
        this.autoCreationResultMesssage = '';
        this.disableToolbarButtons = 'print';
        this.printButtonIsClicked = true;
        this.postData = {};

        break;
      case 'close' :
        // handle close event
        this.autoCreationResultMesssage = '';
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeEventVessel(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
        event.vesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode()
      );

      this.model.vesselId = event.vesselId;
      this.model.vesselName = event.vesselName;

      this.cbVoyage.setValue('');
    } else {
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }
  changeEventVoyage(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.model.voyage = event.voyage;
      this.checkValidSchedule(
        this.model.vesselId,
        this.model.voyage,
        "O",
        this.cookieService.getDefaultLocationCode());  
    } else {
      this.model.voyage = '';
    }
  }

  onCancel(){
    this.model = new Parameter;
    this.cbVessel.setForceValue("");
    this.cbVoyage.setForceValue("");
    this.radioAll.nativeElement.checked = "";
    this.radioSoc.nativeElement.checked = "checked";
    this.radioCoc.nativeElement.checked = "";
    this.radioSlot.nativeElement.checked = "";
    this.autoCreationResultMesssage = '';
    this.disableToolbarButtons = 'print';
    this.printButtonIsClicked = true;
    this.postData = {};    
  }

  checkValidSchedule(
    vesselId : String,
    voyage : String,
    bound : String,
    officeCode : String) : String{
      let validSch: String = "";
      console.log("CEK VALID 1 : "+validSch);
      this.genericService
          .GET(
            this.configService.config.BASE_API.toString() +
              "/MasterSailingSchedules/findSailingScheduleByDoubleClick/"+
              vesselId+"/"+
              this.sCharUtil.htmlEncode(
                String(voyage).trim()
              )+"/"+
              bound+"/"+
              officeCode
          )
          .subscribe((resp) => {
            let dtx: any[];
            let checkValidvalid : String;
            dtx = resp.json()["content"];
            validSch = dtx[0]["isValid"];   
            if(validSch == "N"){
              this.message(
                "information",
                "Information",
                "Schedule is invalid, unable to create B/L.",
                "okonly",
                { ok: "this.onCancel();" }                
              );
              
            }     
          }
        );
          console.log("CEK VALID 3 : "+validSch);

      return validSch;
  }

  radioEvent(check:boolean, type:string) {
    //console.log("redio event "+ check + " - type : "+type );
    if(check){
      this.model.cOwner = type;
    }
  }

  autoCreationProcess(){
    var hitUrl = this.hitUrl + "/AutoCreation/cll/blNew";
    if(this.model.cOwner == "SLOT"){
      hitUrl = this.hitUrl + "/AutoCreation/cll/blNew";
    }
    var hitUrl2 = this.hitUrl + "?q=/AutoCreation/cll/blNew";

    this.autoCreationResultMesssage = "";
    //this.genericService.POST(hitUrl, this.postData).subscribe((resp)=>{
    this._http.post(hitUrl2,this.postData, { headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      console.log(resp);
      if(resp["message"] == "success"){
        var blCreated:any[] = resp["bl-created"];
        var blUpdated:any[] = resp["bl-updated"];
        var blFailed:any[] = resp["bl_failed"];
        var blFinished:any[] = resp["bl-finished"];
        if(blCreated.length > 0){
          this.autoCreationResultMesssage = this.autoCreationResultMesssage + blCreated.length + " BL Created <br>";
        }
        if(blUpdated.length > 0){
          this.autoCreationResultMesssage = this.autoCreationResultMesssage + blUpdated.length + " BL Updated <br>";
        }

        if(resp["discrepancy"] != undefined){
          this.disableToolbarButtons = '';
          this.autoCreationResultMesssage = this.autoCreationResultMesssage + "Error(S) detected!<br><br>Please check Error Report."
          //this.printDiscrepancyReport();
        }

        if(blCreated.length + blUpdated.length > 0){
          this.printButtonIsClicked = false;
          if(this.office.officeCode == "INCCU"){
            this.message("information","Information",this.autoCreationResultMesssage,"okonly",{ok:"this.printBlCreatedListReport()"});
          } else {
            this.message("information","Information",this.autoCreationResultMesssage,"okonly",{ok:""});
          }    
        } else {
          this.message("information","Information",this.autoCreationResultMesssage,"okonly",{ok:""});
        }
        // this.message("information","Information",this.autoCreationResultMesssage,"okonly",{ok:""});
      } else {
        this.message("information","Information","No data found in Booking","okonly",{ok:''});
      }

      this.loading = false;      
    }, error=>{
      console.log(error);
      this.message("information","Information","An error occurred while running auto creation process, please contact your administrator!<br>error status : "+error.statusText,"okonly",{ok:""});
      this.loading = false;
    });
  }

  printBlCreatedListReport(){
    this.printButtonIsClicked = false;
    this.loading = true;
    var reportDate = this.genericUtil.getPSIDate().replace(" ","_");
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/auto_create_bl_list.pdf?vesselId="+this.model.vesselId+"&voyage="+this.model.voyage+"&officeCode="+this.office.officeCode+"&containerOwnership="+this.model.cOwner+"&reportDate="+reportDate;
    var randomNumber = this.genericUtil.getPSIDate().replace(/-/g,"").replace(/:/g,"").replace(" ","");
    var fileName = "AutoCreationBLCreatedReportList"+this.model.vesselName+"-"+this.model.voyage+"-"+randomNumber+".pdf";

    var postData = {};
    postData["fileName"] = fileName;
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
      if(resp.ok){
        var result = resp.json();

        if(result["message"] == "success"){
          this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
          this.pdfContainer.showPDF();
        }

        this.loading = false;
      }
    });
  }

  printDiscrepancyReport(){
    this.printButtonIsClicked = true;
    this.loading = true;
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/discReportCll.pdf?userCreated="+this.userId+"&vessel="+this.model.vesselId+"&voyage="+this.model.voyage+"&bound="+this.model.bound+"&office="+this.office.officeCode+"&cOwnerShip="+this.model.cOwner;
    var fileName = "DiscreapancyReportCll"+this.model.vesselName+"-"+this.model.voyage;

    var postData = {};
    postData["fileName"] = fileName+".pdf";
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
      if(resp.ok){
        var result = resp.json();

        if(result["message"] == "success"){
          this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
          this.pdfContainer.showPDF();
        }

        this.loading = false;
      }
    });
  }

  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      if(this.printButtonIsClicked){
        this.disableToolbarButtons = 'print';
      }
    }
  }

}