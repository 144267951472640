import { Component, OnInit, AfterViewInit, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import {
  ComboPluginComponent, GenericService,
  GenericUtil, ConfigService, CookieService, DialogPluginComponent
} from 'sibego-ui-library';
import FormValidation from '../../utils/formValidation';
import { specialCharUtil } from "../shared";

declare  var $: any;


export class UserDetails {
  userDeptCode = '';
  userDeptName = '';
  userFullName = '';
  userId = '';
  userName = '';
  userPhone = '';
}

export class InvoiceFlagStatusParam {
  invoiceOfficeCode = '';
  invoiceVesselId = '';
  invoiceVesselCode = '';
  invoiceVesselName = '';
  invoiceVoyage = '';
  invoiceBound = 'O';
  invoicePOLCode = '';
  invoicePOLName = '';
  invoicePODCode = '';
  invoicePODName = '';
  invoicePOT1Code = '';
  invoicePOT1Name = '';
  invoicePlaceOfPaymentCode = '';
  invoicePlaceOfPaymentName = '';
  invoiceIsCOC = true;
  invoiceIsSOC = true;
  invoiceIsSLOT = true;
  invoiceIsNVOCC = false;
  invoiceChequeStatusCode = 'ALL';
  invoiceChequeStatusName = 'All';
  invoiceTransshipmentStatusCode = 'ALL';
  invoiceTransshipmentStatusName = 'All';
  invoiceContainerStatusCode = 'ALL';
  invoiceContainerStatusName = 'All';
  invoiceStatusCode = 'ALL';
  invoiceStatusName = 'All';
  invoiceBlRef = '';
  invoiceAcctDebtCode = '';
  invoiceAcctDebtName = '';
  invoicePayerCode = '';
  invoicePayerName = '';
  selected = '';
  invoiceDebtorCode = '';
  constructor() {}
}

@Component({
  selector: 'app-transaction-invoice-flag-status-page',
  templateUrl: './transaction-invoice-flag-status-page.component.html',
  styleUrls: ['./transaction-invoice-flag-status-page.component.css']
})
export class TransactionInvoiceFlagStatusPageComponent  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('cbBound') cbBound: ComboPluginComponent;
  @ViewChild('cbPOL') cbPOL: ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD: ComboPluginComponent;
  @ViewChild('cbPOT1') cbPOT1: ComboPluginComponent;
  @ViewChild('cbPayer') cbPayer: ComboPluginComponent;
  @ViewChild('cbAcctDebtorCode') cbAcctDebtorCode: ComboPluginComponent;
  @ViewChild('cbPlacePayment') cbPlacePayment: ComboPluginComponent;
  @ViewChild('cbTransshipmentStatus') cbTransshipmentStatus: ComboPluginComponent;
  @ViewChild('cbChequeStatus') cbChequeStatus: ComboPluginComponent;
  @ViewChild('cbContainerStatus') cbContainerStatus: ComboPluginComponent;
  @ViewChild('cbInvoiceStatus') cbInvoiceStatus: ComboPluginComponent;
  @ViewChild('cbBlRef') cbBlRef: ComboPluginComponent;
  @ViewChild('dialogPlugin') dialogPlugin: DialogPluginComponent;

  /* Parameter for information into Footer */
  info = '';
  columnDefs;
  gridApi;
  rowData: any;
  rowDataTotal: any;
  progressBarValue = 0;
  progressBarMessage = '';
  getRowStyle;
  currentMenu;
  viewOnlyStatus = false;

  /* Parameter for dialog */
  dialog: any;

  loading = false;
  lock = true;
  isLoad = false;
  isError = false;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingBound;
  settingPOL;
  settingPOD;
  settingPOT1;
  settingPayer;
  settingChequeStatus;
  settingTransshipmentStatus;
  settingContainerStatus;
  settingAcctDebtorCode;
  settingPlacePayment;
  settingInvoiceStatus;
  settingBlRef;
  frmDialogMessage = '';
  gridData = [];

  messagesyncReceiptsFromRS = "";

  // This form model
  model = new InvoiceFlagStatusParam();
  userDetails = new UserDetails();
  // * Form Validation
  formValidation = new FormValidation();

  sCharUtil = new specialCharUtil();


  constructor(private router: Router, private genericUtil: GenericUtil, private genericService: GenericService,
              private configService: ConfigService, private cookieService: CookieService) {

    this.columnDefs = [
      {headerName: 'Deleted', field: 'invStatus', width: 150},
      {headerName: 'Collection', field: 'collection', width: 150},
      {headerName: 'BL Reference', field: 'blReference', width: 150 },
      {headerName: 'Payer', field: 'invoicePayer', width: 200 },
      {headerName: 'Invoice Reference', field: 'invoiceReference' },
      {headerName: 'Actual Invoice Printed', field: 'actualInvoicePrinted' },
      {headerName: 'Invoice By Currency', field: 'invoiceSplitByCurrency' },
      {headerName: 'Do Not Convert to SGD', field: 'convertSGD' },
      {headerName: 'Currency', field: 'invoiceCurrency' },
      {headerName: 'Total Amount', field: 'totalAmount' },
      {headerName: 'Acct/Debtor Code', field: 'acctDebtorCode' },
      { headerName: 'CN Ref.', field: 'CNRef' },
      { headerName: 'DN Ref.', field: 'DNRef' },
      {headerName: 'Credit Limit', field: 'creditLimit' },
      {headerName: 'Credit Days', field: 'creditDays' },
      {headerName: 'Printed By', field: 'printedBy' },
      {headerName: 'RS', field: 'isTransmittedToReceiptSystem' },
      {headerName: 'CODA', field: 'isTransmittedToCoda' },

    ];

    this.getRowStyle = function(params) {
      if (params.data.invStatus === 'Y') {
        return {'background-color': 'lightcoral !important;'};
      }
      if (params.data.isOverCreditLimit === true) {
        return {'background' : '#EA3323'};
      }
    };

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name : 'Close',  event: 'close', icon : 'remove circle' },
        { name: 'Export', event: 'export', icon: 'print' },
        { name: 'AET', event: 'aet', icon: '' },
        { name: 'Sync receipts from R/S', event: 'syncReceiptsFromRS', icon: '' },
      ],
      buttonsDetail         : [ ],
      createDefaultFront    : false,
      createDefaultDetail   : false,
      toolbarType : 'label',
      label : 'Invoice Flag Status'
    };

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 175},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ],
      maxlength: 50
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150},
        {header: 'Local ETA', field: 'localEta', width: 300}
      ],
      maxLength: 20,
    };

    this.settingBound = {
      id          : 'cbBound',
      type        : 'select', // search | select | select input
      url         : '[{ "boundCode" : "O", "boundValue" : "O" }, { "boundCode" : "I", "boundValue" : "I" }]',
      urlType     : 'inject',
      placeholder : 'O',
      code        : 'boundCode',
      title       : 'boundCode', // variable name
    };
    this.settingPOL = {
      id: 'cbPOL',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'invPortOfLoadingName',
      description: '',
      columns: [
        {header: 'Location Code', field: 'invPortOfLoadingCode', width: 150},
        {header: 'Location Name', field: 'invPortOfLoadingName', width: 300},
      ],
      maxlength: 100,
    };

    this.settingPOD = {
      id: 'cbPOD',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'invPortOfDischargeName',
      description: '',
      columns: [
        {header: 'Location Code', field: 'invPortOfDischargeCode', width: 150},
        {header: 'Location Name', field: 'invPortOfDischargeName', width: 300},
      ],
      maxlength: 100,
    };

    this.settingPOT1 = {
      id: 'cbPOT',
      type: 'search enter', // search | select | select input
      url: '',
      maxChars: 0,
      template: 'grid', // default
      placeholder: 'Search ...',
      title: 'invPortOfTransshipmentName',
      description: '',
      columns: [
        {header: 'Location Code', field: 'invPortOfTransshipmentCode', width: 150},
        {header: 'Location Name', field: 'invPortOfTransshipmentName', width: 300},
      ],
    };

    this.settingPayer = {
      id          : 'cbPayer',
      type        : 'search enter', // search | select | select input
      url        : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'invPayerName',
      description  : '',
      //  isMark      : true,
        columns     : [
          {header: 'Payer Id', field: 'invPayerId', width: 100},
          {header: 'Payer Name', field: 'invPayerName', width: 300}
      ],
      maxlength: 200
    };

    this.settingAcctDebtorCode = {
      id          : 'cbAcctDebtorCode',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'invPayerAccCode',
      description  : '',
      // isMark      : true,
      columns     : [
         {header: 'Acct/Debtor Code', field: 'invPayerAccCode', width: 300, type: 'string'}
      ],
      maxlength: 8,
    };

    this.settingTransshipmentStatus = {
      id          : 'cbTransshipmentStatus',
      type        : 'select', // search | select | select input
      url         : '[{ "tsStatusCode" : "ALL", "tsStatusName" : "ALL" },' +
        '{ "tsStatusCode" : "LOCAL", "tsStatusName" : "Local" },' +
        '{ "tsStatusCode" : "TRANSSHIPMENT", "tsStatusName" : "Transshipment" }]',
      urlType     : 'inject',
      placeholder : 'ALL',
      code        : 'tsStatusCode',
      title: 'tsStatusName',
      maxlength: 100,
    };

    this.settingChequeStatus = {
      id          : 'cbChequeStatus',
      type        : 'select', // search | select | select input
      url         : '[{ "chequeStatusCode" : "ALL", "chequeStatusName" : "ALL" },' +
        '{ "chequeStatusCode" : "Cheque", "chequeStatusName" : "Cheque" },' +
        '{ "chequeStatusCode" : "Cash", "chequeStatusName" : "Cash" },' +
        '{ "chequeStatusCode" : "T/T", "chequeStatusName" : "T/T" }]',
      urlType     : 'inject',
      placeholder : 'ALL',
      code        : 'chequeStatusCode',
      title       : 'chequeStatusName'
    };

    this.settingContainerStatus = {
      id          : 'cbContainerStatus',
      type        : 'select', // search | select | select input
      url         : '[{ "containerStatusCode" : "ALL", "containerStatusName" : "ALL" },' +
        '{ "containerStatusCode" : "FULL", "containerStatusName" : "Full" },' +
        '{ "containerStatusCode" : "EMPTY", "containerStatusName" : "Empty" }]',
      urlType     : 'inject',
      placeholder : 'ALL',
      code        : 'containerStatusCode',
      title       : 'containerStatusName'
    };

    this.settingInvoiceStatus = {
      id          : 'cbInvoiceStatus',
      type        : 'select', // search | select | select input
      url         : '[{ "invoiceStatusCode" : "ALL", "invoiceStatusName" : "ALL" },' +
        '{ "invoiceStatusCode" : "ACTUAL", "invoiceStatusName" : "Actual" },' +
        '{ "invoiceStatusCode" : "PROFORMA", "invoiceStatusName" : "Proforma" }]',
      urlType     : 'inject',
      placeholder : 'ALL',
      code        : 'invoiceStatusCode',
      title       : 'invoiceStatusName'
    };

    this.settingBlRef = {
      id          : 'cbBlRef',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 5,
      template    : 'grid', // default
      placeholder : 'BL Ref.',
      title       : 'invBlNo',
      description  : '',
      // isMark      : true,
      columns     : [
        {header: 'BL Ref.', field: 'invBlNo', width: 180}
      ],
      maxlength: 20
    };

    this.settingPlacePayment = {
      id          : 'cbPlacePayment',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'invPlaceOfPaymentName', // variable name
      description : '',
      // isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'invPlaceOfPaymentCode', width: 150},
        {header: 'Location Name', field: 'invPlaceOfPaymentName', width: 300}
      ],
      maxlength: 100,
    };
  }

  ngOnInit() {
    this.model.invoiceOfficeCode = this.cookieService.getDefaultLocationCode();
    this.model.invoicePlaceOfPaymentCode = this.cookieService.getDefaultLocationCode();
    this.model.invoicePlaceOfPaymentName = this.cookieService.getDefaultLocationName();
  }
  ngAfterViewInit() {
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/getUserDetails/' +
      this.cookieService.getName().toUpperCase()).subscribe((resp) => {
      if (resp.json() !== null) {
        this.userDetails.userId = resp.json()['userId'];
        this.userDetails.userDeptCode = resp.json()['userDeptCode'];
        this.userDetails.userDeptName = resp.json()['userDeptName'];
        this.userDetails.userPhone = resp.json()['userPhone'];
        this.userDetails.userFullName = resp.json()['userFullName'];
        this.userDetails.userName = resp.json()['userName'];

        if (localStorage.getItem('close-bl-to-invoice-flag-status') != null) {
          window.scrollTo(0, 0);
          this.model = JSON.parse(localStorage.getItem('close-bl-to-invoice-flag-status'));
          console.log(this.model);

          const self = this;
          this.cbVessel.setUrl(
            this.configService.config.BASE_API.toString() +
            '/MasterVessels/findByComboBoxControl/vesselId={query}'
          );

          // vessel
          this.cbVessel.setValue(
            String(this.model.invoiceVesselId).trim(),
            function (callbackVessel) {
              self.cbVessel.setUrl(
                self.configService.config.BASE_API.toString() +
                '/MasterVessels/findByComboBoxControl/vesselName={query}'
              );

              // bound
              self.cbBound.setValue(self.model.invoiceBound);

              // voyage
              self.cbVoyage.setUrl(self.configService.config.BASE_API.toString() +
                '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
                self.cookieService.getDefaultLocationCode() + '/' + self.model.invoiceVesselId +
                '/' + self.model.invoiceBound  + '/voyage_like={query}');
              self.cbVoyage.setValue(self.model.invoiceVoyage);

              if (self.model.invoicePOLName !== '') {
                self.cbPOL.setForceValue(self.model.invoicePOLName);
              }
              if (self.model.invoicePODName !== '') {
                self.cbPOD.setForceValue(self.model.invoicePODName);
              }
              if (self.model.invoicePOT1Name !== '') {
                self.cbPOT1.setForceValue(self.model.invoicePOT1Name);
              }
              if (self.model.invoicePayerName !== '') {
                self.cbPayer.setForceValue(self.model.invoicePayerName);
              }
              if (self.model.invoicePlaceOfPaymentName !== '') {
                self.cbPlacePayment.setForceValue(self.model.invoicePlaceOfPaymentName);
              }
              if (self.model.invoiceAcctDebtCode !== '') {
                self.cbAcctDebtorCode.setForceValue(self.model.invoiceAcctDebtCode);
              }
              if (self.model.invoiceChequeStatusName !== '') {
                self.cbChequeStatus.setForceValue(self.model.invoiceChequeStatusName);
              }
              if (self.model.invoiceTransshipmentStatusName !== '') {
                self.cbTransshipmentStatus.setForceValue(self.model.invoiceTransshipmentStatusName);
              }
              if (self.model.invoiceContainerStatusName !== '') {
                self.cbContainerStatus.setForceValue(self.model.invoiceContainerStatusName);
              }
              if (self.model.invoiceStatusName !== '') {
                self.cbInvoiceStatus.setForceValue(self.model.invoiceStatusName);
              }
              // BL Ref.
              if (self.model.invoiceBlRef !== '') {
                self.cbBlRef.setValue(self.model.invoiceBlRef);
              }

              self.handleRetrieve();
            });
           localStorage.removeItem('close-bl-to-invoice-flag-status');
        } else {
          this.cbBound.setValue('O');
          this.cbChequeStatus.setValue('ALL');
          this.cbContainerStatus.setValue('ALL');
          this.cbTransshipmentStatus.setValue('ALL');
          this.cbInvoiceStatus.setValue('ALL');
          this.cbPlacePayment.setForceValue(this.model.invoicePlaceOfPaymentName);

          this.model.invoiceBound = 'O';
          this.model.invoiceChequeStatusCode = 'ALL';
          this.model.invoiceContainerStatusCode = 'ALL';
          this.model.invoiceTransshipmentStatusCode = 'ALL';
          this.model.invoiceStatusCode = 'ALL';

          this.setBound();
        }

      }
    });
  }

  setBound() {
    console.log('set bound invoked');
    if (this.userDetails.userDeptCode === '') {
      this.cbBound.clearSelect();
      this.cbBound.setValue('O');
      this.model.invoiceBound = 'O';
    } else {
      if (this.userDetails.userDeptCode.substr(0, 1) === 'I') {
        this.cbBound.clearSelect();
        // this.cbBound.setForceValue('I');
        this.cbBound.setValue('I');
        this.model.invoiceBound = 'I';
      } else {
        this.cbBound.clearSelect();
        this.cbBound.setValue('O');
        this.model.invoiceBound = 'O';
      }
    }
  }

  init() {
    this.currentMenu = this.cookieService.getCookie('currentMenu').split('|')[1];
    if (this.currentMenu.includes('(View Only)')) {
      this.viewOnlyStatus = true;
    }
  }

  toolbarEvent(event: any) {
    switch (event) {
      case 'retrieve':
        this.handleRetrieve();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'close':
        this.router.navigateByUrl('/main/home');
        break;
      case 'export':
        if ( this.gridData.length > 0 ) {
          this.gridApi.exportDataAsExcel();
        } else {
          this.dialogPlugin.show('information', 'Information',
            'No record to export.', 'okonly',
            {ok: 'this.clearGrid();'});
        }
        break;
      case 'aet':
        this.handleAET();
        break;
      case 'syncReceiptsFromRS':
        this.handleSyncReceiptsFromRS();
        break;
      default:
        break;
    }
  }

  /** Combo Change Event Block **/
  changeEventVessel(event) {
    if (event.vesselId != null && event.vesselId !== '' && event.vesselId !== undefined) {
      this.model.invoiceVesselId = event.vesselId;
      this.model.invoiceVesselName = event.vesselName;
      this.model.invoiceVesselCode = event.vesselCode;
      this.model.invoiceVoyage = '';
      this.cbVoyage.setValue('');
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
        this.cookieService.getDefaultLocationCode() + '/' + event.vesselId + '/' + this.model.invoiceBound  + '/voyage_like={query}');
      this.cbBlRef.setValue('');
      this.cbBlRef.setUrl('');
      this.model['error-invoiceVesselId'] = "";
      if (this.model['error-invoiceVoyage'] === "") {
        this.isError = false;
      }
    } else {
      this.model.invoiceVesselId = '';
      this.model.invoiceVesselName = '';
      this.model.invoiceVesselCode = '';
      this.model.invoiceVoyage = '';
      this.cbVoyage.setValue('');
      this.cbVoyage.setUrl('');
      this.cbBlRef.setValue('');
      this.cbBlRef.setUrl('');
    }
  }
  changeEventVoyage(event) {
    if (event.voyage != null) {
      this.model.invoiceVoyage = event.voyage;
      this.model.invoiceBound = event.bound;
      this.model['error-invoiceVoyage'] = "";
      if (this.model['error-invoiceVesselId'] === "") {
        this.isError = false;
      }

      this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPayer/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
        this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
      this.cbPOL.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOL/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
        this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
      this.cbPOD.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOD/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
        this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
      this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOT/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
        this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
      this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findPOP/{query}');
      this.cbAcctDebtorCode.setUrl(this.configService.config.BASE_API.toString() +
        '/invoice/findAcctCode/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
        this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
    } else {
      this.model.invoiceVoyage = '';
    }
    this.cbBlRef.setValue('');
    this.model[`error-invoiceBlRef`] = "";
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }

  private getBlRefSearchUrl() {

    if (!this.model.invoiceVesselId || !this.model.invoiceVoyage) { return ''; }

    const paramOfficeCode = this.model.invoiceOfficeCode;
    const paramVslCode = this.model.invoiceVesselCode.replace('/', '*slash*');
    const paramVoyageCode = this.model.invoiceVoyage.replace('/', '*slash*');
    const paramInvBound = this.model.invoiceBound;
    const paramPOLCode = this.genericUtil.decode(this.model.invoicePOLCode || '*');
    const paramPODCode = this.genericUtil.decode(this.model.invoicePODCode || '*');
    const paramPOT1Code = this.genericUtil.decode(this.model.invoicePOT1Code || '*');
    const paramPayerCode = this.genericUtil.decode(this.model.invoicePayerCode || '*');
    const paramAcctDebtCode = this.genericUtil.decode(this.model.invoiceAcctDebtCode || '*');
    const paramPlaceOfPaymentCode = this.genericUtil.decode(this.model.invoicePlaceOfPaymentCode || '*');
    const paramChequeStatusCode = this.model.invoiceChequeStatusCode;
    const paramTransshipmentStatusCode = this.model.invoiceTransshipmentStatusCode;
    const paramContainerStatusCode = this.model.invoiceContainerStatusCode;
    const paramStatusCode = this.model.invoiceStatusCode;

    // return 'http://localhost:12345' + `/invoice/invoiceFlagStatus/findBlRefByPrefix/${paramOfficeCode}/${paramVslCode}/${paramVoyageCode}/${paramInvBound}/${paramPOLCode}/${paramPODCode}/${paramPOT1Code}/${paramPayerCode}/${paramAcctDebtCode}/${paramPlaceOfPaymentCode}/${paramChequeStatusCode}/${paramTransshipmentStatusCode}/${paramContainerStatusCode}/${paramStatusCode}/{query}`;
    return this.configService.config.BASE_API.toString() + `/invoice/invoiceFlagStatus/findBlRefByPrefix/${paramOfficeCode}/${paramVslCode}/${paramVoyageCode}/${paramInvBound}/${paramPOLCode}/${paramPODCode}/${paramPOT1Code}/${paramPayerCode}/${paramAcctDebtCode}/${paramPlaceOfPaymentCode}/${paramChequeStatusCode}/${paramTransshipmentStatusCode}/${paramContainerStatusCode}/${paramStatusCode}/{query}`;
  }

  changeEventBound(event) {
    this.model.invoiceBound = event.boundCode;

    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
    this.cookieService.getDefaultLocationCode() + '/' + this.model.invoiceVesselId + '/' + this.model.invoiceBound  +
      '/voyage_like={query}');
    this.cbPayer.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPayer/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
      this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
    this.cbPOL.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOL/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
      this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
    this.cbPOD.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOD/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
      this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
    this.cbPOT1.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOT/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
      this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
    this.cbPlacePayment.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findPOP/{query}');
    this.cbAcctDebtorCode.setUrl(this.configService.config.BASE_API.toString() +
      '/invoice/findAcctCode/' + this.model.invoiceVesselCode.replace('/', '*slash*') + '/' +
      this.model.invoiceVoyage.replace('/', '*slash*') + '/' + this.model.invoiceBound + '/' + '{query}');
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());

  }
  changeEventPOL(event) {
    if (event.invPortOfLoadingCode != null && event.invPortOfLoadingCode !== '' && event.invPortOfLoadingCode != undefined) {
      this.model.invoicePOLCode = event.invPortOfLoadingCode;
      this.model.invoicePOLName = event.invPortOfLoadingName;
    } else {
      this.model.invoicePOLCode = '';
      this.model.invoicePOLName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventPOD(event) {
    if (event.invPortOfDischargeCode != null && event.invPortOfDischargeCode !== '' && event.invPortOfDischargeCode != undefined) {
      this.model.invoicePODCode = event.invPortOfDischargeCode;
      this.model.invoicePODName = event.invPortOfDischargeName;
    } else {
      this.model.invoicePODCode = '';
      this.model.invoicePODName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventPOT1(event) {
    if (event.invPortOfTransshipmentCode != null && event.invPortOfTransshipmentCode !== '' && event.invPortOfTransshipmentCode != undefined) {
      this.model.invoicePOT1Code = event.invPortOfTransshipmentCode;
      this.model.invoicePOT1Name = event.invPortOfTransshipmentName;
    } else {
      this.model.invoicePOT1Code = '';
      this.model.invoicePOT1Name = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventPlacePayment(event) {
    if (event.invPlaceOfPaymentCode != null && event.invPlaceOfPaymentCode !== '' && event.invPlaceOfPaymentCode != undefined) {
      this.model.invoicePlaceOfPaymentCode = event.invPlaceOfPaymentCode;
      this.model.invoicePlaceOfPaymentName = event.invPlaceOfPaymentName;
    } else {
      this.model.invoicePlaceOfPaymentCode = '';
      this.model.invoicePlaceOfPaymentName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventChequeStatus(event) {
    console.log('cheque fired');
    console.log(event);
    if (event.chequeStatusCode != null && event.chequeStatusCode !== '' && event.chequeStatusCode != undefined) {
      this.model.invoiceChequeStatusCode = event.chequeStatusCode;
      this.model.invoiceChequeStatusName = event.chequeStatusName;
    } else {
      this.model.invoiceChequeStatusCode = '';
      this.model.invoiceChequeStatusName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventTransshipmentStatus(event) {
    if (event.tsStatusCode != null && event.tsStatusCode !== '' && event.tsStatusCode != undefined) {
      this.model.invoiceTransshipmentStatusCode = event.tsStatusCode;
      this.model.invoiceTransshipmentStatusName = event.tsStatusName;
    } else {
      this.model.invoiceTransshipmentStatusCode = '';
      this.model.invoiceTransshipmentStatusName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventContainerStatus(event) {
    if (event.containerStatusCode != null && event.containerStatusCode !== '' && event.containerStatusCode != undefined) {
      this.model.invoiceContainerStatusCode = event.containerStatusCode;
      this.model.invoiceContainerStatusName = event.containerStatusName;
    } else {
      this.model.invoiceContainerStatusCode = '';
      this.model.invoiceContainerStatusName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventInvoiceStatus(event) {
    if (event.invoiceStatusCode != null && event.invoiceStatusCode !== '' && event.invoiceStatusCode != undefined) {
      this.model.invoiceStatusCode = event.invoiceStatusCode;
      this.model.invoiceStatusName = event.invoiceStatusName;
    } else {
      this.model.invoiceStatusCode = '';
      this.model.invoiceStatusName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventAcctDebtorCode(event){
    if (event.invPayerAccCode != null && event.invPayerAccCode !== '' && event.invPayerAccCode != undefined) {
      this.model.invoiceAcctDebtCode = event.invPayerAccCode;
      this.model.invoiceAcctDebtName = event.invPayerAccCode;
    } else {
      this.model.invoiceAcctDebtCode = '';
      this.model.invoiceAcctDebtName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }
  changeEventBlRef(event) {
    if (event.invBlNo != null && event.invBlNo !== '' && event.invBlNo != undefined) {
      this.model.invoiceBlRef = event.invBlNo;
      this.model[`error-invoiceBlRef`] = "";
    } else {
      this.model.invoiceBlRef = '';
    }
  }


  changeEventPayer(event) {
    if (event.invPayerId != null && event.invPayerId !== '' && event.invPayerId != undefined) {
      this.model.invoicePayerCode = event.invPayerId;
      this.model.invoicePayerName = event.invPayerName;
    } else {
      this.model.invoicePayerCode = '';
      this.model.invoicePayerName = '';
    }
    this.cbBlRef.setValue('');
    this.cbBlRef.setUrl(this.getBlRefSearchUrl());
  }

  /** End of Combo Change Event Block **/


  /** Check Box change event block **/
  changeEventCheckBoxIsCOC(event) { if (event.target.checked) {this.model.invoiceIsCOC = true; } else { this.model.invoiceIsCOC = false; }}
  changeEventCheckBoxIsSOC(event) { if (event.target.checked) {this.model.invoiceIsSOC = true; } else { this.model.invoiceIsSOC = false; }}
  changeEventCheckBoxIsSLOT(event) { if (event.target.checked ) {this.model.invoiceIsSLOT = true; } else { this.model.invoiceIsSLOT = false; }}
  changeEventCheckBoxIsNVOCC(event) { if (event.target.checked) {this.model.invoiceIsNVOCC = true; } else {this.model.invoiceIsNVOCC = false; }}
  /** End of Check box change event **/


  /** Misc Event Block **/
  handleCancel() {
    this.isError = false;

    this.model = new InvoiceFlagStatusParam();
    this.model.invoiceOfficeCode = this.cookieService.getDefaultLocationCode();
    this.cbVessel.setValue('');
    this.cbVoyage.setValue('');
    this.cbVessel.close();
    this.setBound();

    this.cbVoyage.setUrl('');
    this.cbPOL.setUrl('');
    this.cbPOL.setValue('');
    this.cbPOD.setUrl('');
    this.cbPOD.setValue('');
    this.cbPOT1.setUrl('');
    this.cbPOT1.setValue('');
    this.cbPayer.setUrl('');
    this.cbPayer.setValue('');
    this.cbBlRef.setUrl('');
    this.cbBlRef.setValue('');

    const self = this;
    this.cbChequeStatus.clearSelect();
    this.cbTransshipmentStatus.clearSelect();
    this.cbContainerStatus.clearSelect();
    this.cbInvoiceStatus.clearSelect();
    setTimeout(() => {
      self.cbChequeStatus.setValue('ALL');
      self.cbContainerStatus.setValue('ALL');
      self.cbTransshipmentStatus.setValue('ALL');
      self.cbInvoiceStatus.setValue('ALL');

      self.model.invoiceChequeStatusCode = 'ALL';
      self.model.invoiceChequeStatusName = 'All';
      self.model.invoiceContainerStatusCode = 'ALL';
      self.model.invoiceContainerStatusName = 'All';
      self.model.invoiceTransshipmentStatusCode = 'ALL';
      self.model.invoiceTransshipmentStatusName = 'All';
      self.model.invoiceStatusCode = 'ALL';
      self.model.invoiceStatusName = 'All';

    }, 10);
    this.isLoad = false;


    // reset ag-grid
    this.rowData = [];

    // reset progress bar
    this.progressBarValue = 0;
    this.progressBarMessage = '';
    this.onProgress();

    // reset scroll position
    window.scrollTo(0, 0);
  }

  handleRetrieve() {
    console.log('retrieve event invoked');
    console.log(this.model);

    // clear error before validating
    this.clearGrid();
    this.model['error-invoiceVesselId'] = '';
    this.model['error-invoiceVoyage'] = '';
    this.model['error-invoiceContainer'] = '';
    this.isError = false;

    if (this.model.invoiceVesselId === '') {
      this.isError = true;
      this.model['error-invoiceVesselId'] = 'Please input vessel.';
    }

    if (this.model.invoiceVoyage === '') {
      this.isError = true;
      this.model['error-invoiceVoyage'] = 'Please input voyage.';
    }

    if (!this.checkContainerOwnership()) {
      this.isError = true;
      this.model['error-invoiceContainer'] = 'Please select at least one [COC/SOC/SLOT/NVOCC].';
    }

    if (!this.isError) {
      console.log('all ok, proceed for retrieval');
      this.loading = true;
      const self = this;
      this.genericService.POST(this.configService.config.BASE_API.toString() +
        '/invoice/findInvoiceFlagStatus/', this.model).subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['status'] === 'ok') {
                resp.json()['data'].forEach(dt => {
                  self.gridData.push({
                    collection : dt['paymentMode'],
                    blReference : dt['invBlNo'],
                    invoicePayer : dt['invPayerName'],
                    invoiceReference: dt['invNo'],
                    actualInvoicePrinted: dt['invIsPrintedActual'],
                    invoiceSplitByCurrency: dt['invIsPrintByCurrency'],
                    convertSGD: dt['invIsConvertedToSgd'] === 'Y' ? 'N' : 'Y',
                    invoiceCurrency: dt['invHomeCurrency'],
                    totalAmount: dt['invTotalAmount'],
                    acctDebtorCode: dt['invPayerAccCode'],
                    CNRef: dt['cnREFS'],
                    DNRef: dt['dnREFS'],
                    creditLimit: dt['creditLimit'],
                    creditDays: dt['invPayerCreditDays'] + ' day' + (dt['invPayerCreditDays'] <= 1 ? '' : 's'),
                    printedBy: dt['invPrintedBy'],
                    isOverCreditLimit: dt['isOverCreditLimit'],
                    isTransmittedToReceiptSystem: dt['isTransmittedToReceiptSystem'],
                    isTransmittedToCoda: dt['isTransmittedToCoda'],
                    invStatus: dt['invStatus'] === 'C' ? 'Y' : 'N'
                  });
                });

                self.gridApi.updateRowData({
                  add:  self.gridData
                });

                self.loading = false;
          } else {
            this.frmDialogMessage = 'No record found.';
            this.genericUtil.showDialog(
              'not-found',
              'Information',
              350,
              150
            );
            /*
            this.dialogPlugin.show('information', 'Information',
              'No record found.', 'okonly',
              {ok: 'this.loading=false; this.clearGrid();'});
             */
          }
        }
        }, err => {
          this.dialogPlugin.show('error', 'Error',
            'An error has occurred.', 'okonly',
            { ok: 'this.loading=false' });``
          this.loading = false
        })
    }

  }

  onDismiss() {
    this.genericUtil.closeDialog('not-found');
    this.loading = false;
    this.clearGrid();
  }

  clearGrid() {
    console.log('clear grid invoked');
    this.gridData = [];
    this.gridApi.setRowData([]);
  }


  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }
  eventMessage(event) {}

  blockEnter(event) {
    if (event.keyCode === 32) {
      event.preventDefault();
    }
  }
  /** End of Misc Event Block **/
  onDialogBlYesDelete() {
    console.log(this.model);
    this.continueToBLMaintenance(this.model.selected);
    this.genericUtil.closeDialog('blDeleteDialogYesNo');
  }

  onDialogBlNoDelete() {
    this.genericUtil.closeDialog('blDeleteDialogYesNo');
  }

  onDialogBlYesLocked() {
    this.continueToBLMaintenance(this.model.selected);
    this.genericUtil.closeDialog('blLockedDialogYesNo');
  }
  onDialogBlNoLocked() {
    this.genericUtil.closeDialog('blLockedDialogYesNo');
    localStorage.removeItem("navigateFromTransactionInvoiceFlagStatus");
  }

  rowDoubleClick(event) {
    console.log('doubleClick Event');
    console.log(event);
    this.genericService.GET(this.configService.config.BASE_API.toString() +
      '/BL/checkBlByBlNo/' + this.model.invoiceOfficeCode + '/' + event.data.blReference).subscribe((resp) => {
        console.log(resp.json());
        const dt = resp.json();
        this.model.selected = event.data.blReference;

        if (dt.blStatus === 'D') {
          this.frmDialogMessage = 'Current B/L is Deleted by ' + dt.blUserDeleted + ', View Only?';
          this.genericUtil.showDialog('blDeleteDialogYesNo', 'Confirm', 350, 150);
        } else {
          if (dt.blLock === 'Y') {
            // this.fullBookingNo =  dt.bPrefix + '-' + dt.bNo;
            this.frmDialogMessage = 'Current BL is locked! View only?';
            localStorage.setItem("navigateFromTransactionInvoiceFlagStatus", event.data.blReference);
            this.genericUtil.showDialog('blLockedDialogYesNo', 'Confirm', 350, 150);
          } else {
             this.continueToBLMaintenance(event.data.blReference);
          }
      }
    });
  }
  continueToBLMaintenance(blNo: string) {
    this.genericUtil.showLoaderPage();
    const self = this;
    // console.log('Above set time out ');
    setTimeout(function () {
      self.model.selected = blNo;

      localStorage.setItem('retrieve-bl-from-invoice-flag', JSON.stringify(self.model));

      // grid logic to retain previous retrieval value
      localStorage.setItem(
        'invoice-flag-status-row-data',
        JSON.stringify(self.gridApi.data)
      );

      self.router.navigate(['/main/transaction-bl-maintenance-latest']);
    }, 100);
    this.genericUtil.hideLoader();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.hideOverlay();
  }

  onProgress() {
    $('#blflag-progress-bar').progress({
      percent: this.progressBarValue
    });
  }

  CharNumOnlyNoSpace(event: any) {
    const regexPattern = new RegExp('[A-Za-z0-9]');
    this.checkRegex(event, regexPattern);
  }

  CharNumOnly(event: any) {
    const regexPattern = /^[a-zA-Z0-9]*$/;
    this.checkRegex(event, regexPattern);
  }

  CharOnly(event: any) {
    const regexPattern = new RegExp('[a-zA-Z ]');
    this.checkRegex(event, regexPattern);
  }

  checkRegex(event: any, regexPattern:any) {
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  CharNumOnlyInput(event) {
    const regexPattern = /[A-Za-z0-9 ]/;
    const text = event.target.value;
    if (regexPattern.test(text) == false) {
      event.preventDefault();
    }
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = ''
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');

    // GSO-769 validation remain even after paste
    if(field === 'invoiceBlRef'){
      event.preventDefault();
      pastedText = pastedText.replace(" ", "").trim();
      this.cbBlRef.setForceValue(pastedText);
    }
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets and space' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = '';
    }
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case 'Backspace':
      case 'ControlLeft':
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        break;
      default:
        return;
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet = false, validSPChar) {
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = '';
    }
  }

  checkContainerOwnership() {
    return this.model.invoiceIsCOC || this.model.invoiceIsNVOCC || this.model.invoiceIsSLOT || this.model.invoiceIsSOC
  }

  handleAET() {
    this.genericService.GET(this.configService.config.BASE_API.toString() +
      '/invoice/unprintedAI/sendDailyEmailReport').subscribe((resp) => {
        if (resp.ok) {
          if (resp.json()['status'] === 'ok') {
            this.genericUtil.showDialog(
              resp.json()['message'],
              'Information',
              350,
              150
            );
          }
        }
      }, err => {
        this.dialogPlugin.show('error', 'Error',
          'An error has occurred.', 'okonly',
          { ok: 'this.loading=false' }); ``
        this.loading = false
      })
  }

  handleSyncReceiptsFromRS(){
    this.loading = true;
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/invoice/triggerSyncReceipt')
    .subscribe(data => {
      this.messagesyncReceiptsFromRS = data.json()['message'];
      this.genericUtil.showDialog('syncReceiptsFromRS', 'Information', 350, 150);
      this.loading = false;
    }, err => {
      this.dialogPlugin.show('error', 'Error',
      'An error has occurred.', 'okonly',
      { ok: 'this.loading=false' }); ``
    this.loading = false
    })
  }

  offsyncReceiptsFromRS() {
    this.genericUtil.closeDialog('syncReceiptsFromRS');
  }

}
