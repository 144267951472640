export default class FormValidation {
  stringOnly: string = "A-Za-z";
  numOnly: string = "0-9";
  regexPhone: string = "0-9-&,.()+*#/ ";
  regPhone: RegExp = /^[0-9-(.)&+*#/, ]*$/;
  regAllChar: RegExp = /^[\sA-Za-z0-9~`!@#$%^&*()-_=+\|[{\]}:;"'.>,<?\/\\]*$/;
  regName: RegExp = /^[a-zA-Z0-9-().,/ ]*$/;
  regFileName: RegExp = /^[a-zA-Z0-9-() \. _]*$/;
  regNum: RegExp = /^[0-9]*$/;
  regVesVoy: RegExp = /^[a-zA-Z0-9-./ ]*$/;
  regAlphaNumeric: RegExp = /^[a-zA-Z0-9]*$/;
  regAlphaNumericWSpace: RegExp = /^[a-zA-Z0-9 ]*$/;
  regAlphaNumericWSpaceEnter: RegExp = /^[a-zA-Z0-9↵ ]*$/;
  regSurchargeMaster: RegExp = /^[a-zA-Z0-9 '@,^&*(.)\-+[\/:;"\]]*$/;
  regNumOnly: RegExp = /^-?[0-9]\d*(\.\d+)?$/;
  regNegative: RegExp = /^[0-9]\d*(\.\d+)?-?$/;
  regString: RegExp = /^[A-Za-z]*$/;
  regVesVoyNoSpace: RegExp = /^[a-zA-Z0-9-./]*$/;
  regStringOnly: RegExp = /^[a-zA-Z]*$/;
  regStringSpacesOnly: RegExp = /^[a-zA-Z ]*$/;
  regDecimal4: RegExp = /^-?\d*(\.\d{0,4})?$/;
  regStringSpace: RegExp = /^[a-zA-Z ]*$/;
  regAlphabetsCommaDash: RegExp = /^[a-zA-Z-, ]*$/;
  regPortnetReference: RegExp = /^[a-zA-Z0-9-/]*$/;
  regBillingParty: RegExp = /^[a-zA-Z0-9-']*$/;
  regBillingPartyAndSpace: RegExp = /^[a-zA-Z0-9-' ]*$/;
  regOnlyIMO: RegExp = /^[imoIMO]*$/;
  regOnlySpecialChar: RegExp = /[^a-zA-Z0-9\/\s.,() -]/;
  

  constructor() {}

  ModifiedPattern(
    event: any,
    otherPattern: string = "",
    withCharNum: boolean = true,
    withQuote: boolean = false
  ) {
    const regularPattern = withCharNum ? "A-Za-z0-9 " : "";
    const quote = withQuote ? `"` : "";

    const regexPattern = new RegExp(
      `[${regularPattern + otherPattern}${quote}]`,
      "g"
    );

    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  AlphabetsOnly(event: any) {
    const regexPattern = /[A-Za-z ]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  AlphabetsOnlyWOSpace(event: any) {
    const regexPattern = /[A-Za-z]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }
  AlphabetsIncludeSpace(event: any) {
    const regexPattern = /^[A-Za-z\s]+$/
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  PhoneNum(event: any) {
    const regexPattern = /[0-9-(.)&+*#/,]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  BillingPartyAndSpace(event: any) {
    if (this.regBillingPartyAndSpace.test(event.key) == false) {
      event.preventDefault();
    }
  }

  CharNumOnly(event: any) {
    const regexPattern = /[A-Za-z0-9 ]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  CharNumOnlyWOSpace(event: any) {
    const regexPattern = /[A-Za-z0-9]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  CharNumSpecialCharOnly(event: any) {
    const regexPattern = /[A-Za-z0-9-'@,^&*(\)\+[\]/:;"\.  ]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  AllChar(event: any) {
    if (this.regAllChar.test(event.key) == false) {
      event.preventDefault();
    }
  }

  Address(event: any) {
    const regexPattern = /^[a-zA-Z0-9-.&@^*()_+';:/,\\" ]*$/;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumberOnly(event: any) {
    const regexPattern = /^[-0-9]\d*(\.\d+)?$/;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumWDot(event: any) {
    const regexPattern = /^[0-9\.]*$/;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumWOnly(event: any) {
    if (this.regNum.test(event.key) == false) {
      event.preventDefault();
    }
  }

  NumWDash(event: any) {
    const regexPattern = /^[0-9\-]*$/;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  BoundValidator(event: any) {
    const regexPattern = /^[iImMoO]*$/;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  onPaste(event: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.regName.test(pastedText) == false) {
      event.preventDefault();
    }
  }

  onPasteNumOnly(event: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.regNum.test(pastedText) == false) {
      event.preventDefault();
    }
  }

  onPasteVesVoy(event: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.regVesVoy.test(pastedText) == false) {
      event.preventDefault();
    }
  }

  onPasteVesVoyWithError(event: any, field: string, callback: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.regVesVoy.test(pastedText) == false) {
      callback(false, field);
    } else {
      callback(true, field);
    }
  }

  keypressEventRegexWith(event: any, model: any, field: string, regex: string) {
    var regex = regex;
    var regexPattern = new RegExp(regex);
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
    if (event.target.value > 0) model[`error-${field}`] = "";

  }

  pasteEventRegexWith(event: any, model: any, field: string, regex: string, lengthChar: Number) {
    let re = new RegExp(regex, "g");
    let pastedText = event.clipboardData.getData("text").replace(re, "");
    event.preventDefault();
    if (pastedText.length > lengthChar) pastedText = pastedText.slice(0, lengthChar);
    event.target.value = pastedText;
    model[field] = pastedText;
    if (pastedText.length > 0) model[`error-${field}`] = "";
    return
  }

  pasteEventRegexWithV2(event: any, model: any, field: string, regex: string, lengthChar: Number) {
    let re = new RegExp(regex, "g");
    let pastedText = event.clipboardData.getData("text").replace(re, "");
    let text = model[field] + pastedText;

    if (text.length > lengthChar) {
      event.preventDefault();
      pastedText = text.slice(0, lengthChar);
      event.target.value = pastedText;
      model[field] = pastedText;
    } else {
      event.preventDefault();
      const textSelection = window.getSelection().toString().trim();
      if (textSelection == model[field].trim()) {
        model[field] = pastedText;
      } else {
        model[field] = text;
      }
    }
    if (pastedText.length > 0) model[`error-${field}`] = "";
    return
  }

  validateVesselVoyage(event: any) {
    if (this.regVesVoy.test(event.key) == false) {
     event.preventDefault();
    }
  }

  validateFileName(event: any) {
    if (this.regFileName.test(event.key) == false) {
     event.preventDefault();
    }
  }

  decimalFilter(event: any) {
    const reg = /^\d+?\d*(\.\d{0,4})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
        event.preventDefault();
    }
  }

  pasteDecimalFilter(event: any) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (this.regDecimal4.test(pastedText) == false) {
      event.preventDefault();
    }
  }

  validatePortnetReference(event: any) {
    if (this.regPortnetReference.test(event.key) == false) {
     event.preventDefault();
    }
  }

  validateBilling(event: any) {
    const regexPattern = /[A-Za-z0-9- ']/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  valiBoundOnlyIOM(event: any) {
    if (this.regOnlyIMO.test(event.key) == false) {
     event.preventDefault();
    }
  }

}
