import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $: any;

export class Param {
  containerNo = '';
  selected = '';
}

@Component({
  selector: 'app-transaction-bl-cross-reference-by-container-page',
  templateUrl: './transaction-bl-cross-reference-by-container-page.component.html',
  styleUrls: ['./transaction-bl-cross-reference-by-container-page.component.css']
})
export class TransactionBLCrossReferenceByContainerPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: GridPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';  
  frmDialogMessage = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingBound;
  settingGrid;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';

  msg:string = "";
  eventToolbar:string = "";


  // lock
  cButtonLock = true;
  dButtonLock = true;

  param = new Param();
  isError = false;

  validatorRules = {
    containerNo: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Container No.'
      }]
    },
  };


  constructor(private router: Router, private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Cancel', event: 'cancel', icon: 'remove'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL Cross Reference By Container'
    }

    this.settingBound = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingGrid = {
     url: this.configService.config.BASE_API.toString() + '/BL/findBLCrossRefByContainer',
   // url: 'http://localhost:15000/BL/findBLCrossRefByContainer',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 200},
        {header: 'Vessel', field: 'blOfficeVesselName', width: 200},
        {header: 'Voyage', field: 'blOfficeVesselVoyage', width: 200},
        {header: 'ETA', field: 'eta', width: 200},
        {header: 'ETD', field: 'etd', width: 200},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: true,
      sortingColumns:'eta',
      sortingDirection: 'DESC'
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
   
    if(localStorage.getItem("close-bl-to-cross-ref") != null){
      this.param = JSON.parse(localStorage.getItem("close-bl-to-cross-ref"));
      console.log(this.param);
      this.grid.search =  this.param.containerNo.toUpperCase().trim() + "/" + this.cookieService.getDefaultLocationCode();
      this.grid.loadData();
    }
    localStorage.removeItem('close-bl-to-cross-ref');

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    this.eventToolbar = event.toString();
    switch (event) {
      
      case 'retrieve' :
        this.param['error-containerNo'] = '';
        this.isError = this.onValidate(this.param);
        if (!this.isError) {
          this.grid.search =  this.param.containerNo.toUpperCase().trim() + "/" + this.cookieService.getDefaultLocationCode();
          this.grid.sortingColumns = 'eta';
          this.grid.is_sort_direction='DESC';
          this.grid.loadData();

         
        }
        break;
      case 'cancel' :
        this.param = new Param(); 
        this.param['error-containerNo'] = '';
      
        this.isError = false;

        this.grid.onClear();
        this.grid.search = '*/*';
        this.grid.onSortDir('eta','DESC');
        //this.grid.sortingColumns = 'eta';
        //this.grid.is_sort_direction='DESC';
        this.grid.loadData();
        this.grid.is_disabled_next = true;
        this.grid.is_disabled_prev = true;
        
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }

  closeMsgDialog(){
    this.genericUtil.closeDialog('msgNoRecord');
  }

  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeEventBound(event) {}
  gridEvent(event) {
     //// console.log(event);
     switch (event.split('.')[0]) {
      case 'selected':
        break;
      case 'click' :
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== '') {
          const arr: string[] = strEvent.split('-');
          switch (arr[0]) {
            case 'edit':
              break;
            case 'dblClick':
              const dt = this.grid.getSelectedValues()[0];
              

              
              if (dt.blStatus === 'D'  && dt.blIsCombine === '') {
                console.log("BLSTATUSNETI:"+dt.blStatus);

                this.frmDialogMessage = 'Current B/L is Deleted by ' + dt.blUserDeleted + ', View Only?';
                this.genericUtil.showDialog('blDeleteDialogYesNo', 'Confirm', 350, 150);

              } else if   (dt.blStatus === 'D' && dt.blIsCombine === 'Y' ){

                this.frmDialogMessage = 'Current B/L is Deleted by COMBINEBL, View Only?';
                this.genericUtil.showDialog('blDeleteDialogYesNo', 'Confirm', 350, 150);

                /* if (dt.bIsUnlocked === 'N') {
                  this.message('information', 'Confirmation', 'Current booking is locked! View only?', 'yesno', {yes: 'this.continueToMaintenance(dt.blNo);', no: ''});
                } else {
                  this.continueToMaintenance(dt.blNo);
                }

              }
              */
            
             } else {
                this.continueToBLMaintenance(dt.blNo);
              }

              break;
              case 'afterLoad' : 
          
              if(this.grid.listStore.store.length == 0 && this.eventToolbar == 'retrieve'){
                this.msg = "No record(s) found.";
                this.genericUtil.showDialog('msgNoRecord', "Information", 420, 160);                
              }

              break;

            default:
              break;
          }
        }
        break;
    }
  }

  infoGrid(event){}

  continueToBLMaintenance(blNo: string) {
    this.genericUtil.showLoaderPage();
    const self = this;
    // console.log('Above set time out ');
    setTimeout(function() {
      self.param.selected = blNo;

      localStorage.setItem('retrieve-bl-from-cross-ref', JSON.stringify(self.param));
      self.router.navigate(['/main/transaction-bl-maintenance-latest']);
    }, 100);
  }

  onDialogBlYesDelete() {
    
    const dt = this.grid.getSelectedValues()[0];
    this.continueToBLMaintenance(dt.blNo);
    this.genericUtil.closeDialog('blDeleteDialogYesNo');
  }

  onDialogBlNoDelete() {
    this.genericUtil.closeDialog('blDeleteDialogYesNo');
  }

}
