import { Component, OnInit, EventEmitter, AfterViewInit  } from '@angular/core';
import { ListStore, Validator, GenericService, ConfigService } from 'sibego-ui-library';
import { Http, Response, RequestOptions, Headers, Request, RequestOptionsArgs } from '@angular/http';
import { MReport, MReportParam } from '../shared/index';
declare  var $:any;

@Component({
  selector: 'app-machine-report-page',
  templateUrl: './machine-report-page.component.html',
  styleUrls: ['./machine-report-page.component.css']
})
export class MachineReportPageComponent extends Validator  implements OnInit, AfterViewInit {

  /* Parameter for information into Footer */
  info = '';

  test = '';
  supermariobros = '';

  dialog:any;
  loading = false;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';


  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  modeEdit = false;
  modeRun = false;
  formType = 'Edit';

  /* Parameter listStore for Grid */
  listStore = new ListStore;
  listStoreAll = new ListStore;
  model = new MReport;
  buttonAction = '';

  setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;

  setData = [
    { id : '1', rName : "report ASD", query: "select top 10 * from BLMaster where BLNo = $1 and DateCreated = $2 and BLPayer1Code = $3", reportParam:[
      {paramName:"BLNo", dtType:"String/Text"},
      {paramName:"Date Created", dtType:"Date"},
      {paramName:"Customer Code", dtType:"Number"}
    ]},
    { id : '2', rName : "report BFG", query: "select top 10 * from BLMaster where BLDateIssue between $1 and $2 and BLPlaceOfCreation = $3", reportParam:[
      {paramName:"Date From", dtType:"Date"},
      {paramName:"Date To", dtType:"Date"},
      {paramName:"Location", dtType:"String/Text"}
    ]},
    { id : '3', rName : "report XXX", query: "select * from BLMaster where BLNo = $1", reportParam:[
      {paramName:"BLNo", dtType:"String/Text"}
    ]},
    { id : '4', rName : "report XFG", query: "select * from MVessel where VesselCode = $1", reportParam:[
      {paramName:"Vessel Code", dtType:"String/Text"}
    ]},
    { id : '5', rName : "report LKP", query:"select * from MSailDetail where VesselCode = $1 and Voyage = $2 and Bound = $3 and PlaceOfCreation = $4", reportParam:[
      {paramName:"Vessel Code", dtType:"String/Text"},
      {paramName:"Vessel Voyage", dtType:"String/Text"},
      {paramName:"Vessel Bound", dtType:"String/Text"},
      {paramName:"Location", dtType:"String/Text"}
    ]}
  ];

  processData(){
    this.setData.forEach(dt=>{
      var mreport = new MReport;
      mreport.id = dt.id;
      mreport.rName = dt.rName;
      mreport.query = dt.query;
      mreport.reportParam = dt.reportParam;

      this.listStore.addData(mreport);
    });
  }


  /* status success or not */
  isSuccess:boolean = false;
  lock = false;

  /* validatorRules */
  validatorRules: any; /*= {
    rName:{
      rules:[
        {type:'empty', prompt:'Please input report name!'}
      ]
    },
    query:{
      rules:[
        {type:'empty', prompt:'Please input query'}
      ]
    }
  }*/

  constructor(private genericService:GenericService, private configService:ConfigService) {
    super();
    //this.processData();

    /**
     * Setting Toolbar
     * buttonsFront : <set buttons for Front Mode> , default = []
     * buttonsDetail : <set buttons for Detail Mode>, default = []
     * createDefaultFront : <set status to create default buttons for front mode or not>, default = true
     * createDefaultDetail : <set status to create default buttons for detail mode or not>, default = true
     */
    this.settingToolbar = {
      buttonsFront          : [
        {name:'Create', event:'create', icon:'plus'}
      ],
      buttonsDetail         : [
        {name:'Run', event:'runReport', icon:'play'},
        {name:'Cancel', event:'cancel', icon:'remove'}
      ],
      createDefaultFront    : true,
      createDefaultDetail   : true
    }

    /**
      * Setting Grid
      * url : <target url>
      * page : <page per grid>
      * columns : <set columns grid>
      *           example columns = [
                    {header: 'Name', field: "name", width: 100 },
                    {header: 'Address', field: "address", width: 200 },
                    {header: 'Country', field: 'country', width: 50, render : {
                      value: '<i class="\' + element[\'countryCode\'] + \' flag"></i>'
                    }},
                  ];
      * buttons  : <set render button into grid> , default = []
      * store    : <set store for grid>
      * enableSorting : <set enable sorting grid>
      * enableSelectAll : <set enable select all grid>
      * enablePagination : <set enable paging grid>
      */

    this.settingGrid = {
        url: '',
        page: 1,
        columns: [
          {header: 'ID', field : 'id', width: 100},
          {header: 'Report Name', field : 'rname', width: 100},
        ],
        buttons: [
          { name: 'Run', event: 'run', enabled: true, icon: 'play' },
          { name: 'Edit', event: 'edit', enabled: true, icon: 'play' }
        ],
        store: this.listStore,
        enableSorting: false,
        sortingColumns: 'id',
        sortingDirection: 'ASC',
        enableSelectAll: false,
        enablePagination: false,
        actionkey: 'id'

    }
  }

  ngOnInit(){
    this.initLoadData('');
  }

  ngAfterViewInit() {
    $('.ui.dropdown').dropdown();
    /*$('.ui.checkbox').checkbox('attach events', '.toggle.button');*/

    $('.message .close').on('click', function() {
      $(this)
        .closest('.message')
        .transition('fade')
      ;
    });

  }

  afterInit(){
    $('.ui.dropdown')
      .dropdown()
    ;

    $('.ui.calendar').calendar({
      type: 'date',
      monthFirst: false,
      formatter: {
        date: function (date, settings) {
          if (!date) return '';
          var day = date.getDate();
          var month = date.getMonth() + 1;
          var year = date.getFullYear();
          return year + '-' + month + '-' + day;
        }
      }
    });
  }

  /*infoGrid(event) {
    this.info = event;
  }*/

  infoGrid(event) {
    this.info = event;
  }

  message(txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  gridEvent(event) {
    // console.log(event);
    switch (event) {
      case 'select':

        let selectList:any[] = [];
        this.listStore.store.forEach(element => {
          if(element['select'] == 'checked'){
            selectList.push(element);
          }
        });

        //// console.log(selectList.length);
        if(selectList.length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }

        break;
      case 'select-all':
        let selectAllList:any[] = [];
        this.listStore.store.forEach(element => {
          if(element['select'] == 'checked'){
            selectAllList.push(element);
          }
        });

        //// console.log(selectAllList.length);
        if(selectAllList.length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }


        break;
      default:
        //window.alert(event);

        switch(event.split("-")[0]){
          case 'run':
            //window.alert('sippo');
            //this.loadData(event.split("-")[1]);
            this.handleProcess(event.split("-")[1], 'mode-run');
            break;
          case 'edit':
            this.setToolbarButtons('Run', 'Save', 'update', true, 'save');
            this.handleProcess(event.split("-")[1], 'mode-edit');
            break;
          case 'dblClick':
            this.setToolbarButtons('Run', 'Save', 'update', true, 'save');
            this.handleProcess(event.split("-")[1], 'mode-edit');
            break;
          default:
            break;
        }

        break;
    }
  }

  toolbarEvent(event) {
    // console.log('event : ' + event);
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        this.handleSave('save');
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'runReport':
        this.handleRun();
        break;
      default:
        // console.log('search button clicked');
        this.initLoadData(event.split(":")[1]);
        break;
    }
  }

  setToolbarButtons(editButton, editTo, eventEditTo, enableStatEditTo, icon){
    this.settingToolbar.buttonsDetail.forEach(ds=>{
      if(ds.name == editButton){
        ds.name = editTo;
        ds.event = eventEditTo;
		    ds.icon = icon;
      }
    });
  }

  handleCreate()
  {
    // handle create event
    this.modeForm = true;
    this.modeToolbar = true;
    this.modeEdit = true;
    this.isSuccess = false;
    this.formType = 'Create';

    this.setToolbarButtons('Run', 'Save', 'save', true, 'save');

  }


  handleUpdate()
  {
    // handle update event
	this.handleSave('edit');
  }

  handleDelete()
  {
    // handle delete event
    window.alert("you don't have authorization to access this fiture");
  }

  handlePrint()
  {
    // handle print event
    window.alert("you don't have authorization to access this fiture");
  }

  handleSave(action)
  {
    // handle save event
	this.buttonAction = action;
    this.validatorRules = {'rName':{'rules':[{'type':'','prompt':''}]}, 'query':{'rules':[{'type':'','prompt':''}]}};
    this.validatorRules = {
      rName:{
        rules:[
          {type:'empty', prompt:'Please input report name!'}
        ]
      },
      query:{
        rules:[
          {type:'empty', prompt:'Please input query'}
        ]
      }
    };
    var paramValueList;
    this.model.id = $('input[name*=id]').val();
    this.model.rName = $('input[name*=rName]').val();
    this.model.query = $('textarea[name*=query]').val();

	if(!this.onValidate(this.model)){
		if(parseInt($('input[name=param-count]').val()) == 0){
			this.message('Information','No parameter set for report name = '+this.model.rName+' . You should input at least one report parameter!', 'okcancel', { ok: '// console.log("ok button clicked")'});
		} else {
			for(var i=0;i<$('input[name=param-count]').val();i++){
			  paramValueList = {'id':"" ,'mreportId' : "", 'dtType' : "", 'paramName':""};
			  paramValueList.id = $('input[name="formData.'+i+'.param.code"]').val();
			  paramValueList.mreportId = this.model.id;
			  paramValueList.dtType = $('input[name="formData.'+i+'.param.dtType"]').val();
			  paramValueList.paramName = $('input[name="formData.'+i+'.param.paramName"]').val();
			  this.model.reportParam.push(paramValueList);
			}

			this.message('Confirmation','Are you sure want to save report '+this.model.rName+' ?','yesno', { yes : 'this.saveProcess()', no: '// console.log("button cancel clicked");'});
		}
	}
  }

  saveProcess(){
	// console.log(this.model);
	// console.log(this.buttonAction);
	if(this.buttonAction == 'save'){
		this.genericService.PUT(this.configService.config.BASE_API.toString()+'/Mreport/save',this.model).subscribe((resp) =>{
			  if(resp.status == 200){
				  //clear all
				  this.message('Information','Data saved sucessfully','okcancel',{ok:'// console.log("Save Sucessfully")'});
				  this.initLoadData('');
				  this.setGridEvent = 'refresh';
				  this.handleCancel();
			  }
		}, error=> {
			// console.log(error.status);
			this.message('Error','An error occur during save the data with error code: ' +error.status+', Please contact your administrator','okcancel',{ok:'// console.log("errro occur")'});
		});
	} else if(this.buttonAction == 'edit'){
		this.genericService.PUT(this.configService.config.BASE_API.toString()+'/Mreport/save',this.model).subscribe((resp) =>{
			// console.log(resp);
			if(resp.status == 200){
			  //clear all
			  this.message('Information','Data updated','okcancel',{ok:'// console.log("Save Sucessfully")'});
			  this.initLoadData('');
			  this.setGridEvent = 'refresh';
			  this.handleCancel();
			}
		}, error => {
			// console.log(error.status);
			this.message('Error','An error occur during update the data with error code: ' +error.status+', Please contact your administrator','okcancel',{ok:'// console.log("errro occur")'});
		});
	}
  }

  handleCancel(){
    // handle cancel event
    // console.log('handle cancel event');

    this.removeParam();
    $('input[name*=rName]').val('');
    $('input[name*=id]').val('');
    $('textarea[name*=query]').val('');
    $('input[name=param-count]').val(0);
    this.modeForm = false;
    this.modeToolbar = false;
    this.modeRun = false;
    this.modeEdit = false;
	  this.model = new MReport;
	  this.model['error-rName'] = '';
	  this.model['error-query'] = '';

    this.settingToolbar.buttonsDetail.forEach(dc=>{
    if(dc.name != "Cancel"){
        dc.name = "Run";
        dc.event = "runReport";
		    dc.icon = 'play';
      }
    });
  }

  addParam(){
    var i = parseInt($('input[name=param-count]').val());

    $('#param-item-list').append(
        "<div class='items-rese' id='item-rese-"+i+"'>"+
        "<div class='two fields'>"+
		    "<input type='hidden' name='formData."+i+".param.code' value=''>"+
          "<div class='field'>"+
            "<label>Data Type</label>"+
            "<div class='ui selection dropdown'>"+
              "<input type='hidden' name='formData."+i+".param.dtType'>"+
              "<i class='dropdown icon'></i>"+
              "<div class='default text'>dataType</div>"+
              "<div class='menu'>"+
                  "<div class='item' data-value='String/Text'>String/Text</div>"+
                  "<div class='item' data-value='Number'>Number</div>"+
                  "<div class='item' data-value='Date'>Date</div>"+
              "</div>"+
            "</div>"+
          "</div>"+
          "<div class='field'>"+
            "<label>Parameter Name</label>"+
            "<input type='text' name='formData."+i+".param.paramName' placeholder='Parameter Name'>"+
          "</div>"+
		  "<div class='field'>"+
			"<label>&nbsp;</label>"+
			"<button class='ui icon button' id='remove-row-"+i+"'>"+
			  "<i class='remove icon'></i>"+
			"</button>"+
		  "</div>"+
        "</div>"+
        "</div>"

    );

    $('input[name=param-count]').val(i+1);

    $('.ui.dropdown')
      .dropdown()
    ;
	$('#remove-row-'+i).click(function(){
		$('input[name=param-count]').val($('input[name=param-count]').val()-1);
		$('#item-rese-'+i).empty();
		$('#item-rese-'+i).remove();
		window.alert('nilai param count : '+$('input[name=param-count]').val());
		var z = 0;
		$('input[name*=".param."]').each(function(index){
			if($(this).attr('name').search("code") != -1 ){
				$(this).attr('name','formData.'+z+'.param.code');
			}
			if($(this).attr('name').search("dtType") != -1 ){
				$(this).attr('name','formData.'+z+'.param.dtType');
			}
			if($(this).attr('name').search("paramName") != -1 ){
				$(this).attr('name','formData.'+z+'.param.paramName');
				z++;
			}
		});
	});
  }
  remRow(t){
	  alert('sapi '+t);
  }

  dataStore = new ListStore;

  loadParam1(rCode, mode){
    window.alert('Load Param 1');
    // console.log(this.listStoreAll.store);
    this.listStoreAll.store.forEach(kl=>{

    });
  }

  loadParam(rCode, mode){
    var mreportObj = null;
    var reportParamList = null;
    this.listStoreAll.store.forEach(dt=>{
      mreportObj = new MReport;
      mreportObj.id = dt.id;
      mreportObj.rName = dt.rname;
      mreportObj.query = dt.query;
      dt.mreportparamsById.forEach(fe=>{
        reportParamList = new MReportParam;
		    reportParamList.paramId = fe.id;
        reportParamList.paramName = fe.paramname;
        reportParamList.dtType = fe.dttype;
        mreportObj.reportParam.push(reportParamList);
      });
    });

    var i = parseInt($('input[name=param-count]').val());


    if(mode == 'mode-run'){
      $('input[name*=id').val(mreportObj.id);
      $('input[name*=rName]').val(mreportObj.rName);

      mreportObj.reportParam.forEach( el => {
        if(el.dtType == 'Date'){
          $('#param-item-list-run').append(
            "<div class='items-rese'>"+
            "<div class='field'>"+
              "<label>"+el.paramName+"</label>"+
              "<div class='ui calendar' id='example2'>"+
                "<div class='ui input left icon'>"+
                  "<i class='calendar icon'></i>"+
                  "<input type='text' name='formData."+i+".paramValue' placeholder='Date'>"+
                  "<input type='hidden' name='formData."+i+".dtType' value='"+el.dtType+"'>"+
                "</div>"+
              "</div>"+
            "</div>"+
            "</div>"
          );
        } else {
          $('#param-item-list-run').append(
            "<div class='items-rese'>"+
            "<div class='field'>"+
              "<label>"+el.paramName+"</label>"+
              "<input type='text' name='formData."+i+".paramValue' placeholder='"+el.paramName+"'>"+
              "<input type='hidden' name='formData."+i+".dtType' value='"+el.dtType.toString()+"'>"+
            "</div>"+
            "</div>"
          );
        }
        i++;
      });
    } else if(mode == 'mode-edit'){
      $('input[name*=rName]').val(mreportObj.rName);
      $('input[name*=id]').val(mreportObj.id);
      $('textarea[name*=query').val(mreportObj.query);

      mreportObj.reportParam.forEach(el=>{

        $('#param-item-list').append(
            "<div class='items-rese' id='item-rese-"+i+"'>"+
            "<div class='two fields'>"+
			      "<input type='hidden' name='formData."+i+".param.code' value='"+el.paramId+"'>"+
              "<div class='field'>"+
                "<label>Data Type</label>"+
                "<div class='ui selection dropdown'>"+
                  "<input type='hidden' name='formData."+i+".param.dtType' value='"+el.dtType+"'>"+
                  "<i class='dropdown icon'></i>"+
                  "<div class='default text'>dataType</div>"+
                  "<div class='menu'>"+
                      "<div class='item' data-value='String/Text'>String/Text</div>"+
                      "<div class='item' data-value='Number'>Number</div>"+
                      "<div class='item' data-value='Date'>Date</div>"+
                  "</div>"+
                "</div>"+
              "</div>"+
              "<div class='field'>"+
                "<label>Parameter Name</label>"+
                "<input type='text' name='formData."+i+".param.paramName' placeholder='Parameter Name' value='"+el.paramName+"'>"+
              "</div>"+
			  "<div class='field'>"+
				"<label>&nbsp;</label>"+
				"<button class='ui icon button' id='remove-row-"+i+"'>"+
				  "<i class='remove icon'></i>"+
				"</button>"+
			  "</div>"+
            "</div>"+
            "</div>"

        );
		$('#remove-row-'+i).click(function(){
			alert($(this).attr('id') );
			$('input[name=param-count]').val($('input[name=param-count]').val()-1);
			$('#item-rese-'+$(this).attr('id').split('-')[2]).empty();
			$('#item-rese-'+$(this).attr('id').split('-')[2]).remove();
			window.alert('nilai param count : '+$('input[name=param-count]').val());
			var z = 0;
			$('input[name*=".param."]').each(function(index){
				if($(this).attr('name').search("code") != -1 ){
					$(this).attr('name','formData.'+z+'.param.code');
				}
				if($(this).attr('name').search("dtType") != -1 ){
					$(this).attr('name','formData.'+z+'.param.dtType');
				}
				if($(this).attr('name').search("paramName") != -1 ){
					$(this).attr('name','formData.'+z+'.param.paramName');
					z++;
				}
			});
		});
        i++;
      });
    }

    $('input[name=param-count]').val(i);

    this.afterInit();
  }

  handleProcess(rCode, mode){
    //window.alert('rcode : '+ rCode);
    this.modeForm = true;
    this.modeToolbar = true;

    if(mode == 'mode-run')
      this.modeRun = true;
    if(mode == 'mode-edit')
      this.modeEdit = true;

    this.loadData(rCode, mode);
  }

  removeParam(){
    $('.items-rese').empty();
    $('.items-rese').remove();
  }

  loadData(rCode, mode){
    this.listStore.store.forEach(each=>{
      if(rCode == each.id){
        this.listStoreAll.addData(each);
      }
    });

    this.loadParam(rCode, mode);

  }

  handleRun(){
    var paramValueList;
    var mreportRun = new MReport;
    mreportRun.id = $('input[name*=id]').val();
    mreportRun.rName = $('input[name*=rName]').val();
    // console.log("param count : "+$('input[name=param-count]').val());
	var paramString = "";
    for(var i=0;i<$('input[name=param-count]').val();i++){
      paramValueList = {'id':"", 'dtType' : "", 'value':""};
      paramValueList.id = i.toString();
      paramValueList.dtType = $('input[name="formData.'+i+'.dtType"]').val();
      paramValueList.value = $('input[name="formData.'+i+'.paramValue"]').val();
      mreportRun.reportParam.push(paramValueList);

	  paramString = paramString + paramValueList.value + '@';
    }
    // console.log(paramString);

	var hitUrl = this.configService.config.BASE_API.toString() + '/Mreport/runReport/'+mreportRun.id+'/'+mreportRun.rName+'/'+paramString;

	window.open(hitUrl);

  }

  initLoadData(val){
    this.loading = true;
    // console.log('isi val '+ val);
    var hitUrl = this.configService.config.BASE_API.toString() + '/Mreport/findByRName';
    var searchText = (val = '' ? '' : val);
    let body = {
      rName: searchText,
      currentPage: 0,
      page: 0,
      direction: 'ASC',
      columnSort: 'id'
    }


    this.genericService.POST(hitUrl,body).subscribe((resp) =>{
      if(resp.status == 200){
        this.listStore.store = resp.json()['content'];
        // console.log(this.listStore.store);
        this.listStore.store.forEach(each => {
          each['select'] = '';
          each['no'] = each.id;
        });
        if(this.listStore.store.length == 0)
          this.message('NOT FOUND','No data found','okcancel',{ok:'// console.log("errro occur")'});
      }
    }, error=> {
			// console.log(error.status);
			this.message('Error','An error occur during retrieve data with error code: ' +error.status+', Please contact your administrator','okcancel',{ok:'// console.log("errro occur")'});
		});
    this.loading = false;
  }

  hideErrorMessage(key){
    if(key == 'rName'){
      this.validatorRules = {'rName':{'rules':[{'type':'','prompt':''}]}};
      this.validatorRules = {
        rName:{
          rules:[
            {type:'empty', prompt:'Please input report name!'}
          ]
        }
      };
      this.model.rName = $('input[name*="'+key+'"]').val();
    }
    if(key == 'query'){
      this.validatorRules = {'query':{'rules':[{'type':'','prompt':''}]}};
      this.validatorRules = {
        query:{
          rules:[
            {type:'empty', prompt:'Please input query'}
          ]
        }
      };
      this.model.query = $('textarea[name*="'+key+'"]').val();
    }
	this.onValidate(this.model);
  }

  ajaxHitURL(method, hitUrl, header, successMessage, errorMessage){

  }

}
