import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-transaction-charge-tab-page',
  templateUrl: './transaction-charge-tab-page.component.html',
  styleUrls: ['./transaction-charge-tab-page.component.css']
})
export class TransactionChargeTabPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('gridHeader') gridHeader: GridPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'sailingScheduleFlagStatus';

  loading = false;

  /* Parameter settings */
  settingToolbar;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  // setting
  settingVessel;
  settingVoyage;

  settingGridHeader;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Charge Tab History'
    }

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Vessel',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 200},
        {header: 'Vessel Name', field: 'vesselName', width: 350},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search Voyage',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingGridHeader = {
      id: 'gridHeader',
      url: '',  //this.configService.config.BASE_API.toString() + '/EdiPortMapping/findEdiPortMapping',
      page: 10,
      columns: [
        {header: 'Seq', field: 'no', width: 50},
        {header: 'BL No.', field: 'blNo', width: 150},
        {header: 'BL Last Modified By', field: 'userModified', width: 250},
        {header: 'BL Last Modified Date/Time', field: 'dateModified', width: 250},
        {header: 'Invoice No.', field: 'invoiceNo', width: 150},
        {header: 'Bill to (Before)', field: 'billToBefore', width: 150},
        {header: 'Cust. Acct. Code', field: 'custAcctCode', width: 200},
        {header: 'Qty (Before)', field: 'qtyBefore', width:150},
        {header: 'Charge (Before)', field: 'chargeBefore', width: 180},
        {header: 'Unit Amt (Before)', field: 'unitAmtBefore', width: 180},
        {header: 'Total Amt (Before)', field: 'totalAmtBefore', width: 200},
        {header: 'CODA', field: 'codaFlagBefore', width: 80},
        {header: 'CN/DN', field: 'cndnFlagBefore', width: 80},
        {header: 'RS', field: 'rsFlagBefore', width: 80},
        {header: 'Bill to (After)', field: 'billToAfter', width: 150},
        {header: 'Cust. Acct. Code', field: 'custAcctCodeAfter', width: 150},
        {header: 'Qty (After)', field: 'qtyAfter', width: 150},
        {header: 'Charge (After)', field: 'chargeAfter', width: 150},
        {header: 'Unit Amt (After)', field: 'unitAmtAfter', width: 180},
        {header: 'Total Amt (After)', field: 'totalAmtAfter', width: 150},
        {header: 'CODA', field: 'codaFlagAfter', width: 80},
        {header: 'CN/DN', field: 'cndnFlagAfter', width: 80},
        {header: 'RS', field: 'rsFlagAfter', width: 80},
        {header: 'Reason(s)', field: 'reason', width: 250},
        {header: 'Reprinted Date/Time', field: 'dateReprinted', width: 180},
        {header: 'Reprinted By', field: 'userReprinted', width: 150},
        {header: 'Invoice Date/Time', field: 'invoiceDate', width: 150},
        {header: 'Printed By', field: 'printedBy', width: 150}
      ],
      buttons: [{name: 'Edit', event: 'edit', enabled: true} ],
      store: '',
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingDirection: 'ASC',
      sortingColumns: 'blNo',
      auto : false
  };
  }

  ngOnInit() {
  }

  ngAfterViewInit() {

    var lenGrid = this.gridHeader.listStore.store.length
           console.log('afterloading')

           $('#gridHeader-table-head').each(function(i) {
            console.log('masuk loading');

          //var rowGrid = $('#container-table tr').length-1;
          //for(var k=1;k<=lenGrid;k++){
           // console.log('RowSPAN '+(k)+$(this).find("span[id='gridContainer.span."+k+".container']").text());
            //var textVal = $(this).find("span[id='gridContainer.span."+k+".container']").text();

           
              $(this).find("th[id='gridHeader-table-head-th-billToAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-custAcctCodeAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-qtyAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-chargeAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-unitAmtAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-totalAmtAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-codaFlagAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-cndnFlagAfter']").css("background-color", "#14fc52");
              $(this).find("th[id='gridHeader-table-head-th-rsFlagAfter']").css("background-color", "#14fc52");
           
          //}
        });

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeEventVessel(event){}
  changeEventVoyage(event){}
  changeEventBound(event){}
  changeEventInvoiceNo(event){}

  gridEvent(event){
    console.log(event);
    this.info=event;
    console.log('event');
    console.log('kakakakaka');
    switch(event.split(".")[0]){
    case 'afterLoad':
      
           var lenGrid = this.gridHeader.listStore.store.length
           console.log('afterloading')

           $('#gridHeader-table-head').each(function(i) {
            console.log('masuk loading');

          //var rowGrid = $('#container-table tr').length-1;
          for(var k=1;k<=lenGrid;k++){
           // console.log('RowSPAN '+(k)+$(this).find("span[id='gridContainer.span."+k+".container']").text());
            //var textVal = $(this).find("span[id='gridContainer.span."+k+".container']").text();

           
              $(this).find("th[id='gridHeader-table-head-th-billToAfter']").css("background-color", "red");
           
          }

        });
     
     
      break;
       
    }
  }

  infoGrid(event){}
}
