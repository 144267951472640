<div class="ui segment">
  <div class="ui divided items">
    <toolbar-plugin
      #toolbarSetting
      [modeToolbar]="modeToolbar"
      [settings]="settingToolbar"
      [disableButtons]="disableToolbarButtons"
      [invisibleButtons]="invisibleToolbarButtons"
      (eventEmitterClick)="toolbarEvent($event)"
    ></toolbar-plugin>


    <div class="ui inverted dimmer" [class.active]="loading == true" style="z-index: 99">
      <div class="ui medium text loader">Loading</div>
    </div>

    <div [style.display]="modeForm ? 'none' : 'block'">
      <form class="ui form">
        <div class="field">
          <h4
            class="ui dividing header"
            style="text-align: center; margin-top: 10px"
          >
            Customer Code Local Master
          </h4>
        </div>

      <grid-plugin
        #grid
        [settings]="settingGrid"
        (gridEvent)="gridEvent($event)"
        (infoGrid)="infoGrid($event)"
      ></grid-plugin>


      </form>
    </div>

    <div [style.display]="modeForm ? 'block' : 'none'">
      <div class="ui segment" style="padding-left: 1%; padding-right: 1%">
        <div id="container">
          <dialog-plugin
            [settings]="dialog"
            (eventDialog)="eventMessage($event)"
          ></dialog-plugin>

          <!-- Begin of Form -->
          <div class="ui form" *ngIf="form == ''">
            <h4 class="ui dividing header"  style="text-align: center; margin-top: 10px">Customer Code Local Master</h4>

            <div class="three fields">
              <div class="field">
                <label><br /></label>
                <div
                  class="ui test toggle checkbox"

                >
                  <input
                    type="checkbox"
                    [(ngModel)]="model['isValid']"
                    name="isValid"
                    tabindex="0"
                    class="hidden"
                    [disabled]="!isCustomerCodeEnabled"
                  />
                  <label>Valid</label>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >General</a
                  >

                  <div class="ten wide fields">
                    <div class="one field" style="width: 100%">
                      <div class="ten wide fields">
                        <div
                          class="required field"
                          style="width: 100%"
                          [class.error]="
                            model['error-customerCode'] != null &&
                            model['error-customerCode'] != ''
                          "
                        >
                          <label>Customer Code</label>
                          <input
                            type="text"
                            #txtCustomerCode
                            [(ngModel)]="model['customerCode']"
                            (ngModelChange)="model['error-customerCode'] = ''"
                            name="customerCode"
                            placeholder="Customer Code"
                            maxlength="50"

                            [disabled]="!isCustomerCodeEnabled"
                            (keypress)="
                              formValidation.CharNumSpecialCharOnly($event)
                            "
                          />
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-customerCode'] != null &&
                              model['error-customerCode'] != ''
                            "
                          >
                            {{ model["error-customerCode"] }}
                          </div>
                        </div>
                      </div>
                      <div style="font-weight: bold;"> Map to:</div>

                      <div class="ten wide fields">
                        <div
                          class="required field"
                          style="width: 100%"
                          [class.error]="
                            model['error-customerName'] != null &&
                            model['error-customerName'] != ''
                          "
                        >
                          <label>Customer Name (Master)</label>
                           <combo-plugin
                            #cbCustomerName
                            [settings]="settingCustomerName"
                            [disableCombo]="lock"
                            (change)="changeEventCustomer($event)"
                            style="width: 100%"
                          ></combo-plugin>
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-customerName'] != null &&
                              model['error-customerName'] != ''
                            "
                          >
                            {{ model["error-customerName"] }}
                          </div>
                        </div>
                      </div>

                      <div class="ten wide fields">
                        <div
                          class="required five field"
                          style="width: 100%"
                          [class.error]="
                            model['error-countryName'] != null &&
                            model['error-countryName'] != ''
                          "
                        >
                          <label>Country</label>
                          <combo-plugin
                            #cbCountry
                            [settings]="settingCountry"
                            [disableCombo]="lock || lockCreate"
                            (change)="changeEventCountry($event)"
                            style="width: 100%"
                          ></combo-plugin>
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-countryName'] != null &&
                              model['error-countryName'] != ''
                            "
                          >
                            {{ model["error-countryName"] }}
                          </div>
                        </div>
                        <div
                          class="five field"
                          style="width: 100%"
                          [class.error]="
                            model['error-companyRegNo'] != null &&
                            model['error-companyRegNo'] != ''
                          "
                        >
                          <label>Company Registration Number</label>
                          <input
                            type="text"
                            [(ngModel)]="model['companyRegNo']"
                            name="companyRegNo"
                            placeholder="Company Registration Number"
                            [disabled]="lock == true || lockCreate == true"
                            maxlength="50"
                            (keypress)="formValidation.ModifiedPattern($event)"
                          />
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-companyRegNo'] != null &&
                              model['error-companyRegNo'] != ''
                            "
                          >
                            {{ model["error-companyRegNo"] }}
                          </div>
                        </div>
                      </div>

                      <div class="ten wide fields">
                        <div class="field" style="width: 100%">
                          <label>Remarks</label>
                          <textarea
                            style="margin-top: 5px"
                            [(ngModel)]="model['remarks']"
                            name="remarks"
                            placeholder="Input your remarks..."
                            maxlength="200"
                            [disabled]="lock == true || lockCreate == true"
                            (keypress)="formValidation.AllChar($event)"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="one field" style="width: 100%">
                      <div class="ui raised segment" style="margin-left: 5px">
                        <a
                          class="ui red ribbon label"
                          style="margin-bottom: 5px"
                          >Customer Type</a
                        >

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isShipper']"
                                name="isShipper"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Shipper</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isAgency']"
                                name="isAgency"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Agency</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isConsignee']"
                                name="isConsigneee"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Consignee</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isBookingParty']"
                                name="isBookingParty"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Booking Party</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isNotifyParty']"
                                name="isNotifyParty"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Notify Party</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isHaulier']"
                                name="isHaulier"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Haulier</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isPayer']"
                                name="isPayer"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Payer</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isNvocc']"
                                name="isNvocc"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>NVOCC</label>
                            </div>
                          </div>
                        </div>

                        <div class="two fields">
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isFreightParty']"
                                name="isFreightParty"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Freight Party</label>
                            </div>
                          </div>
                          <div class="field" style="width: 100%">
                            <div
                              class="ui test toggle checkbox"
                              [class.disabled]="lock == true || lockCreate == true"
                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="model['isSupplier']"
                                name="isSupplier"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Supplier</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >Container Ownership</a
                  >

                  <div class="three fields">
                    <div class="field" style="width: 100%">
                      <div
                        class="ui test toggle checkbox"
                        [class.disabled]="lock == true || lockCreate == true"
                      >
                        <input
                          type="checkbox"
                          [(ngModel)]="model['isCoc']"
                          (ngModelChange)="
                            changeEventOwnership('COC-' + $event)
                          "
                          name="isCoc"
                          tabindex="0"
                          class="hidden"
                        />
                        <label>COC</label>
                      </div>
                    </div>
                    <div class="field" style="width: 100%">
                      <div
                        class="ui test toggle checkbox"
                        [class.disabled]="lock == true || lockCreate == true"
                      >
                        <input
                          type="checkbox"
                          [(ngModel)]="model['isSoc']"
                          name="isSoc"
                          (ngModelChange)="
                            changeEventOwnership('SOC-' + $event)
                          "
                          tabindex="0"
                          class="hidden"
                        />
                        <label>SOC</label>
                      </div>
                    </div>
                    <div class="field" style="width: 100%">
                      <div
                        class="ui test toggle checkbox"
                        [class.disabled]="lock == true || lockCreate == true"
                      >
                        <input
                          type="checkbox"
                          [(ngModel)]="model['isSlot']"
                          name="isSlot"
                          (ngModelChange)="
                            changeEventOwnership('SLOT-' + $event)
                          "
                          tabindex="0"
                          class="hidden"
                        />
                        <label>SLOT</label>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-cocOperatorCode'] != null &&
                        model['error-cocOperatorCode'] != ''
                      "
                    >
                      <label>Operator Code</label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['cocOperatorCode']"
                        name="cocOperatorCode"
                        placeholder="Operator Code"
                        [disabled]="isCOC == false || lockCreate == true"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-cocOperatorCode'] != null &&
                          model['error-cocOperatorCode'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-cocOperatorCode']"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-socOperatorCode'] != null &&
                        model['error-socOperatorCode'] != ''
                      "
                    >
                      <label>Operator Code</label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['socOperatorCode']"
                        name="socOperatorCode"
                        placeholder="Operator Code"
                        [disabled]="isSOC == false || lockCreate == true"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-socOperatorCode'] != null &&
                          model['error-socOperatorCode'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-socOperatorCode']"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-slotOperatorCode'] != null &&
                        model['error-slotOperatorCode'] != ''
                      "
                    >
                      <label>Operator Code</label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['slotOperatorCode']"
                        name="slotOperatorCode"
                        placeholder="Operator Code"
                        [disabled]="isSLOT == false || lockCreate == true"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-slotOperatorCode'] != null &&
                          model['error-slotOperatorCode'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-slotOperatorCode']"
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-cocOperatorCodeNew'] != null &&
                        model['error-cocOperatorCodeNew'] != ''
                      "
                    >
                      <label>Operator Code <span class="color-red">(3 Chars)</span> </label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['cocOperatorCodeNew']"
                        name="cocOperatorCodeNew"
                        placeholder="Operator Code"
                        [disabled]="isCOC == false || lockCreate == true"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-cocOperatorCodeNew'] != null &&
                          model['error-cocOperatorCodeNew'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-cocOperatorCodeNew']"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-socOperatorCodeNew'] != null &&
                        model['error-socOperatorCodeNew'] != ''
                      "
                    >
                      <label>Operator Code <span class="color-red">(3 Chars)</span></label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['socOperatorCodeNew']"
                        name="socOperatorCodeNew"
                        placeholder="Operator Code"
                        [disabled]="isSOC == false || lockCreate == true"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-socOperatorCodeNew'] != null &&
                          model['error-socOperatorCodeNew'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-socOperatorCodeNew']"
                        ></span>
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-slotOperatorCodeNew'] != null &&
                        model['error-slotOperatorCodeNew'] != ''
                      "
                    >
                      <label>Operator Code <span class="color-red">(3 Chars)</span></label>
                      <label
                        style="font-size: 9px; color: navy; font-weight: 200"
                        >** 2 to 4 characters separated by Comma. Eg:
                        AA,BBBB,...</label
                      >
                      <input
                        type="text"
                        [(ngModel)]="model['slotOperatorCodeNew']"
                        name="slotOperatorCodeNew"
                        placeholder="Operator Code"
                        [disabled]="isSLOT == false || lockCreate == true"
                        maxlength="50"
                        (keypress)="formValidation.ModifiedPattern($event, ',')"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-slotOperatorCodeNew'] != null &&
                          model['error-slotOperatorCodeNew'] != ''
                        "
                      >
                        <span
                          [innerHTML]="model['error-slotOperatorCodeNew']"
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div class="field" style="width: 100%">
                      <label>Account Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['cocAccountCode']"
                        name="cocAccountCode"
                        placeholder="Account Code"
                        [disabled]="isCOC == false || lockCreate == true"
                        maxlength="10"
                        (keypress)="formValidation.ModifiedPattern($event)"
                      />
                    </div>
                    <div class="field" style="width: 100%">
                      <label>Account Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['socAccountCode']"
                        name="socAccountCode"
                        placeholder="Account Code"
                        [disabled]="isSOC == false || lockCreate == true"
                        maxlength="10"
                        (keypress)="formValidation.ModifiedPattern($event)"
                      />
                    </div>
                    <div class="field" style="width: 100%">
                      <label>Account Code</label>
                      <input
                        type="text"
                        [(ngModel)]="model['slotAccountCode']"
                        name="slotAccountCode"
                        placeholder="Account Code"
                        [disabled]="isSLOT == false || lockCreate == true"
                        maxlength="10"
                        (keypress)="formValidation.ModifiedPattern($event)"
                      />
                    </div>
                  </div>

                  <div class="three fields">
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-cocCreditDays'] != null &&
                        model['error-cocCreditDays'] != ''
                      "
                    >
                      <label>Credit Days</label>
                      <input
                        type="number"
                        [(ngModel)]="model['cocCreditDays']"
                        name="cocCreditDays"
                        placeholder="Credit Days"
                        [disabled]="isCOC == false || lockCreate == true"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        (keypress)="noneNum($event)"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-cocCreditDays'] != null &&
                          model['error-cocCreditDays'] != ''
                        "
                      >
                        {{ model["error-cocCreditDays"] }}
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-socCreditDays'] != null &&
                        model['error-socCreditDays'] != ''
                      "
                    >
                      <label>Credit Days</label>
                      <input
                        type="number"
                        [(ngModel)]="model['socCreditDays']"
                        name="socCreditDays"
                        placeholder="Credit Days"
                        [disabled]="isSOC == false || lockCreate == true"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        (keypress)="noneNum($event)"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-socCreditDays'] != null &&
                          model['error-socCreditDays'] != ''
                        "
                      >
                        {{ model["error-socCreditDays"] }}
                      </div>
                    </div>
                    <div
                      class="field"
                      style="width: 100%"
                      [class.error]="
                        model['error-slotCreditDays'] != null &&
                        model['error-slotCreditDays'] != ''
                      "
                    >
                      <label>Credit Days</label>
                      <input
                        type="number"
                        [(ngModel)]="model['slotCreditDays']"
                        name="slotCreditDays"
                        placeholder="Credit Days"
                        [disabled]="isSLOT == false || lockCreate == true"
                        maxlength="4"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        (keypress)="noneNum($event)"
                      />
                      <div
                        class="ui basic red pointing prompt label transition error-message-hidden"
                        [class.error-message-visible]="
                          model['error-slotCreditDays'] != null &&
                          model['error-slotCreditDays'] != ''
                        "
                      >
                        {{ model["error-slotCreditDays"] }}
                      </div>
                    </div>
                  </div>

                  <div class="three fields">
                    <div class ="field">
                      <div class="two fields">
                        <div
                          class="field"
                          style="width: 50%"
                          [class.error]="
                            model['error-cocCreditLimit'] != null &&
                            model['error-cocCreditLimit'] != ''
                          "
                        >
                          <label>Credit Limit</label>
                          <input
                            type="number"
                            [(ngModel)]="model['cocCreditLimit']"
                            name="cocCreditLimit"
                            maxlength="5"
                            placeholder="Credit Limit"
                            [disabled]="isCOC == false || lockCreate == true"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            (keypress)="noneNum($event)"

                          />
                          <div
                            class="ui basic red pointing prompt label transition error-message-hidden"
                            [class.error-message-visible]="
                              model['error-cocCreditLimit'] != null &&
                              model['error-cocCreditLimit'] != ''
                            "
                          >
                            {{ model["error-cocCreditLimit"] }}
                          </div>
                        </div>
                        <div
                        class="field"
                        style="width: 50%">
                        <label>Tolerance (%)</label>
                          <input
                            type="number"
                            [(ngModel)]="model['cocTolerance']"
                            name="cocTolerance"
                            placeholder="Tolerance (%)"
                            [disabled]="isCOC == false || lockCreate == true"
                            maxlength="3"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            (keypress)="noneNum($event)"
                          />
                        </div>
                      </div>
                    </div>
                    <div class ="field">
                        <div class="two fields">
                          <div
                            class="field"
                            style="width: 50%"
                            [class.error]="
                              model['error-socCreditLimit'] != null &&
                              model['error-socCreditLimit'] != ''
                            "
                          >
                            <label>Credit Limit</label>
                            <input
                              type="number"
                              [(ngModel)]="model['socCreditLimit']"
                              name="socCreditLimit"
                              maxlength="5"
                              placeholder="Credit Limit"
                              [disabled]="isSOC == false || lockCreate == true"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              (keypress)="noneNum($event)"

                            />
                            <div
                              class="ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="
                                model['error-socCreditLimit'] != null &&
                                model['error-socCreditLimit'] != ''
                              "
                            >
                              {{ model["error-socCreditLimit"] }}
                            </div>
                          </div>
                          <div
                          class="field"
                          style="width: 50%">
                          <label>Tolerance (%)</label>
                            <input
                              type="number"
                              [(ngModel)]="model['socTolerance']"
                              name="socTolerance"
                              placeholder="Tolerance (%)"
                              [disabled]="isSOC == false || lockCreate == true"
                              maxlength="3"
                              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                              (keypress)="noneNum($event)"
                            />
                          </div>
                        </div>
                      </div>
                      <div class ="field">
                          <div class="two fields">
                            <div
                              class="field"
                              style="width: 50%"
                              [class.error]="
                                model['error-slotCreditLimit'] != null &&
                                model['error-slotCreditLimit'] != ''
                              "
                            >
                              <label>Credit Limit</label>
                              <input
                                type="number"
                                [(ngModel)]="model['slotCreditLimit']"
                                name="slotCreditLimit"
                                maxlength="5"
                                placeholder="Credit Limit"
                                [disabled]="isSLOT == false || lockCreate == true"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                (keypress)="noneNum($event)"

                              />
                              <div
                                class="ui basic red pointing prompt label transition error-message-hidden"
                                [class.error-message-visible]="
                                  model['error-slotCreditLimit'] != null &&
                                  model['error-slotCreditLimit'] != ''
                                "
                              >
                                {{ model["error-slotCreditLimit"] }}
                              </div>
                            </div>
                            <div
                            class="field"
                            style="width: 50%">
                            <label>Tolerance (%)</label>
                              <input
                                type="number"
                                [(ngModel)]="model['slotTolerance']"
                                name="slotTolerance"
                                placeholder="Tolerance (%)"
                                [disabled]="isSLOT == false || lockCreate == true"
                                maxlength="3"
                                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                (keypress)="noneNum($event)"
                              />
                            </div>
                          </div>
                        </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="twelve wide fields">
              <div class="one field" style="width: 100%">
                <div class="ui raised segment">
                  <a class="ui red ribbon label" style="margin-bottom: 5px"
                    >Details</a
                  >

                  <div id="displayGrid">
                    <div class="one fields">
                      <div class="field" style="width: 100%">
                        <div class="ui icon tiny buttons">
                          <button
                            class="ui button sapi"
                            [disabled]="lock == true || lockCreate == true"
                            data-content="Add"
                            (click)="addDetail()"
                          >
                            <i class="plus icon"></i>
                          </button>
                          <button
                            class="ui button sapi"
                            [disabled]="lock == true || lockCreate == true"
                            data-content="Remove"
                            (click)="removeDetail()"
                          >
                            <i class="minus icon"></i>
                          </button>
                        </div>
                        <grid-plugin
                          id="detail-customer"
                          #gridDetails
                          [settings]="settingGridDetails"
                          (gridEvent)="gridEventDetails($event)"
                          (infoGrid)="infoGridDetails($event)"
                        ></grid-plugin>
                      </div>
                    </div>
                  </div>

                  <div id="display">
                    <div class="two fields">
                      <div
                        class="required field"
                        [class.error]="
                          modelDetail['error-customerDisplayName'] != null &&
                          modelDetail['error-customerDisplayName'] != ''
                        "
                      >
                        <label>Display Name</label>
                        <input
                          type="text"
                          [(ngModel)]="modelDetail['customerDisplayName']"
                          (ngModelChange)="
                            modelDetail['error-customerDisplayName'] = ''
                          "
                          name="customerDisplayName"
                          placeholder="Display Name"
                          [disabled]="lock == true"
                          maxlength="50"
                          (keypress)="
                            formValidation.CharNumSpecialCharOnly($event)
                          "
                        />
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-customerDisplayName'] != null &&
                            modelDetail['error-customerDisplayName'] != ''
                          "
                        >
                          {{ modelDetail["error-customerDisplayName"] }}
                        </div>
                      </div>
                      <div
                        class="field"
                        style="width: 100%"
                        [class.error]="
                          modelDetail['error-contactPerson'] != null &&
                          modelDetail['error-contactPerson'] != ''
                        "
                      >
                        <label>Contact Person</label>
                        <input
                          type="text"
                          [(ngModel)]="modelDetail['contactPerson']"
                          name="contactPerson"
                          placeholder="Contact Person"
                          [disabled]="lock == true || lockCreate == true"
                          maxlength="50"
                          (keypress)="
                            formValidation.ModifiedPattern($event, '-&(.)\' ')
                          "
                        />
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-contactPerson'] != null &&
                            modelDetail['error-contactPerson'] != ''
                          "
                        >
                          {{ modelDetail["error-contactPerson"] }}
                        </div>
                      </div>
                    </div>

                    <div class="two fields">
                      <div
                        class="required field"
                        [class.error]="
                          modelDetail['error-fullAddress'] != null &&
                          modelDetail['error-fullAddress'] != ''
                        "
                        style="width: 100%"
                      >
                        <label>Display Address</label>
                        <textarea
                          style="margin-top: 5px"
                          maxlength="255"
                          [(ngModel)]="modelDetail['fullAddress']"
                          (ngModelChange)="
                            modelDetail['error-fullAddress'] = ''
                          "
                          name="fullAddress"
                          placeholder="Input your display address.."
                          [disabled]="lock == true || lockCreate == true"
                        ></textarea>
                        <div
                          class="ui basic red pointing prompt label transition error-message-hidden"
                          [class.error-message-visible]="
                            modelDetail['error-fullAddress'] != null &&
                            modelDetail['error-fullAddress'] != ''
                          "
                        >
                          {{ modelDetail["error-fullAddress"] }}
                        </div>
                      </div>
                      <div class="field" style="width: 100%">
                        <div class="one fields">
                          <div class="field" style="width: 100%">
                            <label>Tel. No(s).</label>
                            <input
                              type="text"
                              [(ngModel)]="modelDetail['telno']"
                              name="tel"
                              placeholder="Tel. No(s)."
                              [disabled]="lock == true || lockCreate == true"
                              maxlength="50"
                              (keypress)="formValidation.PhoneNum($event)"
                            />
                          </div>
                        </div>
                        <div class="one fields">
                          <div
                            class="field"
                            style="width: 100%"
                            [class.error]="
                              modelDetail['error-email'] != null &&
                              modelDetail['error-email'] != ''
                            "
                          >
                            <label>Email</label>
                            <input
                              style="text-transform: uppercase"
                              type="email"
                              [(ngModel)]="modelDetail['email']"
                              (ngModelChange)="modelDetail['error-email'] = ''"
                              name="email"
                              placeholder="Email"
                              [disabled]="lock == true || lockCreate == true"
                              maxlength="50"
                              (keypress)="
                                formValidation.ModifiedPattern($event, '-@_.,')
                              "
                            />
                            <div
                              class="ui basic red pointing prompt label transition error-message-hidden"
                              [class.error-message-visible]="
                                modelDetail['error-email'] != null &&
                                modelDetail['error-email'] != ''
                              "
                            >
                              {{ modelDetail["error-email"] }}
                            </div>
                          </div>
                        </div>
                        <div class="one fields">
                          <div class="field" style="width: 100%">
                            <label>Fax. No(s).</label>
                            <input
                              type="text"
                              [(ngModel)]="modelDetail['fax']"
                              name="fax"
                              placeholder="Fax. No(s)."
                              [disabled]="lock == true || lockCreate == true"
                              maxlength="50"
                              (keypress)="formValidation.PhoneNum($event)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="two fields">
                      <div class="field" style="width: 100%"></div>
                      <div class="two fields" style="width: 100%">
                        <div class="field">
                          <div class="ui buttons">
                            <button class="ui button " (click)="cancelDetail()">
                              Cancel
                            </button>
                            <div class="or"></div>
                            <button
                              class="ui positive button disabled"
                              (click)="saveDetail()"

                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <div class="field">
                          <div class="field">
                            <div
                              class="ui test toggle checkbox disabled"

                            >
                              <input
                                type="checkbox"
                                [(ngModel)]="modelDetail['isValidD']"
                                name="isValidD"
                                tabindex="0"
                                class="hidden"
                              />
                              <label>Valid</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="ui raised segment">
              <div class="two fields">
                <div class="field">
                  <label>Created By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userCreated']"
                    name="userCreated"
                    placeholder="Created By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Created Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateCreated']"
                    name="dateCreated"
                    placeholder="Created Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Last Modified By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userModified']"
                    name="userModified"
                    placeholder="Last Modified By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Last Modified Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateModified']"
                    name="dateModified"
                    placeholder="Last Modified Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
              <div class="two fields">
                <div class="field">
                  <label>Invalid By</label>
                  <input
                    type="text"
                    [(ngModel)]="model['userInvalid']"
                    name="userInvalid"
                    placeholder="Invalid By"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
                <div class="field">
                  <label>Invalid Date</label>
                  <input
                    type="text"
                    [(ngModel)]="model['dateInvalid']"
                    name="dateInvalid"
                    placeholder="Invalid Date"
                    disabled
                    readonly
                    style="background-color: lightgray"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- End of Form -->


          <!-- Begin of History Form -->
          <div class="field">
            <div class="ui form" *ngIf="form == 'history'">
              <h4
                class="ui dividing header"
                style="text-align: center; margin-top: 10px"
              >
                History Customer Code Local Master
              </h4>
              <div class="ui raised segment">
                <a class="ui red ribbon label">Select Customer</a>

                <div class="field" style="margin-top: 10px">
                  <button class="ui button" (click)="retrieveHistory($event)">
                    Retrieve
                  </button>
                  <button
                    class="ui button"
                    (click)="generateCustomerHistoryReport($event)"
                  >
                    Export
                  </button>
                </div>

                <div class="field">
                  <div class="fields">
                    <div class="six wide field">
                      <label>Customer Code</label>
                      <combo-plugin
                        #cbCustomerHistroy
                        [settings]="settingCustomerHistory"
                        (change)="changeEventCustomerHistory($event)"
                      ></combo-plugin>
                    </div>
                  </div>
                </div>


                <div class="field">
                  <grid-plugin
                    #gridHistory
                    [settings]="settingGridHistory"
                    (gridEvent)="gridEventHistory($event)"
                    (infoGrid)="infoGridHistory($event)"
                  ></grid-plugin>
                </div>
              </div>

            </div>

          </div>

          <!-- End of History Form -->
        </div>
      </div>
    </div>
  </div>
</div>
<footer-plugin [info]="info"></footer-plugin>
