import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-setting-lock-period-page',
  templateUrl: './setting-lock-period-page.component.html',
  styleUrls: ['./setting-lock-period-page.component.css']
})
export class SettingLockPeriodPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingCountry;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'New', event: 'new', icon: 'add circle'},
        {name: 'Update', event: 'update', icon: 'edit'},
        {name: 'Delete', event: 'delete', icon: 'minus circle'},
        {name: 'Cancel', event: 'cancel', icon: 'refresh'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Lock Period'
    }

     this.settingGrid = {
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral',
      page: 10,
      columns: [
        {header: 'Seq', field: 'seq', width: 100},
        {header: 'Country', field: 'counry', width: 300},
        {header: 'Lock Period', field: 'lockPeriod', width: 100},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns:'',
      sortingDirection: 'ASC'
    }

      this.settingCountry = {
       id          : 'cbCountry',
       type        : 'search enter', // search | select | select input
       url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
       maxChars    : 3,
       template    : 'grid', // default
       placeholder : 'Search Country',
       title       : 'countryName',
       description  : '',
       isMark      : true,
       columns     : [
                    {header: 'Country Code', field: 'countryCode', width: 100},
                    {header: 'Country Name', field: 'countryName', width: 300},
                    {header: 'Valid', field: 'isValid', width: 50}
       ]
    }

  }
  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeEventCountry(event){}
  gridEvent(event){}
  infoGrid(event){}
}
