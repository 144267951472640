<div class="ui raised segment">
  <toolbar-plugin
    [modeToolbar]="modeToolbar"
    [settings]="settingToolbar"
    [disableButtons]="disableToolbarButtons"
    [invisibleButtons]="invisibleToolbarButtons"
    (eventEmitterClick)="toolbarEvent($event)"
  ></toolbar-plugin>

  <dialog-plugin
    [settings]="dialog"
    (eventDialog)="eventMessage($event)"
  ></dialog-plugin>
  <form class="ui form">
    <!--button class="ui button" style="display:none;"></button-->
    <table class="ui basic table">
      <tbody>
        <tr>
          <td style="width: 50%; padding-left: 3%">
            <div
              class="ui raised segment"
              style="margin-top: 5px; height: 131px; width: 70%"
            >
              <a class="ui blue ribbon label" style="margin-top: 1px"
                >Step 1. Enter a Booking Number</a
              >
              <div
                class="required field"
                style="width: 100%"
                [class.error]="
                  modelBooking['error-bookingNo'] != null &&
                  modelBooking['error-bookingNo'] != ''
                "
              >
                <input
                  style="margin-top: 10px"
                  maxlength="20"
                  type="text"
                  name="bookingNo"
                  placeholder="Booking Number"
                  [(ngModel)]="modelBooking['bookingNo']"
                  (ngModelChange)="modelBooking['error-bookingNo'] = ''"
                  onkeydown="return (event.keyCode!=13);"
                  (keypress)="formValidation.CharNumOnlyWOSpace($event)"
                />
                <div
                  class="
                    ui
                    basic
                    red
                    pointing
                    prompt
                    label
                    transition
                    error-message-hidden
                  "
                  [class.error-message-visible]="
                    modelBooking['error-bookingNo'] != null &&
                    modelBooking['error-bookingNo'] != ''
                  "
                >
                  {{ modelBooking["error-bookingNo"] }}
                </div>
              </div>
            </div>
          </td>
          <td style="padding-right: 3%">
            <div
              class="ui raised segment"
              style="margin-top: 5px; height: 131px; width: 100%"
            >
              <a class="ui blue ribbon label">Step 2. Split This Booking</a>
              <div class="inline fields">
                <div class="field" style="width: 50%">
                  <input
                    name="splitType"
                    type="radio"
                    value="0"
                    id="splitType"
                    tabindex="0"
                    class="hidden"
                    checked
                  />
                  <label>Using Suffix (A-Z)</label>
                </div>

                <div class="field">
                  <label>Quantity</label>
                  <input
                    type="number"
                    name="splitQty"
                    min="0"
                    maxlength="5"
                    style="width: 60%"
                    oninput="this.value = Math.abs(this.value); if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    onkeydown="return (event.keyCode!=13);"
                    placeholder="Quantity"
                    [(ngModel)]="modelBooking['splitQty']"
                  />
                  <input
                    type="hidden"
                    name="sumGridQty"
                    [(ngModel)]="modelBooking['sumGridQty']"
                  />
                </div>
              </div>

              <div class="inline fields">
                <div class="field" style="width: 45%">
                  <input
                    name="splitType"
                    type="radio"
                    id="splitType"
                    value="1"
                    tabindex="0"
                    class="hidden"
                  />
                  <label>Booking References</label>
                </div>

                <div class="field" style="float: right">
                  <button
                    class="ui active button split"
                    data-content="Split"
                    (click)="doSplit()"
                    id="sptQty"
                  >
                    <i class="icon unlinkify"></i>Split
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <fieldset style="border: 1px solid lightgray">
      <div class="field">
        <label style="color: dodgerblue"
          >Quantity in Main Booking Reference :
          {{ modelBooking["lblQty"] }}</label
        >
        <grid-plugin
          id="gridHeader"
          #gridHeader
          [settings]="settingGridHeader"
          (gridEvent)="gridEvent($event)"
          (infoGrid)="infoGrid($event)"
        ></grid-plugin>
      </div>
    </fieldset>
    <div class="field">
      <div class="inline fields">
        <label style="color: dodgerblue; margin-right: 300px"
          >After Split</label
        >
        <!--
          <grid-plugin id="gridSplitDetail" #gridSplitDetail [settings]="settingGridSplitDetail" (gridEvent)="eventGridSplitDetail($event)" (infoGrid)="infoGridSplitDetail($event)"></grid-plugin>
          -->

        <!--label style="color:dodgerblue;">Total Quantity</label>  <input readonly type="text" name="aftersplitQty" value="{{modelBooking['aftersplitQty']}}" style="width:auto;"   placeholder="Quantity" [(ngModel)]="modelBooking['aftersplitQty']" -->
      </div>
      <fieldset>
        <div class="field" style="overflow-y: scroll; height: 250px">
          <table
            class="
              ui
              sortable
              selectable
              compact
              celled
              definition
              striped
              table
            "
            cellspacing="0"
            cellpadding="0"
            id="tblSplit"
            #tblSplit
          >
            <thead class="full-width" style="font-size: 12px">
              <tr>
                <th
                  *ngFor="let col of settingGridSplitDetail.columns"
                  width="{{ col.width }}%"
                >
                  {{ col.header }}
                </th>
              </tr>
            </thead>
            <tbody style="font-size: 12px">
              <tr *ngFor="let data of splitStore" class="tr-bookingReferences-a">
                <td>
                  {{ data.seqnoNew
                  }}<input
                    type="hidden"
                    id="seqId_{{ data.seqnoNew }}"
                    name="seqId_{{ data.seqnoNew }}"
                  />
                </td>
                <td>
                  <span *ngIf="!isBookingReferences">
                    <input type="text" name="bookNo_{{ data.seqnoNew }}" id="bookNo_{{ data.seqnoNew }}" value="{{ data.bNoNew }}" class="custom-input"
                    [(ngModel)]="data.bNoNew" readonly/>
                  </span>
                  <div *ngIf="isBookingReferences" class="flex-bookingReferences-a">
                    <span class="span-bookingReferences-a">{{bookingNumberdefault}}</span>
                    <input type="text" maxlength="6" name="bookNo_{{ data.seqnoNew }}" id="bookNo_{{ data.seqnoNew }}" value="{{ data.bNoNew }}"
                    [(ngModel)]="data.bNoNew"/>
                  </div>
                  <span *ngIf="data.bNoNew && !regexSuffixBookingNumber.test(data.bNoNew) && isBookingReferences"
                    class="message-ErrorSuffixBookingNumber">Should follow by 2 chars (ranging from AA to ZZ) and follow by 4 digits
                  </span>
                </td>
                <td>
                  <select
                    class="sday"
                    name="typeSize_{{ data.seqnoNew }}"
                    id="typeSize_{{ data.seqnoNew }}"
                    (change)="chkContainer(data.seqnoNew, data.bNoNew)"
                    [disabled]="isSuccessSave"
                  >
                    <option
                      *ngFor="let typ of typeSize"
                      [selected]="typ == data.containerTypeNew"
                      value="{{ typ }}"
                    >
                      {{ typ }}
                    </option>
                  </select>
                </td>
                <td>
                  <select
                    class="sday"
                    name="cnSize_{{ data.seqnoNew }}"
                    id="cnSize_{{ data.seqnoNew }}"
                    (change)="qtyBlur(data.seqnoNew)"
                    [disabled]="isSuccessSave"
                  >
                    <option
                      *ngFor="let dataSize of cntSz"
                      [selected]="dataSize == data.containerSizeNew"
                      value="{{ dataSize }}"
                    >
                      {{ dataSize }}
                    </option>
                  </select>
                </td>
                <td>
                  <input
                    type="number"
                    min="0"
                    maxlength="5"
                    oninput="this.value = Math.abs(this.value); if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength)"
                    class="quantyclass"
                    onkeydown="return (event.keyCode!=13);"
                    name="quanty_{{ data.seqnoNew }}"
                    id="quanty_{{ data.seqnoNew }}"
                    value="{{ data.qtyNew }}"
                    (blur)="qtyBlur(data.seqnoNew)"
                    (keyup)="qtyBlur(data.seqnoNew)"
                    (change)="qtyBlur(data.seqnoNew)"
                    [disabled]="isSuccessSave"
                  />
                </td>
                <td>
                  <button
                    style="right: auto"
                    [style.opacity]="isSuccessSave ? '0.5' : '1'"
                    data-content="addRow"
                    (click)="rowEvent('add', data.seqnoNew)"
                    id="addRow"
                    [disabled]="isSuccessSave"
                  >
                    <i class="plus icon"></i>Add
                  </button>
                  <button
                    style="right: auto"
                    [style.opacity]="isSuccessSave ? '0.5' : '1'"
                    data-content="removeRow"
                    [disabled]="isSuccessSave"
                    (click)="rowEvent('del', data.seqnoNew)"
                    id="removeRow"
                  >
                    <i class="remove icon"></i>Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </fieldset>
    </div>

    <div
      class="context-menu"
      id="context-menu"
      style="display: none; position: absolute; z-index: 99"
    >
      <ul>
        <li>
          <a href="#"><i class="fa fa-eye"></i> View</a>
        </li>
        <li>
          <a href="#"><i class="fa fa-share-alt"></i> Share</a>
        </li>
        <li>
          <a href="#"><i class="fa fa-trash"></i> Delete</a>
        </li>
        <li>
          <a href="#"><i class="fa fa-share fa-fw"></i> Move</a>
        </li>
        <li>
          <a href="#"><i class="fa fa-files-o"></i> Copy</a>
        </li>
      </ul>
    </div>
    <!--
    <div class="field">
      <div class="sixteen fields" style="padding-left:15px; padding-right: 15px;">
        <div class="ten fields">
          <fieldset style="border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">Sailing Details</legend>
            <grid-plugin id='gridSailingDetail' #gridSailingDetail [settings]="settingGridSailingDetail" (gridEvent)="gridEventDetails($event)" (infoGrid)="infoGridDetails($event)"></grid-plugin>
          </fieldset>
        </div>
        <div class="field">
          &nbsp;
        </div>
        <div class="three fields">
          <fieldset style="border: 1px solid lightgray;">
            <legend style="font-weight:bold;color:dodgerblue;">Special Exchange Rate</legend>
            <grid-plugin id='gridSailingDetail2' #gridSailingDetail2 [settings]="settingGridSailingDetail2" (gridEvent)="gridEventDetails2($event)" (infoGrid)="infoGridDetails2($event)"></grid-plugin>
          </fieldset>
        </div>
      </div>
    </div>
-->
  </form>
</div>

<div
  id="bookingSplitCheckBlDialog"
  style="display: none; font-family: Lucida Console, monospace"
>
  <form class="ui form">
    <!-- form start -->
    <div class="field" style="margin-bottom: 5px">
      <table border="0">
        <tr>
          <td width="20%" style="text-align: center">
            <i class="info circle huge icon"></i>
          </td>
          <td>
            <div [innerHTML]="bookingSplitDialogMessage"></div>
          </td>
        </tr>
        <tr>
          <td witdth="80%"></td>
          <td width="20%">
            <button
              class="positive ui button"
              (click)="bookingSplitCheckBlDialogOkButton()"
            >
              OK
            </button>
          </td>
        </tr>
      </table>
    </div>
  </form>
</div>

<footer-plugin [info]="info"></footer-plugin>
