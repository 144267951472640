import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Location, Terminal } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, ToolbarPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import FormValidation from '../../utils/formValidation';

declare  var $:any;


@Component({
  selector: 'app-master-location-page',
  templateUrl: './master-location-page.component.html',
  styleUrls: ['./master-location-page.component.css']
})

export class MasterLocationPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent;
  @ViewChild('gridTerminalDetail') gridTerminalDetail:GridPluginComponent;
  @ViewChild('cbLocationNameHistory') cbLocationNameHistory:ComboPluginComponent;
  @ViewChild('cbAgency') cbAgency:ComboPluginComponent;
  @ViewChild('cbTerminalMapping') cbTerminalMapping:ComboPluginComponent;
  @ViewChild('cbTerminalMappingDetail') cbTerminalMappingDetail:ComboPluginComponent;
  @ViewChild('cbCountry') cbCountry:ComboPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeFormTerminal = false;


  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = '';

  /* Parameter listStore for Grid */
  listStore = new ListStore;

  // Form validation
  formValidation = new FormValidation()

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;
  //settingLocationLogStartDate;
  //settingLocationLogEndDate;
  settingLocationHistory;
  settingAgency;
  settingCbTerminalMapping;
  settingGridTerminalDetail;
  settingCbTerminalMappingDetail;
  settingCountry;

  /* Misc Parameters */
  valLocationCode:string = '';
  valLocationName:string = '';
  valLocationId:string = '';
  valLocationIdHistory:string = '';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';
  valTerminalCode:string = '';
  valTerminalId:string = '';
  valTerminalName:string = '';

  terminal = new Terminal;

  /* Parameter model */
  model = new Location;
  //selectList = [];

  loading = false;

  lock = false;
  lockSaveMapping = false;
  lockPop = true;;
  isError: Boolean = false;
  validatorRules = {};

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  isDbClickValue = false;

  errorMessageTerminalName = "";

  /* validatorRules */
  /*validatorRules = {
    locationName: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Location Name.'
        }
      ]
    },
    locationCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Location Code.'
        }/*,
        {
          type  : 'min-length[5]',
          prompt: 'Location Code must be at least 5 characters long.'
        },
        {
          type  : 'max-length[7]',
          prompt: 'Location Code must be maximum 7 characters long.'
        }*/
    /*  ]
    },
    locationBookingRef:{
      rules:[
        {
          type : 'empty',
          prompt: 'Please input Booking Ref.'
        }
      ]
    },
    countryName: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Country.'
        },
      ]
    },
  }*/


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Create',  event: 'create', icon : 'plus' },
        { name : 'Update',  event: 'update', icon : 'write' },
        { name : 'Delete',  event: 'delete', icon : 'minus circle' },
        { name : 'Print' ,  event: 'print' , icon : 'file outline' },
        { name : 'History',  event: 'history' , icon : 'history' },
        { name : 'Terminal Mapping',  event: 'terminal', icon : 'unordered list'},
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
      ],
      buttonsDetail         : [ ],
      createDefaultFront    : false,
      createDefaultDetail   : true,
      searchBy              : [
        {display: 'Location Code', value: 'locationCode'},
        {display: 'Location Name', value: 'locationName'},
        {display: 'Country Name', value: 'countryName'},
        {display: 'Booking Ref', value: 'locationBookingRef'},
        {display: 'Agency Name', value: 'locationAgencyName'},
        {display: 'Port', value: 'isPort'},
        {display: 'Place', value: 'isPlace'},
        {display: 'POT', value: 'isPot'},
        {display: 'POP', value: 'isPop'},
        {display: 'Agency Code (POP)', value: 'agencyCodePop'},
        {display: 'Remarks', value: 'remarks'},
        {display: 'User Created', value: 'userCreated'},
        {display: 'Date Created', value: 'dateCreated'},
        {display: 'User Modified', value: 'userModified'},
        {display: 'Date Modified', value: 'dateModified'},
        {display: 'User Invalid', value: 'userInvalid'},
        {display: 'Date Invalid', value: 'dateInvalid'},
        {display: "User Deleted", value: "userDeleted" },
        {display: "Date Deleted", value: "dateDeleted" },
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingGrid = {
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Location Name', field: 'locationName', width: 200},
        {header: 'Location Code', field: 'locationCode', width: 50},
        {header: 'Country Name', field: 'countryName', width: 150, render : {
          value: '<i class="\' + element[\'countryCode\'] + \' flag"></i> element[\'countryName\']'
        }},
        {header: 'Booking Ref', field: 'locationBookingRef', width: 100},
        {header: 'Agency Name', field: 'agencyName', width: 100},
        {header: 'Agency Code (POP)', field: 'agencyCodePop', width: 50},
        {header: 'Port', field: 'isPort', width: 100},
        {header: 'Place', field: 'isPlace', width: 100},
        {header: 'POT', field: 'isPot', width: 100},
        {header: 'POP', field: 'isPop', width: 100},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        { header: "User Deleted", field: "userDeleted", width: 110 },
        { header: "Date Deleted", field: "dateDeleted", width: 110 },
        { header: "Delete", field: "isDeleted", width: 80 }
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'locationName',
      sortingDirection: 'ASC'
    }

    this.settingGridHistory={
      id:"locationHisotry",
      url: this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Location Id', field:'locationId', width:200},
        {header: 'Location Name', field: 'locationName', width: 200},
        {header: 'Location Code', field: 'locationCode', width: 50},
        {header: 'Country Name', field: 'countryName', width: 150, render : {
          value: '<i class="\' + element[\'countryCode\'] + \' flag"></i> element[\'countryName\']'
        }},
        {header: 'Booking Ref', field: 'locationBookingRef', width: 100},
        {header: 'Agency Name', field: 'agencyName', width: 100},
        {header: 'Agency Code (POP)', field: 'agencyCodePop', width: 50},
        {header: 'Port', field: 'isPort', width: 100},
        {header: 'Place', field: 'isPlace', width: 100},
        {header: 'POT', field: 'isPot', width: 100},
        {header: 'POP', field: 'isPop', width: 100},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        //{header: 'Agency Code', field: 'agencyCodePop', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,

      enablePagination: true
    }

    this.settingGridTerminalDetail={
      url: '',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Terminal Name', field: 'locationName', width: 200},
        {header: 'Terminal Code', field: 'locationCode', width: 100}
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns:'locationName',
      sortingDirection: 'ASC'
    }


    this.settingLocationHistory = {
      id          : 'cbLocationHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralHistory/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingAgency = {
      id          : 'cbAgency',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneralAgencyEnum/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'customerName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Agency Code', field: 'customerId', width:150},
        {header: 'Agency Name', field: 'customerName', width: 150},
        {header: 'Valid?', field: 'isValid', width: 75},
      ]
    }

    this.settingCbTerminalMapping = {
      id          : 'cbTerminalMapping',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralForTerminal/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location ID', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }


    this.settingCbTerminalMappingDetail = {
      id          : 'cbTerminalMappingDetail',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralForTerminal/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 150},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }

    this.settingCountry = {
      id          : 'cbCountry',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search Country',
      title       : 'countryName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Country Code', field: 'countryCode', width: 100},
        {header: 'Country Name', field: 'countryName', width: 300},
        {header: 'Valid', field: 'isValid', width: 100}
      ]
    }

    /*
     this.settingLocationLogStartDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }

     this.settingLocationLogEndDate = {
     modeCal : true,
     type : 'date',
     returnText : 'year-month-day',
     returnTextTime : '',
     separator : '-',
     AltTimeId : '', // default : ''
     hour12 : false
     }
     */
  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    this.firstInit();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "terminal,cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search='*/*/*';
    //this.grid.onFirst();

  }

  firstInit(){
    this.setValidatorNonPop();
    this.currentMenuID = this.cookieService.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;
    }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete,terminal";
    } else {
      this.disableToolbarButtons = "update,delete,terminal";
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  infoGridHistory(event:any){
    this.info=event;
  }
  infoGridTerminalDetail(event:any){
    this.info=event;
  }

  gridEvent(event:any) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete,terminal";
          } else {
            this.disableToolbarButtons = "update,delete,terminal";
          }
        }
        break;
      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete,terminal";
          } else {
            this.disableToolbarButtons = "update,delete,terminal";
          }
        }
      case "afterLoad":
        let self = this;
        setTimeout(() => {
          var idx = 0;
          this.grid.listStore.store.forEach((element) => {
            if(element["isDeleted"] == 'Y'){
              self.markRedRow(idx); //element.no-1
            } else {
              self.markDefaultRow(idx); //element.no-1
            }
            idx++;
          });
        }, 1000);
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){
            this.isDbClickValue = true;
            let dt = this.grid.getSelectedValues()[0];
            if (dt.isDeleted == "Y") {
              this.message(
                "warning",
                "Error Input",
                "The record has been deleted",
                "okonly",
                { ok: "this.loading=false;" }
              );
              return false;
            } else {
              if(this.viewOnlyStatus){
                this.disableToolbarButtons = "update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,history,print";

              this.modeForm = true;
              this.modeToolbar = false;
              this.lock = true;
              this.lockPop=true;

              let dt = this.grid.getSelectedValues()[0];
              let locId:string = String(dt.locationId).trim();
              this.onRetrieve(locId);
            }
          }

        }

        break;
    }
  }

  onRetrieve(locationId:string){
     this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterLocations/findById/' + locationId).subscribe((resp) =>{
          let data = resp.json();
          this.setData(data);
     });

  }

  setValidatorPop() {
    this.isError = false;
    this.model["error-locationName"] = "";
    this.model["error-locationCode"] = "";
    this.model["error-locationBookingRef"] = "";
    this.model["error-countryName"] = "";
    this.model["error-agencyCodePop"] = "";

    this.validatorRules = {
      locationName: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please input Location Name.'
          }
        ]
      },
      locationCode: {
        rules: [
          {
            type  : 'empty',
            prompt: 'Please input Location Code.'
          }/*,
          {
            type  : 'min-length[5]',
            prompt: 'Location Code must be at least 5 characters long.'
          },
          {
            type  : 'max-length[7]',
            prompt: 'Location Code must be maximum 7 characters long.'
          }*/
        ]
      },
      locationBookingRef:{
        rules:[
          {
            type : 'empty',
            prompt: 'Please input Booking Ref.'
          }
        ]
      },
      countryName: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please input Country.'
          },
        ]
      },
      agencyCodePop: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please insert Agency Code (POP).'
          },
        ]
      },

    }
  }

  setValidatorNonPop() {
    this.isError = false;
    this.model["error-locationName"] = "";
    this.model["error-locationCode"] = "";
    this.model["error-locationBookingRef"] = "";
    this.model["error-countryName"] = "";
    this.model["error-agencyCodePop"] = "";

    this.validatorRules = {
      locationName: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please input Location Name.'
          }
        ]
      },
      locationCode: {
        rules: [
          {
            type  : 'empty',
            prompt: 'Please input Location Code.'
          }/*,
          {
            type  : 'min-length[5]',
            prompt: 'Location Code must be at least 5 characters long.'
          },
          {
            type  : 'max-length[7]',
            prompt: 'Location Code must be maximum 7 characters long.'
          }*/
        ]
      },
      locationBookingRef:{
        rules:[
          {
            type : 'empty',
            prompt: 'Please input Booking Ref.'
          }
        ]
      },
      countryName: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please input Country.'
          },
        ]
      },
    }
  }

  gridEventHistory(event:any){
    //do nothing
  }

  toolbarEvent(event:any) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        if(this.model.locationId != ""){
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      case 'terminal':
        this.handleTerminal();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event
    this.lock=false;
    this.lockPop = true;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.disableToolbarButtons="";
    this.model = new Location;

    this.valLocationCode='';
    this.valLocationName='';

    this.cbAgency.setValue("");
    this.cbCountry.setValue("");
  }


  handleUpdate()
  {
    // handle update event

    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;

      //let dt = this.grid.getSelectedValues()[0];
      let locId:string = String(dt.locationId).trim();
      this.onRetrieve(locId);

      this.lock=false;
      if(this.model.isPop){
        this.lockPop = false;
      }else{
        this.lockPop = true;
      }
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,print";
    }
  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      var deleteObj = {"locationId":"","locationCode":""};
      deleteObj.locationId = element.locationId;
      deleteObj.locationCode = element.locationCode;
      deleteList.push(deleteObj);
    });
    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
          "/MasterBookings/checkingListLocationUsed",
          deleteList
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {

            let dataRes = resp.json();
            console.log(dataRes['totalUsed']);
            if (dataRes['totalUsed'] == 0) {
              this.loading = false;
              this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
            } else {
              this.message(
                "information",
                "Information",
                "Record being utilized, cannot be deleted. ",
                "okonly",
                { ok: "this.loading=false;" }
              );
            }

          }
        },
        (error) => {
          this.loading = false;
          this.info = error.message;
        }
      );

    // this.loading = true;
    // this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    // handle print event
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/47/Locations-Export-Reports/*');
    var formatName = "report-Locations-Export-Reports.xls";
    var uri = this.configService.config.BASE_API.toString()+
    "?q=" +
      "/Mreport/runReport/47/Locations-Export-Reports/*";
    var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });

  }

  handleSave()
  {
    // handle save event
    // console.log('handleSave');
    // console.log('Agency Code = > ' + this.model.locationAgencyCode);
    if(this.model.isPop){
      this.setValidatorPop();
    }else{
      this.setValidatorNonPop();
    }

    let isPassedValidation:boolean = this.onValidate(this.model);
    //another barrier of test
    this.model['error-locationCode']="";
    if(this.model.locationCode.length < 5 || this.model.locationCode.length > 7){
      isPassedValidation=true;
      this.model['error-locationCode'] = "Location Code must be between 5 to 7 characters long."
    }


    if(!isPassedValidation){
      if(!this.model.isPort && !this.model.isPlace && !this.model.isPot && !this.model.isPop){
        this.message('warning','Error input', 'Please specify Port/Place/Port of Transshipment.','okonly',{ok: 'this.loading=false;'});
      }else {
        // console.log("LALALALAND => " + this.model.locationAgencyCode);
        if(this.model.locationAgencyCode == undefined){
          // console.log('go to undefined detection');
          this.model.locationAgencyCode='';
        }
        // console.log("PSI Date => " + this.genericUtil.getPSIDate());
        this.model["dateCreated"] = this.genericUtil.getPSIDate();
        this.message('save', 'Saving data', 'Do you want to save the record? ', 'yesno', {
          yes: 'this.saveEvent()',
          no: 'this.loading = false;'
        });
      }
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    // handle cancel event

    if(this.modeFormTerminal && this.isDbClickValue){
      
      //back to edit form
      if(this.viewOnlyStatus){
        this.disableToolbarButtons = "create,update,delete";
        this.invisibleToolbarButtons = "cancel";
      } else {
        this.disableToolbarButtons = "";
        this.invisibleToolbarButtons = "create,history,print";
      }
      //this.disableToolbarButtons = "";
      //this.invisibleToolbarButtons = "create,history,print";

      this.modeForm = true;
      this.modeToolbar = false;

      this.modeFormTerminal=false;
      this.form='';
      //this.valLocationId='';

      this.lock = true;

      //reset grid terminal
      //this.gridTerminalDetail.onClear();
      this.model.masterLocationTerminals = [];

      // console.log("Masuk mode form terminal");

    }else {
      // console.log("Val Location ID = " + this.valLocationIdHistory);
     // if (this.valLocationIdHistory != undefined) {
        this.valLocationCode = '';
        this.valLocationIdHistory = '';
        this.cbLocationNameHistory.setValue("");
        this.cbLocationNameHistory.close();
        this.gridHistory.onHistory("NaN");
        this.gridHistory.onClear();
        //this.gridHistory.loadData();
        this.modeForm = false;
        this.modeToolbar = false;
        this.form = '';
        if(this.viewOnlyStatus){
          this.disableToolbarButtons = "create,update,delete,terminal";
          this.invisibleToolbarButtons = "cancel";
        } else {
          this.disableToolbarButtons = "update,delete,terminal";
          this.invisibleToolbarButtons = "cancel";
        }
        //this.disableToolbarButtons = "update,delete";
        //this.invisibleToolbarButtons = "terminal,cancel";
        this.grid.clearSelectedValues();
        this.model = new Location;
        // console.log("Bukan mode form terminal");
   //   }
    }
    this.isDbClickValue = false;
  }


  handleTerminal()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'terminal';
    this.invisibleToolbarButtons = "save,terminal";
    this.lock=false;
    this.modeFormTerminal=true;

    // console.log(this.model.masterLocationTerminals);
    if(this.model.masterLocationTerminals != null){
        this.gridTerminalDetail.listStore.store = [];
        this.gridTerminalDetail.listStore.store = this.model.masterLocationTerminals;
        this.gridTerminalDetail.loadData();
    }
    let dt = this.grid.getSelectedValues()[0];
    let locId:string = String(dt.locationId).trim();
    this.onRetrieve(locId);
  }

  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete,terminal";
    this.invisibleToolbarButtons = "cancel";

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
   // this.grid.onFirst();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete,terminal";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }



  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.valLocationCode = '';
    this.invisibleToolbarButtons = "save,terminal";
    this.cbLocationNameHistory.setUrl(
      this.configService.config.BASE_API.toString() +
        "/MasterLocations/findGeneralHistory/{query}"
    );
  }

  setData(data:any){
    //SET MODEL DATA FOR UPDATE PROCESS

    this.model = new Location;

    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }

    this.model.locationId = String(data.locationId).trim().toUpperCase();
    this.model.locationCode = String(data.locationCode).trim().toUpperCase();
    this.model.locationName = String(data.locationName).trim().toUpperCase();
    this.model.countryCode = String(data.countryCode).trim().toUpperCase();
    this.model.countryName = String(data.countryName).trim().toUpperCase();
    this.model.locationBookingRef = String(data.locationBookingRef).trim().toUpperCase();
    this.model.locationAgencyCode = String(data.locationAgencyCode).trim().toUpperCase();
    this.model.agencyCodePop = String(data.agencyCodePop).trim().toUpperCase();

    if(data.isPort == 'Y'){
      this.model.isPort=true;
    }else{
      this.model.isPort=false;
    }
    if(data.isPlace == 'Y'){
      this.model.isPlace=true;
    }else{
      this.model.isPlace=false;
    }
    if(data.isPot == 'Y'){
      this.model.isPot=true;
    }else{
      this.model.isPot=false;
    }

    if(data.isPop == 'Y'){
      this.model.isPop=true;
    }else{
      this.model.isPop=false;
    }

    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.hasTerminal = String(data.hasTerminal).trim().toUpperCase();


    this.cbAgency.setUrl(
      this.configService.config.BASE_API.toString() +
      '/MasterCustomers/findByCustomerId/customer_id={query}'
    );

    this.cbAgency.setValue(this.model.locationAgencyCode);



    this.cbCountry.setValue(this.model.countryName.trim().toUpperCase());

    //additional set for terminal section
    this.valLocationName=String(data.locationName).toUpperCase().trim();
    this.valLocationCode=String(data.locationCode).toUpperCase().trim();
    this.valLocationId = String(data.locationId).toUpperCase().trim();

    if(data['masterLocationTerminals'] != null){
        this.gridTerminalDetail.listStore.store = [];
        this.model.masterLocationTerminals = [];
        this.model.masterLocationTerminals =  data['masterLocationTerminals'];
        this.gridTerminalDetail.listStore.store = this.model.masterLocationTerminals;
        this.gridTerminalDetail.loadData();
    }

  }

  valueLocationHistory(event:any){
    this.valLocationCode=event["locationCode"];
    this.valLocationIdHistory=event["locationId"];

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valLocationIdHistory);
  }

  valueAgency(event:any){
    this.model.locationAgencyCode = event["customerId"];
    this.cbAgency.setUrl(this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneralAgencyEnum/{query}');
  }

  generateLocationHistoryReport(event:any){
    if(this.valLocationIdHistory == '' || this.valLocationIdHistory == 'undefined'){
      this.message('warning','Warning','Please select (1) historical data before exporting.','okonly',{ok:''});
    }else {
      var formatName = "report-History-Charge-"+this.valLocationIdHistory+"-"+this.valLocationCode+".xls";
      var uri = this.configService.config.BASE_API.toString()+
      "?q=" +
        "/Mreport/runReport/46/History-Location-"
        + this.valLocationIdHistory + '-' 
        + this.valLocationCode + '/' 
        + this.valLocationIdHistory + '@' 
        + this.valHistoryStartDate + '@' 
        + this.valHistoryEndDate
      var self = this;
      this.loading = true;
      $.ajax({
        url: uri,
        method: "GET",
        beforeSend: function(request) {
          request.setRequestHeader("Token", localStorage.getItem("token"));
        },
        xhrFields: {
          responseType: "blob",
        },
        success: function (data) {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);
          self.loading = false;
        },
      });
      }
    // {
    //   window.open(this.configService.config.BASE_API.toString().replace('9090','10000').replace("5001","10000") + '/Mreport/runReport/46/History-Location-' + this.valLocationIdHistory + '-' + this.valLocationCode + '/' + this.valLocationIdHistory + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);
    // }
  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  saveEvent(){
    console.log('saveEvent'+this.model.agencyCodePop);
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterLocations/save',this.model).subscribe((resp) =>{
      // console.log('STATUS=' + resp.status);
      // console.log('MESSAGE=' + resp.json()['message']);
      if(resp.status == 200 && resp.json()['message'] == 'OK'){
        // console.log("ENTER SAVE");
        this.cbCountry.setValue("");
        this.model = new Location;
        this.loading = false;
        this.info = 'save successfully';
        //this.setGridEvent = 'refresh';
        this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
      }else{
        this.loading = false;
        this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
      }

    },error => {
      this.loading = false;
      this.info = error.message;
      this.loading = false;
    });
  }

  valueTerminalMapping(event:any){
    this.valLocationId = event["locationId"];
  }

  gridEventTerminalDetail(event:any){

  }

  valueTerminalMappingDetail(event:any){
    if(event["locationId"] != null){
      this.valTerminalId = event["locationId"];
      this.errorMessageTerminalName = "";
    }else{
      this.valTerminalId = '';
    }
    if(event["locationCode"] != null){
      this.valTerminalCode = event["locationCode"];
    }else{
      this.valTerminalCode = '';
    }
    if(event["locationName"] != null){
      this.valTerminalName = event["locationName"];
    }else{
      this.valTerminalName = '';
    }
  }

  deleteEvent(){
    // console.log("Delete Event")
    this.loading = true;

    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.locationId);
    });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"locationId":[]}
    paramsDelete.locationId = deleteList;

    // let deleteList = [];
    // this.grid.getSelectedValues().forEach(element => {
    //   deleteList.push(element.locationId);
    // });
    this.genericService.POST(
      this.configService.config.BASE_API.toString() +
      // '/MasterLocations/delete',
      // deleteList
      "/MasterLocations/softDelete",
          paramsDelete
      )
    .subscribe((resp) =>{
      if(resp.status == 200 && resp.json()['message'] == 'SUCCESS'){

        this.cbCountry.setValue("");
        this.model = new Location;
        this.loading = false;
        this.grid.clearSelectedValues();
        this.grid.onFirst();
        this.loading=true;
        this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});

      }else{
        this.loading=true;
        this.message('information','Information',resp.json()['message'], 'okonly', { ok: 'this.loading=false; this.afterDelete();'});
      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  addDetail() {
    this.disableToolbarButtons = "save,cancel";
    this.lockSaveMapping = true;

    if(this.model['error-customerName'] != null){
      this.model['error-customerName'] = '';
    }

    this.cbTerminalMappingDetail.setValue('');
    this.valTerminalCode='';
    this.valTerminalName='';
    this.valTerminalId=''

    this.terminal = new Terminal;
    $('#displayForm').transition();
    $('#displayGrid').transition('hide');
  }

  removeDetail() {
    if(this.gridTerminalDetail.getSelectedValues().length > 0){
      this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.removeDetailEvent()', no: '' });
    }else{
      if(this.gridTerminalDetail.listStore.store.length > 0){
        this.message('delete','Deletion Record','Please select at least one (1) option.', 'okonly', { ok: ''});
      }
    }
  }

  cancelDetail() {
    this.disableToolbarButtons = "";
    this.lockSaveMapping = false;
    $('#displayForm').transition('hide');
    $('#displayGrid').transition();
  }

  saveDetail() {
      console.log(this.valTerminalId);
      if(!this.valTerminalId){
        this.errorMessageTerminalName = "Please enter Terminal Name";
        return
      }else{
        this.errorMessageTerminalName = "";
      }
      // t

  //  if(!this.onValidate(this.terminal)){

      if(this.terminal.no == 0){
        // console.log("masuk insert detail");
        let data = new Terminal;
        data.terminalId = this.valTerminalId.toUpperCase().trim();
        data.locationId = this.valLocationId.toUpperCase().trim();
        data.locationName = this.valTerminalName.toUpperCase().trim();
        data.locationCode = this.valTerminalCode.toUpperCase().trim();


        /*
        for(let i=0; i< this.gridTerminalDetail.listStore.store.length; i++){
          // console.log("Element number => " + i + " = " + this.gridTerminalDetail.listStore.store[i]["terminalId"]);
        }
        */

        //duplicate checking
        let duplicateFlag:boolean = false;
        this.gridTerminalDetail.listStore.store.forEach(element => {
          if(data.terminalId == element["terminalId"]){
            duplicateFlag = true;
          }
        });

        if(!duplicateFlag){
          this.gridTerminalDetail.listStore.addData(data);
          this.gridTerminalDetail.loadData();
          this.updateSeqNo();

          this.terminal = new Terminal;

          // this.setValidatorHeader();
          this.disableToolbarButtons = "";
          this.lockSaveMapping = false;
          $('#displayForm').transition();
          $('#displayGrid').transition();
        }else{
          this.message('warning','Warning','Terminal '+data.locationName+' is duplicated.', 'okonly', { ok: 'this.loading=false;'});
        }
      }else{

        let data = new Terminal;
        data.no = this.terminal.no;
        data.terminalId = this.terminal.terminalId;
        data.locationId = String(this.terminal.locationId).toUpperCase().trim();
        data.locationName = String(this.terminal.locationName).toUpperCase().trim();
        data.locationCode = String(this.valTerminalCode).toUpperCase().trim();


        this.gridTerminalDetail.listStore.updateData(this.terminal.no-1 ,data);
        this.gridTerminalDetail.loadData();

        this.terminal = new Terminal;

     //   this.setValidatorHeader();
        this.disableToolbarButtons = "";
        this.lockSaveMapping =  false;
        $('#displayForm').transition();
        $('#displayGrid').transition();
      }
  //  }
  }

  updateSeqNo(){
    let index:number = 0;
    this.gridTerminalDetail.listStore.store.forEach(element => {
      element['seqNo']=element['no'];
      this.gridTerminalDetail.listStore.updateData(index,element);
      index++;
    });
  }

  removeDetailEvent() {
    this.gridTerminalDetail.getSelectedValues().forEach(element => {
      this.gridTerminalDetail.listStore.removeData(element);
    });
    this.gridTerminalDetail.loadData();
    this.updateSeqNo();

    this.gridTerminalDetail.clearSelectedValues();
    this.gridTerminalDetail.is_select_all = '';

  }

  saveTerminalMapping(){
    this.loading = true;
    let md = this.model;
    md.masterLocationTerminals = [];
    this.gridTerminalDetail.listStore.store.forEach(element => {
      md.masterLocationTerminals.push(element);
    });
    // console.log(md);
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterTerminals/save',md).subscribe((resp) =>{
      if(resp.status == 200){
        this.loading = false;
        // update the new has_terminal to model accordingly
        this.model.hasTerminal = md.masterLocationTerminals.length > 0 ? 'Y' : 'N';
        this.message('information','Information','Terminal mapping save successfully. ', 'okonly', { ok: 'this.afterSaveMapping()'});
      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  afterSaveMapping(){
      //back to edit form
      this.disableToolbarButtons = "";
      this.invisibleToolbarButtons = "create,history,print";

      this.modeForm = true;
      this.modeToolbar = false;

      this.modeFormTerminal=false;
      this.form='';
      //this.valLocationId='';

      this.lock = true;

      //reset grid terminal
      //this.gridTerminalDetail.onClear();
      this.model.masterLocationTerminals = [];
  }

  noneSpace(event:any){
    // console.log("Event none => " + event);
    if(event.keyCode == 32){
      event.preventDefault();
    }
  }
  changePop(event:any) {
    if(this.model.isPop){
      this.lockPop = false;
    }else{
      this.lockPop = true;
      this.model.agencyCodePop = "";
    }

  }

  changeEventCountry(event:any) {
    if(event['countryCode'] != null){
      this.model.countryCode = event['countryCode'];
    }else{
      this.model.countryCode = "";
    }

    if(event['countryName'] != null){
      this.model.countryName = event['countryName'];
    }else{
      this.model.countryName = "";
    }

  }

  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }
}
