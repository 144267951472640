import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Currency } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService, UploadPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-hscode-toolkit-page',
  templateUrl: './hscode-toolkit-page.component.html',
  styleUrls: ['./hscode-toolkit-page.component.css']
})

export class HSCodeToolkitPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('gridBL') gridBL:GridPluginComponent;
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHSCode') gridHSCode:GridPluginComponent;
  @ViewChild('gridContainer') gridContainer:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent;
  @ViewChild('cbCurrencyNameHistory') cbCurrencyNameHistory:ComboPluginComponent;
  @ViewChild('uploadPlg') uploadPlg:UploadPluginComponent;
  @ViewChild('cbHScode') cbHScode:ComboPluginComponent;


  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;
  dialog2: any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  postData = {};
  fileName = "";
  ediData:any;
  vessel;
  voyage;
  fName;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  modeDetail = false;

  modeUpdate = '';

  /* Parameter form (Default : '') */
  form = '';

  /* Parameter listStore for Grid */
  listStore = new ListStore;

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridBL
  settingGridHistory;
  settingGridHSCode;
  settingUpload;
  settingGridContainer;
  settingHSCode;


  //settingCurrencyLogStartDate;
  //settingCurrencyLogEndDate;
  settingCurrencyHistory;


  /* Misc Parameters */
  valCurrencyCode:string = '';
  valCurrencyName:string = '';
  valCurrencyId:string = '';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';

  tempCntHS = [];
  tempBL = 1;

  pushHSCode = []

  typeCurrencyLogStartDate = 'calendar';
  typeCurrencyLogEndDate = 'calendar';

   /* Parameter model */
  model = new Currency;
  //selectList = [];

  loading = false;
  loadingUpload = false;

  lock = false;

  hitUrl:string = this.configService.config.BASE_API.toString();

  /* validatorRules */
  validatorRules = {
    currencyName: {
        rules:[
          {
            type  : 'empty',
            prompt: 'Please input Currency Name.'
          }
        ]
    },
    currencyCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Currency Code.'
        },
        {
          type  : 'length[3]',
          prompt: 'Currency Code must be 3 characters long.'
        }
      ]
    }
  }

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService,private router:Router) {
    super();

    this.settingToolbar = {
      buttonsFront          : [
       // { name : 'Load',  event: 'load', icon : 'search' },
        //{ name : 'Export',  event: 'export', icon : 'print' },
        //{ name : 'Split',  event: 'split', icon : 'unlinkify' },
        { name : 'Batch Update By BL',  event: 'updatebl', icon : 'save' },
        {name  : 'Batch Update By Container', event: 'updatecontainer', icon : 'save'},
       // {name  : 'Single Update By Container', event: 'sinupdate', icon : 'remove'},
        {name  : 'Re-Generate EDI', event: 'generate', icon : 'file'},
        { name : 'Cancel',  event: 'cancelact', icon : 'remove' },
        {name  : 'Close', event: 'close', icon : 'power'},

      ],
      buttonsDetail         : [

        { name : 'Finish',  event: 'save', icon : 'save' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' }

      ],
      createDefaultFront    : false,
      createDefaultDetail   : false,
      toolbarType           : 'none',
      label                 : 'HS Code'
    }

    this.settingHSCode = {
      id          : 'cbHScode',
      type        : 'search enter', // search | select | select input
      //url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findLikeVesselNameOrVesselIdAndIsValid/*/{query}',
      url         : this.configService.config.BASE_API.toString() + '/MasterHSCodes/findByComboBoxControl/hsCodeLikeDescriptionLike={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search HS Code',
      title       : 'hsCodeName',
      description  : '',
      isMark      : true,
      showOnTop:true,
      columns     : [
        {header: 'HS Code', field: 'hsCode', width: 100},
        {header: 'HS Name', field: 'hsDescription', width: 500}
      ]
    }

    this.settingUpload = {
      id   : 'upload',
      uploadDir : '/edi',
      maxFiles : 1,
      filterFiles : '',
      showUploadButton: true,

    }

    this.settingGridBL = {
      id:'gridBL',
      url: '',
      page: 10,
      columns: [
        {header: 'BL List', field: 'blNo', width: 300},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      height:200,
      auto:false
  }

  this.settingGridHSCode = {
    url: '',
    page: 10,
    columns: [
      {header: 'HS Code List', field: 'hscodeDesc', width: 700},
    ],
    buttons: [
      { name: 'Edit', event: 'edit', enabled: true }
    ],
    //store: this.listStore,
    enableSorting: true,
    enableSelectAll: false,
    enablePagination: false,
    minHeight:50,
    height:220,
    auto:false

}

  this.settingGridContainer = {
    id:'gridContainer',
    url: '',
    page: 10,
    columns: [
      {header: 'Container List', field: 'container', width: 300},
    ],
    buttons: [
      { name: 'Edit', event: 'edit', enabled: true }
    ],
    //store: this.listStore,
    enableSorting: true,
    enableSelectAll: false,
    enablePagination: false,
    auto:false
}


    this.settingGrid = {
        url: this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneral',
        page: 10,
        columns: [
          {header: 'No', field: 'no', width: 15},
          {header: 'Currency Name', field: 'currencyName', width: 200, type: 'string'},
          {header: 'Currency Code', field: 'currencyCode', width: 50},
          {header: 'Remarks', field: 'remarks', width: 300},
          {header: 'User Created', field: 'userCreated', width: 100},
          {header: 'Date Created', field: 'dateCreated', width: 100},
          {header: 'User Modified', field: 'userModified', width: 100},
          {header: 'Date Modified', field: 'dateModified', width: 100},
          {header: 'User Invalid', field: 'userInvalid', width: 100},
          {header: 'Date Invalid', field: 'dateInvalid', width: 100},
          {header: 'Valid?', field: 'isValid', width: 50}
        ],
        buttons: [
          { name: 'Edit', event: 'edit', enabled: true }
        ],
        //store: this.listStore,
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingColumns:'currencyName',
        sortingDirection: 'ASC'
    }

    this.settingGridHistory={
      url: this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneralHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Currency Id', field:'currencyId', width:200},
        {header: 'Currency Name', field: 'currencyName', width: 200, type: 'string'},
        {header: 'Currency Code', field: 'currencyCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true
    }

    this.settingCurrencyHistory = {
      id          : 'cbCurrencyHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCurrencies/findGeneralHistory/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'currencyName',
      description : '',
      isMark      : true,
      columns     : [
        {header: 'Currency Id', field: 'currencyId', width:50},
        {header: 'Currency Name', field: 'currencyName', width: 50},
        {header: 'Currency Code', field: 'currencyCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }
/*
    this.settingCurrencyLogStartDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }

    this.settingCurrencyLogEndDate = {
      modeCal : true,
      type : 'date',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false
    }
*/
  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    this.disableToolbarButtons = "update,delete";
   // this.invisibleToolbarButtons = "cancel";
    $('#addBtn').prop('disabled',true);
    $('#removeBtn').prop('disabled',true);

    //this.setGridEvent = 'search:*/*/* + refresh';
  //  this.grid.search='*/*/*';
    //this.grid.onFirst();

  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  message2(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  changeEventHScode(event){
    console.log('gogo');
   console.log(event);

   //this.cbHScode.setForceValue(this.cbHScode.getValue().hsCode)

  }


  infoGrid(event:any) {
    this.info = event;
  }

  infoGridHistory(event:any){
    this.info=event;
  }

  gridEventContainer(event:any){
    this.info=event;
    console.log('event')

    switch(event.split(".")[0]){

      case 'click' :
      var mode = event.split(".")[1].split("-")[0];
      var no = event.split(".")[1].split("-")[1];

      switch(mode){
        case 'checked' :
          this.selectedListMod(no, this.gridContainer,'no');
          this.gridHSCode.onClear();
          this.pushHSCode = [];

          this.ediData.forEach(ele => {
            if(ele.blNo ==  this.gridBL.getSelectedValues()[0].blNo){

              ele.containerDetails.forEach(dll=>{

                if(dll.containerNo == this.gridContainer.getSelectedValues()[0].container){
                  //console.log('container'+ dll.containerNo)
                  //ele.containerDetails.push({'hsCodeNo':this.gridHSCode.listStore.store})
                  if(dll['hsCode'] != undefined){
                    this.gridHSCode.listStore.store = dll['hsCode'];
                    this.pushHSCode.push(dll['hsCode']);
                    ele.select = 'checked';
                   // this.tempCntHS.push({'cntTemp':dll.containerNo})
                  }
                }

              })

            }
          });

          $('#addBtn').prop('disabled',false);
          $('#removeBtn').prop('disabled',false);

          this.gridHSCode.loadData();

        break;
      }

      break;

      case 'afterLoad':
      console.log('afterloading')
      this.loadingUpload = true
      var lenGrid = this.gridContainer.listStore.store.length
      var self = this;

      setTimeout(function(){
              $('#gridContainer-table').each(function(i) {
                console.log('masuk loading');

              //var rowGrid = $('#container-table tr').length-1;
              for(var k=1;k<=lenGrid;k++){
                console.log('RowSPAN '+(k)+$(this).find("span[id='gridContainer.span."+k+".container']").text());
                var textVal = $(this).find("span[id='gridContainer.span."+k+".container']").text();

                if(self.tempCntHS.indexOf(textVal) > -1)
                  $(this).find("span[id='gridContainer.span."+k+".container']").css('color','red')
                else
                  $(this).find("span[id='gridContainer.span."+k+".container']").css('color','black')
              }

            });
            self.loadingUpload = false
        }
         , 800);


      break;

    }
  }

  gridEventBL(event:any){

    switch(event.split(".")[0]){

      case 'click' :
      var mode = event.split(".")[1].split("-")[0];
      var no = event.split(".")[1].split("-")[1];
      switch(mode){
        case 'checked' :
        this.gridContainer.onClear();
          this.selectedListMod(no, this.gridBL,'no');

          this.gridBL.getSelectedValues().forEach(yy=>{
            console.log('yy');
            console.log(yy);
            yy.containerDetails.forEach(uu=>{
              this.gridContainer.listStore.store.push({
                'container':uu.containerNo
              })
            })
            //this.gridContainer.listStore.store.push({
              //'container':yy
            //})
          })

          console.log(this.gridContainer.listStore.store)
          this.gridContainer.loadData();
          this.gridHSCode.onClear();



          break;
        case 'unchecked' :
          break;
      }

      break;

      default:
      break;

    }

  }
  gridEvent(event:any) {
    console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }
        break;
      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "update,delete";
        }
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){

            this.disableToolbarButtons = "";
            this.invisibleToolbarButtons = "create,history,print";

            this.modeForm = true;
            this.modeToolbar = false;
            this.setData();
            this.lock = true;
          }

        }

        break;
    }
  }

  gridEventHSCode(event:any){
    console.log(event);
  }

  gridEventHistory(event:any){
    //do nothing
  }

  toolbarEvent(event:any) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'updatebl':
        this.modeUpdate = 'bl';
        this.handleUpdateBL();

        break;

        case 'updatecontainer':

        this.modeUpdate = 'cnt';
        this.handleUpdateCnt();

        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
          this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'cancelact':
        this.handleCancel2();
        break;
      case 'close':
        this.handleClose();
        break;
      case 'generate':
        this.handleGenerate();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleUpdateBL(){
    this.modeToolbar = true;
    this.gridContainer.disable()
    this.gridBL.disable();
    this.gridContainer.onClear();
    this.gridBL.onSelectAllByValue(true);
    this.gridBL.is_select_all = 'checked'

    $('#addBtn').prop('disabled',false);
    $('#removeBtn').prop('disabled',false);
  }

  handleUpdateCnt(){

    if(this.gridBL.getSelectedValues().length == 0){
      this.message('warning','HS Code Toolkit','Please select BL list!','okonly',{ok: 'this.loading = false;' });
    }else{
      this.modeToolbar = true;
      this.gridContainer.disable()
      this.gridContainer.onSelectAllByValue(true);
      this.gridContainer.is_select_all = 'checked'
      $('#addBtn').prop('disabled',false);
      $('#removeBtn').prop('disabled',false);
    }
  }

  handleClose(){
    //handle close event
    this.router.navigateByUrl("/main/home");
  }

  handleCreate()
  {
    // handle create event
    this.lock=false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Currency;
      this.valCurrencyCode='';
      this.valCurrencyName='';
  }


  handleUpdate()
  {
    // handle update event
    this.modeForm = true;
    this.modeToolbar = true;
    this.setData();
    this.lock=false;
    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";
  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/6/Currencies-Export-Reports/*');
    // handle print event
  }

  handleSaveCnt(){

  }

  handleSave()
  {
    // handle save event
    console.log('handleSave');

      this.message('save','Saving data','Do you want to update the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});

  }

  handleCancel2(){

    this.gridBL.onClear();
    this.gridContainer.onClear();
    this.gridHSCode.onClear();
   // $('#addBtn').prop('disabled',true);
    //$('#removeBtn').prop('disabled',true);
    this.uploadPlg.store = [];

  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.modeUpdate = '';
    this.form = '';
    this.gridContainer.enable();
    this.gridContainer.onSelectAllByValue(false)
    this.gridContainer.is_select_all = '';
    this.model =  new Currency;
    this.disableToolbarButtons = "update,delete";
   // this.invisibleToolbarButtons = "cancel";
     this.modeToolbar = false
//this.grid.clearSelectedValues();

    if(this.valCurrencyId != '') {
      this.valCurrencyCode = '';
      this.valCurrencyId = '';
      this.cbCurrencyNameHistory.setValue("");
      this.gridHistory.onHistory("NaN")
      this.gridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    console.log("grid-> " + this.grid.getSelectedValues().length);

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }



  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
  }

  setData(){
      //SET MODEL DATA FOR UPDATE PROCESS
      let data = this.grid.getSelectedValues()[0];

      this.model = new Currency;

      if(data.isValid=='Y'){
        this.model.isValid=true;
      }else{
        this.model.isValid=false;
      }

      this.model.currencyId = String(data.currencyId).trim().toUpperCase();
      this.model.currencyCode = String(data.currencyCode).trim().toUpperCase();
      this.model.currencyName = String(data.currencyName).trim().toUpperCase().replace(/&NBSP;/g," ");
      this.model.remarks = String(data.remarks).trim().toUpperCase();
      this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
      this.model.userCreated = String(data.userCreated).trim().toUpperCase();
      this.model.dateModified = String(data.dateModified).trim().toUpperCase();
      this.model.userModified = String(data.userModified).trim().toUpperCase();
      this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
      this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();



  }

  valueCurrencyHistory(event:any){
    this.valCurrencyCode=event["currencyCode"];
    this.valCurrencyId=event["currencyId"];
    console.log("curr code= " + event["currencyCode"]);

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valCurrencyId);
  }

  generateCurrencyHistoryReport(event:any){
    //this.valHistoryStartDate= $('#logHistoryCurrencyStartDate').find('#datePicker').val();
   // this.valHistoryEndDate= $('#logHistoryCurrencyEndDate').find('#datePicker').val();
    window.open(this.configService.config.BASE_API.toString() + '/Mreport/runReport/4/History-Currency-' + this.valCurrencyId + '-' + this.valCurrencyCode +'/'+this.valCurrencyId + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);

  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  saveEvent(){
    console.log('saveEvent');
   // this.loading = true;

    if(this.modeUpdate == 'bl'){

            this.ediData.forEach(ck => {

                ck.containerDetails.forEach(etc=>{

                    if(this.tempCntHS.indexOf(etc.containerNo) == -1)
                      this.tempCntHS.push(etc.containerNo)

                    etc['hsCode'] = this.gridHSCode.listStore.store;


                })


            });

            
            this.gridBL.enable();
            this.gridBL.onSelectAllByValue(false);
            this.gridBL.is_select_all = ''
            this.gridContainer.enable()    ;
           // this.gridHSCode.onClear();
            $('#addBtn').prop('disabled',false);
            $('#removeBtn').prop('disabled',false);

            var thas = this
            $('#gridBL-table').each(function(i) {
              console.log('masuk loading');

            //var rowGrid = $('#container-table tr').length-1;
            for(var k=1;k<=thas.gridBL.listStore.store.length;k++){

                $(this).find("span[id='gridBL.span."+k+".blNo']").css('color','blue')
            }

          });
    }
    else{

      this.ediData.forEach(ck => {
        if(ck.blNo ==  this.gridBL.getSelectedValues()[0].blNo){
          ck.containerDetails.forEach(etc=>{

              if(this.tempCntHS.indexOf(etc.containerNo) == -1)
                this.tempCntHS.push(etc.containerNo)

              etc['hsCode'] = this.gridHSCode.listStore.store;


          })
        }


      });
    

      var thos = this
      $('#gridContainer-table').each(function(i) {
        console.log('masuk loading');

      //var rowGrid = $('#container-table tr').length-1;
      for(var k=1;k<=thos.gridContainer.listStore.store.length;k++){

          $(this).find("span[id='gridContainer.span."+k+".container']").css('color','red')
      }

    });

    }

    this.modeToolbar = false;
    this.gridContainer.enable()
    this.gridContainer.onSelectAllByValue(false)
    this.gridContainer.is_select_all = ''
   // this.gridHSCode.onClear();
    $('#addBtn').prop('disabled',false);
    $('#removeBtn').prop('disabled',false);
        console.log(this.ediData);

  }

  addNewRow(){
    this.tempBL = 0;
    console.log('add new row' + this.modeUpdate);
    var belf = this;
    if(this.modeUpdate != 'bl' && this.modeUpdate != 'cnt'){
        if(this.gridContainer.getSelectedValues().length > 0){
          console.log('puish hs code'+this.pushHSCode.indexOf(this.cbHScode.getValue().hsCode))
          console.log(this.pushHSCode)
          if(this.pushHSCode.indexOf(this.cbHScode.getValue().hsCode)== -1){

              this.pushHSCode.push(this.cbHScode.getValue().hsCode)

              this.gridHSCode.listStore.store.push({

                'hscodeDesc' : this.cbHScode.getValue().hsCode+ ' - '+this.cbHScode.getValue().hsDescription,
                'hsCode' : this.cbHScode.getValue().hsCode,
                'hsCodeName' : this.cbHScode.getValue().hsDescription

              })

              this.ediData.forEach(ele => {
                if(ele.blNo ==  this.gridBL.getSelectedValues()[0].blNo){

                  ele.containerDetails.forEach(dll=>{

                    if(dll.containerNo == this.gridContainer.getSelectedValues()[0].container){
                      //console.log('container'+ dll.containerNo)
                      //ele.containerDetails.push({'hsCodeNo':this.gridHSCode.listStore.store})
                      if(this.tempCntHS.indexOf(this.gridContainer.getSelectedValues()[0].container) == -1)
                        this.tempCntHS.push(dll.containerNo)

                      dll['hsCode'] = this.gridHSCode.listStore.store;
                    }

                  })

                }
              });

              $('#gridContainer-table').each(function(i) {
                console.log('masuk loading');

              //var rowGrid = $('#container-table tr').length-1;
              for(var k=1;k<=belf.gridContainer.listStore.store.length;k++){
                var textContainer = $(this).find("span[id='gridContainer.span."+k+".container']").text();
                console.log('textContainer'+textContainer);
                console.log('indexof'+belf.tempCntHS.indexOf(textContainer));
                if(belf.tempCntHS.indexOf(textContainer) > -1)
                  $(this).find("span[id='gridContainer.span."+k+".container']").css('color','red')
              }

            });


            this.gridBL.getSelectedValues().forEach(see=>{


              see.containerDetails.forEach(uu=>{

                if(this.tempCntHS.indexOf(uu.containerNo) > -1){
                  console.log('isi'+uu.containerNo)
                  this.tempBL = this.tempBL + 1;
                }
              })




            })
            console.log('tempBEELS'+this.tempBL)
            if(this.tempBL == this.gridContainer.listStore.store.length){

              $('#gridBL-table').each(function(i) {
                console.log('masuk loading');

              //var rowGrid = $('#container-table tr').length-1;


                  $(this).find("span[id='gridBL.span."+belf.gridBL.getSelectedValues()[0].no+".blNo']").css('color','blue')


            });

            }else{
              console.log('masuk else sini')
              $('#gridBL-table').each(function(i) {
                console.log('masuk loading');

              //var rowGrid = $('#container-table tr').length-1;


                  $(this).find("span[id='gridBL.span."+belf.gridBL.getSelectedValues()[0].no+".blNo']").css('color','black')


             });
            }
              console.log(this.tempCntHS)

            //this.gridHSCode.listStore.store.push(this.cbHScode.getValue().hsCode+ ' - '+this.cbHScode.getValue().hsDescription)

            this.gridHSCode.loadData() ;
            this.cbHScode.setValue('');

          }else{
            this.loading = true;
            this.message('warning','HS Code Toolkit','Duplicate Entry!','okonly',{ok: 'this.loading = false;' });
          }

        }else{
          this.loading = true;
          this.message('warning','HS Code Toolkit','Please select container list!','okonly',{ok: 'this.loading = false;' });
        }
      }else{

          this.gridHSCode.listStore.store.push({

            'hscodeDesc' : this.cbHScode.getValue().hsCode+ ' - '+this.cbHScode.getValue().hsDescription,
            'hsCode' : this.cbHScode.getValue().hsCode,
            'hsCodeName' : this.cbHScode.getValue().hsDescription

          });

          this.gridHSCode.loadData() ;
          this.cbHScode.setValue('');
      }
  }

  removeRow(){

    var storeTemp = new ListStore;
    var boxCheck : number;
    this.pushHSCode = [];

    console.log(this.gridHSCode.getSelectedValues())
   // console.log(this.gridHSCode.getSelectedValues().length)

    if(this.gridHSCode.getSelectedValues().length == 0 ){
      console.log('masuk 0000')
      this.loading = true;
      this.message('warning','HS Code Toolkit','Please select data(s) to delete!','okonly',{ok: 'this.loading = false;' });
    }else{

      this.gridHSCode.listStore.store.forEach(gugu=>{
        storeTemp.addData( gugu)
      })

      console.log(storeTemp);

      storeTemp.store.forEach(el=>{

        if(el['select'] == 'checked') {

          console.log('masuk');
          boxCheck = this.gridHSCode.listStore.store.indexOf(el);
          console.log(boxCheck)
          this.gridHSCode.listStore.store.splice(boxCheck,1);

         // $('input[name="gridCount"]').val(parseInt($('input[name="gridCount"]').val())-1);
        }
      //  console.log(gaga);

        //this.gridHSCode.loadData();
       // this.gridHSCode.clearSelectedValues();


      });
      var j = 0;
      var un = 0
      var gelf = this;
      this.ediData.forEach(ele => {
        if(ele.blNo ==  this.gridBL.getSelectedValues()[0].blNo){

          ele.containerDetails.forEach(dll=>{

            if(dll.containerNo == this.gridContainer.getSelectedValues()[0].container){
              //console.log('container'+ dll.containerNo)
              //ele.containerDetails.push({'hsCodeNo':this.gridHSCode.listStore.store})
              dll['hsCode'] = '';
              dll['hsCode'] = this.gridHSCode.listStore.store;
            }

            if(this.gridHSCode.listStore.store.length == 0)
              un=1

          })

        }

      });


      this.gridHSCode.listStore.store.forEach(buy=>{
        this.pushHSCode.push(buy['hsCode']);
      })

      console.log('isi push code')
      console.log(this.pushHSCode)

      $('#gridContainer-table').each(function(i) {

        console.log('masuk loading' + gelf.gridContainer.getSelectedValues()[0].container);

      //var rowGrid = $('#container-table tr').length-1;
      for(var k=1;k<=gelf.gridContainer.listStore.store.length;k++){
        var textContainer = $(this).find("span[id='gridContainer.span."+k+".container']").text();
        //console.log('RowSPAN '+(k)+$(this).find("span[id='gridContainer.span."+k+".container']").text());
      // console.log('text uey'+gelf.tempCntHS.indexOf(gelf.gridContainer.getSelectedValues()[0].container));

        if(gelf.tempCntHS.indexOf(gelf.gridContainer.getSelectedValues()[0].container) > -1 && gelf.gridHSCode.listStore.store.length == 0 && textContainer == gelf.gridContainer.getSelectedValues()[0].container){
         console.log('masuk  kondisi' + k)
         //console.log($(this).find("span[id='gridContainer.span."+k+".container']").text());
         $(this).find("span[id='gridContainer.span."+k+".container']").css('color','black');
          gelf.tempCntHS.splice(gelf.tempCntHS.indexOf(gelf.gridContainer.getSelectedValues()[0].container),1);
        }
      }
      console.log(gelf.tempCntHS)

    });

    if(un > 0){
        $('#gridBL-table').each(function(i) {
          console.log('masuk loading');

        //var rowGrid = $('#container-table tr').length-1;


            $(this).find("span[id='gridBL.span."+gelf.gridBL.getSelectedValues()[0].no+".blNo']").css('color','black')


      });
  }


    }

  }

  selectedListMod(value, store:GridPluginComponent, key){
    console.log("no "+value);
    if(store.getSelectedValues().length > 1){
      store.getSelectedValues().forEach(ff=>{
        if(ff[key] != value){
          ff['select'] = '';
        }
      });

      store.getSelectedValues().splice(0,1);
    }
    console.log("total checked "+store.getSelectedValues().length);
  }

  deleteEvent(){
    console.log("Delete Event")
    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.currencyId);
    });
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterCurrencies/delete',deleteList).subscribe((resp) =>{
          if(resp.status == 200){
              this.model = new Currency;
            //  this.selectList = [];
              this.loading = false;
              this.grid.clearSelectedValues();
              this.grid.onFirst();
              //this.setGridEvent = 'refresh';
              this.loading=true;
              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});


          }
    },error => {
      this.loading = false;
      console.log(error.message);
      this.info = error.message;
    });

  }

  uploadEvent(event){
    console.log(event);
    var list = event.split("-");
    switch(event){
      case "success":
        this.loadingUpload = true;
        this.processUploadFile();
        /*
        if(!this.uplReplace){
          this.retrieveUploadedData();
        } else {
          this.uplReplace = false;
          this.retrieveUploadedData("replace");
        }*/
        break;
      default:
        //console.log(list[1]);
        switch(list[1].trim()){
          case "uploaded":
            this.fileName = list[0].trim();
            this.postData["dateCreated"] = this.genericUtil.getPSIDate();
            this.postData["fileName"] = this.fileName;

            console.log(this.postData);
            break;
        }
        break;
    }
  }

  processUploadFile(){
    var uplUri = this.hitUrl + "/ContainerLoadList/upload/edi";
    this.gridBL.onClear();
    this.gridContainer.onClear();
    this.gridHSCode.onClear();

    this.genericService.POST(uplUri, this.postData).subscribe((resp)=>{
      if(resp.ok){
        console.log("result");
        console.log(resp.json());
        this.gridBL.listStore.store.splice(0, this.gridBL.listStore.store.length);
        this.ediData = resp.json()["dataList"];
        this.vessel = resp.json()["vesselName"];
        this.voyage = resp.json()["voyage"];
        this.fName = resp.json()["fileName"];

        this.ediData.forEach(fe=>{
          this.gridBL.listStore.store.push(fe);
        });

        this.gridBL.loadData();

        this.loadingUpload = false;
      }
    }, error=>{
      this.message("Information","information","An Error occured, please contact your administrator!","okonly",{ok:""});
      this.loadingUpload = false;
    });
  }


  infoGridHSCode(event){}
  infoGridContainer(event){}

  handleGenerate(){
    //console.log(this.ediData);
    var postData = {};
    postData["dataList"] = this.ediData;
    postData["vesselName"] = this.vessel;
    postData["voyage"] = this.voyage;
    postData["fileName"] = this.fName;

    console.log("post data")
    console.log(postData);

    var postURL = this.hitUrl + "/ContainerLoadList/generateHSCodeReport";
    this.genericService.POST(postURL, postData).subscribe((resp)=>{
      if(resp.ok){
        if(resp.json()["message"] == "ok"){
          window.open("http://glossys.samudera.com/"+resp.json()["fileLocation"].replace("/var/www/html/",""));
        } else {
          this.message("Information","information","An Error occured, please contact your administrator!","okonly",{ok:""});    
        }
      }
    }, error=>{
      this.message("Information","information","An Error occured, please contact your administrator!","okonly",{ok:""});
    });

  }
}
