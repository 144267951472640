import { forEach } from '@angular/router/src/utils/collection';
import { AllocationService } from './allocation.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//CR001 Added Code for Remove Allocation data from Grid
//CR002 Update Code done for ref pls check withCoreection No CR002

import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  DialogPluginComponent,
  UploadPluginComponent,
} from "sibego-ui-library";
import { Router } from "@angular/router";
import { element } from "@angular/core/src/render3/instructions";
import { Alert } from "selenium-webdriver";
import { specialCharUtil } from "../../shared";
import FormValidation from "../../../utils/formValidation";
import { Subject } from 'rxjs';
import { IsRowSelectable } from 'ag-grid-community';
import { TransactionTsPortHomeService } from '../transaction-ts-port-home.service';

declare var $: any;

export class PortPairAllocationCopy {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyage: string = "";
  bound: string = "O"
  bPot1Code: string;
  newVesselId: string;
  newVoyage: string;

  constructor() { }
}

export class PortPairAllocation {
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyage: string = "";
  bound: string = "";
  eta: string = "";
  coc: number = 0;
  cocLocal: number = 0;
  cocTS: number = 0;
  bPOT = "";
  bPot1Code = "";
  bPot1Name = "";
  containerOwnerShip = "COC";
  tsServiceId = "";
  tsTotal = "";
  tsLocal = "";
  tsTS = "";
  tsEta = "";
  tsEtd = "";
  tsRotation = "";
  vesselName: any;
  totalAllocated: any;
  totalBooked: any;
  totalConfirmed: any;
  totalAvailable: any;
  tsServiceCode = "";

  constructor() { }
}

type IPortPair = PortPairAllocation;

export class PortPairAllocationDetail {
  id: string = "";
  officeCode: string = "";
  vesselId: string = "";
  vesselCode: string = "";
  voyage: string = "";
  bound: string = "";
  polid: string = "";
  polCode: string = "";
  polName: string = "";
  polTerminalCode: string = "";
  podid: string = "";
  podCode: string = "";
  podName: string = "";
  podTerminalCode: string = "";
  weight: number = 0;
  alloted: number = 0;
  booked: number = 0;
  confirm: number = 0;
  available: number = 0;
  Index: number = 0;
  novval: number = 0;
  polTerminalName: any;
  podTerminalName: any;
  bPot1Code: any;
  bpolTerminal: any;
  isUpdated: boolean = false;

  constructor() { }
}

@Component({
  selector: "app-transaction-ts-port-pair-allocation-page-v2",
  templateUrl: "./transaction-ts-port-pair-allocation-page-v2.component.html",
  styleUrls: ["./transaction-ts-port-pair-allocation-page-v2.component.css"],
  providers: [
    AllocationService
 ]
})
export class TransactionTSPortPairAllocationPageComponentV2
  extends Validator
  implements OnInit, AfterViewInit, OnDestroy {
  dialog: any;

  private onDestroy$ = new Subject<boolean>();

  @ViewChild("cbVessel") cbVessel: ComboPluginComponent;
  @ViewChild("cbVoyage") cbVoyage: ComboPluginComponent;

  @ViewChild("cbLoadPort") cbLoadPort: ComboPluginComponent;
  @ViewChild("cbDischargePort") cbDischargePort: ComboPluginComponent;
  @ViewChild("cbDischargePortTerminal")
  cbDischargePortTerminal: ComboPluginComponent;

  @ViewChild("cbVesselCopy") cbVesselCopy: ComboPluginComponent;
  @ViewChild("cbVoyageCopy") cbVoyageCopy: ComboPluginComponent;
  @ViewChild("cbPOT1") cbPOT1: ComboPluginComponent;
  @ViewChild("cbPOT1Insert") cbPOT1Insert: ComboPluginComponent;
  @ViewChild("cbPOT1Copy") cbPOT1Copy: ComboPluginComponent;
  @ViewChild("cbLoadPortForm") cbLoadPortForm: ComboPluginComponent;
  @ViewChild("cbLoadPortInsert") cbLoadPortInsert: ComboPluginComponent;
  @ViewChild("cbTerminalLoadPort") cbTerminalLoadPort: ComboPluginComponent;
  @ViewChild("dialogPortPair") dialogPluginPortPair: any;

  @ViewChild('ppaFileUpload') ppaFileUpload: UploadPluginComponent;

  // * Special Char Utils
  sCharUtil = new specialCharUtil();

  // Form Validation
  formValidation = new FormValidation();

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "insert";
  disableToolbarButtons = "";

  modeToolbar = false;

  loading = false;
  loadingUpload = false;
  isDisableProcessButton = true;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingVessel;
  settingVoyage;

  settingLoadPort;
  settingLoadPortTerminal;
  settingDischargePort;
  settingDischargePortTerminal;

  settingVesselCopy;
  settingVoyageCopy;

  settingUploadPPAFile;

  //types
  typeLocalETA = "calendarETA";
  typeLocalETD = "calendarETD";
  typeBlDate = "calendarBL";
  typeExchangeRateDate = "calendarEX";

  // lock
  cButtonLock = false;
  dButtonLock = false;

  isError: Boolean = false;
  isErrorDetail: Boolean = false;
  isErrorCopy: Boolean = false;

  model = new PortPairAllocation();
  modelDetail = new PortPairAllocationDetail();
  modelCopy = new PortPairAllocationCopy();

  officeCode: any;

  lockPOLTerminal: Boolean = true;
  lockPODTerminal: Boolean = true;
  isRetrieve: Boolean = false;
  radioContainerOwnerSOC: any;
  getRowStyle: any;

  /*
  validatorRules = {
    bound: {
      rules: [{
        type: 'empty',
        prompt: 'Please input bound.'
      }]
    },
    vesselCode: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Vessel.'
      }]
    },
    voyage: {
      rules:[{
          type  : 'empty',
          prompt: 'Please input Voyage.'
      }]
    },
    allocationValidator: {
      rules:[{
          type  : 'custom',
          prompt: 'Allocation has not been set for this vessel, unable to create allocation.'
      }]
    },

  }
  */

  validatorRules = {};
  rdCoc: string;
  rdSoc: string;
  defaultColumnDefs: any;
  rowData = [];
  isView = true;
  modeForm: 'CREATE' | 'COPY';
  viewOnlyStatus = false;
  gridApi: any;
  pinnedBottomRowData = [];
  rotation = "";
  test = 'O';
  radioContainerOwnerCOC = 'Y';
  settingPOT1: any;
  columnApi: any;
  settingLoadPortInsert: any;
  userID: string;
  BASE_URL = this.configService.config.BASE_API.toString();
  BASE_URL_TEST = 'http://172.16.193.245:13000';
  settingPOT1Insert: any;
  newArray = [];
  totalAllocated: any;
  totalBooked: any;
  totalConfirmed: any;
  totalAvailable: any;
  isEdit = false;
  isRowSaved = false;
  isPodAllowedToChangedAfterSaved = false;
  rowActive: any;
  labelConfirmCancelClose = "";
  settingTerminalLoadPort: any;

  isRowSelectable: IsRowSelectable = (
    params: any
  ) => {
    return params.data.tslStatus !== "Deleted";
  };

  postData: any = {};
  filePath = '';

  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private router: Router,
    private configService: ConfigService,
    private cookieService: CookieService,
    private cdr: ChangeDetectorRef,
    private allocationService: AllocationService,
    private transactionTsPortHomeService: TransactionTsPortHomeService,
    private _http: HttpClient
  ) {
    super();

    this.handleSetting();
  }

  ngOnInit() {
    this.model.bound = "O";
    this.modelCopy.bound = "O";
    this.officeCode = this.cookieService.getDefaultLocationCode().toString().trim();
    this.model.officeCode = this.officeCode;
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    this.eventAfterInitView();
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
    this.cbLoadPortInsert.close();
    this.cbLoadPortInsert.clearSelect();
    // this.dialogPlugin.ngOnDestroy();
  }

  handleSetting() {
    this.settingToolbar = {
      buttonsFront: [
        { name: "Retrieve", event: "search", icon: "search" },
        { name: "New", event: "new", icon: "plus" },
        { name: "Save", event: "save", icon: "save" },
        { name: "Insert", event: "insert", icon: "plus" },
        { name: "Copy From", event: "copy", icon: "copy" },
        { name: "Delete", event: "delete", icon: "delete" },
        { name: "Cancel", event: "cancel", icon: "cancel" },
        { name: "Close", event: "close", icon: "power" },
        { name: "Receive From Excel", event: "uploadExcel", icon: "upload" },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: "label",
      label: "Transhipment Port-Pair Allocation",
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        // "/MasterLocations/newFindByComboBoxControlExcludeFreightGroup/locationName={query}",
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Load Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
      ],
      maxlength: 50
    };

    this.settingLoadPortInsert = {
      id: "cbLoadPortInsert",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Load Port",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 200 },
        { header: "Location Name", field: "locationName", width: 200 },
        // { header: "Terminal Code", field: "terminalCode", width: 150 },
        // { header: "Terminal Name", field: "terminalName", width: 150 },
      ],
    };

    this.settingTerminalLoadPort = {
      id: "cbTerminalLoadPort",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Terminal (POL)",
      title: "locationName", // variable name
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
      ],
    };

    this.settingPOT1 = {
      id: "cbPOT1",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findPotProspectBooking/{query}",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Port of Transhipment",
      description: "",
      title: "locationName",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
        { header: "POT", field: "isPot", width: 50 },
      ],
      maxlength: 50
    };

    this.settingPOT1Insert = {
      id: "cbPOT1Insert",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Port of Transhipment",
      description: "",
      title: "locationName",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 150 },
        { header: "Location Name", field: "locationName", width: 150 },
        { header: "Terminal Code", field: "terminalCode", width: 150 },
        { header: "Terminal Name", field: "terminalName", width: 150 },
        { header: "ETA", field: "arrivalDate", width: 150 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
      maxlength: 50
    };

    this.defaultColumnDefs = [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        showDisabledCheckboxes: true, // only available from AG Grid 28.1
      },
      { headerName: 'Load Port', field: 'tslLoadPortName', width: 150 },
      { headerName: 'Terminal(POL)', field: 'tslLoadPortTerminalName', width: 150 },
      { headerName: 'Discharge Port', field: 'tslDischargePortName', width: 150 },
      { headerName: 'Terminal(POD)', field: 'tslDischargePortTerminalName', width: 200 },
      { headerName: 'Allocated(Teus)', field: 'tslAllocatedTeus' },
      { headerName: 'Booked(Teus)', field: 'tslBookedTeus' },
      { headerName: 'Confirmed(Teus)', field: 'tslConfirmedTeus' },
      { headerName: 'Available(Teus)', field: 'tslAvailableTeus' },
      { headerName: 'Status', field: 'tslStatus' },
      { headerName: 'Created By', field: 'tslCreatedUserid' },
      { headerName: 'Created Date', field: 'tslCreatedDate' },
      { headerName: 'Modified By', field: 'tslModifiedUserid' },
      { headerName: 'Modified Date', field: 'tslModifiedDate' },
      { headerName: 'Deleted By', field: 'tslDeletedUserid' },
      { headerName: 'Deleted Date', field: 'tslDeletedDate' },
    ];

    this.settingVessel = {
      id: "cbVessel",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 200 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyage = {
      id: "cbVoyage",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 200 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.settingGrid = {
      url: "",
      page: 10,
      columns: [
        //{header: 'ID', field: 'id', width: 50},
        //{header: 'Index', field: 'Index', width: 20},
        { header: "Load Port", field: "polName", width: 200 },
        { header: "Discharge Port", field: "podName", width: 200 },
        //{header: 'Container Type', field: 'contType', width: 200},
        { header: "Allocated (Teus)", field: "alloted", width: 200 },
        { header: "Booked (Teus)", field: "booked", width: 200 },
        { header: "Confirmed (Teus)", field: "confirm", width: 200 },
        { header: "Available (Teus)", field: "available", width: 200 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      /*
      footer : {
        label : 'Total Allocation'
      },
      */
      //store: this.listStore,
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "locationName",
      sortingDirection: "ASC",
    };

    this.settingLoadPort = {
      id: "cbLoadPort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Load Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingLoadPortTerminal = {
      id: "cbLoadPortTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Load Port Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingDischargePort = {
      id: "cbDischargePort",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Discharge Port --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 130 },
        { header: "Location Name", field: "locationName", width: 130 },
        { header: "Terminal Code", field: "terminalCode", width: 130 },
        { header: "Terminal Name", field: "terminalName", width: 130 },
      ],
    };

    this.settingDischargePortTerminal = {
      id: "cbDischargePortTerminal",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 3,
      template: "grid", // default
      placeholder: "-- Discharge Port Terminal --",
      title: "locationName",
      description: "",
      isMark: true,
      columns: [
        { header: "Location Code", field: "locationCode", width: 100 },
        { header: "Location Name", field: "locationName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVesselCopy = {
      id: "cbVesselCopy",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "vesselName",
      description: "",
      isMark: true,
      columns: [
        { header: "Vessel Code", field: "vesselCode", width: 100 },
        { header: "Vessel Name", field: "vesselName", width: 200 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingVoyageCopy = {
      id: "cbVoyageCopy",
      type: "search enter", // search | select | select input
      url: "",
      maxChars: 0,
      template: "grid", // default
      placeholder: "Search ...",
      title: "voyage",
      description: "",
      isMark: true,
      columns: [
        { header: "Voyage", field: "voyage", width: 100 },
        { header: "Bound", field: "bound", width: 100 },
        { header: "Service", field: "serviceCode", width: 100 },
      ],
    };

    this.getRowStyle = function (params) {
      if (params.node.rowPinned) {
        return { "font-weight": "bold" };
      }
      if (params.data.tslIsDeleted === "Y") {
        return { "background-color": "lightcoral !important;" };
      }
    };

    this.settingUploadPPAFile = {
      id   : 'ppaFileUpload',
      uploadDir : '/PPA',
      maxFiles : 1,
      filterFiles : 'xlsx',
      showUploadButton: false,
      customButtons : [
      ]
    }
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case "new":
        this.handleCreate();
        break;
      case "search":
        this.handleSearch();
        break;
      case "save":
        this.handleSave();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "copy":
        this.handleCopy();
        break;
      case "cancel":
        this.confirmCancel("cancel");
        break;
      case "close":
        this.confirmCancel("close");
        // this.router.navigateByUrl("/main/home");
        break;
      case "uploadExcel":
        $("#uploadExcelDialog").modal("setting", "closable", false);
        $("#uploadExcelDialog").modal("show");
        break;
    }
  }

  setValidatorRetrieve() {
    this.model["error-bound"] = "";
    this.model["error-vessel"] = "";
    this.model["error-voyage"] = "";
    this.model["error-pot"] = "";
    this.model["error-allocationValidator"] = "";

    this.validatorRules = {
      bound: {
        rules: [
          {
            type: "empty",
            prompt: "Please input bound.",
          },
        ],
      },
      vesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
      bPot1Code: {
        rules: [
          {
            type: "empty",
            prompt: "Please input POT.",
          },
        ],
      },
      allocationValidator: {
        rules: [
          {
            type: "custom",
            prompt:
              "Allocation has not been set for this vessel, unable to create allocation.",
          },
        ],
      },
    };
  }

  setValidatorAdd() {
    this.validatorRules = {
      polCode: {
        rules: [
          {
            type: "empty",
            prompt: "Missing Load Port.",
          },
        ],
      },
      podCode: {
        rules: [
          {
            type: "empty",
            prompt: "Missing Discharge Port.",
          },
        ],
      },
      alloted: {
        rules: [
          {
            type: "emptyZero",
            prompt: "Allocated figure must be equal or greater than 1.",
          },
        ],
      },
    };
  }

  setValidatorCopy() {
    this.validatorRules = {
      vesselCode: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Vessel.",
          },
        ],
      },
      voyage: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Voyage.",
          },
        ],
      },
    };
  }

  eventMessage(event) {
    if (event != '') {
      eval(event);
    }
  }

  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any,
    id?: string
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
      id: 'dialog-port-pair'
    };
    setTimeout(() => {
      $("#dialog-port-pair").modal("setting", "closable", false);
      $("#dialog-port-pair").modal("show");
    }, 10);

  }

  changeEventVessel(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.model.voyage = "";
      this.modelDetail.vesselId = event.vesselId;
      this.modelDetail.voyage = "";
      this.cbVoyage.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
        this.officeCode + '/' +
        event.vesselId +
        "/" +
        this.model.bound.toUpperCase() + '/voyage_like=' +
        "{query}" + "?portOfCall=" + this.model.bPot1Code
      );
      this.cbVoyage.setValue("");


      this.model.eta = "";
      this.model.coc = 0;
      this.model.cocTS = 0;
      this.model.cocLocal = 0;
      this.model['error-vesselCode'] = "";

      if (this.model.bPot1Code && this.model.vesselCode && this.model.voyage) {
        this.isError = false;
      }
    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
      this.model.voyage = "";
      this.modelDetail.vesselId = "";
      this.modelDetail.voyage = "";
      this.cbVoyage.setValue("");
    }
  }

  changeEventVesselCopy(event) {
    if (
      event.vesselId != null &&
      event.vesselId != "" &&
      event.vesselId != undefined
    ) {
      this.modelCopy.vesselId = event.vesselId;
      this.modelCopy.vesselCode = event.vesselCode;
      this.modelCopy.voyage = "";

      this.cbVoyageCopy.setUrl(
        this.configService.config.BASE_API.toString() +
        "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
        this.officeCode + '/' +
        event.vesselId +
        "/" +
        this.model.bound.toUpperCase() + '/voyage_like=' +
        "{query}" + "?portOfCall=" + this.model.bPot1Code
      );
      this.cbVoyageCopy.setValue("");
    }
  }

  changeEventBound(event) {
    this.cbVoyage.setUrl(
      this.configService.config.BASE_API.toString() +
      "/MasterSailingSchedules/distinctVoyage/" +
      this.model.vesselId +
      "/" +
      this.model.bound.toUpperCase() +
      "/{query}/" +
      this.officeCode
    );
  }

  changeEventVoyage(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.model.eta = "";
      this.model.coc = 0;
      this.model.cocTS = 0;
      this.model.cocLocal = 0;
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleForTS/" +
          this.model.officeCode +
          "/" +
          this.model.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(event.voyage).trim()) +
          "/" +
          this.model.bound.toUpperCase()
        )
        .subscribe((resp) => {
          this.model.voyage = event.voyage;
          this.modelDetail.voyage = event.voyage;
          this.model['error-voyage'] = "";
          if (this.model.bPot1Code && this.model.vesselCode && this.model.voyage) {
            this.isError = false;
          }
        });
    } else {
      this.model.voyage = "";
      this.modelDetail.voyage = "";
    }
  }

  changeEventVoyageCopy(event) {
    if (
      event.voyage != null &&
      event.voyage != "" &&
      event.voyage != undefined
    ) {
      this.genericService
        .GET(
          this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findSailingScheduleForTS/" +
          this.model.officeCode +
          "/" +
          this.modelCopy.vesselId +
          "/" +
          this.sCharUtil.htmlEncode(String(event.voyage).trim()) +
          "/" +
          this.model.bound.toUpperCase()
        )
        .subscribe((resp) => {
          this.modelCopy.voyage = event.voyage;
        });
    }
  }

  changeEventLoadPort(event) {
    console.log(event);

    if (event.locationCode != null) {
      this.modelDetail.polid = event.locationId;
      this.modelDetail.polCode = event.locationCode;
      this.modelDetail.polName = event.locationName;

      if (event.hasTerminal == "Y") {
        this.lockPOLTerminal = false;
      } else {
        this.lockPOLTerminal = true;
      }

      //this.cbLoadPortTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
      //this.cbLoadPortTerminal.setValue("");
    } else {
      this.modelDetail.polCode = "";
      this.modelDetail.polName = "";

      //this.cbLoadPortTerminal.setUrl("");
      //this.cbLoadPortTerminal.setValue("");
    }
  }

  changeEventLoadPortInsert(event) {
    if (event.locationCode != null) {
      this.modelDetail.polid = event.locationId;
      this.modelDetail.polCode = event.locationCode;
      this.modelDetail.polName = event.locationName;
      this.modelDetail.polTerminalCode = event.terminalCode;
      this.modelDetail.polTerminalName = event.terminalName;

      if (event.hasTerminal == "Y") {
        this.lockPOLTerminal = false;
        this.cbTerminalLoadPort.setUrl(
          this.configService.config.BASE_API.toString() +
          "/MasterTerminals/findTerminalByLocation/" +
          event.locationId +
          "/{query}"
        );
      } else {
        this.lockPOLTerminal = true;
      }
      this.cbTerminalLoadPort.setValue("");
    } else {
      this.modelDetail.polCode = "";
      this.modelDetail.polName = "";
      this.cbTerminalLoadPort.setValue("");
    }
  }

  changeEventTerminalLoadPort(event) {
    if (event.locationCode != null) {
      this.modelDetail.polTerminalCode = event.locationCode;
      this.modelDetail.polTerminalName = event.locationName;
    } else {
      this.modelDetail.polTerminalCode = "";
      this.modelDetail.polTerminalName = "";
    }
  }

  changeEventDischargePort(event) {
    // console.log(event);
    var self = this;
    if (event.locationCode != null) {
      this.modelDetail.podid = event.locationId;
      this.modelDetail.podCode = event.locationCode;
      this.modelDetail.podName = event.locationName;
      this.modelDetail.podTerminalCode = event.terminalCode;
      this.modelDetail.podTerminalName = event.terminalName;

      if (event.hasTerminal == "Y") {
        this.lockPODTerminal = false;
      } else {
        this.lockPODTerminal = true;
      }

      //this.cbDischargePortTerminal.setUrl(this.configService.config.BASE_API.toString() + "/MasterTerminals/findTerminalByLocation/" + event.locationId + "/{query}");
      //this.cbDischargePortTerminal.setValue("");
    } else {
      this.modelDetail.podCode = "";
      this.modelDetail.podName = "";
    }
  }

  changeEventDischargePortTerminal(event) {
    // console.log(event);
    if (event.locationCode != null) {
      this.modelDetail.podTerminalCode = event.locationCode;
    } else {
      this.modelDetail.podTerminalCode = "";
    }
  }

  infoGrid(event) { }

  searchEvent() {
    this.genericUtil.showLoader();
    const disableToolbarButtons = this.disableToolbarButtons.concat(",search");
    this.disableToolbarButtons = disableToolbarButtons;
    this.allocationService.getAllocation(this.model).pipe(
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.genericUtil.hideLoader();
        const disableToolbarButtons = this.disableToolbarButtons.replace(',search','');
        this.disableToolbarButtons = disableToolbarButtons;
      })
    ).subscribe(
      res => {
        if (res) {
          const data = res.json();
          this.handleAfterSearch(data);
        } else {
          this.message(
            "Information",
            "information",
            'Something went wrong',
            "okonly",
            { ok: "" }
          );
          this.saveStage([], 0, 0, 0, 0);
        }
      }
    )
  }

  handleAfterSearch(data) {
    if (data.message) {
      this.rowData = [];
      this.model.tsRotation = "";
      this.saveStage([], 0, 0, 0, 0);
      this.message(
        "Information",
        "information",
        data.message,
        "okonly",
        { ok: "" }
      );
      return;
    }
    this.gridApi.setPinnedBottomRowData([
      {
        tslLoadPortName: 'Total Allocation:',
        tslAllocatedTeus: data.totalAllocated,
        tslBookedTeus: data.totalBooked,
        tslConfirmedTeus: data.totalConfirmed,
        tslAvailableTeus: data.totalAvailable
      }
    ]);
    this.totalAllocated = data.totalAllocated;
    this.totalBooked = data.totalBooked;
    this.totalConfirmed = data.totalConfirmed;
    this.totalAvailable = data.totalAvailable;
    this.disableToolbarButtons = "delete,search";
    if (data.tsPortPairAllocationLines.find(x => x.tslIsDeleted != 'Y' && data.tsPortPairAllocationLines.length > 0)) {
      this.disableToolbarButtons = "delete,copy,search";
    }
    this.rowData = data.tsPortPairAllocationLines;
    this.cbVessel.disableCombo = true;
    this.cbVoyage.disableCombo = true;
    this.cbPOT1.disableCombo = true;
      this.cbLoadPortInsert.setUrl(
        this.configService.config.BASE_API.toString() +
        // "/MasterSailingSchedules/getPortOfRotationForBookingTransferDetails3/" +
        "/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName=" +
        "{query}"
    );
    this.newArray = [];
    this.setData(data.portPairAllocation);

    this.saveStage(data.tsPortPairAllocationLines, data.totalAllocated, data.totalBooked, data.totalConfirmed, data.totalAvailable);
  }

  saveStage(gird, totalAllocated, totalBooked, totalConfirmed, totalAvailable){
    this.gridApi
    let paramData = {
      model: this.model,
      gird: gird,
      totalAllocated: totalAllocated,
      totalBooked : totalBooked,
      totalConfirmed : totalConfirmed, 
      totalAvailable : totalAvailable,
    }
    this.transactionTsPortHomeService.setDataPortPairAllocation(paramData);
  }

  showDialog(id) {
    $(id).modal("setting", "closable", false).modal("show");
  }

  closeDialog(id) {
    $(id).modal("setting", "closable", false).modal("hide");
  }

  addAllocation() {
    this.modelDetail = new PortPairAllocationDetail();

    this.cbDischargePort.setUrl( this.configService.config.BASE_API.toString() +
    `/MasterSailingSchedules/newGetPortOfRotationForBookingTransferDetails2/${this.officeCode}/${this.model.vesselId}/${this.model.voyage}/${this.model.bound}/` +
    "{query}")
    // this.showDialog("#addAllocDialog");
    this.genericUtil.showDialog("addAllocDialog", "Allocation Entry", 800, 480);
    this.isEdit = false;
    this.userID = this.cookieService.getName();
    this.modelDetail.officeCode = this.model.officeCode;
    this.modelDetail.vesselId = this.model.vesselId;
    this.modelDetail.vesselCode = this.model.vesselCode;
    this.modelDetail.voyage = this.model.voyage;
    this.modelDetail.bound = this.model.bound;
    this.modelDetail.booked = 0;
    this.modelDetail.confirm = 0;
    this.modelDetail.available =
      this.modelDetail.alloted - this.modelDetail.booked;
    this.modelDetail.polid = "";
    this.modelDetail.podid = "";
    this.cbPOT1Insert.setForceValue(this.model.bPot1Name);
    this.cbLoadPortInsert.setValue("");
    this.cbLoadPortInsert.close();
    this.cbDischargePort.setValue("");
    this.cbTerminalLoadPort.setValue("");
    this.isEdit = false;
    this.isRowSaved = false;

    // this.cbLoadPort.setValue("");
    //this.cbLoadPortTerminal.setValue('');
    // this.cbDischargePort.setValue("");
    //this.cbDischargePortTerminal.setValue('');
    this.modelDetail.novval = 0;
    this.isRetrieve = true;
    this.isErrorDetail = false;

    this.setValidatorAdd();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.gridApi.hideOverlay();
  }

  onSaveCopy() {
    this.isErrorCopy = false;
    this.modelCopy["error-vesselCode"] = "";
    this.modelCopy["error-voyage"] = "";

    this.isErrorCopy = this.onValidate(this.modelCopy);
    console.log("this.isErrorCopy : " + this.isErrorCopy);

    if (!this.isErrorCopy) {
      this.modelCopy.bPot1Code = this.model.bPot1Code;
      this.modelCopy.newVesselId = this.model.vesselId;
      this.modelCopy.newVoyage = this.model.voyage;
      this.genericUtil.showLoader();
      this.allocationService.postCopy(this.modelCopy).pipe(finalize(() => this.genericUtil.hideLoader())).subscribe(
        res => {
          const data = res.json();
          this.message(
            "information",
            "Information",
            data.message,
            "okonly",
            { ok: "" }
          );
          if (data.status == 'OK') {
            data.tsPortPairAllocationLines.forEach(element => {
              this.rowData.push({
                ...element,
                isCopy: true,
                index: this.rowData.length + 1
              })
            });
            this.newArray = [...this.newArray, ...data.tsPortPairAllocationLines];
            this.gridApi.setRowData(this.rowData);
            this.disableToolbarButtons = "delete,search,copy";
            this.totalAllocated = data.totalAllocated;
            this.totalAvailable = data.totalAvailable;
            this.totalBooked = data.totalAvailable;
            this.totalConfirmed = data.totalConfirmed;
            this.gridApi.setPinnedBottomRowData([
              {
                tslLoadPortName: 'Total Allocation:',
                tslAllocatedTeus: data.totalAllocated,
                tslBookedTeus: data.totalBooked,
                tslConfirmedTeus: data.totalConfirmed,
                tslAvailableTeus: data.totalAvailable
              }
            ]);
          }
          this.closeDialog("#copyAllocDialog");
        }
      )

    }
  }

  onInsert() {
    console.log("** onInsert **");
    console.log(this.modelDetail);
    // CR002
    this.isErrorDetail = false;
    this.modelDetail["error-polCode"] = "";
    this.modelDetail["error-podCode"] = "";
    this.modelDetail["error-alloted"] = "";
    this.setValidatorAdd();

    this.isErrorDetail = this.onValidate(this.modelDetail);

    if (!Number.isInteger(this.modelDetail.alloted)) {
      this.modelDetail["error-alloted"] = "Allocated figure must be integer";
      this.isErrorDetail = true;
      return;
    }

    if (!this.isErrorDetail) {
      // this.modelDetail.available =
      this.modelDetail.alloted - this.modelDetail.booked;
      this.cbPOT1Insert.setForceValue(this.model.bPot1Name);
      this.modelDetail.bPot1Code = this.model.bPot1Code;
      this.handlePostInsert();
    }
  }

  onCancelCopy() {
    this.modelCopy = new PortPairAllocationCopy();
    this.cbVesselCopy.setValue("");
    this.cbVoyageCopy.setValue("");
    this.isErrorCopy = false;
    this.modelCopy["error-vesselCode"] = "";
  }

  onCancel() {
    if (this.isEdit && this.rowActive.tslAllocSeqno) {
      this.modelDetail.alloted = 0;
      return;
    }
    this.modelDetail = new PortPairAllocationDetail();
    this.cbLoadPortInsert.setValue("");
    this.cbDischargePort.setValue("");
    this.cbTerminalLoadPort.setValue("");
    this.modelDetail.alloted = 0;
    this.isErrorDetail = false;
    this.modelDetail["error-podCode"] = "";
    this.modelDetail["error-polCode"] = "";
  }

  onCloseCopy() {
    this.closeDialog("#copyAllocDialog");
  }

  onClose() {
    // this.closeDialog("#addAllocDialog");
    this.genericUtil.closeDialog("addAllocDialog");
  }

  handleSearch() {
    this.isError = false;
    this.model["allocationValidator"] = false;
    this.model["error-allocationValidator"] = "";
    // if (
    //   this.model.coc == 0 &&
    //   this.model.cocLocal == 0 &&
    //   this.model.cocTS == 0
    // ) {
    //   this.model["allocationValidator"] = true;
    //   this.model["error-allocationValidator"] =
    //     "Allocation has not been set for this vessel, unable to create allocation.";
    // }
    console.log(this.model);

    this.isError = this.onValidate(this.model);
    if (!this.isError) {
      this.genericUtil.showLoader();
      this.searchEvent();
    }
  }

  handleCreate() {
    this.addAllocation();
  }

  handleSave() {
    this.genericUtil.showLoader();
    const body = {
      userId: this.cookieService.getName(),
      tsPortPairAllocationLines: this.rowData.filter(x => x.tslIsDeleted !== 'Y'),
      tsPortPairAllocation: {
        pot: this.model.bPot1Code,
        vesselId: this.model.vesselId,
        voyage: this.model.voyage,
        bound: this.model.bound,
        containerOwnership: this.model.containerOwnerShip,
        officeCode: this.officeCode,
        tsConnVesselCode: this.model.vesselCode,
        tsConnVesselName: this.model.vesselName,
        tsPotName: this.model.bPot1Name
      }
    }
    this.allocationService.saveAllocation(body).pipe(finalize(() => this.genericUtil.hideLoader())).subscribe(
      res => {
        const data = res.json();
        if (data.message != 'SUCCESS') {
          this.message(
            "information",
            "Information",
            data.message,
            "okonly",
            { ok: ''}
          );
          return;
        }
        this.message(
          "information",
          "Information",
          data.message,
          "okonly",
          { ok: this.searchEvent() }
        );
      }
    )
    this.cButtonLock = false;
    this.dButtonLock = false;
  }

  handleInsert() { }

  handleCopyForm() {
    this.isView = false;
    this.modeForm = "COPY";
    // this.modeToolbar = true;
    this.invisibleToolbarButtons = "search,save,new,copy";
    this.disableToolbarButtons = "";
  }

  removeAllocation() {
    //CR001
    // console.log(this.grid.getSelectedValues());
    // if (this.grid.getSelectedValues().length > 0) {
    //   this.grid.getSelectedValues().forEach((element) => {
    //     this.grid.listStore.removeData(element);
    //   });
    //   this.grid.loadData();
    // } else {
    //   this.message(
    //     "information",
    //     "Information",
    //     "No Record Has Been Selected.",
    //     "okonly",
    //     { ok: "" }
    //   );
    // }
  }

  deleteAllocation() {
    var listData = [];
    // this.grid.getSelectedValues().forEach((element) => {
    //   listData.push(element);
    // });
    if (listData.length > 0) {
      this.genericService
        .POST(
          this.configService.config.BASE_API.toString() +
          "/Transshipment/delete",
          listData
        )
        .subscribe((resp) => {
          this.message(
            "information",
            "Information",
            "Delete Allocation Successfully.",
            "okonly",
            { ok: "this.searchEvent()" }
          );
        });
    } else {
      this.message(
        "information",
        "Information",
        "Please select data at lease one data.",
        "okonly",
        { ok: "" }
      );
    }
  }

  changeEventAllocated(event) {
    let val = Number.parseInt(event.target.value);
    if (isNaN(val)) {
      val = 0;
      this.modelDetail.alloted = 0;
    }

    if (val < 0) {
      val = 0;
      this.modelDetail.alloted = 0;
    }
  }

  changeEventCoc(event) {
    if (event.target.value.toString().trim() === 'coc') {
      this.rdCoc = 'Y';
      this.rdCoc = 'N';
    }
  }
  changeEventSoc(event) {
    if (event.target.value.toString().trim() === 'soc') {
      this.rdCoc = 'N';
      this.rdCoc = 'Y';
    }
  }

  changeBound(event) {
    this.model.bound = event.target.value ? event.target.value : '';
    this.cbVoyage.setValue("");
    this.model.voyage = "";
    this.cbVoyage.setUrl(
      this.BASE_URL +
      "/MasterSailingSchedules/distinctVoyage/" +
      this.model.vesselId +
      "/" +
      this.model.bound.toUpperCase() +
      "/{query}/" +
      this.officeCode
    );
  }

  changeBoundCopy(event) {
    this.cbVoyageCopy.setUrl(
      this.BASE_URL +
      "/MasterSailingSchedules/distinctVoyage/" +
      this.model.vesselId +
      "/" +
      this.model.bound.toUpperCase() +
      "/{query}/" +
      this.officeCode
    );
  }

  onSelectionChanged(params) {
    console.log(params);
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      this.disableToolbarButtons = "search";
      if (this.rowData.find(x => x.tslIsDeleted != 'Y')) {
        this.disableToolbarButtons = "search,copy";
      }
      return;
    } else {
      this.disableToolbarButtons = "delete,search";
      if (this.rowData.find(x => x.tslIsDeleted != 'Y')) {
        this.disableToolbarButtons = "delete,search,copy";
      }
    }
  }

  handleRowDataChanged(event) {
    console.log(event);
  }

  changeEventPOT1(event) {
    if (event.locationCode) {
      this.model.bPot1Code = event.locationCode;
      this.model.bPot1Name = event.locationName;
      if (this.model.bPot1Code && this.model.vesselCode && this.model.voyage) {
        this.isError = false;
      }
      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + event.locationCode + "/{query}" + "?purpose=connecting");
      this.cbLoadPortInsert.setForceValue(event.locationName);
      this.modelDetail.bPot1Code = event.locationCode;
      this.model['error-bPot1Code'] = "";
    } else {
      this.model.bPot1Code = "";
      this.model.bPot1Name = "";
      this.cbLoadPortInsert.setForceValue("");
    }
  }

  changeEventTransmit(event) {
    this.model.containerOwnerShip = 'COC'
  }

  setData(data: IPortPair) {
    this.model.tsServiceId = data.tsServiceId;
    this.model.tsServiceCode = data.tsServiceCode;
    this.model.tsEta = data.tsEta;
    this.model.tsEtd = data.tsEtd;
    this.model.tsLocal = data.tsLocal;
    this.model.tsRotation = data.tsRotation;
    this.model.tsTotal = data.tsTotal;
    this.model.tsTS = data.tsTS;
  }

  rowDoubleClick(params) {
    let dt = params.data;
    this.rowActive = dt;
    if (dt.tslIsDeleted === "Y") {
      this.message(
        "information",
        "Information",
        "The record has been deleted.",
        "okonly",
        { ok: "" }
      );
      return;
    }
    this.handleEditRow(dt);
  }

  confirmCancel(type){
    this.labelConfirmCancelClose = type;
    let message = type === 'cancel' ? 'No data will be saved. Proceed to cancel?' : 'No data will be saved. Proceed to close?'
    this.message('confirm', 'Confirm', message, 'yesno', { yes: 'this.confirmYes()', no: '' });
  }

  confirmYes(){
    console.log(this.labelConfirmCancelClose);
    if(this.labelConfirmCancelClose === 'cancel'){
      this.handleCancel();
    }else{
      this.router.navigateByUrl("/main/home");
    }
  }

  handleCancel() {
    this.model = new PortPairAllocation();
    this.modelCopy = new PortPairAllocationCopy();
    this.modelDetail = new PortPairAllocationDetail();
    this.setValidatorRetrieve();
    this.isView = true;
    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");
    this.cbPOT1.setValue("SGSIN");
    this.cbVessel.disableCombo = false;
    this.cbVoyage.disableCombo = false;
    this.cbPOT1.disableCombo = false;
    this.invisibleToolbarButtons = "insert";
    this.disableToolbarButtons = "save,copy,new,delete";
    this.officeCode =
      this.cookieService.getDefaultLocationCode() == null
        ? "*"
        : this.cookieService.getDefaultLocationCode();
    this.model.officeCode = this.officeCode;
    this.model.bound = "O";
    this.model.bPot1Code = "SGSIN";
    this.model.bPot1Name = "SINGAPORE";
    this.modelDetail.bPot1Code = "SGSIN"
    this.isRetrieve = false; //CR001
    this.isError = false;
    this.rowData = [];
    this.gridApi.setPinnedBottomRowData([]);
    this.saveStage([], 0, 0, 0, 0);
  }

  handlePostInsert() {
    this.genericUtil.showLoader();
    this.allocationService.postInsert(this.modelDetail).pipe(finalize(() => this.genericUtil.hideLoader())).subscribe(res => {
      if (res) {
        const data = res.json();
        if (data.message) {
          this.isErrorDetail = true;
          this.modelDetail['error-api'] = data.message;
          return;
        }
        this.isErrorDetail = false;
        console.log(this.rowActive);

        if (this.isEdit) {
          if (this.modelDetail.alloted -  (this.rowActive.tslConfirmedTeus + this.rowActive.tslBookedTeus) < 0) {
            this.isErrorDetail = true;
            this.modelDetail['error-api'] = "Insufficient Allocated (Teus).";
            return;
          }

          // Update new total
          this.totalAllocated = (this.totalAllocated - this.rowActive.tslAllocatedTeus) + this.modelDetail.alloted;
          this.totalBooked = this.totalBooked - this.rowActive.tslBookedTeus + data.TsPortPairAllocationLines.tslBookedTeus;
          this.totalConfirmed = this.totalConfirmed - this.rowActive.tslConfirmedTeus + data.TsPortPairAllocationLines.tslConfirmedTeus;
          this.totalAvailable = this.totalAvailable - this.rowActive.tslAvailableTeus + data.TsPortPairAllocationLines.tslAvailableTeus;
          if (this.rowActive.index) {
            for (const row of this.rowData) {
              if (row.index == this.rowActive.index) {
                row.tslLoadPortCode = this.modelDetail.polCode;
                row.tslLoadPortName = this.modelDetail.polName;
                row.tslDischargePortCode = this.modelDetail.podCode;
                row.tslDischargePortName = this.modelDetail.podName;
                row.tslAllocatedTeus = this.modelDetail.alloted;
                row.tslAvailableTeus = this.modelDetail.alloted - (this.rowActive.tslConfirmedTeus + this.rowActive.tslBookedTeus);
                row.tslLoadPortTerminalCode = this.modelDetail.polTerminalCode;
                row.tslLoadPortTerminalName = this.modelDetail.polTerminalName;
                row.isUpdated = true;
              }
            }
          } else {
            for (const row of this.rowData) {
              if (row.tslAllocSeqno == this.rowActive.tslAllocSeqno) {
                row.tslLoadPortCode = this.modelDetail.polCode;
                row.tslLoadPortName = this.modelDetail.polName;
                row.tslDischargePortCode = this.modelDetail.podCode;
                row.tslDischargePortName = this.modelDetail.podName;
                row.tslAllocatedTeus = this.modelDetail.alloted;
                row.tslAvailableTeus = this.modelDetail.alloted - (this.rowActive.tslConfirmedTeus + this.rowActive.tslBookedTeus);
                row.tslLoadPortTerminalCode = this.modelDetail.polTerminalCode;
                row.tslLoadPortTerminalName = this.modelDetail.polTerminalName;
                row.isUpdated = true;
              }
            }
          }
          this.gridApi.setPinnedBottomRowData([
            {
              tslLoadPortName: 'Total Allocation:',
              tslAllocatedTeus: this.totalAllocated,
              tslBookedTeus: this.totalBooked - this.rowActive.tslBookedTeus + data.TsPortPairAllocationLines.tslBookedTeus,
              tslConfirmedTeus: this.totalConfirmed - this.rowActive.tslConfirmedTeus + data.TsPortPairAllocationLines.tslConfirmedTeus,
              tslAvailableTeus: this.totalAvailable - this.rowActive.tslAvailableTeus + data.TsPortPairAllocationLines.tslAvailableTeus
            }
          ]);
          this.gridApi.setRowData(this.rowData);
          // this.handleSortTable();
          this.handleResetInsert();
          this.isErrorDetail = false;
          this.isEdit = false;
          if (this.rowData.find(x => x.tslIsDeleted != 'Y' || x.isInsert)) {
            this.disableToolbarButtons = "delete,search,copy";
          }
          this.genericUtil.closeDialog("addAllocDialog");
          return
        }
        const newItem = {
          isInsert: true,
          tslLoadPortName: this.modelDetail.polName,
          tslLoadPortTerminalName: this.modelDetail.polTerminalName || "",
          tslDischargePortName: this.modelDetail.podName,
          tslLoadPortCode: this.modelDetail.polCode,
          tslDischargePortTerminalName: this.modelDetail.podTerminalName,
          tslLoadPortTerminalCode: this.modelDetail.polTerminalCode,
          tslDischargePortCode: this.modelDetail.podCode,
          tslDischargePortTerminalCode: this.modelDetail.podTerminalCode,
          index: this.rowData.length + 1,
          ...data.TsPortPairAllocationLines
        };
        this.rowData.unshift(newItem);
        this.newArray.unshift(newItem);
        this.calculateAllocation(newItem, true);
        this.gridApi.setRowData(this.rowData);
        // this.handleSortTable();
        if (this.rowData.find(x => x.tslIsDeleted != 'Y' || x.isInsert)) {
          this.disableToolbarButtons = "delete,search,copy";
        }
        this.handleResetInsert();
        this.genericUtil.closeDialog("addAllocDialog");
      }
    }, err => {
      this.message(
        "information",
        "Information",
        "Something Went Wrong.",
        "okonly",
        { ok: "" }
      );
    })
  };

  handleResetInsert() {
    this.cbDischargePort.setValue("");
    this.cbLoadPortInsert.setValue("");
    this.cbTerminalLoadPort.setValue("");
    this.modelDetail['alloted'] = 0;
  }

  handleDelete() {
    const selectedRow = this.gridApi.getSelectedRows()
    let isAlreadyDeleted = selectedRow.some(x=> x.tslStatus === "Deleted");
    if(isAlreadyDeleted){
      this.message(
        "information",
        "Information",
        "Record has already been deleted",
        "okonly",
        { ok: ''}
      );
    }else{
      this.message('delete', 'Deletion Record', 'Selected record(s) will be deleted. Proceed to Delete?', 'yesno', { yes: 'this.deleteEvent()', no: 'this.loading = false;' });
    }
  }

  deleteEvent() {
    const selectedRow = this.gridApi.getSelectedRows();
    const nodes = this.gridApi.getSelectedNodes();

    const allocLineSeqnoList = [];
    const tslAllocId = [];

    nodes.forEach(row => {

      if (row.data.isInsert || row.data.isCopy) {
        const index = row.data.index ? this.rowData.findIndex(x => x.index == row.data.index) : this.rowData.findIndex(x => x.tslAllocSeqno == row.data.tslAllocSeqno) + 1;
        this.calculateAllocation(row.data, false);
        this.rowData.splice(index, 1);
      } else {
        allocLineSeqnoList.push(row.data.tslAllocSeqno);
        tslAllocId.push(row.data.tslAllocId);
      }
    });

    if (allocLineSeqnoList.length > 0) {
      const body = {
        userDeleted: this.userID,
        tslAllocId: tslAllocId[0],
        allocLineSeqnoList
      }
      this.allocationService.deleteAllocation(body).subscribe(
        res => {
          const data = res.json();
          if (data.status != 'ok') {
            this.message(
              "information",
              "Information",
              data.message,
              "okonly",
              { ok: ''}
            );
            if (data.content.unqualifiedList.length > 0) {
              data.content.unqualifiedList.forEach(e => {
                for (const row of this.rowData) {
                  if (e.tslAllocSeqno == row.tslAllocSeqno) {
                    row.tslStatus = "Unable to delete."
                  }
                }
              })
            }
            if (data.content.deletedList.length > 0) {
              allocLineSeqnoList.forEach(tsId => {
                const id = this.rowData.findIndex(x => x.tslAllocSeqno == tsId);
                const ele = data.content.deletedList.find(x => x.tslAllocSeqno == tsId);

                if (this.rowData[id].tslIsDeleted != 'Y' && ele) {
                  this.rowData[id] = ele;
                  this.rowData[id].tslIsDeleted = 'Y';
                  this.calculateAllocation(this.rowData[id], false);
                }
              });
            }
            this.gridApi.setRowData(this.rowData);
            return;
          }
          allocLineSeqnoList.forEach(tsId => {
            const id = this.rowData.findIndex(x => x.tslAllocSeqno == tsId);
            if (this.rowData[id].tslIsDeleted != 'Y') {
              const ele = data.content.deletedList.find(x => x.tslAllocSeqno == tsId);
              this.rowData[id] = ele;
              this.rowData[id].tslIsDeleted = 'Y';
              this.calculateAllocation(this.rowData[id], false);
            }
          });
          this.gridApi.setRowData(this.rowData);
          if (this.rowData.every(x => x.tslIsDeleted == 'Y')) {
            this.disableToolbarButtons = "delete,search";
          }
          if (data.content.unqualifiedList.length > 0) {
            data.content.unqualifiedList.forEach(e => {
              for (const row of this.rowData) {
                if (e.tslAllocSeqno == row.tslAllocSeqno) {
                  row.tslStatus = "Unable to delete."
                }
              }
            })
          }
          this.message(
            "information",
            "Information",
            "Deleted successfully.",
            "okonly",
            { ok: ''}
          );
          // this.handleSortTable();
        }
      )
    } else {
      this.gridApi.setRowData(this.rowData);
      // this.handleSortTable();
      if (this.rowData.every(x => x.tslIsDeleted == 'Y')) {
        this.disableToolbarButtons = "delete,search";
      }
    }
  }

  handleCopy() {
    this.modelCopy = new PortPairAllocationCopy();
    this.cbVesselCopy.setValue("");
    this.cbVoyageCopy.setValue("");
    this.cbPOT1Copy.setForceValue(this.model.bPot1Name);
    this.isErrorCopy = false;
    this.setValidatorCopy();
    this.showDialog("#copyAllocDialog");
    this.cbVesselCopy.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findVesselListForProspectBkg/" + this.officeCode + '/' + this.model.bPot1Code + "/{query}");
  }

  eventAfterInitView() {
    this.setValidatorRetrieve();

    this.cbPOT1.setValue("SGSIN");

    var self = this;
    setTimeout(() => {
      self.disableToolbarButtons = "new,save,copy,delete";
      self.assignValueStage();
    }, 100);
  }

  assignValueStage(){
    this.transactionTsPortHomeService.getDataPortPairAllocation().subscribe(data=>{
      if(data.model && data.gird){
        this.cbVessel.setForceValue(data.model.vesselName);
        this.cbVoyage.setForceValue(data.model.voyage);
        this.model = data.model;
        this.rowData = data.gird;
        this.cbVoyage.setUrl(
          this.configService.config.BASE_API.toString() +
            "/MasterSailingSchedules/findVoyagesListForProspectBkg/connecting/" +
            this.officeCode +
            "/" +
            data.model.vesselId +
            "/" +
            this.model.bound.toUpperCase() +
            "/voyage_like=" +
            "{query}" +
            "?portOfCall=" +
            this.model.bPot1Code
        );

        this.gridApi.setPinnedBottomRowData([
          {
            tslLoadPortName: 'Total Allocation:',
            tslAllocatedTeus: data.totalAllocated,
            tslBookedTeus: data.totalBooked,
            tslConfirmedTeus: data.totalConfirmed,
            tslAvailableTeus: data.totalAvailable
          }
        ]);
        this.totalAllocated = data.totalAllocated;
        this.totalBooked = data.totalBooked;
        this.totalConfirmed = data.totalConfirmed;
        this.totalAvailable = data.totalAvailable;
      }
    })
  }

  calculateAllocation(data, isInsert) {
    const tslAllocatedTeus = this.totalAllocated = ( isInsert ? data.tslAllocatedTeus + this.totalAllocated : this.totalAllocated - data.tslAllocatedTeus );
    const tslBookedTeus = this.totalBooked = ( isInsert ? data.tslBookedTeus + this.totalBooked : this.totalBooked - data.tslBookedTeus );
    const tslConfirmedTeus = this.totalConfirmed = ( isInsert ? data.tslConfirmedTeus + this.totalConfirmed : this.totalConfirmed - data.tslConfirmedTeus );
    const tslAvailableTeus = this.totalAvailable = ( isInsert ?  data.tslAvailableTeus + this.totalAvailable : this.totalAvailable - data.tslAvailableTeus );
    this.gridApi.setPinnedBottomRowData([
      {
        tslLoadPortName: 'Total Allocation:',
        tslAllocatedTeus: tslAllocatedTeus,
        tslBookedTeus: tslBookedTeus,
        tslConfirmedTeus: tslConfirmedTeus,
        tslAvailableTeus: tslAvailableTeus
      }
    ]);
  }

  handleEditRow(dt) {
    this.modelDetail = new PortPairAllocationDetail();

    this.genericUtil.showDialog("addAllocDialog", "Allocation Entry", 800, 480);
    this.isEdit = true;
    this.userID = this.cookieService.getName();
    this.modelDetail.officeCode = this.model.officeCode;
    this.modelDetail.vesselId = this.model.vesselId;
    this.modelDetail.vesselCode = this.model.vesselCode;
    this.modelDetail.voyage = this.model.voyage;
    this.modelDetail.bound = this.model.bound;
    this.modelDetail.booked = 0;
    this.modelDetail.confirm = 0;
    this.modelDetail.available =
    this.modelDetail.alloted - this.modelDetail.booked;
    this.modelDetail.polid = "";
    this.modelDetail.podid = "";
    this.cbPOT1Insert.setForceValue(this.model.bPot1Name);

    this.modelDetail.polCode = dt.tslLoadPortCode;
    this.modelDetail.polName = dt.tslLoadPortName;
    this.modelDetail.podCode = dt.tslDischargePortCode;
    this.modelDetail.podName = dt.tslDischargePortName;
    this.modelDetail.alloted = dt.tslAllocatedTeus;
    this.modelDetail.polTerminalName = dt.tslLoadPortTerminalName;
    this.modelDetail.polTerminalCode = dt.tslLoadPortTerminalCode;

    this.isRowSaved = dt.isInsert ? false : true;

    if(dt.tslAllocatedTeus == dt.tslAvailableTeus) {
      this.isPodAllowedToChangedAfterSaved = false;
    }else{
      this.isPodAllowedToChangedAfterSaved = true;
    }


    console.log(this.modelDetail);

    this.cbLoadPortInsert.setForceValue(this.modelDetail.polName);
    this.cbDischargePort.setForceValue(this.modelDetail.podName);
    this.cbTerminalLoadPort.setForceValue(this.modelDetail.polTerminalName);

    this.modelDetail.novval = 0;
    this.isRetrieve = true;
    this.isErrorDetail = false;

    this.setValidatorAdd();
  }

  handleSortTable() {
    const sortModel = [
      { colId: 'tslStatus', sort: 'asc' },
      { colId: 'tslCreatedDate', sort: 'desc'}
    ];
    this.gridApi.setSortModel(sortModel);
  }

  
  uploadEventPPAFile(event) {
    console.log(event);
    if (event.includes('-')) {
      const temp = event.split('-');
      switch (temp[1].trim()) {
        case 'uploaded':
          this.postData['userId'] = this.cookieService.getName();
          this.postData['fileName'] = temp[0].trim();
          this.ppaFileUpload.onUpload();
          this.isDisableProcessButton = false;
          break;
        case 'deleted':
          this.postData['fileName'] = '';
          this.isDisableProcessButton = true;
          break;
      }
    } else {
      switch (event) {
        case 'success':
          this.isDisableProcessButton = false;
          break;
        default:
          break;
      }
    }

  }

  cancelUploadExcelDialog(){
    this.ppaFileUpload.deleteItem(this.postData['fileName']);
    this.postData = {};
    this.isDisableProcessButton = true;
    this.loadingUpload = false;
  }

  closeUploadExcelDialog(){
    this.cancelUploadExcelDialog();
    $("#uploadExcelDialog").modal("hide");
  }

  processUploadExcelDialog(){
    this.loadingUpload = true;
    this.isDisableProcessButton = true;
    this.postData['createdDate'] = this.genericUtil.getPSIDate();
    this.postData['officeCode'] = this.officeCode;

    console.log('postData');
    console.log(this.postData);
    this.filePath = '';

    if(this.postData['fileName'] != ''){
      this._http.post(this.configService.config.BASE_API.toString() + '?q=/PortPairAllocation/UploadExcelPPA', this.postData, { headers: new HttpHeaders(
        {'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}),
        responseType: 'json'}).subscribe((resp) => {

          console.log(resp);
          const result = resp;
          if (result['status'] === 'ok') {
            console.log('Processing Done');
            this.filePath = result['filePath'];
            this.message('Information', 'information', result['message'], 'okonly', {ok: ''});
            this.loadingUpload = false;
          } else {
            this.message('Information', 'information', result['message'], 'okonly', {ok: ''});
            this.loadingUpload = false;
          }

          this.cancelUploadExcelDialog();
      });
    }

  }
}
