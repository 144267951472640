import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { officeModel } from '../shared/index';
import { Router } from '@angular/router';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';
declare  var $:any;


export class ReportParam {
  blNo='';
  reportType = 'PDF';

  constructor() { }
}

@Component({
  selector: 'app-report-operation-container-loading-list-bl-page',
  templateUrl: './report-operation-container-loading-list-bl-page.component.html',
  styleUrls: ['./report-operation-container-loading-list-bl-page.component.css']
})
export class ReportOperationContainerLoadingListBlPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;




  @ViewChild('cllPdfContainer') cllPdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPOL;
  settingPOD;
  settingPOT;
  settingPDF;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new ReportParam();
  office = new officeModel();
  userID = '';
  hitUrl = this.configService.config.BASE_API.toString();

  validatorRules = {};


  constructor(private genericService: GenericService, private router: Router, private configService: ConfigService, private cook: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Container Loading List Report'
    }

    

    this.settingPDF = {
      id : 'cllPdfContainer',
      url : '',
      hidePrint : false,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.office.officeCode = this.cook.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cook.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cook.getCookie("defaultLocation").split("|")[0];
    this.userID = this.cook.getName();

    this.checkedRadio('pdf', true);
  }
  

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'print' :
      if(this.model.blNo != '' && this.model.blNo != '' ){
        this.handleprint();
      } else {
        if(this.model.blNo == ""){
          this.message("information","Information","Please input B/L number!", "okonly",{ok:""});
        }
      }
        
        break;
      case 'cancel' :
        // cancel
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  
  

  handleprint(){
    this.loading = true;
    console.log("yomaan");
    console.log(this.model);
    

   
    var uri = this.hitUrl + '/BLReport/blContainer/getData/'+this.office.officeCode+'/'+this.model.blNo+'/'+this.userID;

    this.genericService.GET(uri).subscribe((resp)=>{
      if(resp.ok){
        if(resp.json()["data"] != ''){
          var hitUrl = this.configService.config.getPDFUrl  + '/edi-util/getFile';
        
          var jasperURL = this.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/containerLoadListBL.pdf?officeCode='+this.office.officeCode+"&blNo="+this.model.blNo+"&userId="+this.userID;
          var fileName = 'containerLoadListBLWise'+this.model.blNo+'.pdf';
          var postData = {};
            postData["fileName"] = fileName;
            postData["url"] = jasperURL;

            this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
              if(resp.ok){
                var result = resp.json();

                if(result["message"] == "success"){
                  this.cllPdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
                  this.cllPdfContainer.showPDF();
                  this.loading=false;
                }
              }

        });
      }else{
        this.message("information","Information","Invalid B/L No!", "okonly",{ok:""});
        this.loading = false;
      } 


        
    }
    
    }, error=>{

    });
  

  }

  handleCancel(){
    this.model = new ReportParam;


  }

  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      //this.disableToolbarButtons = 'print';
    }
  }

  
  checkedRadio(val, checked:boolean){
    $("#rType[value="+val+"]").prop('checked', checked);
  }

}
