import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService,CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

export class BLCrossByBooking{
  bNo:string='';
  vessel :string='';
  voyage:string='';
  bound :string = '';
  bNoNew:string='';
  bNoDate:string='';
  selected = '';


  constructor(){};
}

@Component({
  selector: 'app-transaction-bl-cross-reference-by-booking-page',
  templateUrl:'./transaction-bl-cross-reference-by-booking-page.component.html',
  styleUrls: ['./transaction-bl-cross-reference-by-booking-page.component.css']
})
export class TransactionBLCrossReferenceByBookingPageComponent extends Validator  implements OnInit, AfterViewInit {

  @ViewChild('grid') grid:GridPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new BLCrossByBooking;
  office:string='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService,private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Cancel', event: 'cancel', icon: 'remove'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL Cross Refrence By Booking'
    }

    this.settingGrid = {
      id:'grid',
      url: '',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 300},
        {header: 'B/L Status', field: 'blStatus', width: 300},
        {header: 'Remark', field: 'blRemark', width: 300},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: false }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      //sortingColumns:'locationName',
      //sortingDirection: 'ASC'

    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    window.scrollTo(0,0);

    this.office = this.cookieService.getDefaultLocationCode();

    if(localStorage.getItem("close-bl-to-cross-book-ref") != null){
      this.model = JSON.parse(localStorage.getItem("close-bl-to-cross-book-ref"));
      
     this.doRetrieve();

     localStorage.removeItem('close-bl-to-cross-book-ref');
    }

    console.log('isi url grid ini');
    console.log(localStorage);

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'cancel' :
        this.grid.onClear();
        this.model.bNo = '';
        this.model.vessel = '';
        this.model.voyage = '';
        this.model.bNoDate = '';
        this.model.bound = '';

        break;
      
      case 'retrieve':
        // handle close event
        this.doRetrieve();
      break;

      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){
    if(event != ''){
      eval(event);
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  doRetrieve(){
    this.grid.onClear();
    if(this.model.bNo == ''){
      this.message('information','Information','Please Input Booking No.','okonly',{ok:''});
    }else{
          this.loading = true;
          var datas : any;
          var tempTable = [];
          this.genericService.GET(this.configService.config.BASE_API.toString() + '/BLBooking/crossCheckBLByBNo/'+this.office+'/'+(this.model.bNo).toUpperCase()).subscribe((resp) =>{
          //ditambahin SSL karena di API nya di substr
          
            if(resp.ok){
              this.loading = false;
              console.log('lenthnya'+Object.keys(resp.json()['content']).length);
              if(Object.keys(resp.json()['content']).length > 1){

                datas = resp.json()['content'];

                this.model.bNoDate = resp.json()['content'][0].bookingDate;
                this.model.voyage = resp.json()['content'][0].voyage;
                this.model.bound = resp.json()['content'][0].bound;
                this.model.vessel = resp.json()['content'][0].vesselName;

                
                datas.forEach(gt=>{
                  if(gt.blNo != undefined && gt.blNo != ''){
                      tempTable.push({
                        blNo : gt.blNo,
                        blRemark : gt.blRemark,
                        blStatus : gt.blStatus
                      })
                  }
                });

                console.log(tempTable);

                tempTable.forEach(sz=>{
                  this.grid.listStore.addData(sz);
                });

                
                this.grid.loadData();


              }else{
                this.message('information','Information','No Booking Record.','okonly',{ok:''});
              }
            }


          });

   }
  }

  gridEvent(event){
    switch (event.split('.')[0]) {
      case 'selected':

        break;
      case 'click' :
        break;
      default:
        const strEvent: string = event;
        if (strEvent !== '') {
          const arr: string[] = strEvent.split('-');
          switch (arr[0]) {
            case 'edit':
              break;
            case 'dblClick':
              const dt = this.grid.getSelectedValues()[0];

              /*
              if (dt.blStatus === 'D') {

                this.frmDialogMessage = 'Current B/L is Deleted by ' + dt.blUserDeleted + ', View Only?';
                this.genericUtil.showDialog('blDeleteDialogYesNo', 'Confirm', 350, 150);

              } else {

                if (dt.bIsUnlocked === 'N') {
                  this.message('information', 'Confirmation', 'Current booking is locked! View only?', 'yesno', {yes: 'this.continueToMaintenance(dt.blNo);', no: ''});
                } else {
                  this.continueToMaintenance(dt.blNo);
                }

              }
              */
              this.continueToBLMaintenance(dt.blNo);

              break;

            default:
              break;
          }
        }
        break;
    }
  }
  infoGrid(event){}

  continueToBLMaintenance(blNo: string) {
    this.genericUtil.showLoaderPage();
    const self = this;
    // console.log('Above set time out ');
    setTimeout(function() {
      self.model.selected = blNo;

      localStorage.setItem('retrieve-bl-from-book-ref', JSON.stringify(self.model));
      self.router.navigate(['/main/transaction-bl-maintenance-latest']);
    }, 100);
  }

}
