import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ReceiptAndDeliveryType } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';

declare  var $:any;

@Component({
  selector: 'app-master-recdel-type-page',
  templateUrl: './master-recdel-type-page.component.html',
  styleUrls: ['./master-recdel-type-page.component.css']
})

export class MasterRecDelTypePageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent;
  @ViewChild('cbReceiptAndDeliveryTypeNameHistory') cbReceiptAndDeliveryTypeNameHistory:ComboPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for disable & invisible Toolbar buttons */
  disableToolbarButtons = "";
  invisibleToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;

  /* Parameter modeToolbar (Default : false) */
  modeToolbar = false;

  /* Parameter form (Default : '') */
  form = '';

  /* Parameter listStore for Grid */
  listStore = new ListStore;

  //setGridEvent:string = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridHistory;

  settingReceiptAndDeliveryTypeHistory;


  /* Misc Parameters */
  valReceiptAndDeliveryTypeCode:string = '';
  valReceiptAndDeliveryTypeName:string = '';
  valReceiptAndDeliveryTypeId:string = '';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';

  /* Parameter model */
  model = new ReceiptAndDeliveryType;
  //selectList = [];

  loading = false;

  lock = false;

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {
    receiptAndDeliveryTypeName: {
      rules:[
        {
          type  : 'empty',
          prompt: 'Please input Receipt and Delivery Type Name.'
        }
      ]
    },
    receiptAndDeliveryTypeCode: {
      rules: [
        {
          type  : 'empty',
          prompt: 'Please input Receipt and Delivery Type Code.'
        }
      ]
    }
  };

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        {display: 'Rec. Del. Code', value: 'receiptAndDeliveryTypeCode'},
        {display: 'Rec. Del. Name', value: 'receiptAndDeliveryTypeName'},
        {display: 'Remarks', value: 'remarks'},
        {display: 'User Created', value: 'userCreated'},
        {display: 'Date Created', value: 'dateCreated'},
        {display: 'User Modified', value: 'userModified'},
        {display: 'Date Modified', value: 'dateModified'},
        {display: 'User Invalid', value: 'userInvalid'},
        {display: 'Date Invalid', value: 'dateInvalid'}
      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    };

    this.settingGrid = {
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneral',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Rec. Del. Name', field: 'receiptAndDeliveryTypeName', width: 200},
        {header: 'Rec. Del. Code', field: 'receiptAndDeliveryTypeCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50}
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'receiptAndDeliveryTypeName',
      sortingDirection: 'ASC'
    };

    this.settingGridHistory={
      url: this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneralHistoryById',
      page: 10,
      columns: [
        {header: 'No', field: 'no', width: 15},
        {header: 'Rec. Del. Id', field:'receiptAndDeliveryTypeId', width:200},
        {header: 'Rec. Del. Name', field: 'receiptAndDeliveryTypeName', width: 200},
        {header: 'Rec. Del. Code', field: 'receiptAndDeliveryTypeCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 300},
        {header: 'User Created', field: 'userCreated', width: 100},
        {header: 'Date Created', field: 'dateCreated', width: 100},
        {header: 'User Modified', field: 'userModified', width: 100},
        {header: 'Date Modified', field: 'dateModified', width: 100},
        {header: 'User Invalid', field: 'userInvalid', width: 100},
        {header: 'Date Invalid', field: 'dateInvalid', width: 100},
        {header: 'Valid?', field: 'isValid', width: 50},
        {header: 'Action Log', field: 'action', width: 50},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      
      enablePagination: true
    };

    this.settingReceiptAndDeliveryTypeHistory = {
      id          : 'cbReceiptAndDeliveryTypeHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/findGeneralHistory/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'receiptAndDeliveryTypeName',
      description : '',
      isMark      : true,
      columns     : [
        {header: 'Rec. Del. Id', field: 'receiptAndDeliveryTypeId', width:50},
        {header: 'Rec. Del. Name', field: 'receiptAndDeliveryTypeName', width: 50},
        {header: 'Rec. Del. Code', field: 'receiptAndDeliveryTypeCode', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50},
      ]
    }

  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    this.firstInit();
    //this.disableToolbarButtons = "update,delete";
    //this.invisibleToolbarButtons = "cancel";

    //this.setGridEvent = 'search:*/*/* + refresh';
    //this.grid.search='*/*/*';
    //this.grid.onFirst();

  }

  firstInit(){
    this.currentMenuID = this.cookieService.getCookie("currentMenu").split("|")[0];
    this.currentMenu = this.cookieService.getCookie("currentMenu").split("|")[1];
    if(this.currentMenu.includes("(View Only)")){
      this.viewOnlyStatus = true;      
    }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";                
    }
    this.invisibleToolbarButtons = "cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }


  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  infoGridHistory(event:any){
    this.info=event;
  }

  gridEvent(event:any) {
    // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
        break;
      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "";
          }
        }else{
          if(this.viewOnlyStatus){
            this.disableToolbarButtons = "create,update,delete";
          } else {
            this.disableToolbarButtons = "update,delete";
          }
        }
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "edit"){
            this.handleUpdate();

          }else if(arr[0] == "dblClick"){

            if(this.viewOnlyStatus){
              this.disableToolbarButtons = "update,delete";
            } else {
              this.disableToolbarButtons = "";
            }
            this.invisibleToolbarButtons = "create,history,print";

            this.modeForm = true;
            this.modeToolbar = false;
            this.setData();
            this.lock = true;
          }

        }

        break;
    }
  }

  gridEventHistory(event:any){
    //do nothing
  }

  toolbarEvent(event:any) {
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event
    this.lock=false;
    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new ReceiptAndDeliveryType;
    this.valReceiptAndDeliveryTypeCode='';
    this.valReceiptAndDeliveryTypeName='';
  }


  handleUpdate()
  {
    // handle update event
    this.modeForm = true;
    this.modeToolbar = true;
    this.setData();
    this.lock=false;
    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";
  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    window.open(this.configService.config.BASE_API.toString().replace('9090','10000') + '/Mreport/runReport/31/ReceiptAndDeliveryType-Export-Reports/*');
    // handle print event
  }

  handleSave()
  {
    // handle save event
    // console.log('handleSave');
    let isPassedValidation:boolean = this.onValidate(this.model);
    // console.log('isPassedValidation ' + isPassedValidation);
    if(!isPassedValidation){
      /*Patch for local timing
       * 1. Based on the discussion with MIS and BIzApp on 5 January 2017
       * 2. System should use local timing instead of server timing
       * 3. Format should be YYYY-MM-DD hh:mm:ss
       */
      // console.log("PSI Date => " + this.genericUtil.getPSIDate());
      this.model["dateCreated"] = this.genericUtil.getPSIDate();
      this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    // handle cancel event
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    if(this.viewOnlyStatus){
      this.disableToolbarButtons = "create,update,delete";
      this.invisibleToolbarButtons = "cancel";  
    } else {
      this.disableToolbarButtons = "update,delete";
      this.invisibleToolbarButtons = "cancel";  
    }
    this.grid.clearSelectedValues();

    if(this.valReceiptAndDeliveryTypeId != '') {
      this.valReceiptAndDeliveryTypeCode = '';
      this.valReceiptAndDeliveryTypeId = '';
      this.cbReceiptAndDeliveryTypeNameHistory.setValue("");
      this.cbReceiptAndDeliveryTypeNameHistory.close();
      this.gridHistory.onHistory("NaN")
      this.gridHistory.loadData();
    }
  }

  /* Patch for auto-redirection upon save, reported on 5 January 2017 by BizApp
   * 1. After saving record system should auto redirect to flag status screen
   * 2. Grid must be auto-refreshed and automatically ordered based on Date Created DESC
   * 3. So the last inputted record will be appeared in the top of the grid
   */
  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
    this.grid.onFirst();
  }

  afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    // console.log("grid-> " + this.grid.getSelectedValues().length);

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }



  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";
  }

  setData(){
    //SET MODEL DATA FOR UPDATE PROCESS
    let data = this.grid.getSelectedValues()[0];

    this.model = new ReceiptAndDeliveryType;

    if(data.isValid=='Y'){
      this.model.isValid=true;
    }else{
      this.model.isValid=false;
    }

    this.model.receiptAndDeliveryTypeId = String(data.receiptAndDeliveryTypeId).trim().toUpperCase();
    this.model.receiptAndDeliveryTypeCode = String(data.receiptAndDeliveryTypeCode).trim().toUpperCase();
    this.model.receiptAndDeliveryTypeName = String(data.receiptAndDeliveryTypeName).trim().toUpperCase();
    this.model.remarks = String(data.remarks).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();



  }

  valueReceiptAndDeliveryTypeHistory(event:any){
    this.valReceiptAndDeliveryTypeCode=event["receiptAndDeliveryTypeCode"];
    this.valReceiptAndDeliveryTypeId=event["receiptAndDeliveryTypeId"];
    // console.log("curr code= " + event["receiptAndDeliveryTypeCode"]);

    //refresh the history grid accordingly
    this.gridHistory.onHistory(this.valReceiptAndDeliveryTypeId);
  }

  generateReceiptAndDeliveryTypeHistoryReport(event:any){
    //this.valHistoryStartDate= $('#logHistoryReceiptAndDeliveryTypeStartDate').find('#datePicker').val();
    // this.valHistoryEndDate= $('#logHistoryReceiptAndDeliveryTypeEndDate').find('#datePicker').val();
    window.open(this.configService.config.BASE_API.toString().replace('9090','10000').replace("5001","10000") + '/Mreport/runReport/32/History-ReceiptAndDeliveryType-' + this.valReceiptAndDeliveryTypeId + '-' + this.valReceiptAndDeliveryTypeCode +'/'+this.valReceiptAndDeliveryTypeId + '@' + this.valHistoryStartDate + '@' + this.valHistoryEndDate);

  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  saveEvent(){
    // console.log('saveEvent');
    this.loading = true;

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/save',this.model).subscribe((resp) =>{
      // console.log('STATUS=' + resp.status);
      // console.log('MESSAGE=' + resp.json()['message']);
      if(resp.status == 200 && resp.json()['message'] == 'OK'){
        // console.log("ENTER SAVE");
        this.model = new ReceiptAndDeliveryType;
        this.loading = false;
        this.info = 'save successfully';
        //this.setGridEvent = 'refresh';
        this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});
      }else{
        this.loading = false;
        this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
      this.loading = false;
    });
  }

  deleteEvent(){
    // console.log("Delete Event")
    this.loading = true;
    //this.setGridEvent = '';
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.receiptAndDeliveryTypeId);
    });
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterReceiptAndDeliveryTypes/delete',deleteList).subscribe((resp) =>{
      if(resp.status == 200){
        this.model = new ReceiptAndDeliveryType;
        //  this.selectList = [];
        this.loading = false;
        this.grid.clearSelectedValues();
        this.grid.onFirst();
        //this.setGridEvent = 'refresh';
        this.loading=true;
        this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false; this.afterDelete();'});


      }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }


}
