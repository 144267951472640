<form class="ui raised segment">
  <toolbar-plugin [modeToolbar]="modeToolbar" [settings]="settingToolbar" [disableButtons]="disableToolbarButtons" [invisibleButtons]="invisibleToolbarButtons" (eventEmitterClick)="toolbarEvent($event)" ></toolbar-plugin>

  <div id='container'>
    <dialog-plugin [settings]="dialog" (eventDialog)="eventMessage($event)"></dialog-plugin>
    <form class="ui form">
      <div class="field" style ="width:400px">
          <div class="ui action input">
              <input type="text" id="_attachmentName">
              <label for="attachmentName" class="ui primary mini button btn-file">
                  <i class="attach icon"></i> &nbsp; Browse
                  <input type="file" id="attachmentName" name="attachmentName" style="display: none">
              </label>
          </div>
      </div>        
    </form>
  </div>  <!-- end div container -->
</form>


<footer-plugin [info]="info"></footer-plugin>
