import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Http, Response, RequestOptions, Headers, Request, RequestOptionsArgs  } from '@angular/http';
import { MasterVesselSchedule, MasterVesselScheduleDetail, ediVesselScheduleHeader, ediVesselScheduleDetails } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, CalendarPluginComponent, GenericService, GenericUtil, ConfigService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';

declare var $:any;

@Component({
  selector: 'app-edi-vessel-schedule-page',
  templateUrl: './edi-vessel-schedule-page.component.html',
  styleUrls: ['./edi-vessel-schedule-page.component.css']
})
export class EdiVesselSchedulePageComponent extends Validator implements OnInit, AfterViewInit {

  /* Parameter for information into Footer */
  info = '';
  space = '&nbsp;&nbsp;';

  @ViewChild('grid') grid:GridPluginComponent;

  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;

  //@ViewChild('comboTest') cbComboTest:ComboPluginComponent;


  /* Parameter for dialog */
  dialog:any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  /* Parameter modeForm (Default : false) */
  modeForm = 'flagStatus';
  modeToolbar = false;

  /* Parameter settings */
  settingVessel;
  settingVoyage;
  settingToolbar;
  settingGrid;
  settingTest;

   /* Parameter model */
  model = new MasterVesselSchedule;
  monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  dayList = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
  storeTransmited:any = [];

  /* status success or not */
  loading = false;
  setGridEvent:string = '';

  lock = true;
  dLock = true;
  testLock = false;

  /* validatorRules */
  validatorRules = new Object;

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private router: Router) {
    super();

    this.settingToolbar = {
      buttonsFront          : [
        { name : 'Retrieve',  event: 'retrieve', icon : 'search' },
        { name : 'Transmit',  event: 'transmit', icon : 'file excel outline' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
        { name : 'Close',  event: 'close', icon : 'power' },

      ],
      buttonsDetail         : [],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [],
      toolbarType           : 'label',
      label                 : 'TRANSMIT VESSEL SCHEDULE'
    };

    this.settingVessel = {
       id          : 'cbVessel',
       type        : 'search enter',
       url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findGeneral/Y/*/{query}/1/5/*/*',
       maxChars    : 2,
       template    : 'grid',
       placeholder : 'Search ...',
       code        : 'vesselCode',
       title       : 'vesselName',
       description  : '',
       flag        : '',
       columns     : [
         {header: 'Vessel Name', field: 'vesselName', width: 100},
         {header: 'PSA.Abbr. Name', field: 'psaVesselCode', width: 150},
         {header: 'Vessel Code', field: 'vesselCode', width: 130},
         {header: 'A/C Code', field: 'docsysCode', width: 100},
         {header: 'Call Sign', field: 'callSign', width: 100},
         {header: 'SSL', field: 'isSsl', width: 50},
         {header: 'Slot', field: 'isSlot', width: 50},
         {header: '3rd Party', field: 'is3RdParty', width: 150},
         {header: 'Vessel Type', field: 'vesselType', width: 100},
         {header: 'Valid', field: 'isValid', width: 80}
       ]
    }

    this.settingVoyage = {
       id          : 'cbVoyage',
       type        : 'search enter', // search | select | select input
       url         : '',
       maxChars    : 0,
       template    : 'grid', // default
       placeholder : 'Search base voyage',
       title       : 'voyage',
       description  : '',
       columns     : [
                    {header: 'voyage', field: 'voyage', width: 300},
       ]
    }

    this.settingGrid = {
        url: '',
        page: 10,
        columns: [
          {header: 'No', field: 'no', width: 10},
          {header: 'Schedule ID', field: 'scheduleID', width: 10},
          {header: 'Vessel Name', field: 'vesselName', width: 10},
          {header: 'Vessel Code', field: 'vesselCode', width: 10},
          {header: 'Voyage', field: 'voyage', width: 10},
          {header: 'PSA Abv. Name', field: 'psaAbvName', width: 10},
          {header: 'Service', field: 'serviceCode', width: 10},
          {header: 'Service Type', field: 'serviceType', width: 10},
          {header: 'Vessel Type', field: 'vesselType', width: 10},
          {header: 'Valid?', field: 'isValid', width: 10}
        ],
        buttons: [ ],
        store: '',
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingDirection: 'ASC',
        sortingColumns: 'scheduleID'

    };

    this.settingTest = {
      id          : 'cbTest',
      type        : 'select input', // search | select | select input
      url         : '',//this.configService.config.BASE_API.toString() + '/MasterSurcharges/NCTByIdAndCtrName/SG00000000253/DRY',//'assets/json/callreason.json',
      placeholder : 'Select Test',
      code        : 'containerTypeName',
      title       : 'containerTypeName',
      urlType     : 'dynamic'
    };

  }

  ngOnInit(){
  }

  ngAfterViewInit() {

    $('#kampreeet').dropdown();

    this.disableToolbarButtons = "transmit";
    this.invisibleToolbarButtons = "";
    this.model.baseVoyage = "*";

    this.validatorRules = {
      vesselName : {
        rules:[
          {type:'empty', prompt:'Please input vessel name!'}
        ]
      },
      baseVoyage : {
        rules:[
          {type:'empty', prompt:'Please input base voyage!'}
        ]
      }
    };
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  message1(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any, txtIcon1:string, txtHeader1:string, txtContent1:string, btns1:string){
    //$('#dialog1').modal('attach events', '#dialog .yes.button');
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve, icon1:txtIcon1, header1: txtHeader1, content1: txtContent1, buttons1: btns1 };
    $('#dialog').modal('show');
  }

  eventMessage(event) {
    if(event != ''){
      eval(event);
    }
  }

  gridEvent(event){
    // console.log(event);
  }

  infoGrid(event) {
    this.info = event;
  }

  getHVName(event){
    // console.log(event);
    if(event.vesselId != ''){
      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
      this.model.psaVesselCode = event.psaVesselCode;
    }
  }

  getBVName(event){
    // console.log(event);
    this.getHVName(event);
    if(this.model.vesselCode != ''){
      this.loadbaseVoyageData('#idBatchVoyage', this.model);
      this.lock = false;
    }
  }

  loadbaseVoyageData(id:string, data){
    $(id).find('.menu').find('.item').empty();
    $(id).find('.menu').find('.item').remove();
    $(id).dropdown('clear');

    var store = [];
    var hitUrl = this.configService.config.BASE_API.toString() + '/MasterVesselSchedules/findGeneral/*/distinctBaseVoyage/'+ (data.vesselCode != null ? data.vesselCode.trim() : "*") +'/1/100/*/*';
    if(data.vesselCode != null && data.vesselCode != ''){
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if(resp.ok){
          var blankSelect = true;
          store = resp.json()['content'];
          store.forEach(st=>{
            if(blankSelect){
              $(id).find('.menu').append('<div class="item" data-value="">---</div>');
              blankSelect = false;
            }
            $(id).find('.menu').append('<div class="item" data-value="'+st.voyage+'">'+st.voyage+'</div>');
          });
          $(id).dropdown();
        }
      });
    }
  }

  toolbarEvent(event) {
    // console.log('event : ' + event);
    switch (event) {
      case 'transmit':
        this.handleTransmit2();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'retrieve':
        this.retrieveData2();
        break;
      case 'close' :
        this.handleCancel();
        this.router.navigateByUrl("/main/home");
        break;
      default:
        break;
    }
  }

  handleTransmit2(){
    // console.log('new transmit process');
    var hitUrl = '';
    if(this.grid.getSelectedValues().length > 0){
      this.grid.getSelectedValues().forEach(d=>{
        var sigBool = false;
        var headerData = new ediVesselScheduleHeader;
        headerData.functionIndicator = 'N';
        headerData.vesselName = d['vesselName'];
        headerData.voyageNumber = d['voyage'];
        headerData.customerServiceCode = d['psServiceCode'];
        headerData.remarks = d['remarks'];
        var tempDetails = [];
        tempDetails = d['details'];
        tempDetails.forEach(det=>{
          var detail = new ediVesselScheduleDetails;
          detail.functionIndicator = 'N';
          detail.vesselName = d['vesselName'];
          detail.voyageNumber = d['voyage'];
          detail.portOfCall = det['portofcall'];
          var s = parseInt(det['seq']);
          detail.sequence = (s < 10 ? "0"+s : s.toString() );
          detail.eta = this.dateUtilConvert(det['eta'],'date') + this.dateUtilConvert(det['eta'],'time');
          detail.etd = this.dateUtilConvert(det['etd'],'date') + this.dateUtilConvert(det['etd'],'time');
          detail.beforeAfterIndicator = 'B';
          if(detail.portOfCall == "SGSIN"){
            sigBool = true;
            detail.abbrVoyage1 = d['voyage'];
            detail.berthingVoyage1 = d['voyage'];
          }
          if(sigBool){
            detail.beforeAfterIndicator = 'A';
          }
          detail.term1 = det['term1'];
          detail.term2 = det['term2'];
          detail.term3 = det['term3'];
          detail.term4 = det['term4'];
          detail.term5 = det['term5'];
          headerData.details.push(detail);
        });
        // console.log(headerData);

        var options = new RequestOptions();
        options.headers = new Headers();
        options.headers.append('Content-Type', 'application/octet-stream');
        options.headers.append('Content-Disposition', 'attachment');

        this.genericService.POST(this.configService.config.BASE_API.toString() + '/edi-util/generateEDI', headerData).subscribe((resp) =>{
          if(resp.ok){
            // console.log(resp["_body"]);
            var mediaType = 'text/plain';
            var blob = new Blob([resp["_body"].toString()], {type: mediaType});
            var filename = 'EDI-'+ this.model.vesselCode.trim() + "-" + headerData.voyageNumber + "-" + this.genericUtil.getPSIDate() + '.txt';
            //var fileURL = URL.createObjectURL(blob);
            //window.open(fileURL);
            //this.genericUtil.download(blob, filename);
            FileSaver.saveAs(blob, filename);
          }
        },error => {
        });

      });
    }
  }

  handleTransmit(){
    // console.log('transmit process');
    var hitUrl = '';
    if(this.model.vesselCode != ''){
      hitUrl = this.configService.config.BASE_API.toString() + '/MasterVesselSchedules/findGeneral/*/psa/'+ this.model.vesselCode + this.model.baseVoyage + '/1/1000/*/*';
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if(resp.ok){
          var data = resp.json()["content"];
          data.forEach(d=>{
            var sigBool = false;
            var headerData = new ediVesselScheduleHeader;
            headerData.functionIndicator = 'N';
            headerData.vesselName = d['vesselName'];
            headerData.voyageNumber = d['voyage'];
            headerData.customerServiceCode = d['psServiceCode'];
            headerData.remarks = d['remarks'];
            var tempDetails = [];
            tempDetails = d['details'];
            tempDetails.forEach(det=>{
              var detail = new ediVesselScheduleDetails;
              detail.functionIndicator = 'N';
              detail.vesselName = d['vesselName'];
              detail.voyageNumber = d['voyage'];
              detail.portOfCall = det['portofcall'];
              var s = parseInt(det['seq']);
              detail.sequence = (s < 10 ? "0"+s : s.toString() );
              detail.eta = this.dateUtilConvert(det['eta'],'date') + this.dateUtilConvert(det['eta'],'time');
              detail.etd = this.dateUtilConvert(det['etd'],'date') + this.dateUtilConvert(det['etd'],'time');
              detail.beforeAfterIndicator = 'B';
              if(detail.portOfCall == "SGSIN"){
                sigBool = true;
                detail.abbrVoyage1 = d['voyage'];
                detail.berthingVoyage1 = d['voyage'];
              }
              if(sigBool){
                detail.beforeAfterIndicator = 'A';
              }
              detail.term1 = det['term1'];
              detail.term2 = det['term2'];
              detail.term3 = det['term3'];
              detail.term4 = det['term4'];
              detail.term5 = det['term5'];
              headerData.details.push(detail);
            });
            // console.log(headerData);
            //window.open()
            //var options = new RequestOptions();
            var options:RequestOptions = new RequestOptions();//this.genericService.getRequestOptions();
            options.headers.append('Content-Type', 'application/json');
            //options.headers.append("Access-Control-Allow-Origin","http://localhost:4200");

            this.genericService.POST(this.configService.config.BASE_API.toString() + '/edi-util/generateEDI', headerData).subscribe((resp) =>{
              if(resp.ok){
                // console.log(resp["_body"]);
                var mediaType = 'text/plain';
                var blob = new Blob([resp["_body"].toString()], {type: mediaType});
                var filename = 'EDI-'+this.model.vesselCode + this.model.baseVoyage + this.genericUtil.getPSIDate() + '.txt';
                //var fileURL = URL.createObjectURL(blob);
                //window.open(fileURL);
                //this.genericUtil.download(blob, filename);
                FileSaver.saveAs(blob, filename);
              }
            },error => {
            });
          });
        }
      });
    }

  }

  handleCancel(){
    // handle cancel event
    // console.log('handle cancel event');
    this.modeToolbar = false;
    this.lock = false;
    this.dLock = true;
    this.invisibleToolbarButtons = '';
    this.disableToolbarButtons = "transmit";
    this.grid.url = "";
    this.grid.listStore.store.splice(0, this.grid.listStore.store.length);
    this.grid.loadData();

    this.cbVessel.setValue("");
    this.cbVoyage.setValue("");

    //this.cbComboTest.setUrl(this.configService.config.BASE_API.toString() + '/MasterSurcharges/NCTByIdAndCtrName/SG00000000253/DRY');
    //this.cbComboTest.loadSelect();
  }

  retrieveData(){
    var hitUrl = '';
    if(this.model.vesselCode != ''){
      hitUrl = this.configService.config.BASE_API.toString() + '/MasterVesselSchedules/findGeneral/*/psa/'+ this.model.vesselCode + this.model.baseVoyage + '/1/1000/*/*';
      this.genericService.GET(hitUrl).subscribe((resp) => {
        if(resp.ok){
          this.grid.listStore.store = resp.json()['content'];
          this.grid.loadData();
          this.dLock = false;
          this.disableToolbarButtons = '';
        }
      });
    }
  }

  retrieveData2(){
    var hitUrl = '';
    if(this.model.vesselCode != ''){
      hitUrl = this.configService.config.BASE_API.toString() + '/MasterVesselSchedules/findEDIListRetrieval';
      this.grid.url = hitUrl;
      this.grid.search = this.model.vesselId + '/' + this.model.baseVoyage;
      this.grid.loadData();
      this.dLock = false;
      this.disableToolbarButtons = '';
    }
  }

  dateUtilConvert(dataDate:string, type){
    var vdate = dataDate.split(" ")[0];
    var vtime = dataDate.split(" ")[1];

    var indexMonth = this.monthsList.indexOf(vdate.split("-")[1]) + 1;

    if(type == "date"){
      return vdate.split("-")[2]+(indexMonth<10?"0":"")+indexMonth+vdate.split("-")[0];
    } else {
      return vtime.split(":")[0]+vtime.split(":")[1]
    }

  }

  changeEventVessel(event) {
    this.model['error-vesselName'] = "";
    if(event.vesselId != null){
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/O/"+"{query}/THBKK");
      this.cbVoyage.setValue("");

      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;
      this.model.vesselName = event.vesselName;
    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
      this.model.vesselName = "";
    }

  }

  changeEventVoyage(event) {
    this.model['error-voyage'] = "";

    if(event.baseVoyage != null){
      this.model.baseVoyage = event.baseVoyage;
    } else {
      this.model.baseVoyage = "*";
    }
  }

  testEvent(event){

  }

}
