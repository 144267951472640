import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService,ConfigService,CookieService, GenericUtil,PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { specialCharUtil } from "../shared/index";
import * as FileSaver from "file-saver";
import { trimTrailingNulls } from '../../../node_modules/@angular/compiler/src/render3/view/util';
import { DISABLED } from '../../../node_modules/@angular/forms/src/model';

declare  var $:any;

export class Param {
  blOfficeCode: string='';
  blOceanVesselIdFrom: string='';
  blOceanVesselCodeFrom: string='';
  blOceanVesselVoyageFrom: string='';
  blOceanVesselBoundFrom: string = '';

  blOceanVesselIdTo: string='';
  blOceanVesselCodeTo: string='';
  blOceanVesselVoyageTo: string='';
  //blOceanVesselBoundTo: string = '';

  constructor() {

  }
}

export class ParamBL {
  blNo: string='';
  constructor() {

  }
}

@Component({
  selector: 'app-edi-transmit-to-data-center-sha-converter',
  templateUrl: './edi-transmit-to-data-center-sha-converter-page.component.html',
  styleUrls: ['./edi-transmit-to-data-center-sha-converter-page.component.css']
})
export class EDITransmitToDataCenterShaConverterPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('cbVesselFrom') cbVesselFrom:ComboPluginComponent;
  @ViewChild('cbVoyageFrom') cbVoyageFrom:ComboPluginComponent;
  @ViewChild('cbPOD') cbPOD:ComboPluginComponent;
  @ViewChild('cbPOT') cbPOT:ComboPluginComponent;
  @ViewChild('gridBLTransferByVessel')  gridBLTransferByVessel :GridPluginComponent;
  @ViewChild('gridBLTransferByBL')  gridBLTransferByBL :GridPluginComponent;
  @ViewChild('ediTransmissionList') ediTransmissionList: PdfPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVesselFrom;
  settingVoyageFrom;
  settingPOD;
  settingPOT;
  settingGridBLTransferByVessel;
  settingGridBLTransferByBL;
  settingPDF;

  type: string='';
  om: string='';

  formatName:string='';

  podCode:string='%';
  potCode:string='%';

  /* Model */
  model = new Param();
  modelAdd = new ParamBL();

  radioTransferByVessel = 'Y'

  isError: Boolean = false;
  isErrorAdd: boolean = false;
  validatorRules = {};
  parameter = [];
  respJson = [];
  parameterJoin:string='';
  radioTransferByVesselWise:string="N";
  radioTransferByBlWise:string="N";
  portOfCallTo = ""
  portOfCallFrom = "";
  hitUrl = this.configService.config.BASE_API.toString();

  sCharUtil = new specialCharUtil();


  blFinished: Boolean = true;
  blFinishedU: Boolean = true;
  blFinishedN:Boolean = true;
  blFinishedD: Boolean = true;

  blUnfinished: Boolean = false;
  blUnfinishedU: Boolean = false;
  blUnfinishedN:Boolean = false;
  blUnfinishedD: Boolean = false;

  vesVoyGrid: string = "";

  isDownloadable: Boolean =true;

  constructor(private auth:Authorize, private genericService:GenericService,private configService:ConfigService,private cookieService: CookieService, private genericUtil:GenericUtil, private router: Router) {
    super();

    this.settingToolbar = {
      buttonsFront: [
       // {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: "Transmit", event: "transmit", icon: "exchange"},
        {name: "Transmit V1.5", event: "transmit15", icon: "exchange"},
        {name: 'Cancel', event: 'cancel', icon: 'remove'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Transmit to DOCSYS (SHA) Converter'
    }

    this.settingVesselFrom = {
      id          : 'cbVesselFrom',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Vessel',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 130},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyageFrom = {
      id          : 'cbVoyageFrom',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Voyage',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingPOD = {
      id          : 'cbPOD',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'POD',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 50},
        {header: 'Location Name', field: 'locationName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingPOT = {
      id          : 'cbPOT',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'POT',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Location Name', field: 'locationName', width: 100},
        {header: 'Valid', field: 'isValid', width: 100}
      ]
    }


    this.settingGridBLTransferByVessel = {
      id : 'gridBLTransferByVessel',
      url: this.configService.config.BASE_API.toString() + '/BLTransfer/searchBLForSSS',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 300},

      ],
      buttons: [
        { /*name: 'Edit', event: 'edit', enabled: true */}
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'blNo',
      editable:false,
      sortingDirection: 'ASC'
    }

    this.settingGridBLTransferByBL = {
      id : 'gridBLTransferByBL',
      url: '',
      page: 10,
      columns: [
        {header: 'B/L No', field: 'blNo', width: 300 ,  editType:'text'},

      ],
      buttons: [
        { /*name: 'Edit', event: 'edit', enabled: true */}
      ],
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'blNo',
      editable:true,
      sortingDirection: 'ASC'
    }

    this.settingPDF = {
      id : 'ediTransmissionList',
      url : '',
      hidePrint : false,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {



    console.log('init');
    this.setValidatorTransferByVessel();
    this.model.blOceanVesselBoundFrom = "O";
    //this.model.blOceanVesselBoundTo = "O";
    this.gridBLTransferByBL.disable();
      $('#addGrid').attr('disabled',true);
      $('#remGrid').attr('disabled',true);
    $('#blUnfinishedN').attr('disabled',true);
    $('#blUnfinishedU').attr('disabled',true);
    $('#blUnfinishedD').attr('disabled',true);
    this.radioTransferByVesselWise = 'Y';


    $('#blUnfinished').change(function() {
      // this will contain a reference to the checkbox
      if (this.checked) {
          // the checkbox is now checked
          $('.clUnfinished').attr('disabled',false);
          $('.clUnfinished').attr('checked',true);
      } else {
          // the checkbox is now no longer checked
          $('.clUnfinished').attr('disabled',true);
          $('.clUnfinished').attr('checked',false);
      }
  });

  $('#blFinished').change(function() {
    // this will contain a reference to the checkbox
    if (this.checked) {
        // the checkbox is now checked
        $('.clFinished').attr('disabled',false);
        $('.clFinished').attr('checked',true);
    } else {
        // the checkbox is now no longer checked
        $('.clFinished').attr('disabled',true);
        $('.clFinished').attr('checked',false);
    }
});

    if(this.cookieService.getDefaultLocationCode() === 'SGSIN'){
      console.log('singapore');

      this.isDownloadable = false;
      console.log(this.isDownloadable);
    }else {
      this.isDownloadable = true;
      console.log('non singapore');
      console.log(this.isDownloadable);
    }
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        this.onRetrieve();
        break;
      case 'transmit' :
        //this.onTransmit();
        this.onTransmit1();
        break;
      case 'transmit15' :
        this.onTransmit15();
        break;
      case 'transmitV2' :
        console.log("berhasil");
        this.onTransmitV2();
        break;
      case 'cancel' :
        this.onCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  setValidatorTransferByVessel(){
    this.isError = false;
    this.model['error-blOceanVesselCodeFrom'] = '';
    this.model['error-blOceanVesselVoyageFrom'] = '';
    this.model['error-blOceanVesselBoundFrom'] = '';
    this.model['error-blOceanVesselCodeTo'] = '';
    this.model['error-blOceanVesselVoyageTo'] = '';
    //this.model['error-blOceanVesselBoundTo'] = '';
    this.model['error-blNoValidator'] = '';

    this.validatorRules = {
      blOceanVesselCodeFrom: {
        rules: [{
          type: "empty",
          prompt: "Vessel should not be empty."
        }]
      },

      blOceanVesselVoyageFrom: {
        rules: [{
          type: "empty",
          prompt: "Voyage should not be empty."
        }]
      },

      blOceanVesselBoundFrom: {
        rules: [{
          type: "empty",
          prompt: "Bound should not be empty."
        }]
      },



    }
  }



  setValidatorTransferByBL(){
    this.isError = false;
    this.model['error-blOceanVesselCodeFrom'] = '';
    this.model['error-blOceanVesselVoyageFrom'] = '';
    this.model['error-blOceanVesselBoundFrom'] = '';
    this.model['error-cbPOD'] = '';
    this.model['error-cbPOT'] = '';
    //this.model['error-blOceanVesselBoundTo'] = '';
    this.model['error-blNoValidator'] = '';
    /*
    this.validatorRules = {
      blOceanVesselCodeTo: {
        rules: [{
          type: "empty",
          prompt: "Vessel Destination should not be empty."
        }]
      },

      blOceanVesselVoyageTo: {
        rules: [{
          type: "empty",
          prompt: "Voyage Destination should not be empty."
        }]
      },

      blOceanVesselBoundTo: {
        rules: [{
          type: "empty",
          prompt: "Bound Destination should not be empty."
        }]
      },

      blNoValidator: {
        rules:[{
          type  : 'custom',
          prompt: 'BL No(s) are not valid. Please check the grid.'
        }]
      },
    }
    */
  }

  changeEventVesselFrom(event){
    if(event.vesselId == null || event.vesselId == "" || event.vesselId == undefined){
      this.model.blOceanVesselIdFrom = '';
      this.model.blOceanVesselCodeFrom = '';

    }else{
      this.model.blOceanVesselIdFrom = event.vesselId.trim();
      this.model.blOceanVesselCodeFrom = event.vesselCode;

      this.cbVoyageFrom.disableCombo = false;
      // this.cbVoyageFrom.setUrl(
      //   this.configService.config.BASE_API.toString() + 
      //   "/MasterSailingSchedules/findByComboBoxControlVoyageForSSS/" + 
      //   this.cookieService.getDefaultLocationCode() + "/" + 
      //   this.model.blOceanVesselIdFrom + "/" + 
      //   this.model.blOceanVesselBoundFrom  + "/voyage_like={query}");
      this.cbVoyageFrom.setUrl(
        this.configService.config.BASE_API.toString() +
          "/MasterSailingSchedules/findByComboBoxControlVoyage/" +
          this.cookieService.getDefaultLocationCode() +
          "/" +
          event.vesselId +
          "/" +
          this.model.blOceanVesselBoundFrom +
          "/voyage_like={query}"
      );
      this.cbVoyageFrom.setValue("");
    }
  }

  changeEventPOD(event){

    if(event.locationCode == null){
      this.podCode='%';
    }else {
      this.podCode = event.locationCode;
    }

  }

  changeEventPOT(event){

    if(event.locationCode == null){
      this.potCode='%';
    }else {
      this.potCode = event.locationCode;
    }

  }

  changeEventVoyageFrom(event){
    if(event.voyage == null){
      this.model.blOceanVesselVoyageFrom = '';
      this.cbPOD.setValue("");
      this.cbPOT.setValue("");
    }else{
      this.model.blOceanVesselVoyageFrom = event.voyage.trim();
      this.cbPOD.setUrl(this.configService.config.BASE_API.toString() + "/MasterBookings/distinctPODByOfficeCodeVesselIdVoyageBoundForSSS/" +  this.cookieService.getDefaultLocationCode() + "/" + this.model.blOceanVesselIdFrom + "/" + event.voyage + "/" + this.model.blOceanVesselBoundFrom+"/"+"{query}");
      this.cbPOD.setValue("");
      this.cbPOT.setUrl(this.configService.config.BASE_API.toString() + "/MasterBookings/distinctPODByOfficeCodeVesselIdVoyageBoundForSSS/" + this.cookieService.getDefaultLocationCode()  + "/" + this.model.blOceanVesselIdFrom + "/" + event.voyage + "/" + this.model.blOceanVesselBoundFrom+"/"+"{query}");
      this.cbPOT.setValue("");
    }
  }

 changeEventTransmit(event){

  if(event.target.value.toString().trim().toUpperCase() == "VESSEL"){
    this.radioTransferByVesselWise = 'Y';
    this.radioTransferByBlWise = "N";
    this.disableToolbarButtons = '';
    this.gridBLTransferByBL.disable();
    $('#addGrid').attr('disabled',true);
    $('#remGrid').attr('disabled',true);

    $("#myfieldset").attr('disabled',false);
    $("#myfieldset2").attr('disabled', false);
    $("#myfieldset3").attr('disabled', false);
    $("#myfieldset4").attr('disabled', false);
    this.gridBLTransferByBL.onClear();

    //this.grabBLNumber();
  }else{

    this.gridBLTransferByBL.enable();
    $('#addGrid').attr('disabled',false);
    $('#remGrid').attr('disabled',false);

    this.cbVesselFrom.setValue('');
    this.cbVoyageFrom.setValue('');
    this.model.blOceanVesselBoundFrom = 'O';
    this.cbPOD.setValue('');
    this.cbPOT.setValue('');

    $("#myfieldset").attr('disabled', true);
    $("#myfieldset2").attr('disabled', true);
    $("#myfieldset3").attr('disabled', true);
    $("#myfieldset4").attr('disabled', true);

    this.radioTransferByBlWise = "Y";
    this.radioTransferByVesselWise='N';
    this.setValidatorTransferByBL()
    this.disableToolbarButtons = 'retrieve';

    $('#blUnfinishedN').attr('disabled',true);
    $('#blUnfinishedU').attr('disabled',true);
    $('#blUnfinishedD').attr('disabled',true);
    $('#blUnfinished').prop('checked',false);
    $('.clUnfinished').attr('disabled',true);
    $('.clUnfinished').attr('checked',false);

    $('.clFinished').prop('checked',true);
    $('#blFinished').prop('checked',true);

    $('#ckCOC').prop('checked',true);
    $('#ckSOC').prop('checked',true);
    $('#ckSLOT').prop('checked',true);
    $('#ckCOCTS').prop('checked',true);
    $('#ckSOCTS').prop('checked',true);

  }

 }
  changeEventTransferByVessel(event){
//    console.log(event)
    if(event.target.checked){
      this.radioTransferByVessel = 'Y'
      this.setValidatorTransferByVessel()
      this.disableToolbarButtons = '';
    }
  }
  changeEventTransferByBL(event){
  //  console.log(event)
    if(event.target.checked){
      this.radioTransferByVessel = 'N'
      this.setValidatorTransferByBL()
      this.disableToolbarButtons = 'retrieve';
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  rowEvent(act:string){
    if(act == 'add'){
      let modelAdd = new ParamBL;
      modelAdd.blNo = "SSL"
      this.gridBLTransferByBL.listStore.addData(modelAdd)
      this.gridBLTransferByBL.loadData();
      //this.genericUtil.showDialog('AddForm', 'Add BL', 300, 300)
    }else{
      var seqNo = 0;
      var countCheck = 0;
      var boxCheck : number;
      var storeTemp = new ListStore;

      this.gridBLTransferByBL.listStore.store.forEach(xy=>{
        // // // // console.log("xy "+xy);
        storeTemp.addData(xy);
      })


      storeTemp.store.forEach(el=>{
        if(el['select'] == 'checked') {
          boxCheck = this.gridBLTransferByBL.listStore.store.indexOf(el);
          this.gridBLTransferByBL.listStore.store.splice(boxCheck,1);
          this.gridBLTransferByBL.loadData();

        //  $('input[name="gridCount"]').val(parseInt($('input[name="gridCount"]').val())-1);
        }
      });



      /*
      this.gridBLTransferByBL.listStore.store.forEach(col=>{
        seqNo = seqNo + 1;
        col.seqNo=seqNo;
      });
      */

      console.log("Sizenya: "+this.gridBLTransferByBL.store.length);
      if(this.gridBLTransferByBL.store.length == 0 ){
        this.vesVoyGrid = "";
      }
      this.gridBLTransferByBL.loadData();
      this.gridBLTransferByBL.clearSelectedValues();
    }
  }

  closeDialog(id:string){
    this.genericUtil.closeDialog(id);
    this.gridBLTransferByBL.loadData();
  }

  infoGrid(event){
    this.info = event;
  }

  gridEventByVessel(event){
  }

  gridEventByBL(event){
    console.log(event);
   // console.log(event.split(".")[0]);
    switch (event.split(".")[0]) {

      case 'click':
      break;

      case 'selected' :
      break;

      case 'afterLoad' :
      break;

      case 'AfterEdit':
        console.log('after edit');
        console.log((event.split(";")[2]).toUpperCase());
        console.log(parseInt(event.split(".")[1]))

        this.checkValidBL((event.split(";")[2]).toUpperCase(),parseInt(event.split(".")[1]));
      break;

      default:
      break;

    }
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
        testObject = {};

    inputArray.map(function(item) {
      var itemPropertyName = item[propertyName].toUpperCase();
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      }
      else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  checkValidBL(param,nogrid:number){
    console.log('grid no: '+nogrid);
    console.log(param);
    var doble = [];
    var dblFlag = false;

    this.gridBLTransferByBL.listStore.store.forEach(key=>{
      doble.push(key);
    })
    //RESET vesvoygrid if grid lengthnya cuma 1 karena tidak ada pembanding dengan BL lain
    if(this.gridBLTransferByBL.listStore.store.length == 1)this.vesVoyGrid = ''
   // console.log(doble)
    dblFlag = this.checkDuplicateInObject('blNo',doble);

            if(dblFlag == true){
              this.message('information','Information','Duplicate BL Reference(s) detected.','okonly',{ok: 'this.loading=false;'});
              this.gridBLTransferByBL.listStore.store.splice(nogrid-1,1);
              if(this.gridBLTransferByBL.store.length == 0 ){
                this.vesVoyGrid = "";
              }
              this.gridBLTransferByBL.loadData();
            }


            var cleanParam = param.replace(/[^a-z0-9]/gi,"-").replace(/;/g, "-");
            if (cleanParam == '')cleanParam = '-'
            console.log('clean param'+cleanParam);

      // GCHO-1
      // this.genericService.GET(this.configService.config.BASE_API.toString() + "/blreinstatement/getStatusByBlNoForSSS/"+this.cookieService.getDefaultLocationCode()+"/"+cleanParam.toUpperCase()).subscribe((resp) => {
      this.genericService.GET(this.configService.config.BASE_API.toString() + "/blreinstatement/getStatusByBlNoForD4A/"+cleanParam.toUpperCase()).subscribe((resp) => {

      if(resp.ok){

        if(Object.keys(resp.json()['content']).length > 0){
          console.log('vesvoygrid '+this.vesVoyGrid);
          if(this.vesVoyGrid !=""){
            if(resp.json()['content'][0].blOceanVesselCode+resp.json()['content'][0].blOceanVesselVoyage == this.vesVoyGrid){
              this.vesVoyGrid = resp.json()['content'][0].blOceanVesselCode+resp.json()['content'][0].blOceanVesselVoyage;
            }else{
              this.message('information','Information','BL(s) from different vessels not allowed.','okonly',{ok: 'this.loading=false;'});
              this.gridBLTransferByBL.listStore.store.splice(nogrid-1,1);
              if(this.gridBLTransferByBL.store.length == 0 ){
                this.vesVoyGrid = "";
              }
              this.gridBLTransferByBL.loadData();

            }
          }else{
            this.vesVoyGrid = resp.json()['content'][0].blOceanVesselCode+resp.json()['content'][0].blOceanVesselVoyage;
          }
        }else{

          this.message('information','Information','Invalid BL No.','okonly',{ok: 'this.loading=false;'});
          this.gridBLTransferByBL.listStore.store.splice(nogrid-1,1);
          if(this.gridBLTransferByBL.store.length == 0 ){
            this.vesVoyGrid = "";
          }

          this.gridBLTransferByBL.loadData();
        }
      }


    });
  }
  onRetrieve() {
    this.setValidatorTransferByVessel()
    this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
    this.isError = this.onValidate(this.model);
    if(!this.isError){
      this.gridBLTransferByVessel.setBody(this.model);
      this.gridBLTransferByVessel.loadData();
    }
  }
  finBLNumber(callback?: (data)=>void){

    var flag=0

    this.gridBLTransferByBL.listStore.store.forEach(yur=>{
      this.parameter[flag]=yur.blNo.toUpperCase();
      flag++;
    });
    var ocean='x'
    this.genericService.GET(this.configService.config.BASE_API.toString() + "/BL/findBlByOfficeCodeAndBlNoForSSS/"+this.cookieService.getDefaultLocationCode()+"/"+this.parameter[0]).subscribe((resp) => {

      if(resp.ok){
        console.log(resp.json().blData['blOceanVesselVoyage']);
        this.model.blOceanVesselCodeFrom = resp.json().blData['blOceanVesselCode'];
        this.model.blOceanVesselIdFrom = resp.json().blData['blOceanVesselId'];
        this.model.blOceanVesselVoyageFrom = resp.json().blData['blOceanVesselVoyage'];
        console.log(ocean+'llalallala');
        this.parameterJoin = this.parameter.join();

        //this.formatName = this.model.blOceanVesselCodeFrom.trim()+''+this.model.blOceanVesselVoyageFrom.trim()+'.'+this.genericUtil.getPSIDate();
        //this.formatName = this.formatName.replace('-','').replace(' ','.').replace(':','');
        this.formatName = this.model.blOceanVesselCodeFrom.trim()+''+this.model.blOceanVesselVoyageFrom.trim()+'.'+((((this.genericUtil.getPSIDate().replace('-','')).replace('-','')).replace(':','')).replace(':','')).replace(' ','');


      }

      callback && callback('');

    });

  }
  grabBLNumber(callback?: (data) => void){

    var postData = [];
    var cntOwn = {};
    var blFinished = {};
    var blFinishedSt = {};
    var blUnFinished = {};
    var blUnFinishedSt = {};

      /*
      postData["vesselId"] = this.model.blOceanVesselCodeFrom;
      postData["voyage"] = this.model.blOceanVesselVoyageFrom;
      postData["bound"] = this.model.blOceanVesselBoundFrom;
      postData["office"] = this.cookieService.getDefaultLocationCode();
      postData["date"] = this.genericUtil.getPSIDate();
      */
      if($('#ckSOC').is(':checked')){
        cntOwn["soc"] = 'SOC';
      }else{
        cntOwn["soc"] = 'XYZ';
      }

      if($('#ckCOC').is(':checked')){
        cntOwn["coc"] = 'COC';
      }else{
        cntOwn["coc"] = 'XYZ';
      }

      if($('#ckSOCTS').is(':checked')){
        cntOwn["socTS"] = 'SOCTS';
      }else{
        cntOwn["socTS"] = 'XYZ';
      }

      if($('#ckCOCTS').is(':checked')){
        cntOwn["cocTS"] = 'COCTS';
      }else{
        cntOwn["cocTS"] = 'XYZ';
      }

      if($('#ckSLOT').is(':checked')){
        cntOwn["slot"] = 'SLOT';
      }else{
        cntOwn["slot"] = 'XYZ';
      }

      if($('#blFinishedN').is(':checked')){
        blFinishedSt["blStatusN"] = 'N';
      }else{
        blFinishedSt["blStatusN"] = 'X';
      }


      if($('#blFinished').is(':checked')){
        blFinished["blFinished"] = 'Y';
      }else{
        blFinished["blFinished"] = 'X';
      }

      if($('#blFinishedU').is(':checked')){
        blFinishedSt["blStatusU"] = 'U';
      }else{
        blFinishedSt["blStatusU"] = 'X';
      }


      if($('#blFinishedD').is(':checked')){
        blFinishedSt["blStatusD"] = 'D';
      }else{
        blFinishedSt["blStatusD"] = 'X';
      }


      if($('#blUnfinished').is(':checked')){
        blUnFinished["blUnifinshed"] = 'N';
      }else{
        blUnFinished["blUnifinshed"] = 'X';
      }


      if($('#blUnfinishedN').is(':checked')){
        blUnFinishedSt["blStatusUN"] = 'N';
      }else{
        blUnFinishedSt["blStatusUN"] = 'X';
      }

      if($('#blUnfinishedU').is(':checked')){
        blUnFinishedSt["blStatusUU"] = 'U';
      }else{
        blUnFinishedSt["blStatusUU"] = 'X';
      }


      if($('#blUnfinishedD').is(':checked')){
        blUnFinishedSt["blStatusUD"] = 'D';
      }else{
        blUnFinishedSt["blStatusUD"] = 'X';
      }

      //String fileName = map.get("vesselCode")+ "" + map.get("voyage")+ "." + map.get("dateProc").replaceAll("-","").replaceAll(" ",".").replaceAll(":","");
      this.formatName = this.model.blOceanVesselCodeFrom.trim()+''+this.model.blOceanVesselVoyageFrom.trim()+'.'+((((this.genericUtil.getPSIDate().replace('-','')).replace('-','')).replace(':','')).replace(':','')).replace(' ','');


      console.log('nama file'+this.formatName);

      postData.push({
        vesselId : this.model.blOceanVesselIdFrom,
        vesselCode : this.model.blOceanVesselCodeFrom,
        voyage : this.model.blOceanVesselVoyageFrom,
        bound  : this.model.blOceanVesselBoundFrom,
        office : this.cookieService.getDefaultLocationCode(),
        date   : this.genericUtil.getPSIDate(),
        blFinished : blFinished["blFinished"] ,
        blUnfinished : blUnFinished["blUnifinshed"],
        blFinishedN :  blFinishedSt["blStatusN"] ,
        blFinishedD : blFinishedSt["blStatusD"] ,
        blFinishedU : blFinishedSt["blStatusU"] ,
        blUnfinishedN : blUnFinishedSt["blStatusUN"] ,
        blUnfinishedD : blUnFinishedSt["blStatusUD"] ,
        blUnfinishedU : blUnFinishedSt["blStatusUU"] ,
        soc : cntOwn['soc'],
        coc : cntOwn['coc'],
        socTS : cntOwn['socTS'],
        cocTS : cntOwn['cocTS'],
        slot : cntOwn['slot'],
        podCode : this.podCode,
        potCode : this.potCode


      });


    this.genericService.POST(this.configService.config.BASE_API.toString() + '/ediController/grabBLNumberForSSS/',postData).subscribe((resp) =>{

      if(resp.ok){

        if(resp.json().length > 0){
          this.respJson = resp.json();

          var lenResp = 0;

          this.respJson.forEach(yx=>{
            this.parameter[lenResp] = yx.blNo;
            lenResp++;
            //this.parameter.push({
              //blNo : yx.blNo
            //})

          });

          this.parameterJoin = this.parameter.join();

        }//end lenght

        console.log(this.parameter);

      }//end ok

      //if(callback)
      //callback('');
      callback && callback('');

    });

  }

  grabBLNumberShanghai(callback?: (data) => void){
    var postData = [];
    var cntOwn = {};
    var blFinished = {};
    var blFinishedSt = {};
    var blUnFinished = {};
    var blUnFinishedSt = {};

    if($('#ckSOC').is(':checked')){
      cntOwn["soc"] = 'SOC';
    }else{
      cntOwn["soc"] = 'XYZ';
    }

    if($('#ckCOC').is(':checked')){
      cntOwn["coc"] = 'COC';
    }else{
      cntOwn["coc"] = 'XYZ';
    }

    if($('#ckSOCTS').is(':checked')){
      cntOwn["socTS"] = 'SOCTS';
    }else{
      cntOwn["socTS"] = 'XYZ';
    }

    if($('#ckCOCTS').is(':checked')){
      cntOwn["cocTS"] = 'COCTS';
    }else{
      cntOwn["cocTS"] = 'XYZ';
    }

    if($('#ckSLOT').is(':checked')){
      cntOwn["slot"] = 'SLOT';
    }else{
      cntOwn["slot"] = 'XYZ';
    }

    if($('#blFinishedN').is(':checked')){
      blFinishedSt["blStatusN"] = 'N';
    }else{
      blFinishedSt["blStatusN"] = 'X';
    }


    if($('#blFinished').is(':checked')){
      blFinished["blFinished"] = 'Y';
    }else{
      blFinished["blFinished"] = 'X';
    }

    if($('#blFinishedU').is(':checked')){
      blFinishedSt["blStatusU"] = 'U';
    }else{
      blFinishedSt["blStatusU"] = 'X';
    }


    if($('#blFinishedD').is(':checked')){
      blFinishedSt["blStatusD"] = 'D';
    }else{
      blFinishedSt["blStatusD"] = 'X';
    }


    if($('#blUnfinished').is(':checked')){
      blUnFinished["blUnifinshed"] = 'N';
    }else{
      blUnFinished["blUnifinshed"] = 'X';
    }


    if($('#blUnfinishedN').is(':checked')){
      blUnFinishedSt["blStatusUN"] = 'N';
    }else{
      blUnFinishedSt["blStatusUN"] = 'X';
    }

    if($('#blUnfinishedU').is(':checked')){
      blUnFinishedSt["blStatusUU"] = 'U';
    }else{
      blUnFinishedSt["blStatusUU"] = 'X';
    }


    if($('#blUnfinishedD').is(':checked')){
      blUnFinishedSt["blStatusUD"] = 'D';
    }else{
      blUnFinishedSt["blStatusUD"] = 'X';
    }

    //String fileName = map.get("vesselCode")+ "" + map.get("voyage")+ "." + map.get("dateProc").replaceAll("-","").replaceAll(" ",".").replaceAll(":","");
    this.formatName = this.model.blOceanVesselCodeFrom.trim()+''+this.model.blOceanVesselVoyageFrom.trim()+'.'+((((this.genericUtil.getPSIDate().replace('-','')).replace('-','')).replace(':','')).replace(':','')).replace(' ','');


    console.log('nama file'+this.formatName);

    postData.push({
      vesselId : this.model.blOceanVesselIdFrom,
      vesselCode : this.model.blOceanVesselCodeFrom,
      voyage : this.model.blOceanVesselVoyageFrom,
      bound  : this.model.blOceanVesselBoundFrom,
      office : this.cookieService.getDefaultLocationCode(),
      date   : this.genericUtil.getPSIDate(),
      blFinished : blFinished["blFinished"] ,
      blUnfinished : blUnFinished["blUnifinshed"],
      blFinishedN :  blFinishedSt["blStatusN"] ,
      blFinishedD : blFinishedSt["blStatusD"] ,
      blFinishedU : blFinishedSt["blStatusU"] ,
      blUnfinishedN : blUnFinishedSt["blStatusUN"] ,
      blUnfinishedD : blUnFinishedSt["blStatusUD"] ,
      blUnfinishedU : blUnFinishedSt["blStatusUU"] ,
      soc : cntOwn['soc'],
      coc : cntOwn['coc'],
      socTS : cntOwn['socTS'],
      cocTS : cntOwn['cocTS'],
      slot : cntOwn['slot'],
      podCode : this.podCode,
      potCode : this.potCode


    });


    this.genericService.POST(this.configService.config.BASE_API.toString() + '/ediController/grabBLNumberForShanghai/',postData).subscribe((resp) =>{

      if(resp.ok){

        if(resp.json().length > 0){
          this.respJson = resp.json();

          var lenResp = 0;

          this.respJson.forEach(yx=>{
            this.parameter[lenResp] = yx.blNo;
            lenResp++;
          });

          this.parameterJoin = this.parameter.join();

        }//end lenght

        console.log(this.parameter);

      }//end ok

      callback && callback('');

    });

  }

  onTransmit(){

    let isPassedValidation:boolean;
    this.parameter = [];
    this.parameterJoin = '';
    this.formatName = '';
    var self=this;

    console.log("#1")
    console.log(this.radioTransferByVesselWise)

    if(this.radioTransferByVesselWise == 'Y'){
      this.setValidatorTransferByVessel();
      isPassedValidation = this.onValidate(this.model);

      console.log('ispasseevalidation'+isPassedValidation);

      if(!isPassedValidation){
        //this.grabBLNumber();

        //this.ediTransmissionList.setPDFURL
        this.grabBLNumber(function (callback) {


         // setTimeout(function(){

          if(self.parameterJoin.length > 0){

             // var cleanFileName = self.formatName.replace('.','');
             var  cleanFileName =  self.formatName.replace(' ','.');
              cleanFileName = cleanFileName.replace(':','');
              cleanFileName = cleanFileName.replace('-','');

              self.type = "Vessel"

              self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type)

              //console.log('process callback'+vallback);

               /*
                console.log('cleanfilename '+cleanFileName);
                var hitUrl = self.hitUrl  + '/edi-uti/lgetFile';
                var jasperURL = 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+cleanFileName+'&userid=ade';
                console.log('jasperURL '+jasperURL);
                var fileName = 'EDI-TRANSMISSION-REPORT-LIST';

                var postDatas = {};
                postDatas["fileName"] = fileName;
                postDatas["url"] = jasperURL;

                self.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
                  if(resp.ok){
                    var result = resp.json();

                    if(result["message"] == "success"){
                      self.ediTransmissionList.setPDFURL("http://glossys.samudera.com"+result["filePath"]);
                      self.ediTransmissionList.showPDF();
                    }

                    self.loading = false;

                  }
                });
                */



          }else{
            self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
            this.cbPOD.setValue('');
            this.cbPOD.setUrl('');
            this.cbPOT.setValue('');
            this.cbPOT.setUrl('');
          }

      //  },1000)



         // self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom);

        });




      }

    }else{

     this.finBLNumber(function(callback){

          if(self.parameterJoin.length > 0){

            var  cleanFileName =  self.formatName.replace(' ','.');
            cleanFileName = cleanFileName.replace(':','');
            cleanFileName = cleanFileName.replace('-','');

            self.type = "BL"

            self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type)//{


           // }

        }else{
          self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
          this.cbPOD.setValue('');
          this.cbPOD.setUrl('');
          this.cbPOT.setValue('');
          this.cbPOT.setUrl('');
        }



     });

     /*
      var self=this;
      setTimeout(function(){

      if(self.parameterJoin.length > 0){


          self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,self.formatName,function (valcallback){

          });

      }else{
        self.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});
      }

    },1000)

      */






      //console.log('yuuuu');
      //console.log(this.parameter);
      //console.log(this.parameterJoin);

    }



  }

  onTransmit1(){

    let isPassedValidation:boolean;
    this.parameter = [];
    this.parameterJoin = '';
    this.formatName = '';
    var self=this;

    console.log("#1")
    console.log(this.radioTransferByVesselWise)

    if(this.radioTransferByVesselWise == 'Y'){
      this.setValidatorTransferByVessel();
      isPassedValidation = this.onValidate(this.model);

      console.log('ispasseevalidation'+isPassedValidation);

      if(!isPassedValidation){
        this.grabBLNumberShanghai(function (callback) {

          if(self.parameterJoin.length > 0){
            //  var cleanFileName =  self.formatName.replace(' ','.');
            //   cleanFileName = cleanFileName.replace(':','');
            //   cleanFileName = cleanFileName.replace('-','');
              var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);

              self.type = "Vessel";

              self.actionTransmit1(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type);
          }else{
            self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
            this.cbPOD.setValue('');
            this.cbPOD.setUrl('');
            this.cbPOT.setValue('');
            this.cbPOT.setUrl('');
          }
        });
      }

    }else{

     this.finBLNumber(function(callback){

          if(self.parameterJoin.length > 0){
            var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);
            // var  cleanFileName =  self.formatName.replace(' ','.');
            // cleanFileName = cleanFileName.replace(':','');
            // cleanFileName = cleanFileName.replace('-','');

            self.type = "BL"

            self.actionTransmit1(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type);

        }else{
          self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
          this.cbPOD.setValue('');
          this.cbPOD.setUrl('');
          this.cbPOT.setValue('');
          this.cbPOT.setUrl('');
        }



     });

     /*
      var self=this;
      setTimeout(function(){

      if(self.parameterJoin.length > 0){


          self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,self.formatName,function (valcallback){

          });

      }else{
        self.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});
      }

    },1000)

      */






      //console.log('yuuuu');
      //console.log(this.parameter);
      //console.log(this.parameterJoin);

    }



  }

  onTransmit15(){

    let isPassedValidation:boolean;
    this.parameter = [];
    this.parameterJoin = '';
    this.formatName = '';
    var self=this;

    console.log("#1")
    console.log(this.radioTransferByVesselWise)

    if(this.radioTransferByVesselWise == 'Y'){
      this.setValidatorTransferByVessel();
      isPassedValidation = this.onValidate(this.model);

      console.log('ispasseevalidation'+isPassedValidation);

      if(!isPassedValidation){
        //this.grabBLNumber();

        //this.ediTransmissionList.setPDFURL
        //this.grabBLNumber(function (callback) {
        this.grabBLNumberShanghai(function (callback) {
         // setTimeout(function(){

          if(self.parameterJoin.length > 0){

             // var cleanFileName = self.formatName.replace('.','');
            //  var  cleanFileName =  self.formatName.replace(' ','.');
            //   cleanFileName = cleanFileName.replace(':','');
            //   cleanFileName = cleanFileName.replace('-','');
            var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);
              self.type = "Vessel"

              self.actionTransmit15(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type)

              //console.log('process callback'+vallback);

               /*
                console.log('cleanfilename '+cleanFileName);
                var hitUrl = self.hitUrl  + '/edi-util/getFile';
                var jasperURL = 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+cleanFileName+'&userid=ade';
                console.log('jasperURL '+jasperURL);
                var fileName = 'EDI-TRANSMISSION-REPORT-LIST';

                var postDatas = {};
                postDatas["fileName"] = fileName;
                postDatas["url"] = jasperURL;

                self.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
                  if(resp.ok){
                    var result = resp.json();

                    if(result["message"] == "success"){
                      self.ediTransmissionList.setPDFURL("http://glossys.samudera.com"+result["filePath"]);
                      self.ediTransmissionList.showPDF();
                    }

                    self.loading = false;

                  }
                });
                */

          }else{
            self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
            this.cbPOD.setValue('');
            this.cbPOD.setUrl('');
            this.cbPOT.setValue('');
            this.cbPOT.setUrl('');
          }

      //  },1000)



         // self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom);

        });




      }

    }else{

     this.finBLNumber(function(callback){

          if(self.parameterJoin.length > 0){
            var cleanFileName = self.sCharUtil.htmlEncode(self.formatName);
            // var  cleanFileName =  self.formatName.replace(' ','.');
            // cleanFileName = cleanFileName.replace(':','');
            // cleanFileName = cleanFileName.replace('-','');

            self.type = "BL"

            self.actionTransmit15(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type)//{


           // }

        }else{
          self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
          this.cbPOD.setValue('');
          this.cbPOD.setUrl('');
          this.cbPOT.setValue('');
          this.cbPOT.setUrl('');
        }



     });

     /*
      var self=this;
      setTimeout(function(){

      if(self.parameterJoin.length > 0){


          self.actionTransmit(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,self.formatName,function (valcallback){

          });

      }else{
        self.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});
      }

    },1000)

      */






      //console.log('yuuuu');
      //console.log(this.parameter);
      //console.log(this.parameterJoin);

    }



  }

  onTransmitV2(){

        let isPassedValidation:boolean;
        this.parameter = [];
        this.parameterJoin = '';
        this.formatName = '';
        var self=this;

        console.log("#1")
        console.log(this.radioTransferByVesselWise)

        if(this.radioTransferByVesselWise == 'Y'){
          this.setValidatorTransferByVessel();
          isPassedValidation = this.onValidate(this.model);

          console.log('ispasseevalidation'+isPassedValidation);

          if(!isPassedValidation){
            //this.grabBLNumber();

            //this.ediTransmissionList.setPDFURL
            this.grabBLNumber(function (callback) {


             // setTimeout(function(){

              if(self.parameterJoin.length > 0){

                 // var cleanFileName = self.formatName.replace('.','');
                 var  cleanFileName =  self.formatName.replace(' ','.');
                  cleanFileName = cleanFileName.replace(':','');
                  cleanFileName = cleanFileName.replace('-','');

                  self.type = "Vessel"

                  self.actionTransmitV2(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type)
              }else{
                self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
                this.cbPOD.setValue('');
                this.cbPOD.setUrl('');
                this.cbPOT.setValue('');
                this.cbPOT.setUrl('');
              }
            });
          }
        }else{
         this.finBLNumber(function(callback){

              if(self.parameterJoin.length > 0){

                var  cleanFileName =  self.formatName.replace(' ','.');
                cleanFileName = cleanFileName.replace(':','');
                cleanFileName = cleanFileName.replace('-','');

                self.type = "BL"

                self.actionTransmitV2(self.model.blOceanVesselIdFrom,self.model.blOceanVesselVoyageFrom,self.model.blOceanVesselBoundFrom,self.parameterJoin,self.model.blOceanVesselCodeFrom,cleanFileName,self.type)
            }else{
              self.message('information','Information','No data(s) found.','okonly',{ok: 'self.loading=false;'});
              this.cbPOD.setValue('');
              this.cbPOD.setUrl('');
              this.cbPOT.setValue('');
              this.cbPOT.setUrl('');
            }
         });
    }
  }

  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      //this.disableToolbarButtons = 'print';
    }
  }

  actionTransmit(vesselId:string,voyage:string,bound:string,parameter:string,vesselCode:string,formatName:string,type:string){
    var objToday = new Date();
      var curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours());
      var curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();
      var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";

      var curDate = objToday.getDate()+'/'+(objToday.getMonth()+1)+'/'+objToday.getFullYear();

    var localTime = curDate+'@'+curHour+':'+curMinute+'@'+curMeridiem;
    console.log('local Time'+localTime);
    console.log('FORMAT NAME'+formatName)
    var dullName = formatName.replace('.','-');
    var ceked = '';

    if($('#ckDownload').is(':checked')){
      ceked = 'cek';
    }else{
      ceked = 'not';
    }
    /*
    $.ajax({
    	url: "/blank.html",
    	success: download.bind(true, "text/html", "dlAjaxCallback.html")
    });
*/ // var uri = this.configService.config.BASE_API.toString() + "?q=/ediController/transmit/edi/"+this.cookieService.getDefaultLocationCode()+"/"+ vesselId+"/"+voyage+"/"+bound+"/"+this.genericUtil.getPSIDate()+"/"+parameter+"/"+vesselCode+"/"+dullName+"/"+ceked;
    var uri = this.configService.config.getPDFUrl + "/ediController/transmit/edi/"+this.cookieService.getDefaultLocationCode()+"/"+ vesselId+"/"+voyage+"/"+bound+"/"+this.genericUtil.getPSIDate()+"/"+parameter+"/"+vesselCode+"/"+dullName+"/"+ceked;
    var self=this;
    this.loading = true;
    $.ajaxSetup({
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });
$.ajax({
  url: uri,
  method: 'GET',
  xhrFields: {
      responseType: 'blob'
  },
  success: function (data) {

    console.log("#3")
    console.log(data)

    if($('#ckDownload').is(':checked')){

      var a = document.createElement('a');
      var url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = formatName;
      a.click();
      window.URL.revokeObjectURL(url);

    }else{


    }

      var hitUrl = self.configService.config.getPDFUrl  + '/edi-util/getFile';
      var jasperURL = self.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+formatName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime+'&type='+type;
      var fileName = 'EDI-TRANSMISSION-REPORT-LIST.pdf';

      console.log('jasperurl'+jasperURL);

      var postDatas = {};
      postDatas["fileName"] = fileName;
      postDatas["url"] = jasperURL;
      self.loading = false;
      self.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
        if(resp.ok){
          var result = resp.json();

          if(result["message"] == "success"){
            self.ediTransmissionList.setPDFURL(self.configService.config.pdfUrl + result["filePath"]);
            self.ediTransmissionList.showPDF();
          }



        }
      });

  }
});
   // window.location.href = uri;


    /*
    var windowRef = window.open(uri);
    console.log(windowRef.closed)
    if(windowRef.closed){
      console.log('kepanggil action');
      var hitUrl = this.hitUrl  + '/edi-util/getFile';
      var jasperURL = 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code='+this.cookieService.getDefaultLocationCode()+'&filename='+formatName+'&userid=ade';
      var fileName = 'EDI-TRANSMISSION-REPORT-LIST';

      var postDatas = {};
      postDatas["fileName"] = fileName;
      postDatas["url"] = jasperURL;

      this.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
        if(resp.ok){
          var result = resp.json();

          if(result["message"] == "success"){
            this.ediTransmissionList.setPDFURL("http://glossys.samudera.com"+result["filePath"]);
            this.ediTransmissionList.showPDF();
          }

          this.loading = false;

        }
      });

    }
    */



  }

  actionTransmit1(vesselId:string,voyage:string,bound:string,parameter:string,vesselCode:string,formatName:string,type:string){
      var objToday = new Date();
      var curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours());
      var curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();
      var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";

      var curDate = objToday.getDate()+'/'+(objToday.getMonth()+1)+'/'+objToday.getFullYear();

      var localTime = curDate+'@'+curHour+':'+curMinute+'@'+curMeridiem;
      console.log('local Time'+localTime);
      console.log('FORMAT NAME'+formatName)
      var dullName = formatName;//.replace('.','-');
      var ceked = '';

      if($('#ckDownload').is(':checked')){
        ceked = 'cek';
      }else{
        ceked = 'not';
      }
      //this.configService.config.getPDFUrl = 'http://localhost:12000'
    // var uri = this.configService.config.getPDFUrl + "/ediController/transmitShanghai/edi/"+this.cookieService.getDefaultLocationCode()+"/"+voyage+"/"+bound+"/"+this.genericUtil.getPSIDate()+"/"+parameter+"/"+vesselCode+"/"+dullName+"/"+ceked;
    var uri =
    //'http://localhost:12000'+
     this.configService.config.getPDFUrl +
      "/ediController/transmitShanghai/edi/" +
      this.cookieService.getDefaultLocationCode() +
      "/" +
      vesselId +
      "/" +
      this.sCharUtil.htmlEncode(String(voyage).trim()) +
      "/" +
      bound +
      "/" +
      this.sCharUtil.htmlEncode(this.genericUtil.getPSIDate().trim()) +
      "/" +
      parameter +
      "/" +
      vesselCode +
      "/" +
      dullName +
      "/" +
      ceked;
    var self = this;

    console.log("Get Uri Action Transmit : ", uri);
    var self=this;
    this.loading = true;
    $.ajaxSetup({
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });
    $.ajax({
      url: uri,
      method: 'GET',
      xhrFields: {
          responseType: 'blob'
      },
      success: function (data) {

        console.log("#3")
        console.log(data)

        if($('#ckDownload').is(':checked')){

          var a = document.createElement('a');
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = formatName;
          a.click();
          window.URL.revokeObjectURL(url);

        }else{


        }

          var hitUrl = self.configService.config.getPDFUrl  + '/edi-util/getFile';
          // var jasperURL = self.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReportSIN.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+formatName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime+'&type='+type;
          var jasperURL = self.configService.config.jasperUrl + 
          '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReportSIN_COPY.pdf?'+
          '&office_code=CNSHA&filename='+formatName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime+'&type='+type;
          var fileName = 'EDI-TRANSMISSION-REPORT-LIST.pdf';

          console.log('jasperurl'+jasperURL);

          var postDatas = {};
          postDatas["fileName"] = fileName;
          postDatas["url"] = jasperURL;
          self.loading = false;
          self.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
            if(resp.ok){
              var result = resp.json();

              if(result["message"] == "success"){
                self.ediTransmissionList.setPDFURL(self.configService.config.pdfUrl + result["filePath"]);
                self.ediTransmissionList.showPDF();
              }



            }
          });

        }
    });
  }

  actionTransmit15(vesselId:string,voyage:string,bound:string,parameter:string,vesselCode:string,formatName:string,type:string){
    var objToday = new Date();
    var curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours());
    var curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();
    var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";

    var curDate = objToday.getDate()+'/'+(objToday.getMonth()+1)+'/'+objToday.getFullYear();

    var localTime = curDate+'@'+curHour+':'+curMinute+'@'+curMeridiem;
    console.log('local Time'+localTime);
    console.log('FORMAT NAME'+formatName)
    var dullName = formatName; //.replace('.','-');
    var ceked = '';

    if($('#ckDownload').is(':checked')){
      ceked = 'cek';
    }else{
      ceked = 'not';
    }
    /*
    $.ajax({
    	url: "/blank.html",
    	success: download.bind(true, "text/html", "dlAjaxCallback.html")
    });
    this.configService.config.BASE_API.toString() 
*/ // var uri = this.configService.config.BASE_API.toString() + "?q=/ediController/transmit/edi/"+this.cookieService.getDefaultLocationCode()+"/"+ vesselId+"/"+voyage+"/"+bound+"/"+this.genericUtil.getPSIDate()+"/"+parameter+"/"+vesselCode+"/"+dullName+"/"+ceked;
    var uri =  this.configService.config.BASE_API.toString() + "?q="+
    // var uri =  "http://localhost:12000" +
    //'http://localhost:12000'+
        //this.configService.config.getPDFUrl + 
        "/ediController/transmit15Chinese/edi/"+
        this.cookieService.getDefaultLocationCode()+
        "/"+
        this.sCharUtil.htmlEncode(voyage)+
        "/"+
        bound+
        "/"+
        this.sCharUtil.htmlEncode(this.genericUtil.getPSIDate())+
        "/"+
        parameter+
        "/"+
        vesselCode+
        "/"+
        dullName+
        "/"+
        ceked;
    
    //var uri = "http://localhost:17000/ediController/transmit15/edi/"+this.cookieService.getDefaultLocationCode()+"/"+ vesselId+"/"+voyage+"/"+bound+"/"+this.genericUtil.getPSIDate()+"/"+parameter+"/"+vesselCode+"/"+dullName+"/"+ceked;
    var self=this;
    this.loading = true;

    $.ajaxSetup({
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });

    $.ajax({
      url: uri,
      method: 'GET',      
      xhrFields: {
          responseType: 'blob'
      },
      success: function (data) {

        console.log("#3")
        console.log(data)

        if($('#ckDownload').is(':checked')){

          // var a = document.createElement('a');
          // var url = window.URL.createObjectURL(data);
          // a.href = url;
          // a.download = formatName;
          // a.click();
          // window.URL.revokeObjectURL(url);
          
          console.log('file Name '+ self.sCharUtil.htmlDecode(dullName));
          FileSaver.saveAs(data, self.sCharUtil.htmlDecode(dullName));

        }else{


        }

        var hitUrl = self.configService.config.getPDFUrl  + '/edi-util/getFile';
        // var jasperURL = self.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReportSIN.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+formatName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime+'&type='+type;
        var jasperURL = self.configService.config.jasperUrl + 
          '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReportSIN.pdf?'+
          '&office_code=CNSHA&filename='+dullName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime+'&type='+type;
        var fileName = 'EDI-TRANSMISSION-REPORT-LIST.pdf';

        console.log('jasperurl'+jasperURL);

        var postDatas = {};
        postDatas["fileName"] = fileName;
        postDatas["url"] = jasperURL;
        self.loading = false;
        self.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
          if(resp.ok){
            var result = resp.json();

            if(result["message"] == "success"){
              self.ediTransmissionList.setPDFURL(self.configService.config.pdfUrl + result["filePath"]);
              self.ediTransmissionList.showPDF();
            }
          }
        });
      }
    });
  }

  actionTransmitV2(vesselId:string,voyage:string,bound:string,parameter:string,vesselCode:string,formatName:string,type:string){
    var objToday = new Date();
      var curHour = objToday.getHours() > 12 ? objToday.getHours() - 12 : (objToday.getHours() < 10 ? "0" + objToday.getHours() : objToday.getHours());
      var curMinute = objToday.getMinutes() < 10 ? "0" + objToday.getMinutes() : objToday.getMinutes();
      var curMeridiem = objToday.getHours() >= 12 ? "PM" : "AM";

      var curDate = objToday.getDate()+'/'+(objToday.getMonth()+1)+'/'+objToday.getFullYear();

    var localTime = curDate+'@'+curHour+':'+curMinute+'@'+curMeridiem;
    console.log('local Time'+localTime);
    console.log('FORMAT NAME'+formatName)
    var dullName = formatName.replace('.','-');
    var ceked = '';

    if($('#ckDownload').is(':checked')){
      ceked = 'cek';
    }else{
      ceked = 'not';
    }
    var uri = this.configService.config.getPDFUrl + "/ediController/transmitCopy/edi/"+this.cookieService.getDefaultLocationCode()+"/"+ vesselId+"/"+voyage+"/"+bound+"/"+this.genericUtil.getPSIDate()+"/"+parameter+"/"+vesselCode+"/"+dullName+"/"+ceked;
    var self=this;
    this.loading = true;
    $.ajaxSetup({
      beforeSend: function(xhr) {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('Token', localStorage.getItem("token"))
      }
    });
$.ajax({
  url: uri,
  method: 'GET',
  xhrFields: {
      responseType: 'blob'
  },
  success: function (data) {

    console.log("#3")
    console.log(data)

    if($('#ckDownload').is(':checked')){

      var a = document.createElement('a');
      var url = window.URL.createObjectURL(data);
      a.href = url;
      a.download = formatName;
      a.click();
      window.URL.revokeObjectURL(url);

    }else{


    }

      var hitUrl = self.configService.config.getPDFUrl  + '/edi-util/getFile';
      var jasperURL = self.configService.config.jasperUrl + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/ediTransmissionReport.pdf?office_code='+self.cookieService.getDefaultLocationCode()+'&filename='+formatName.replace('.','')+'&userid='+self.cookieService.getName()+'&localTime='+localTime+'&type='+type;
      var fileName = 'EDI-TRANSMISSION-REPORT-LIST.pdf';

      console.log('jasperurl'+jasperURL);

      var postDatas = {};
      postDatas["fileName"] = fileName;
      postDatas["url"] = jasperURL;
      self.loading = false;
      self.genericService.POST(hitUrl,postDatas).subscribe((resp)=>{
        if(resp.ok){
          var result = resp.json();

          if(result["message"] == "success"){
            self.ediTransmissionList.setPDFURL(self.configService.config.pdfUrl + result["filePath"]);
            self.ediTransmissionList.showPDF();
          }



        }
      });
    }
    });
  }

  onCancel(){


    this.cbVesselFrom.setValue('');
    this.cbVesselFrom.clearSelect();
    this.cbVoyageFrom.setValue('');
    this.cbVoyageFrom.clearSelect();
    this.cbVoyageFrom.setUrl('');

    this.cbPOD.setValue('');
    this.cbPOD.setUrl('');
    this.cbPOT.setValue('');
    this.cbPOT.setUrl('');
    this.gridBLTransferByBL.onClear();
    this.model.blOceanVesselBoundFrom = 'O';
    this.parameter = [];
    this.parameterJoin = '';
    this.vesVoyGrid = "";
    this.setValidatorTransferByVessel();

    $('#blUnfinishedN').attr('disabled',true);
    $('#blUnfinishedU').attr('disabled',true);
    $('#blUnfinishedD').attr('disabled',true);

    $('#blUnfinished').prop('checked',false);
    $('.clFinished').prop('checked',true);

    $('.clUnfinished').attr('disabled',true);
    $('.clFinished').attr('disabled',false);

    $('.clUnfinished').attr('checked',false);

    $('.clUnfinished').prop('checked',false);
    $('#blFinished').prop('checked',true);



    if(this.cookieService.getDefaultLocationCode() === 'SGSIN'){
      this.isDownloadable = false;
      $('#ckDownload').prop('checked',false);
    }else {
      this.isDownloadable = true;
      $('#ckDownload').prop('checked',true);
    }


    $('#ckCOC').prop('checked',true);
    $('#ckSOC').prop('checked',true);
    $('#ckSLOT').prop('checked',true);
    $('#ckCOCTS').prop('checked',true);
    $('#ckSOCTS').prop('checked',true);



    this.blFinished = true;
    this.blFinishedU = true;
    this.blFinishedN=true;
    this.blFinishedD = true;



    this.blUnfinished = false;
    this.blUnfinishedU = false;
    this.blUnfinishedN = false;
    this.blUnfinishedD= false;


    /*
   this.genericService.GET(this.configService.config.BASE_API.toString() + '/ediController/ediDL/THBKK/LALAL').subscribe((resp) =>{

    console.log(resp);

  });
  */

    if(this.cookieService.getDefaultLocationCode() === 'SGSIN'){
      this.isDownloadable=false;
    }else{
      this.isDownloadable=true;
    }

  }

  onTransfer(){
    if(this.radioTransferByVessel == 'N'){
      this.setValidatorTransferByBL()
      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.model['blNoValidator'] = this.checkBLNoValidator();

      this.isError = this.onValidate(this.model);

      if(!this.isError){

      }

    }else{
      this.setValidatorTransferByVessel()
      this.model.blOfficeCode = this.cookieService.getDefaultLocationCode();
      this.isError = this.onValidate(this.model);
      if(!this.isError){

      }

    }
  }

  checkBLNoValidator():Boolean{
    console.log("** check BL No Validator **")
    let result:Boolean = false;
    if(this.gridBLTransferByBL.listStore.store.length == 0){
      this.model['error-blNoValidator'] = 'BL(s) should not be empty. Please input BL at least one BL.'
      result = true;
    }else{
      this.gridBLTransferByBL.listStore.store.forEach(element => {
        console.log(element);
      });
    }
    return result;
  }

  checklistFinishedDisabled(){
    $('.clFinished').attr('disabled',true);
  }

  checklistUnfinishedDisabled(){
    $('.clUnfinished').attr('disabled',true);
  }


  eventBLFinished(event){

    if(event.target.checked){
      this.blFinished= true ;
      this.blFinishedN= true ;
      this.blFinishedU= true ;
      this.blFinishedD= true ;
    }else{
      this.blFinished= false ;
      this.blFinishedN= false ;
      this.blFinishedU= false ;
      this.blFinishedD= false ;
      this.checklistFinishedDisabled();
    }
  }


  eventBLFinishedN(event){

    if(event.target.checked){
      this.blFinishedN= true ;
      this.blFinished = true;


    }else{
      this.blFinishedN= false ;
      console.log("EVENT CHECK N UNCHECK: "+this.blFinishedD);
      if (this.blFinishedD==false && this.blFinishedU==false){
        this.blFinished = false;
        this.checklistFinishedDisabled();
      }

    }
  }

  eventBLFinishedU(event){

    if(event.target.checked){
      this.blFinishedU= true ;
      this.blFinished = true;


    }else{
      this.blFinishedU= false ;
      if (this.blFinished ==false && this.blFinishedN==false){
        this.blFinished = false;
        this.checklistFinishedDisabled();
      }

    }
  }

  eventBLFinishedD(event){

    if(event.target.checked){
      this.blFinishedD= true ;
      this.blFinished = true;


    }else{
      this.blFinishedD= false ;
      if (this.blFinishedU==false && this.blFinishedN==false){
        this.blFinished = false;
        this.checklistFinishedDisabled();
      }


    }
  }

  eventBLUnfinished(event){

    if(event.target.checked){
      this.blUnfinished= true ;
      this.blUnfinishedN= true ;
      this.blUnfinishedU= true ;
      this.blUnfinishedD= true ;
    }else{
      this.blUnfinished= false ;
      this.blUnfinishedN= false ;
      this.blUnfinishedU= false ;
      this.blUnfinishedD= false ;
      this.checklistUnfinishedDisabled();
    }
  }

  eventBLUnfinishedN(event){

    if(event.target.checked){
      this.blUnfinishedN= true ;
      this.blUnfinished = true;


    }else{
      this.blUnfinishedN= false ;
      console.log("EVENT CHECK N UNCHECK: "+this.blUnfinishedD);
      if (this.blUnfinishedD==false && this.blUnfinishedU==false){
        this.blUnfinished = false;
        this.checklistUnfinishedDisabled();
      }

    }
  }

  eventBLUnfinishedU(event){

    if(event.target.checked){
      this.blUnfinishedU= true ;
      this.blUnfinished = true;

    }else{
      this.blUnfinishedU= false ;
      if (this.blUnfinishedD==false && this.blUnfinishedN==false){
        this.blUnfinished = false;
        this.checklistUnfinishedDisabled();
      }

    }
  }

  eventBLUnfinishedD(event){

    if(event.target.checked){
      this.blUnfinishedD= true ;
      this.blUnfinished= true;


    }else{
      this.blUnfinishedD= false ;
      if (this.blUnfinishedU==false && this.blUnfinishedN==false){
        this.blUnfinished = false;
        this.checklistUnfinishedDisabled();
      }


    }
  }

}
