<div>
  <div class="field" style="margin-bottom: 0 !important;">
    <div class="ui top attached tabular menu" style="background-color: beige;">
      <a class="item" [class.active]="tabStatus == tab.SUMMARY_PORT_PAIR" (click)="handleInitTab(tab.SUMMARY_PORT_PAIR)">Summary - Transhipment Port Pair
        Allocation &nbsp;
      </a>
      <a class="item" [class.active]="tabStatus == tab.PORT_PAIR" (click)="handleInitTab(tab.PORT_PAIR)">Port Pair
        Allocation &nbsp;
      </a>
      <a class="item" [class.active]="tabStatus == tab.PROSPECT" (click)="handleInitTab(tab.PROSPECT)">Prospect Booking
        &nbsp;
      </a>
      <a class="item" [class.active]="tabStatus == tab.RENOMINATION"
        (click)="handleInitTab(tab.RENOMINATION)">Transfer/Renomination Booking &nbsp;
      </a>
      <a class="item" [class.active]="tabStatus == tab.EXCEPTION" (click)="handleInitTab(tab.EXCEPTION)">Exception
        &nbsp;
      </a>
    </div>
  </div>

  <ng-container *ngIf="tabStatus == tab.SUMMARY_PORT_PAIR">
    <app-transaction-ts-summary-port-pair-allocation-page></app-transaction-ts-summary-port-pair-allocation-page>
  </ng-container>

  <ng-container *ngIf="tabStatus == tab.PORT_PAIR">
    <app-transaction-ts-port-pair-allocation-page-v2></app-transaction-ts-port-pair-allocation-page-v2>
  </ng-container>

  <ng-container *ngIf="tabStatus == tab.PROSPECT">
    <app-transaction-prospect-booking></app-transaction-prospect-booking>
  </ng-container>

  <ng-container *ngIf="tabStatus == tab.RENOMINATION">
    <app-transaction-ts-renomination></app-transaction-ts-renomination>
  </ng-container>

  <ng-container *ngIf="tabStatus == tab.EXCEPTION">
    <app-transaction-ts-exception
      (redirectToExceptionHandel)="clickOnExceptionHandel($event)"></app-transaction-ts-exception>
  </ng-container>
</div>
