import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { Customer, CustomerDetail } from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
declare  var $:any;

@Component({
  selector: 'app-master-customer-view-page',
  templateUrl: './master-customer-view-page.component.html',
  styleUrls: ['./master-customer-view-page.component.css']
})
export class MasterCustomerViewPageComponent extends Validator  implements OnInit, AfterViewInit {
  @ViewChild('grid') grid:GridPluginComponent;
  @ViewChild('gridDetails') gridDetails:GridPluginComponent;
  @ViewChild('cbCountry') cbCountry:ComboPluginComponent;
  @ViewChild('cbCustomerHistroy') cbCustomerHistory:ComboPluginComponent;
  @ViewChild('gridHistory') gridHistory:GridPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for dialog */
  dialog:any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  form = '';

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridDetails;
  settingCountry;
  settingCustomerHistory;
  settingGridHistory;

  typeCustomerLogStartDate = 'calendar';
  typeCustomerLogEndDate = 'calendar';

  /* Parameter model */
  model = new Customer;
  modelDetail =  new CustomerDetail;

  loading = false;

  lock = false;
  customerIdHistory:string='';
  customerCodeHistory:string='';
  valHistoryStartDate:string = '';
  valHistoryEndDate:string = '';

  isSOC:boolean = false;
  isCOC:boolean = false;
  isSLOT:boolean = false;

  /* validatorRules */
  validatorRules = {
  }

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront          : [ ],
      buttonsDetail         : [ ],
      createDefaultFront    : true,
      createDefaultDetail   : true,
      searchBy              : [
        { display: 'Customer Name', value: 'customerName'},
        { display: 'Country', value: 'countryName'},
        { display: 'Container Ownership', value: 'containerOwnership'},
       // { display: 'Operator Code', value: 'operatorCode'},
        { display: 'COC Op Code', value: 'cocOperatorCode'},
        { display: 'SOC Op Code', value: 'socOperatorCode'},
        { display: 'SLOT Op Code', value: 'slotOperatorCode'},
        { display: 'Shipper', value: 'isShipper'},
        { display: 'Consignee', value: 'isConsignee'},
        { display: 'Notify Party', value: 'isNotifyParty'},
        { display: 'Payer', value: 'isPayer'},
        { display: 'Agency', value: 'isAgency'},
        { display: 'Booking Party', value: 'isBookingParty'},
        { display: 'Haulier', value: 'isHaulier'},
        { display: 'NVOCC', value: 'isNvocc'},
        { display: 'Supplier', value: 'isSupplier'},
        { display: 'Co. Reg. No', value: 'companyRegNo'},
        { display: 'Remarks', value: 'remarks'},
       /* { display: 'User Created', value: 'userCreated'},
        { display: 'Date Created', value: 'dateCreated'},
        { display: 'User Modified', value: 'userModified'},
        { display: 'Date Modified', value: 'dateModified'},
        { display: 'User Invalid', value: 'userInvalid'},
        { display: 'Date Invalid', value: 'dateInvalid'},*/


      ],
      urlhelp               : 'assets/pdf/panduan.pdf'
    }

    this.settingGrid = {
        url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneral',
        page: 10,
        columns: [
                    {header: 'No', field: 'no', width: 50},
                    {header: 'Customer Name', field: 'customerName', width: 200, type: 'string'},
                    {header: 'Country', field: 'countryName', width: 150, render : {
                          value: '<i class="\' + element[\'countryCode\'] + \' flag"></i> element[\'countryName\']'
                    }},
                    {header: 'Container Ownership', field: 'containerOwnership', width: 160},
              //      {header: 'Operator Code', field: 'operatorCode', width: 150},
                    {header: 'COC Op Code', field: 'cocOperatorCode', width: 100},
                    {header: 'SOC Op Code', field: 'socOperatorCode', width: 100},
                    {header: 'SLOT Op Code', field: 'slotOperatorCode', width: 100},
                    {header: 'Shipper', field: 'isShipper', width: 100},
                    {header: 'Consignee', field: 'isConsignee', width: 100},
                    {header: 'Notify Party', field: 'isNotifyParty', width: 100},
                    {header: 'Payer', field: 'isPayer', width: 100},
                    {header: 'Agency', field: 'isAgency', width: 100},
                    {header: 'Booking Party', field: 'isBookingParty', width: 110},
                    {header: 'Haulier', field: 'isHaulier', width: 100},
                    {header: 'NVOCC', field: 'isNvocc', width: 100},
                    {header: 'Supplier', field: 'isSupplier', width: 100},
                    {header: 'Co. Reg. No', field: 'companyRegNo', width: 120},
                    {header: 'Remarks', field: 'remarks', width: 100},
                    {header: 'User Created', field: 'userCreated', width: 110},
                    {header: 'Date Created', field: 'dateCreated', width: 110},
                    {header: 'User Modified', field: 'userModified', width: 110},
                    {header: 'Date Modified', field: 'dateModified', width: 110},
                    {header: 'User Invalid', field: 'userInvalid', width: 110},
                    {header: 'Date Invalid', field: 'dateInvalid', width: 110},
          {header: 'Valid', field: 'isValid', width: 80}
        ],
        buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
        enableSorting: true,
        enableSelectAll: true,
        enablePagination: true,
        sortingColumns:'customerName',
        sortingDirection: 'ASC'
    }

    this.settingGridHistory = {
        url: this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneralHistoryById',
        page: 10,
        columns: [
                    {header: 'No', field: 'no', width: 50},
                    {header: 'Customer Name', field: 'customerName', width: 200, type: 'string'},
                    {header: 'Country', field: 'countryName', width: 150, render : {
                          value: '<i class="\' + element[\'countryCode\'] + \' flag"></i> element[\'countryName\']'
                    }},
                    {header: 'Container Ownership', field: 'containerOwnership', width: 160},
                 //   {header: 'Operator Code', field: 'operatorCode', width: 150},
                    {header: 'COC Op Code', field: 'cocOperatorCode', width: 150},
                    {header: 'SOC Op Code', field: 'socOperatorCode', width: 150},
                    {header: 'SLOT Op Code', field: 'slotOperatorCode', width: 150},
                    {header: 'Shipper', field: 'isShipper', width: 100},
                    {header: 'Consignee', field: 'isConsignee', width: 100},
                    {header: 'Notify Party', field: 'isNotifyParty', width: 100},
                    {header: 'Payer', field: 'isPayer', width: 100},
                    {header: 'Agency', field: 'isAgency', width: 100},
                    {header: 'Booking Party', field: 'isBookingParty', width: 110},
                    {header: 'Haulier', field: 'isHaulier', width: 100},
                    {header: 'NVOCC', field: 'isNvocc', width: 100},
                    {header: 'Supplier', field: 'isSupplier', width: 100},
                    {header: 'Co. Reg. No', field: 'companyRegNo', width: 120},
                    {header: 'Remarks', field: 'remarks', width: 100},
                    {header: 'User Created', field: 'userCreated', width: 110},
                    {header: 'Date Created', field: 'dateCreated', width: 110},
                    {header: 'User Modified', field: 'userModified', width: 110},
                    {header: 'Date Modified', field: 'dateModified', width: 110},
                    {header: 'User Invalid', field: 'userInvalid', width: 110},
                    {header: 'Date Invalid', field: 'dateInvalid', width: 110},
                    {header: 'Valid', field: 'isValid', width: 80},
                    {header: 'Action Log', field: 'action', width: 110}
        ],
        buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
        enableSorting: true,
        
        enablePagination: true
    }

    this.settingGridDetails = {
        url: '',
        page: 5,
        columns: [
                    {header: 'Seq', field: 'seqNo', width: 50},
                    {header: 'Display Name', field: 'customerDisplayName', width: 150},
                    {header: 'Address', field: 'fullAddress', width: 200},
                    {header: 'Contact Person', field: 'contactPerson', width: 150},
                    {header: 'Tel. No(s)', field: 'telno', width: 100},
                    {header: 'Email', field: 'email', width: 100},
                    {header: 'Fax. No(s)', field: 'fax', width: 100}
        ],
        buttons: [  { name: 'Edit', event: 'edit', enabled: true }  ],
        enableSorting: false,
        enableSelectAll: true,
        enablePagination: false,
        sortingColumns:'customerDisplayName',
        sortingDirection: 'ASC'
    }

    this.settingCountry = {
       id          : 'cbCountry',
       type        : 'search enter', // search | select | select input
       url         : this.configService.config.BASE_API.toString() + '/MasterCountries/findGeneral/*/*/{query}/1/5/*/*',
       maxChars    : 3,
       template    : 'grid', // default
       placeholder : 'Search Country',
       title       : 'countryName',
       description  : '',
       isMark      : true,
       columns     : [
                    {header: 'Country Code', field: 'countryCode', width: 100},
                    {header: 'Country Name', field: 'countryName', width: 300},
                    {header: 'Valid', field: 'isValid', width: 50}
       ]
    }


    this.settingCustomerHistory = {
      id          : 'cbCustomerHistory',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterCustomers/findGeneralHistory/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'customerName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Customer ID', field: 'customerId', width: 50},
        {header: 'Customer Name', field: 'customerName', width: 50, type:'string'},
        {header: 'Container Ownership', field: 'containerOwnership', width: 50},
        {header: 'Remarks', field: 'remarks', width: 50},
        {header: 'Valid?', field: 'isValid', width: 50}
      ]
    }


  }

  ngOnInit(){
    $('.ui.accordion').accordion();
  }

  ngAfterViewInit() {
    $('.test.checkbox').checkbox();
    $('.special.cards .image').dimmer({
      on: 'hover'
    });

    this.setValidatorHeader();
    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,update,delete,cancel";

    this.grid.search = '*/*/*';
    this.grid.onFirst();
    this.genericUtil.setBreadcrumb("Master,Entity,Master-Customer:/main/master-customer");

  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = { icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  eventMessage(event:any) {
    if(event != ''){
      eval(event);
    }
  }

  infoGrid(event:any) {
    this.info = event;
  }

  infoGridDetails(event:any) {
    this.info = event;
  }

  gridEvent(event:any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "";
        }
        this.invisibleToolbarButtons = "create,update,delete,history,print";
        break;

      case 'click' :
        if(this.grid.getSelectedValues().length > 0){
          this.disableToolbarButtons = "";
        }else{
          this.disableToolbarButtons = "";
        }
        this.invisibleToolbarButtons = "create,update,delete,history,print";
        break;
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
         switch(arr[0]){
            case 'edit':
              this.handleUpdate();
              break;
            case 'dblClick':

              this.disableToolbarButtons = "";
              this.invisibleToolbarButtons = "create,update,delete,history,print";

              this.modeForm = true;
              this.modeToolbar = false;

              let dt = this.grid.getSelectedValues()[0];
              let custId:string = String(dt.customerId).trim();
              this.onRetrieve(custId);

              if(this.model.isCoc == true){
                  this.isCOC =  true;
              }else{
                  this.isCOC =  false;
              }

              if(this.model.isSoc == true){
                  this.isSOC =  true;
              }else{
                  this.isSOC =  false;
              }

              if(this.model.isSlot == true){
                  this.isSLOT =  true;
              }else{
                  this.isSLOT =  false;
              }

              this.lock = true;
              this.gridDetails.disable();
              break;

            default:
            break;
          }

        }

        break;
    }
  }

  gridEventDetails(event:any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
         switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              this.setValidatorDetails();
              this.disableToolbarButtons = "save,cancel";

              if(this.model['error-customerName'] != null){
                this.model['error-customerName'] = '';
              }

              let dt = this.gridDetails.getSelectedValues()[0];
              this.gridDetails.listStore.store.forEach(element => {
                 if(dt.no ==  element['no']){
                    this.modelDetail = new CustomerDetail;
                    this.modelDetail = element;
                    // console.log(this.modelDetail);
                 }
              });

              $('#display').transition();
              $('#displayGrid').transition('hide');

              break;

            default:
            break;
          }

        }

        break;
    }

  }

  toolbarEvent(event:any) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'create':
        this.handleCreate();
        break;
      case 'update':
        this.handleUpdate();
        break;
      case 'delete':
        this.handleDelete();
        break;
      case 'print':
        this.handlePrint();
        break;
      case 'save':
        if(this.model.customerId != ""){
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'history':
        this.handleHistory();
        break;
      default:
        let str:string = event;
        if(str.indexOf('search') > -1 ){
          this.grid.search = str.replace("search:","");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        }else{
          this.handleCancel();
        }
        break;
    }
  }

  handleCreate()
  {
    // handle create event

    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.model = new Customer;
    this.cbCountry.setValue("");
    this.model.isValid = true;
    this.modelDetail = new CustomerDetail;
    this.gridDetails.listStore.store = [];
    this.gridDetails.loadData();

    this.genericUtil.setBreadcrumb("Master,Entity,Master-Customer:/main/master-customer,Form");

  }


  handleUpdate()
  {
    // handle update event
    this.modeForm = true;
    this.modeToolbar = true;

    this.disableToolbarButtons = "";
    this.invisibleToolbarButtons = "create,print";

    let dt = this.grid.getSelectedValues()[0];
    let custId:string = String(dt.customerId).trim();
    this.onRetrieve(custId);


    if(this.model.isCoc == true){
        this.isCOC =  true;
    }else{
        this.isCOC =  false;
    }

    if(this.model.isSoc == true){
        this.isSOC =  true;
    }else{
        this.isSOC =  false;
    }

    if(this.model.isSlot == true){
        this.isSLOT =  true;
    }else{
        this.isSLOT =  false;
    }


    this.lock = false;
    this.gridDetails.enable();
  }

  handleDelete()
  {
    // handle delete event
    this.loading = true;
    this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.deleteEvent()', no: 'this.loading = false;' });
  }

  handlePrint()
  {
    // handle print event
    var splitUrl = this.configService.config.getPDFUrl.split(":");
     window.open(this.configService.config.getPDFUrl.replace(splitUrl[2],'10000') + '/Mreport/runReport/39/Customers-Export-Reports/*');
  }

  handleSave()
  {
    // handle save event
    this.model.dateCreated = this.genericUtil.getPSIDate();
    this.model.masterCustomerDetails = [];
    this.model.masterCustomerDetails = this.gridDetails.listStore.store;

    // console.log(this.model);

    let isValid:boolean = this.onValidate(this.model);

    //additional validation
    if(this.model.isBookingParty){
      if(this.model.isCoc && this.model.cocOperatorCode.trim() == ""){
        this.model["error-cocOperatorCode"] = "Please input OP Code(s).";
        isValid=true;
      }
      if(this.model.isSoc && this.model.socOperatorCode.trim() == ""){
        this.model["error-socOperatorCode"] = "Please input OP Code(s).";
        isValid=true;
      }
      if(this.model.isSlot && this.model.slotOperatorCode.trim() == ""){
        this.model["error-slotOperatorCode"] = "Please input OP Code(s).";
        isValid=true;
      }
    }

    if(!isValid){

      if(!this.model.isShipper && !this.model.isConsignee && !this.model.isNotifyParty && !this.model.isPayer && !this.model.isAgency && !this.model.isBookingParty && !this.model.isHaulier && !this.model.isNvocc && !this.model.isSupplier){
        this.message('warning','Error Input', 'Please select at least one (1) option for Customer Type.', 'okonly',{ok:'this.loading=false;'});
      }else if(!this.model.isSoc && !this.model.isCoc && !this.model.isSlot){
        this.message('warning','Error Input', 'Please select at least one (1) option for Container Ownership.', 'okonly',{ok:'this.loading=false;'});
      /*
      }else if(this.model.masterCustomerDetails.length == 0){
        this.message('warning','Error Input', 'Please insert customer detail.', 'okonly',{ok:'this.loading=false;'});
      */
      }else {
        this.message('save','Saving data','Do you want to save the record? ', 'yesno', { yes: 'this.saveEvent()', no: 'this.loading = false;'});
      }
    }else{
      this.loading = false;
    }
  }

  handleCancel(){
    // handle cancel event
    this.model = new Customer;
    this.modelDetail = new CustomerDetail;
    this.gridDetails.onClear();

    this.modeForm = false;
    this.modeToolbar = false;
    this.form = '';
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";
    this.lock = false;

    this.cbCustomerHistory.setValue("");
    this.cbCustomerHistory.close();
    this.customerIdHistory='';
    this.customerCodeHistory = '';
    this.gridHistory.onClear();
    this.gridHistory.onHistory("");
  }

  handleHistory()
  {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = 'history';
    this.invisibleToolbarButtons = "save";

    this.gridHistory.onClear();
  }

  onRetrieve(customerId:string){
    //// console.log('OnRetrieve : ' + customerId)
     this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterCustomers/findById/' + customerId).subscribe((resp) =>{
          let data = resp.json();
          this.setData(data);
     });

  }

  setData(data:any){
      this.model = new Customer;
      this.model.customerId = String(data.customerId).trim().toUpperCase();
      this.model.customerCode = String(data.customerCode).trim();
      this.model.customerName = String(data.customerName).trim().toUpperCase().replace(/&NBSP;/g," ");
      this.model.countryCode = String(data.countryCode).trim().toUpperCase();
      this.model.countryName = String(data.countryName).trim().toUpperCase();
      this.cbCountry.setValue(this.model.countryName.trim().toUpperCase());
      this.model.companyRegNo = String(data.companyRegNo).trim().toUpperCase();
      this.model.remarks = String(data.remarks).trim().toUpperCase();

      if(String(data.isValid).trim().toUpperCase() == 'Y'){
        this.model.isValid = true;
      }else{
        this.model.isValid = false;
      }

      if(String(data.isShipper).trim().toUpperCase() == 'Y'){
        this.model.isShipper = true;
      }else{
        this.model.isShipper = false;
      }

      if(String(data.isConsignee).trim().toUpperCase() == 'Y'){
        this.model.isConsignee = true;
      }else{
        this.model.isConsignee = false;
      }

      if(String(data.isNotifyParty).trim().toUpperCase() == 'Y'){
        this.model.isNotifyParty = true;
      }else{
        this.model.isNotifyParty = false;
      }

      if(String(data.isPayer).trim().toUpperCase() == 'Y'){
        this.model.isPayer = true;
      }else{
        this.model.isPayer = false;
      }

      if(String(data.isAgency).trim().toUpperCase() == 'Y'){
        this.model.isAgency = true;
      }else{
        this.model.isAgency = false;
      }

      if(String(data.isBookingParty).trim().toUpperCase() == 'Y'){
        this.model.isBookingParty = true;
      }else{
        this.model.isBookingParty = false;
      }

      if(String(data.isHaulier).trim().toUpperCase() == 'Y'){
        this.model.isHaulier = true;
      }else{
        this.model.isHaulier = false;
      }

    if(String(data.isNvocc).trim().toUpperCase() == 'Y'){
      this.model.isNvocc = true;
    }else{
      this.model.isNvocc = false;
    }

    if(String(data.isSupplier).trim().toUpperCase() == 'Y'){
      this.model.isSupplier = true;
    }else{
      this.model.isSupplier = false;
    }

      if(String(data.isCoc).trim().toUpperCase() == 'Y'){
        this.model.isCoc = true;
      }else{
        this.model.isCoc = false;
      }

      if(String(data.isSoc).trim().toUpperCase() == 'Y'){
        this.model.isSoc = true;
      }else{
        this.model.isSoc = false;
      }

      if(String(data.isSlot).trim().toUpperCase() == 'Y'){
        this.model.isSlot= true;
      }else{
        this.model.isSlot = false;
      }

      if(data.cocOperatorCode != null){
        this.model.cocOperatorCode = String(data.cocOperatorCode).trim().toUpperCase();
      }
      if(data.socOperatorCode != null){
        this.model.socOperatorCode = String(data.socOperatorCode).trim().toUpperCase();
      }
      if(data.slotOperatorCode != null){
        this.model.slotOperatorCode = String(data.slotOperatorCode).trim().toUpperCase();
      }

      if(data.cocAccountCode != null){
        this.model.cocAccountCode = String(data.cocAccountCode).trim().toUpperCase();
      }
      if(data.socAccountCode != null){
        this.model.socAccountCode = String(data.socAccountCode).trim().toUpperCase();
      }
      if(data.slotAccountCode != null){
        this.model.slotAccountCode = String(data.slotAccountCode).trim().toUpperCase();
      }

      if(data.cocCreditDays != null){
        this.model.cocCreditDays = Number.parseInt(data.cocCreditDays);
      }
      if(data.socCreditDays != null){
        this.model.socCreditDays = Number.parseInt(data.socCreditDays);
      }
      if(data.slotCreditDays != null){
        this.model.slotCreditDays = Number.parseInt(data.slotCreditDays);
      }

      // console.log(data);
      if(data['masterCustomerDetails'] != null){
        this.gridDetails.listStore.store = [];
        this.model.masterCustomerDetails = [];
        this.model.masterCustomerDetails =  data['masterCustomerDetails'];
        this.gridDetails.listStore.store = this.model.masterCustomerDetails;
        this.gridDetails.loadData();

      }


      this.model.userCreated = String(data.userCreated).trim().toUpperCase();
      this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
      this.model.userModified = String(data.userModified).trim().toUpperCase();
      this.model.dateModified = String(data.dateModified).trim().toUpperCase();

  }

  changeEventOwnership(event:any) {
    //// console.log(event);
    let e:string = event;
    let ee:string[] = e.split('-');
    switch (ee[0]) {
      case 'COC':
        if(ee[1] == 'true'){
          this.isCOC = true;

        }else{
          this.isCOC = false;
          this.model.cocOperatorCode="";
          /*
          this.model.cocOperatorCode = '';
          this.model.cocAccountCode = '';
          this.model.cocCreditDays = 0;
          */
        }

        break;

      case 'SOC':
        if(ee[1] == 'true'){
          this.isSOC = true;
        }else{
          this.isSOC = false;
          this.model.socOperatorCode="";
          /*
          this.model.socOperatorCode = '';
          this.model.socAccountCode = '';
          this.model.socCreditDays = 0;
          */
        }
        break;

      case 'SLOT':
        if(ee[1] == 'true'){
          this.isSLOT = true;
        }else{
          this.isSLOT = false;
          this.model.slotOperatorCode="";
          /*
          this.model.slotOperatorCode = '';
          this.model.slotAccountCode = '';
          this.model.slotCreditDays = 0;
          */
        }
        break;

      default:
        break;
    }
  }

  changeEventCountry(event:any) {
    if(event['countryCode'] != null){
      this.model.countryCode = event['countryCode'];
    }else{
      this.model.countryCode = "";
    }

    if(event['countryName'] != null){
      this.model.countryName = event['countryName'];
    }else{
      this.model.countryName = "";
    }

  }

  changeEventCustomerHistory(event:any) {
    this.customerIdHistory = '';
    this.customerCodeHistory = '';
    // console.log(event);
   // if(event.customerId == ''){
   //   if(event.target != ''){

   //   }

   // }else{
      this.customerIdHistory = String(event.customerId).trim();
      this.customerCodeHistory = String(event.customerCode).trim();
      this.gridHistory.onHistory(this.customerIdHistory);
   // }

  }

  afterSave(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.form = '';
    this.lock =  false;
    this.isSOC = false;
    this.isCOC = false;
    this.isSLOT = false;

    if(this.grid.getSelectedValues().length >= 1){
      this.grid.onSortDir("dateModified","DESC");
    }else{
      this.grid.onSortDir("dateCreated","DESC");
    }

    this.grid.clearSelectedValues();
  //  this.grid.onFirst();
  }

   afterDelete(){
    this.modeForm = false;
    this.modeToolbar = false;
    this.disableToolbarButtons = "update,delete";
    this.invisibleToolbarButtons = "cancel";

    this.form = '';
    this.lock =  false;
    this.isSOC = false;
    this.isCOC = false;
    this.isSLOT = false;

    this.grid.clearSelectedValues();
    this.grid.loadData();
  }


  saveEvent(){
    this.loading = true;
    //// console.log(this.model);

    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterCustomers/save',this.model).subscribe((resp) =>{
          if(resp.status == 200 && resp.json()['message'] == 'OK'){
              //clear all
              this.model = new Customer;
              this.cbCountry.setValue("");
              this.modelDetail = new CustomerDetail;
              this.gridDetails.listStore.store = [];
              this.gridDetails.loadData();

              this.loading = false;
              this.info = 'save successfully';

              this.message('information','Information','Record saved successfully.', 'okonly', { ok: 'this.loading=false;this.afterSave();'});

          }else{
            this.loading = false;
            this.message('warning','Warning',resp.json()['content'],'okonly',{ok: 'this.loading=false;'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });
  }

  deleteEvent(){
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach(element => {
      deleteList.push(element.customerId);
    });
    this.genericService.POST(this.configService.config.BASE_API.toString() + '/MasterCustomers/delete',deleteList).subscribe((resp) =>{
          if(resp.status == 200){
              this.model = new Customer;
              this.cbCountry.setValue("");
              this.modelDetail = new CustomerDetail;
              this.gridDetails.listStore.store = [];
              this.gridDetails.loadData();

              this.loading = false;

              this.message('information','Information','Record deleted successfully. ', 'okonly', { ok: 'this.loading=false;this.afterDelete();'});
          }
    },error => {
      this.loading = false;
      // console.log(error.message);
      this.info = error.message;
    });

  }

  setValidatorHeader() {
    this.validatorRules = {
        customerName: {
          rules:[
            {
              type  : 'empty',
              prompt: 'Please input Customer Name.'
            },
          ]
        },

        countryName: {
          rules:[
            {
              type  : 'empty',
              prompt: 'Please input Country.'
            },
          ]
        },

        cocOperatorCode:{
          rules:[
            {
              type: 'operatorCode',
              prompt:''
            }
          ]
        },

        socOperatorCode:{
          rules:[
            {
              type: 'operatorCode',
              prompt:''
            }
          ]
        },

        slotOperatorCode:{
          rules:[
            {
              type: 'operatorCode',
              prompt:''
            }
          ]
        },

        cocCreditDays:{
          rules:[
            {
              type: 'positiveInteger',
              prompt:'No negative value!'
            }
          ]
        },

        socCreditDays:{
          rules:[
            {
              type: 'positiveInteger',
              prompt:'No negative value!'
            }
          ]
        },

        slotCreditDays:{
          rules:[
            {
              type: 'positiveInteger',
              prompt:'No negative value!'
            }
          ]
        },

    }
  }

  setValidatorDetails() {
    this.validatorRules = {
        customerDisplayName: {
          rules:[
            {
              type  : 'empty',
              prompt: 'Please input Display Name.'
            }
          ]
        },
        fullAddress: {
          rules:[
            {
              type  : 'empty',
              prompt: 'Please input Display Address.'
            }
          ]
        },
        email: {
          rules:[
            {
              type  : 'email',
              prompt: 'Invalid email format.'
            }
          ]
        }
    }
  }

  addDetail() {
    this.setValidatorDetails();
    this.disableToolbarButtons = "save,cancel";

    if(this.model['error-customerName'] != null){
      this.model['error-customerName'] = '';
    }

    this.modelDetail = new CustomerDetail;
    $('#display').transition();
    $('#displayGrid').transition('hide');
  }

  removeDetail() {
    if(this.gridDetails.getSelectedValues().length > 0){
      this.message('delete','Deletion Record','Confirm to delete record(s)?','yesno',{ yes:'this.removeDetailEvent()', no: '' });
    }else{
      if(this.gridDetails.listStore.store.length > 0){
        this.message('delete','Deletion Record','Please select at least one (1) option.', 'okonly', { ok: ''});
      }
    }
  }

  removeDetailEvent() {
    this.gridDetails.getSelectedValues().forEach(element => {
       this.gridDetails.listStore.removeData(element);
    });
    this.gridDetails.loadData();
    this.gridDetails.clearSelectedValues();
    this.updateSeqNo();
  }

  cancelDetail() {
    this.setValidatorHeader();
    this.disableToolbarButtons = "";
    this.gridDetails.clearSelectedValues();
    $('#display').transition();
    $('#displayGrid').transition();
  }

  saveDetail() {

    if(!this.onValidate(this.modelDetail)){

       if(this.modelDetail.no == 0){
          let data = new CustomerDetail;
          data.customerDisplayName = String(this.modelDetail.customerDisplayName).toUpperCase().trim();
          data.fullAddress = String(this.modelDetail.fullAddress).toUpperCase().trim();
          data.contactPerson = String(this.modelDetail.contactPerson).toUpperCase().trim();
          data.telno = String(this.modelDetail.telno);
          data.email = String(this.modelDetail.email).trim();
          data.fax = String(this.modelDetail.fax);

          this.gridDetails.listStore.addData(data);
          this.gridDetails.loadData();
          this.updateSeqNo();

          this.gridDetails.clearSelectedValues();
          this.modelDetail = new CustomerDetail;

          this.setValidatorHeader();
          this.disableToolbarButtons = "";
          $('#display').transition();
          $('#displayGrid').transition();
       }else{

          let data = new CustomerDetail;
          data.no = this.modelDetail.no;
          data.seqNo = this.modelDetail.seqNo;
          data.customerDisplayName = String(this.modelDetail.customerDisplayName).toUpperCase().trim();
          data.fullAddress = String(this.modelDetail.fullAddress).toUpperCase().trim();
          data.contactPerson = String(this.modelDetail.contactPerson).toUpperCase().trim();
          data.telno = String(this.modelDetail.telno);
          data.email = String(this.modelDetail.email).trim();
          data.fax = String(this.modelDetail.fax);

          this.gridDetails.listStore.updateData(this.modelDetail.no-1 ,data);
          this.gridDetails.loadData();
          this.gridDetails.clearSelectedValues();
          this.modelDetail = new CustomerDetail;

          this.setValidatorHeader();
          this.disableToolbarButtons = "";
          $('#display').transition();
          $('#displayGrid').transition();
       }
    }
  }

  updateSeqNo(){
    let index:number = 0;
    this.gridDetails.listStore.store.forEach(element => {
      element['seqNo']=element['no'];
      this.gridDetails.listStore.updateData(index,element);
      index++;
    });
  }


  generateCustomerHistoryReport(event:any){
    // console.log("customerIdHistory => " + this.customerIdHistory);
    if(this.customerIdHistory == '' || this.customerIdHistory == 'undefined'){
      this.message('warning','Warning','Please select (1) historical data before exporting.','okonly',{ok:''});
    }else{
     // if(this.gridHistory.listStore.store.length > 0){
        window.open(this.configService.config.BASE_API.toString().replace('9090','10000') + '/Mreport/runReport/40/History-Customer-' + this.customerIdHistory +'/'+this.customerIdHistory);
     // }
    }
  }

  gridEventHistory(event:any){
    //do nothing
  }

  retrieveHistory(event:any){
    this.gridHistory.loadData();
  }

  infoGridHistory(event:any){
    // this.info=event;
  }

  none(event:any){
    if(event.keyCode == 13){
        event.preventDefault();
    }
  }







}
