import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { autoCreationBLModel} from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService, UploadPluginComponent, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $:any;

@Component({
  selector: 'app-transaction-sast-page',
  templateUrl: './transaction-sast-page.component.html',
  styleUrls: ['./transaction-sast-page.component.css']
})
export class TransactionSASTPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;
  @ViewChild('sastFileUpload') sastFileUpload:UploadPluginComponent;
  @ViewChild('sastPM4FileUpload') sastPM4FileUpload:UploadPluginComponent;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;
  labelToolbar = "SAST";

  /* Parameter settings */
  settingToolbar;
  settingUploadSastFile;
  settingUploadSastPM4File;
  settingPDF;

  //types


  // lock

  // model declaration
  model = new autoCreationBLModel;    
  hitUrl:string = this.configService.config.BASE_API.toString();
  isSASTExist:boolean = false;
  isPM4Exist:boolean  = false;
  postData:any = {};

  validatorRules = {};

 
  constructor(
    private auth:Authorize, 
    private genericService:GenericService, 
    private genericUtil:GenericUtil, 
    private router: Router, 
    private configService:ConfigService, 
    private cookieService:CookieService,
    private _http:HttpClient) {
    super();
    var officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
    if(officeCode == "SGSIN"){
      this.labelToolbar = "SAST(Matching)";
    }

    // {name: 'Print Report', event: 'pReport', icon: 'power'},
    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'power'},
        
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar
    }

    this.settingUploadSastFile = {
      id   : 'uploadSAST',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'csv',
      showUploadButton: false,
      customButtons : [
      ]
    }
    this.settingUploadSastPM4File = {
      id   : 'uploadPM4',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'csv',
      showUploadButton: false,
      customButtons : [
      ]
    }
    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : true,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initForm();
    var self = this;
    $("#myfile").change(function(e){
       console.log("file name : "+$(this).val());
      var fileName = $(this).val();
      if(fileName.split(".").pop().toLowerCase() == "csv"){
        self.model.fileName = $(this).val(); 
        self.disableToolbarButtons = '';    
      } else {
        self.message("information","Information", "Please choose CSV file!","okonly",{ok:""});   
      }
    });    
  }



  initModel(){
    this.model.fileName = "";
    this.model.pm4FileName = "";
    this.model.fileType = "SAST";
    this.model.isCreateBL = false;
    this.model.messageValue = "TO BEGIN, PLEASE BROWSE FOR THE FILE TO BE PROCESSED.\nTHEN CHOOSE A FILE TYPE: QLEC/SAST AND CLICK ON THE PROCESS BUTTON.";
    this.disableToolbarButtons = 'process';
    this.model.userCreated = this.cookieService.getName();
    this.model.officeCode = this.cookieService.getDefaultLocationCode();

    this.postData["officeCode"] = this.model.officeCode;
    this.postData["userId"] = this.model.userCreated;
    this.postData["fileName"] = "";
    this.postData["pm4FileName"] = "";
    //this.postData["createdDate"] = this.genericUtil.getPSIDate();


    this.sastFileUpload.store = [];
    this.sastPM4FileUpload.store = [];

    this.isSASTExist = false;
    this.isPM4Exist  = false;
  }

  initForm(){
    this.initModel();    
  }    

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'process' :
        this.uploadSastFileToServer();
        break;
      case 'cancel' :
        this.initModel();
       break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  uploadProcess(type:string){
      switch(type){
        case "SAST":
          this.sastFileUpload.onUpload();
          break;
        case "PM4":
          this.sastPM4FileUpload.onUpload();
          break;
      }
  }  

  uploadEventSastFile(event){
    console.log(event);    
    if(event.includes("-")){
      var temp = event.split("-");
      switch(temp[1].trim()){
        case "uploaded":
          this.disableToolbarButtons = "process";
          this.model.fileName = temp[0].trim();          
          this.isSASTExist = true;          
          this.uploadProcess("SAST");          
          //this.uploadSastFileToServer();
          break;
        case "deleted":
          this.model.fileName = "";
          this.postData["fileName"] = "";
          this.isSASTExist = false;
          if(!this.isPM4Exist){
            this.disableToolbarButtons = "process";
          }
          break;
      }
    } else {
      switch(event){
        case "success":
          this.postData["fileName"] = this.model.fileName;
          this.disableToolbarButtons = "";
          //this.processingSASTFile(postData);

          break;
        default:
          break;
      }
    }
  }

  uploadEventSastPM4File(event){
    if(event.includes("-")){
      var temp = event.split("-");
      switch(temp[1].trim()){
        case "uploaded":
          this.disableToolbarButtons = "process";
          this.model.pm4FileName = temp[0].trim(); 
          this.isPM4Exist = true;          
          this.uploadProcess("PM4");         
          //this.uploadSastFileToServer();
          break;
        case "deleted":
          this.model.pm4FileName = "";
          this.postData["pm4FileName"] = "";
          this.isPM4Exist = false;
          if(!this.isSASTExist){
            this.disableToolbarButtons = "process";
          }
          break;
      }
    } else {
      switch(event){
        case "success":
          this.postData["pm4FileName"] = this.model.pm4FileName;
          this.disableToolbarButtons = "";        
          //this.processingSASTFile(postData);

          break;
        default:
          break;
      }
    }
  }


  uploadSastFileToServer(){    
    this.loading = true;
    this.postData["sastFileExist"] = this.isSASTExist;   
    this.postData["pm4FileExist"] = this.isPM4Exist;
    this.postData["createdDate"] = this.genericUtil.getPSIDate();

    console.log("postData");
    console.log(this.postData);
    this.processingSASTFile(this.postData);

  }

  processingSASTFile(postData){
    var uri = this.hitUrl + "?q=/ContainerLoadList/upload/SAST";
    this._http.post(uri,postData,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      console.log(resp);
      var result = resp;
      if(result["status"] == "ok"){
        var reportDate = this.postData["createdDate"].split(" ")[0].split("-")[2] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[1] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[0] + "X" + this.postData["createdDate"].split(" ")[1];
        var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
        var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/SastDiscrepancyReport.pdf?sessionId="+result["session-id"]+"&Date="+reportDate+"&userId="+this.model.userCreated;
        var fileName = "SAST-Discrepancy-Report-"+this.model.userCreated+".pdf";

        var postDataX = {};
        postDataX["fileName"] = fileName;
        postDataX["url"] = jasperURL;

        this.genericService.POST(hitUrl,postDataX).subscribe((resp)=>{
          if(resp.ok){
            var result = resp.json();
    
            if(result["message"] == "success"){
              this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
              this.pdfContainer.showPDF();
            }
                
          }

          this.loading = false;
        });

      } else {
        this.message("Information","information",result["message"],"okonly",{ok:""});
        this.loading = false;
      }
    });

  }

  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      this.initModel();
    }
  }


}