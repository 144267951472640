import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, CalendarPluginComponent, GenericUtil, DatePluginComponent,CookieService,ConfigService, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { Promise } from 'q';
import { config } from 'rxjs/internal/config';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { load } from '../../../node_modules/@angular/core/src/render3/instructions';

export class ReportParam{

  fromDate = '';
  toDate = '';

  reportType = 'normal';

  constructor(){}

}

declare  var $:any;


@Component({
  selector: 'app-report-monthly-lifting-normal-page',
  templateUrl: './report-monthly-lifting-normal-page.component.html',
  styleUrls: ['./report-monthly-lifting-normal-page.component.css']
})
export class ReportMonthlyLiftingNormalPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  @ViewChild('etaFrom') etaFrom:DatePluginComponent;
  @ViewChild('etaTo') etaTo:DatePluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingPOC;
  settingDatePicker;
  settingDateFrom;
  settingDateTo;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';

  officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
  userName = this.cookieService.getName();
  isError : Boolean = false;

  // lock
  //lock2
  cButtonLock = true;
  dButtonLock = true;

  model = new ReportParam;

  validatorRules = {};


  constructor(private _http: HttpClient,private auth:Authorize, private cookieService:CookieService,private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Monthly Lifting Report By Normal'
    }

    this.settingDatePicker = {
      modeCal : true,
      type : 'datetime',
      returnText : 'year-month-day',
      returnTextTime : '',
      separator : '-',
      AltTimeId : '', // default : ''
      hour12 : false,
    }

    this.settingDateFrom = {
      id: 'idEtaFrom',
      placeholder : 'ETA FROM',
      autoHide: true,
      type: 'date', // date|custom
      format: 'yyyy-mm-dd',
      customtext: ''
    }

    this.settingDateTo = {
      id: 'idEtaTo',
      placeholder : 'ETA TO',
      autoHide: true,
      type: 'date', // date|custom
      format: 'yyyy-mm-dd',
      customtext: ''
    }


     this.settingPOC = {
        id          : 'cbPOC',
        type        : 'search enter', // search | select | select input
        url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneral/*/*/{query}/1/100/*/*',
        maxChars    : 3,
        template    : 'grid',
        placeholder : 'Search ...',
        title       : 'locationName',
        desciption  : '',
        flag        : '',// for template : 2
        columns      :[
            {header: 'Location Id', field: 'locationId', width: 100},
            {header: 'Location Name', field: 'locationName', width: 300},
            {header: 'Country Name', field: 'countryName', width: 100},
            {header: 'Remarks', field: 'remarks', width: 100},
            {header: 'Is Valid?', field: 'isValid', width: 100},
        ]
    }

  }

  clearValidatorError(){
    this.model['error-fromDate'] = '';
    this.model['error-toDate'] = '';
    this.model['error-etaToValidator'] = '';
    this.isError = false;
    this.model['dateValidator'] = false;
  }

  setValidator(){
    this.validatorRules = {
      fromDate: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel ETA From."
        }]
      },
      toDate: {
        rules: [{
          type: "empty",
          prompt: "Please input Vessel ETA To."
        }]
      },
      dateValidator: {
        rules: [{
          type: 'custom',
          prompt: 'Eta to Date must be later than Eta From.'
        }]
      },
    }
  }

  dateValidator(): Boolean {
    let result: Boolean = false;
    if (this.model.toDate != '') {
      if (this.model.toDate <= this.model.fromDate) {
        this.model['error-etaToValidator'] = 'Expiry On Date (' + this.model.toDate.toString() + ') must be later than ETA From.';
        return true;
      }
    }

    return result;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    });

  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'cancel' :
      this.onCancel();
        break;
      case 'print' :
      console.log('printing');
      this.model.fromDate = $('#etaFrom').val();
      this.model.toDate =  $('#etaTo').val();
      this.handlePrint();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }


  eventMessage(event){}
  handlePrint(){
  this.setValidator();
  this.isError = this.onValidate(this.model);
  this.clearValidatorError();
  this.model['dateValidator'] = false;
    console.log("VALIDASINYA -- > "+this.onValidate(this.model));
    let isPassedValidation:boolean = this.onValidate(this.model);
     this.model['dateValidator'] = this.dateValidator();
     if(this.model['dateValidator'])this.isError = true;

    if(!isPassedValidation&&!this.model['dateValidator']){
    //if(!this.isError){
      this.clearValidatorError();
      this.loading = true;
      var jasperURL = '';
  //      jasperURL= 'http://glossys.samudera.com:8080/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/monthlyLiftingNormal.xlsx?etafrom='+$('#etaFrom').val()+'&ETATo='+$('#etaTo').val()+'&officeCode='+this.officeCode;
      jasperURL = this.configService.config.jasperUrl.toString() + '/jasperserver/rest_v2/reports/reports/GLOSSYS/REPORTS/monthlyLiftingNormal.xlsx?etafrom=' + $('#etaFrom').val()+' 00:00:00' + '&etato=' + $('#etaTo').val()+' 23:59:59' + '&officecode=' + this.officeCode;

      //const urlHit = jasperURL;
      const urlHitData = this.configService.config.BASE_API.toString()+ '/BookingReport/GenMonthlyLiftingNormalReport/'+this.officeCode+'/'+$('#etaFrom').val()+'/'+$('#etaTo').val()+'/'+this.userName;
      this.genericService.GET(urlHitData).subscribe((resp)=>{
        console.log ("RESP Data : "+resp.json()['totalData']);
        if(resp.json()['totalData'] == 0){
          this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=true;'});

          this.loading=false;
        }else{
          window.open(jasperURL);
          this.loading=false;

        }
      });




    }

    /*this._http.get(urlHit,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token':localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{

      console.log(res.size);

      //if(res.size > 4138)
          FileSaver.saveAs(res, 'Monthly Lifting Normal.xlsx');
      //else
          //this.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});

      this.loading = false;
    });*/
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  onCancel(){
    this.clearValidatorError();
    $('#etaFrom').val('');
    $('#etaTo').val('');
  }


}
