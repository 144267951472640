import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { transhipmentModel} from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

export class Param{
  officeCode:string='';
  vesselId:string='';
  vesselCode:string='';
  voyage:string='';
  loadPort:string = '';
  nextPort:string = '';
  status:string = 'A';
  method:string = 'Pending';

  constructor(){}
}

@Component({
  selector: 'app-transaction-bl-transshipment-page',
  templateUrl: './transaction-bl-transshipment-page.component.html',
  styleUrls: ['./transaction-bl-transshipment-page.component.css']
})
export class TransactionBLTransshipmentPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  @ViewChild('cbLoadPort') cbLoadPort: ComboPluginComponent;
  @ViewChild('cbDischargePort') cbDischargePort:ComboPluginComponent;
  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;

  @ViewChild('transhipmentGrid') transhipmentGrid:GridPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  loading = false;
  formMode = "Pending";

  /* Parameter settings */
  settingToolbar;
  settingLoadPort;
  settingDischargePort;
  settingVessel;
  settingVoyage;
  settingTranshipmentGrid;

  //types


  // lock
  lock:boolean = false;
  isError: Boolean = false;
  model = new Param();

  officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();


  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    // setting
    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Batch Confirm', event: 'confirm', icon: 'save'},
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'T/S Port Pair Allocation'
    }

    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findLikeLocationNameOrLocationCodeIsValid/*/{query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Load Port --',
      title       : 'locationName',
      description  : '',
      isMark      : true,
      columns     : [
                   {header: 'Location Code', field: 'locationCode', width: 100},
                   {header: 'Location Name', field: 'locationName', width: 300},
                   {header: 'Valid', field: 'isValid', width: 50}
      ]
   };

   this.settingDischargePort = {
        id          : 'cbDischargePort',
        type        : 'search enter', // search | select | select input
        url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findLikeLocationNameOrLocationCodeIsValid/*/{query}',
        maxChars    : 3,
        template    : 'grid', // default
        placeholder : '-- Next Port --',
        title       : 'locationName',
        description  : '',
        isMark      : true,
        columns     : [
                    {header: 'Location Code', field: 'locationCode', width: 100},
                    {header: 'Location Name', field: 'locationName', width: 300},
                    {header: 'Valid', field: 'isValid', width: 50}
        ]
    };


    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 100},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingTranshipmentGrid = {
      id : 'tGrid',
      url: this.configService.config.BASE_API.toString() + "/MasterBookings/getBookingsTranshipment",
      page: 10,
      columns: [
                  {header: 'Original B/L No', field: 'originalBlNo', width: 50},
                  {header: 'Transhipment B/L No', field: 'transhipmentBlNo', width: 150},
                  {header: 'Shipper', field: 'shipperName', width: 150,},
                  {header: 'Load Port', field: 'polName', width: 100,},
                  {header: 'Port of Transhipment', field: 'pot1Name', width: 100},
                  {header: 'Next Port', field: 'podName', width: 100},
                  {header: 'Vessel Source', field: 'vesselSource', width: 100},
                  {header: 'Voyage Source', field: 'voyageSource', width: 100},
                  {header: 'First Vessel Date', field: 'vesselSourceETD', width: 100},
                  {header: 'Summary Container', field: 'summaryContainer', width: 100},
                  {header: 'D20', field: 'd20', width: 100},
                  {header: 'H20', field: 'h20', width: 100},
                  {header: 'D40', field: 'd40', width: 100},
                  {header: 'H40', field: 'h40', width: 100},
                  {header: 'D45', field: 'd45', width: 100},
                  {header: 'H45', field: 'h45', width: 100},
                  {header: 'R20', field: 'r20', width: 100},
                  {header: 'RH20', field: 'rh20', width: 100},
                  {header: 'R40', field: 'r40', width: 100},
                  {header: 'RH40', field: 'rh40', width: 100},
                  {header: 'R45', field: 'r45', width: 100},
                  {header: 'RH45', field: 'rh45', width: 100},
                  {header: 'COC', field: 'coc', width: 100},
                  {header: 'COC Local', field: 'cocLocal', width: 100},
                  {header: 'COC T/S', field: 'cocTs', width: 100},
                  {header: 'COC T/S Taken', field: 'cocTsBooked', width: 100},
                  {header: 'Balance', field: 'balance', width: 100},
                  {header: 'Vessel Suggested', field: 'vesselName', width: 200, editType:'combo',
                  maxCharsCombo:0,
                  urlCombo:this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
                  titleCombo:'vesselName',
                  maxChars:0,
                  columnsCombo:[
                    {header: 'Vessel Code', field: 'vesselCode', width: 100},
                    {header: 'Vessel Name', field: 'vesselName', width: 200},
                    {header: 'Valid', field: 'isValid', width: 50}
                  ]},
                  {header: 'Voyage Suggested', field: 'voyage', width: 150, editType:'combo',
                  maxCharsCombo:0,
                  urlCombo:'',
                  titleCombo:'voyage',
                  maxChars:0,
                  columnsCombo:[
                    {header: 'Voyage', field: 'voyage', width: 100},
                    {header: 'Bound', field: 'bound', width: 100},
                    {header: 'Service', field: 'serviceCode', width: 100}
                  ]},
                  {header: 'Voyage Suggested', field: 'voyage', width: 100},
                  {header: 'Departure Date', field: 'vesselSuggestedETD', width: 100},
                  {header: 'Service', field: 'service', width: 100},
                  {header: 'Status', field: 'status', width: 100}
      ],
      buttons: [ { name: 'Confirm', event: 'confirm', enabled: true } ],
      allowButtons: true,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns:'originalBlNo',
      sortingDirection: 'ASC',
      editable: true
    }

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    $('.menu .item')
    .tab()
    ;

    this.model = new Param;
    this.model.officeCode = this.officeCode;

    this.disableToolbarButtons = 'print';
  }


  // event
  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'pending' :
        this.formMode = "Pending";
        break;
      case 'nominate' :
        this.formMode = "Nominate";
        break;
      case 'retrieve' :
        this.handleRetrieve();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }

  eventMessage(event){}

  changeEventLoadPort(event) {
    // console.log(event);
    if (event.locationCode != null) {
       this.model.loadPort = event.locationCode;
    } else {
       this.model.loadPort = '';
    }
  }

  changeEventDischargePort(event) {
    // console.log(event);
    if (event.locationCode != null) {
       this.model.nextPort = event.locationCode;
    } else {
       this.model.nextPort = '';
    }
  }

  changeEventVessel(event){
    if(event.vesselId != null){

      //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/O/{query}/"+this.officeCode);
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.officeCode + "/" + event.vesselId + "/O/voyage_like={query}");
      this.cbVoyage.setValue("");

      this.model.vesselId = event.vesselId;
      this.model.vesselCode = event.vesselCode;

    } else {
      this.model.vesselId = "";
      this.model.vesselCode = "";
    }
  }

  changeEventVoyage(event){}

  eventTranshipmentGrid(event){
    // console.log(event);

    switch (event.split(".")[0]) {
      case 'selected':
        break;
      case 'click' :
        break;
      case 'AfterEdit':
        var no = Number.parseInt(event.split(".")[1])
        var vesselId = this.transhipmentGrid.getValue(no,"vesselId")
        // console.log('vessel id => ' + vesselId);
        this.transhipmentGrid.setUrlCombo(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.officeCode + "/" + vesselId + "/O/voyage_like={query}",no,"voyage");
        //this.transhipmentGrid.setUrlCombo(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.officeCode + "/" + event.vesselId + "/O/voyage_like={query}",no);
        break;
      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          if(arr[0] == "confirm"){
            // console.log('confirm process');
            //this.handleUpdate();
            // console.log(arr[1]);
            var no = Number.parseInt(arr[1])
            let xx = this.transhipmentGrid.listStore.store[no-1]
            // console.log(xx);

            this.handleConfirm();

          }else if(arr[0] == "dblClick"){
          }else if(arr[0] == "footer"){
            // console.log(arr[1].toString());
          }

        }

        break;
    }
  }

  infoGridDetails(event){}

  //dialog function
  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  onTab(){

  }

  changeStatus(event){
    // console.log(event.target.value);
    this.model.status =  event.target.value;
  }

  handleRetrieve(){
    if(this.formMode == "Nominate"){
      this.model.method = 'Nominate'
    }else{
      this.model.method = 'Pending'
    }
    // console.log(this.model);
    this.transhipmentGrid.setBody(this.model);
    this.transhipmentGrid.loadData();
  }

  handleConfirm() {

  }


}
