import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { ReportPrint } from '../shared/index';
import { Router } from '@angular/router';
declare  var $: any;

export class ReportParam {
  vesselId = '';
  voyage = '';
  bound = 'O';
  pol = '';
  constructor() { }
}

@Component({
  selector: 'app-report-cll-multiple-dg-list-page',
  templateUrl: './report-cll-multiple-dg-list-page.component.html',
  styleUrls: ['./report-cll-multiple-dg-list-page.component.css']
})

export class ReportCllMultipleDGListPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort: ComboPluginComponent;
  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;
  isError = false;
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingLoadPort;
  validatorRules = {
    vesselId: {
      rules: [{
        type  : 'empty',
        prompt: 'Please input Vessel.'
      }]
    },
    voyage: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Voyage.'
      }]
    },
    bound: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Bound.'
      }]
    },
    pol: {
      rules: [{
        type: 'empty',
        prompt: 'Please input Loading Port.'
      }]
    },
  };

  param = new ReportParam();

  constructor(private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Report CLL Multiple DG List'
    }

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }

    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControlExcludeFreightGroup/locationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
        {header: 'Location Id', field: 'locationId', width: 100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.param = new ReportParam();
  }

  toolbarEvent(event) {
    // // console.log('event : ' + event);
    switch (event) {
      case 'print' :
        this.handlePrint();
        break;
      case 'cancel' :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeEventVessel(event) {
    if(event != null && event != '' && event != undefined && String(event) != 'NaN') {
      this.param.vesselId = event.vesselId;
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' + this.param.vesselId + '/' + this.param.bound.toUpperCase() + '/{query}/' + this.cookieService.getDefaultLocationCode());
    }else{
      this.param.vesselId = '';
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }

  changeEventVoyage(event) {
    if(event != null && event != '' && event != undefined && String(event) != 'NaN') {
      this.param.voyage = event.voyage;
    }else{
      this.param.voyage = '';
    }
  }

  changeEventLoadPort(event) {
    if(event != null && event != '' && event != undefined && String(event) != 'NaN') {
      this.param.pol = event.locationCode;
    }else{
      this.param.pol = '';
    }
  }

  handleCancel() {
    this.isError = false;
    this.param = new ReportParam();
    this.cbVessel.setUrl('');
    this.cbVessel.setValue('');
    this.cbVoyage.setUrl('');
    this.cbVoyage.setValue('');
    this.cbLoadPort.setValue('');
    this.cbLoadPort.setValue('');
  }

  handlePrint() {
    this.resetError();
    this.isError = this.onValidate(this.param);
    // console.log('this.iserror => ' + this.isError);
    if (!this.isError) {
      /*const urlHit = 'http://glossys.samudera.com:16000' + '/CLLReport/cllReeferReport/' + this.cookieService.getDefaultLocationCode() + '/' +  this.param.vesselId + '/' + this.param.voyage + '/' + this.param.bound + '/' + this.param.pol + '/' + this.cookieService.getName()
      window.open(urlHit);*/
    }
  }

  resetError(){
    this.param['error-vesselId'] = '';
    this.param['error-voyage'] = '';
    this.param['error-bound'] = '';
    this.param['error-pol'] = '';
  }
}
