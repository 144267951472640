import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import {splitBL, gridAfterSplit, gridheaderSplit} from "../shared/models/splitBL";
import {isString} from "util";
import {Z_ASCII} from "zlib";
declare  var $:any;


export class BLSplit{
  bookingNo:string='';
  splitQty :number;
  lblQty:string='';
  sumGridQty = 0;
  bNoNew:string='';
  containerTypeNew:string='';
  containerSizeNew=0;
  qtyNew = 0;
  aftersplitQty=0;
  brootbno = '';



  //bookingmsg:boolean=false;
  //destinationVoyage:string='';
  //destinationBound:string='';


  constructor(){};
}

@Component({
  selector: 'app-transaction-bl-split-page',
  templateUrl: './transaction-bl-split-page.component.html',
  styleUrls: ['./transaction-bl-split-page.component.css']
})

export class TransactionBLSplitPageComponent extends Validator  implements OnInit, AfterViewInit {
  validatorRules: any;


  dialog:any;

  @ViewChild('gridQty') gridQty:GridPluginComponent;
  @ViewChild('gridMain') gridMain:GridPluginComponent;
  @ViewChild('gridSplitDetail') gridSplitDetail:GridPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';
  typeSize = ["NORMAL","REEFER","PLATFORM","REEFER DRY","OPEN TOP","TANK"];

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGridQty;
  settingGridMain;
  settingGridSplit;
  isLoad : boolean = false;
  lock:boolean=true;
  splitDetails=null;
  splitStore = [];
  cntSz = [];
  settingGridSplitDetail;







  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;
  //lock:boolean=false;
  isType:string = '';
  isDefault:boolean=false;
  retLock = false;
  isAZ:boolean=false;
  isNumber:boolean=false;
  freight:boolean=false;
  prorate:boolean=false;
  target:string = '';
  model = new splitBL();
  modelA= new gridheaderSplit;
  modelAfterSplit =new gridAfterSplit;
  private trimPrefix: string;
  private office: any;
  private URL_Retrieve: string;

  setValidatorSplit(){
    this.validatorRules = {
      blNo: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Booking Number. <br />"
          },
          {
            type: "min-length[10]",
            prompt: "Minimum length booking number is 16 characters, Maximum 17 Characters in Total."
          }
        ]
      },

    }
  }


  private kesp: any;
  private modelBooking: any;
  private resp: any;
  private char: any;
  private A: any;
  private qty1: number;
  private msg: string;



  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'retrieve', icon: 'search'},
        {name: 'Split', event: 'split', icon: 'random'},
        {name: 'Save', event: 'save', icon: 'save'},
        {name: 'Cancel', event: 'cancel', icon: 'remove'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'BL Split'
    }

    this.settingGridQty={
      id: 'gridQty',
      url: '',//this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralHistoryById',
      //page: ,
      columns: [
        {header: 'Booking Ref', field: 'blNo', width: 250},
        {header: 'D20', field:'D20', width:70},
        {header: 'R20', field: 'R20', width: 70},
        {header: 'HC20', field: 'HC20', width: 70},
        {header: 'RH20', field: 'RH20', width:70},
        {header: 'D40', field: 'D40', width: 70},
        {header: 'R40', field:'R40', width:70},
        {header: 'HC40', field: 'HC40', width: 70},
        {header: 'RH40', field: 'RH40', width: 70},
        {header: 'D45', field: 'D45', width: 70},
        {header: 'R45', field: 'R45', width: 70},
        {header: 'HC45', field:'HC45', width:70},
        {header: 'RH45', field: 'RH45', width: 70},
        {header: 'TK20', field: 'TK20', width: 70},
        {header: 'TK40', field: 'TK40', width: 70},
        {header: 'TK45', field: 'TK45', width:70},

      ],
      buttons: [

      ],
      //store: this.listStore,
      height:40,
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.settingGridSplitDetail={
      id: 'gridSplitDetail',
      url:'',// this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralHistoryById',
      page: 4,
      columns: [
        {header: 'Main B/L No', field: 'blNo', width: 250},
        {header: 'D20', field:'D20', width:70},
        {header: 'R20', field: 'R20', width: 70},
        {header: 'HC20', field: 'HC20', width: 70},
        {header: 'RH20', field: 'RH20', width:70},
        {header: 'D40', field: 'D40', width: 70},
        {header: 'R40', field:'R40', width:70},
        {header: 'HC40', field: 'HC40', width: 70},
        {header: 'RH40', field: 'RH40', width: 70},
        {header: 'D45', field: 'D45', width: 70},
        {header: 'R45', field: 'R45', width: 70},
        {header: 'HC45', field:'HC45', width:70},
        {header: 'RH45', field: 'RH45', width: 70},
        {header: 'TK20', field: 'TK20', width: 70},
        {header: 'TK40', field: 'TK40', width: 70},
        {header: 'TK45', field: 'TK45', width:70},

      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      height:40,
      enableSorting: false,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC'
    }

    this.settingGridMain={
      id: 'gridMain',
      url: '',//this.configService.config.BASE_API.toString() + '/MasterLocations/findGeneralHistoryById',
      page: 4,
      columns: [
        {header: 'Split BL No', field: 'blNo', width: 250},
        {header: 'D20', field:'D20', width:70, editType:'number'},
        {header: 'R20', field: 'R20', width: 70, editType:'number'},
        {header: 'HC20', field: 'HC20', width: 70, editType:'number'},
        {header: 'RH20', field: 'RH20', width:70, editType:'number'},
        {header: 'D40', field: 'D40', width: 70, editType:'number'},
        {header: 'R40', field:'R40', width:70, editType:'number'},
        {header: 'HC40', field: 'HC40', width: 70, editType:'number'},
        {header: 'RH40', field: 'RH40', width: 70, editType:'number'},
        {header: 'D45', field: 'D45', width: 70, editType:'number'},
        {header: 'R45', field: 'R45', width: 70, editType:'number'},
        {header: 'HC45', field:'HC45', width:70, editType:'number'},
        {header: 'RH45', field: 'RH45', width: 70, editType:'number'},
        {header: 'TK20', field: 'TK20', width: 70, editType:'number'},
        {header: 'TK40', field: 'TK40', width: 70, editType:'number'},
        {header: 'TK45', field: 'TK45', width:70, editType:'number'},

      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns: 'blNo',
      sortingDirection: 'ASC',
      editable:true
    }

    // this.model.splitQty=0;

    this.modelAfterSplit.aftersplitQty=0;
    //this.model.bookingNo='';
    //this.model.lblQty = '';
    //this.model.sumGridQty = 0;
    //set default voyage to *
    //this.model.voyage= "*";
  }
  ngOnInit() {

  }

  ngAfterViewInit() :void {
    $('#gridHeader').find('th.collapsing').find('div.checkbox').remove();
    var self = this;
    this.gridQty.search = '';
    this.gridQty.onFirst();
    this.freight=true;
    //this.isAZ=true;
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        this.handleRetrieve();
        break;
      case 'save' :
        this.handleSave();
        break;
      case 'cancel':
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case 'split' :
        this.handleSplit();
        break;
    }
  }
  eventMessage(event){
    if(event != ''){
      eval(event);
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeType(event){}

  gridEvent(event){
    // console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case 'click' :
        //this.selectedListMod(event);
        // this.gridSailingDetail.onClear();
        let dt = this.gridSplitDetail.getSelectedValues()[0];
        this.getSailingDetail(dt.vesselId.trim(),dt.voyage,dt.bound,dt.serviceCode);
        this.gridSplitDetail.clearSelectedValues();
        // console.log("Vessel Code nya :"+dt.vesselCode);

        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          // console.log("before split -> " + strEvent);
          // console.log("arr -> " + arr);
          // console.log("arr-split -> " + arr[0]);
          // console.log("arr-split -> " + arr[1]);
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;
            default:
              break;
          }

        }

        break;
    }

    /*switch (event.split(".")[0]) {
     case 'selected':
     if(this.gridQty.getSelectedValues().length > 0){
     this.disableToolbarButtons = "";
     }else{
     this.disableToolbarButtons = "retrieve";
     }
     break;

     case 'click' :
     if(this.gridQty.getSelectedValues().length > 0){
     this.disableToolbarButtons = "";
     }else{
     this.disableToolbarButtons = "retrieve";
     }



     this.disableToolbarButtons = "";
     //this.invisibleToolbarButtons = "create,history,print";
     //this.modeForm = true;
     this.modeToolbar = false;

     let dt = this.gridQty.getSelectedValues()[0];
     let custId:string = String(dt.customerId).trim();
     //this.onRetrieve(custId);

     this.retLock = true;
     // this.gridDetails.disable();
     break;

     default:
     break;

     }*/
  }
  infoGrid(event){
    this.info = event;

  }

  doUpdateHeaderTable(){

    // $('#gridHeader-table').each(function(i) {

    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingByNo/'+this.office.officeCode+'/'+this.model.blNo.toUpperCase()).subscribe((resp) =>{

      if(resp.ok){

        var store3 = [];
        var rowGrid = $('#gridQty-table tr').length-1;
        var rowSplitore = $('#tblSplit tr').length-1;

        var objsb = {};
        var qtyNew= [];
        var lsz = 0;
        var attrGrid =[];

        // console.log('rowsplit length '+rowSplitore);

        if(Object.keys(resp.json()['content']).length > 0){


          // console.log('After Clear');
          store3 = resp.json()['content'];
          // console.log(resp.json()['content']);
          store3.forEach(sb=>{
            //this.gridHeader.listStore.addData(sb);
            // console.log(sb);
          });
          //// console.log(store3[1].qty);
          for(var t = 0;t< rowGrid;t++){
            // console.log('Bagian yang eror  ' +resp.json()['content'][t].bNo);
            attrGrid.push({
              bNo : resp.json()['content'][t].blNo,
              containerSize: resp.json()['content'][t].containerSize,
              containerType : resp.json()['content'][t].containerType,
              qty: resp.json()['content'][t].qty
            });

          }


          for(var g = 1;g<=rowSplitore;g++){
            lsz = $('#quanty_'+g).val();
            var tableStore = [];
            for(var i = 0;i< rowGrid;i++){

              // console.log('loop yang ke'+lsz);
              var spanValQty = attrGrid[i]['qty']; //resp.json()['content'][i].qty;
              var spanValSz =  attrGrid[i]['containerSize'];//resp.json()['content'][i].containerSize;
              qtyNew[i]=resp.json()['content'][i].qty;

              if (Math.abs(lsz) > 0){

                if(spanValSz == $('#cnSize_'+g).val() && (spanValQty > 0 || spanValQty != '0')){

                  attrGrid[i]['qty'] = parseInt(attrGrid[i]['qty']) - Math.abs(lsz);
                  lsz =   Math.abs(lsz) - parseInt(spanValQty);

                  // console.log('flagGrid '+attrGrid[i]['qty']+':'+lsz);

                  if(attrGrid[i]['qty'] < 0 || attrGrid[i]['qty'] == 0){


                    attrGrid[i]['qty'] = 0;

                    // console.log('masuk ke kondisi 0');
                    // self.gridHeader.listStore.updateData(x,{})
                  }else{
                    attrGrid[i]['qty']  = Math.abs(lsz);
                  }

                }

              }

              tableStore.push({
                bNo: resp.json()['content'][i].bNo,
                containerType: resp.json()['content'][i].containerType,
                containerSize: resp.json()['content'][i].containerSize,
                qty: Math.abs(attrGrid[i]['qty'])
              });

            }

          }
          // console.log('After Calculate');
          this.gridQty.onClear();
          tableStore.forEach(sz=>{
            this.gridQty.listStore.addData(sz);
            // console.log(sz);
          });

          this.gridQty.loadData();
        }

      }

    });
  }



  gridEventDetails(event) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case 'selected':

        break;

      case 'click' :
        break;

      default:
        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;

            default:
              break;
          }

        }

        break;
    }

  }


  infoGridDetails(event) {
    this.info = event;
  }

  gridEventMain(event){}
  infoGridMain(event){
    this.info = event;

  }
  gridEventSplit(event){}
  infoGridSplit(event){}
  gridEventSplitDetail(event){

  }

  handleCancel(){
    this.gridMain.onClear();
    this.gridQty.onClear();
    this.gridSplitDetail.onClear();
    this.model.splitQty=0;
    this.model.aftersplitQty=0;
    this.freight=true;
  }

  handleSplit(){
    if($('#tblSplit tr').length > 0)

      this.setValidatorSplit();
    let isPassedValidationT:boolean = this.onValidate(this.model);
    if(!isPassedValidationT) {

      if ((this.model.splitQty) == 0 || (this.model.splitQty) < 0) {
        this.message('information', 'Information', 'Please Check character or number', 'okonly', {ok: ''});
      }

      else {
        this.disableToolbarButtons = "";
        this.model.aftersplitQty = (this.model.splitQty) + 1;
        // console.log('SplitValue ' + $('#splitType').val())

      }


      if (this.isAZ == true) {
        // console.log("ini if");
        let qty: number;
        qty = this.model.splitQty;
        // console.log("QTY " + qty + 1);
        let abc = [];
        let i = 0;
        let k = 0;
        let bl: string = 'A';
        let RR: string = '';
        let TK: string = '';

        k = bl.charCodeAt(i);
        TK = this.model.blNo.trim();


        // console.log("abc ", bl + 1);
        for (i = 0; qty > i; i++) {

          RR = String.fromCharCode(k);
          abc[i] = TK + RR;
          //// console.log("K= "+i+" " +k);
          // console.log("BL No " + i + " " + abc[i]);
          k = k + 1;

          //this.gridMain.url=this.URL_Retrieve;
          // untuk Grid Main atau grid 3


          this.modelAfterSplit = new gridAfterSplit();
          let data1 = new gridAfterSplit();

          data1.blNo = String(TK + RR);
          data1.D20 = Number(this.modelAfterSplit.D20);
          // console.log("123" + data1.D20);
          data1.R20 = Number(this.modelAfterSplit.R20);
          data1.HC20 = Number(this.modelAfterSplit.HC20);
          data1.RH20 = Number(this.modelAfterSplit.RH20);
          data1.D40 = Number(this.modelAfterSplit.D40);
          data1.R40 = Number(this.modelAfterSplit.R40);
          data1.HC20 = Number(this.modelAfterSplit.HC20);
          data1.D40 = Number(this.modelAfterSplit.D40);
          data1.R40 = Number(this.modelAfterSplit.R40);
          data1.HC40 = Number(this.modelAfterSplit.HC40);
          data1.RH40 = Number(this.modelAfterSplit.RH40);
          data1.D45 = Number(this.modelAfterSplit.D45);
          data1.R45 = Number(this.modelAfterSplit.R45);
          data1.HC45 = Number(this.modelAfterSplit.HC45);
          data1.RH45 = Number(this.modelAfterSplit.RH45);
          data1.TK20 = Number(this.modelAfterSplit.TK20);
          data1.TK40 = Number(this.modelAfterSplit.TK40);
          data1.TK45 = Number(this.modelAfterSplit.TK45);
          //data1.HC40=8;

          this.gridMain.listStore.addData(data1);
          this.gridMain.url='';
          this.gridMain.loadData();
          //this.splitStore.push(this.modelA);


// untuk grid kedua atau grid header
          var URL_Retrieve = "http://localhost:16000/blsplit/GetBlSplit/" + this.model.blNo.trim() + '/1/10/ASC/blno';
          this.isLoad = true;
          this.gridSplitDetail.url = URL_Retrieve;
          this.model.aftersplitQty = qty + 1;

          this.gridSplitDetail.loadData();
          this.model.gridheaderSplit = [];
          this.model.gridheaderSplit = this.gridMain.listStore.store;
          this.genericService.GET(URL_Retrieve).subscribe((resp) => {
            if (resp.ok) {
              this.modelA = new gridheaderSplit;
              this.modelA.blNo = resp.json()['content'][0]['blNo'];
              this.modelA.D20 = resp.json()['content'][0]['D20'];
              this.modelA.R20 = resp.json()['content'][0]['R20'];
              this.modelA.HC20 = resp.json()['content'][0]['HC20'];
              this.modelA.D40 = resp.json()['content'][0]['D40'];
              this.modelA.R40 = resp.json()['content'][0]['R40'];
              this.modelA.HC40 = resp.json()['content'][0]['HC40'];
              this.modelA.RH40 = resp.json()['content'][0]['RH40'];
              this.modelA.D45 = resp.json()['content'][0]['D45'];
              this.modelA.R45 = resp.json()['content'][0]['R45'];
              this.modelA.HC45 = resp.json()['content'][0]['HC45'];
              this.modelA.RH45 = resp.json()['content'][0]['RH45'];
              this.modelA.TK20 = resp.json()['content'][0]['TK20'];
              this.modelA.TK40 = resp.json()['content'][0]['TK40'];
              this.modelA.TK45 = resp.json()['content'][0]['TK45'];
              //// console.log("ada isinya gk ya" +this.gridSplitDetail.listStore.store);


              // console.log(this.modelA);

            }
          })


          /*let data = new splitBL();
           data.blNo = String(TK +RR);
           // console.log("tes "+data.blNo);
           data.D20= Number(this.model.D20);
           data.R20= Number(this.model.R20);
           data.HC20= Number(this.model.HC20);
           data.RH20= Number(this.model.RH20);
           data.D40= Number(this.model.D40);
           data.R40= Number(this.model.R40);
           data.HC20= Number(this.model.HC20);
           data.D40= Number(this.model.D40);
           data.R40= Number(this.model.R40);
           data.HC40= Number(this.model.HC40);
           data.RH40= Number(this.model.RH40);
           data.D45= Number(this.model.D45);
           data.R45= Number(this.model.R45);
           data.HC45= Number(this.model.HC45);
           data.RH45= Number(this.model.RH45);
           data.TK20= Number(this.model.TK20);
           data.TK40= Number(this.model.TK40);
           data.TK45= Number(this.model.TK45);





           this.gridMain.listStore.addData(data);
           // this.gridMain.url = '';
           this.gridMain.loadData();
           this.gridMain.lock;
           this.gridMain.dataSelect;


           //  this.gridcontainer.clearSelectedValues();
           this.model = new splitBL;
           this.lock=false;*/


          //// console.log("Haha : " + RR);


          //if(this.cntOwner != 'SLOT')
          //this.typeSize = ["NORMAL","REEFER","PLATFORM","REEFER DRY","OPEN TOP","TANK"];
          //else
          //this.typeSize = ["NORMAL","REEFER","TANK"];


        }
        this.model.splitQty = 0;
      }else {
        // console.log("test");
        let qty:number=0;
        qty=this.model.splitQty;

        var URL_RETRIEVENUMBER = "http://localhost:16000/blsplit/GetBLNumber/1/10/ASC/blNo";
        // this.isLoad = true;
        this.gridMain.url = URL_RETRIEVENUMBER;
        this.model.aftersplitQty = qty + 1;
        let data1;
        ///this.gridMain.loadData();
        this.model.gridAfterSplit = [];
        this.model.gridAfterSplit = this.gridMain.listStore.store;
        this.genericService.GET(URL_RETRIEVENUMBER).subscribe((resp) => {
          if (resp.ok) {
            let i=0;



            //// console.log("total1 : "+total1);
            // resp.json()['content'][0]['blNo'];
            for (i = 0; qty > i; i++) {
              this.modelAfterSplit  = new gridAfterSplit;
              this.modelAfterSplit.blNo = resp.json()['content']['blNo'];
              let data  = this.modelAfterSplit.blNo.substr(0,14);
              let data1 = Number(this.modelAfterSplit.blNo.substr(14,15));
              let total = qty+data1;
              let data2 = String(data+total);

              let data3 = new gridAfterSplit();
              data3.blNo = String(data2);
              // console.log("test : "+data2);
              this.gridMain.listStore.addData(data3);
              this.gridMain.url='';
              this.gridMain.loadData();
            }
            //// console.log("ada isinya gk ya" +this.gridSplitDetail.listStore.store);


            // console.log(this.modelAfterSplit);

          }
        })

      }

    }
  }





  changeEventNo(event){

  }

  changeEventAZ(event){
    if(event.target.checked){
      this.isAZ=true;
      //this.model.blType = "S";
    }else{
      this.isAZ=false;
    }
  }

  generateBookingNumber(iteration:number,callback?: (data) => void){
    var maxSeq = 0;
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingByNo/'+this.office.officeCode+'/'+this.model.blNo.toUpperCase()).subscribe((resp) =>{


      if (this.resp.ok) {

        if (Object.keys(this.resp.json()['blNo']).length > 0) {
          this.splitDetails = new BLSplit();
          this.splitDetails.seqnoNew = iteration + 1;
          this.splitDetails.blNo = this.model.blNo.substring(0, 4) + this.resp.json()['blNo'];
          /* this.splitDetails.containerTypeNew = this.typeSize[0];
           this.splitDetails.containerSizeNew = this.cntSz[0];
           this.splitDetails.qtyNew = 0;
           this.splitDetails.bSeqPrefix = 0;
           this.splitDetails.brootbno = '';*/
          this.splitStore.push(this.splitDetails);

        }
      }

      if (callback)
        callback('');
    });


  }

  saveEvent() {
    this.model.blNo="";
    this.model.splitQty=0;
    this.model.aftersplitQty=0;
    this.gridMain.clearSelectedValues();
    this.gridSplitDetail.clearSelectedValues();
    this.gridQty.clearSelectedValues();
  }

  handleSave(){

    // console.log("main store : "+this.gridMain.listStore.store);
    // console.log(this.model);
    this.model.gridAfterSplit = [];
    this.model.gridAfterSplit = this.gridMain.listStore.store;
    this.genericService.POST('http://localhost:16000/blsplit/savesplit', this.model).subscribe((resp) => {


      // console.log(this.modelAfterSplit);


       this.message('information','information','Split Successfully','okonly',{ok: 'this.updateEvent()'});


      this.gridSplitDetail.onClear();
      this.gridMain.onClear();
      this.gridQty.onClear();
      this.model.aftersplitQty=0;
      this.model.splitQty=0;
    })
    //this.updateEvent();
  }

  updateEvent(){
    /*// console.log(this.model);
    this.model.gridAfterSplit = [];
    this.model.gridAfterSplit = this.gridMain.listStore.store;
    this.genericService.POST('http://localhost:16000/blsplit/update', this.model).subscribe((resp) => {


      // console.log(this.modelAfterSplit);


      this.message('information','information','Split Successfully','okonly',{ok: 'this.saveEvent()'});


      this.gridSplitDetail.onClear();
      this.gridMain.onClear();
      this.gridQty.onClear();
    })*/
  }



  handleRetrieve(){

    var result = true;

    var warnError = 'The booking number you have supplied (44) doesn`t seems to be a valid booking number.<br/>';
    warnError = warnError + 'A valid booking number (min. 16 characters, max. 17 characters in total) <br/>will consist od the following fields: <br/>----------------------------------------------------------------------------- ';
    warnError = warnError + '<br/>1) Prefix: 4 characters';
    warnError = warnError + '<br/>2) Port of Loading: 2 characters';
    warnError = warnError + '<br/>3) Port of Discharge: 3 characters';
    warnError = warnError + '<br/>4) Booking Type: 1 characters';
    warnError = warnError + '<br/>5) A-Z alphabet: 2 characters';
    warnError = warnError + '<br/>6) Running Number: 4 characters';
    warnError = warnError + '<br/>7) Suffix (Optional): 1 characters';

    // console.log("retrieve");

    this.setValidatorSplit();

    this.model.blNo = this.model.blNo.trim();

    let isPassedValidation:boolean = this.onValidate(this.model);
    if(!isPassedValidation){





      this.trimPrefix = this.model.blNo;
      this.trimPrefix = this.trimPrefix.substring(0,4);
      // console.log(this.trimPrefix);


      if(this.model.blNo.length < 15 && this.model.blNo.length > 0){


        // console.log('blNo ');
        this.message('information','Information',warnError,'okonly',{ok:''});

        result = false;

      }else {

        var URL_Retrieve = "http://localhost:16000/blsplit/GetBlSplit/" + this.model.blNo.trim()+'/1/10/ASC/blno';
        this.isLoad = true;
        this.gridQty.url = URL_Retrieve;
        this.gridSplitDetail.url = URL_Retrieve;
        //this.gridSplitDetail.url = URL_Retrieve;
        this.gridQty.loadData();
        this.gridSplitDetail.loadData();

        var URL_GRIDMAIN = "http://localhost:16000/blsplit/GetBlSplitMain/" + this.model.blNo.trim()+"/1/10";



        this.genericService.GET(URL_GRIDMAIN).subscribe((resp)=>{
          if(resp.ok){
            this.model.blNo=resp.json()['content'][0]['blNo'];
            this.model.D20=resp.json()['content'][0]['D20'];
            this.model.R20=resp.json()['content'][0]['R20'];
            this.model.HC20=resp.json()['content'][0]['HC20'];
            this.model.D40=resp.json()['content'][0]['D40'];
            this.model.R40=resp.json()['content'][0]['R40'];
            this.model.HC40=resp.json()['content'][0]['HC40'];
            this.model.RH40=resp.json()['content'][0]['RH40'];
            this.model.D45=resp.json()['content'][0]['D45'];
            this.model.R45=resp.json()['content'][0]['R45'];
            this.model.HC45=resp.json()['content'][0]['HC45'];
            this.model.RH45=resp.json()['content'][0]['RH45'];
            this.model.TK20=resp.json()['content'][0]['TK20'];
            this.model.TK40=resp.json()['content'][0]['TK40'];
            this.model.TK45=resp.json()['content'][0]['TK45'];

            // console.log(this.model);
            this.gridMain.url = URL_GRIDMAIN;
            this.gridMain.loadData();
          }
        })


        //this.gridMain.url=URL_Retrieve;

        //this.gridMain.loadData();
      }
      this.disableToolbarButtons="";
      this.splitStore = [];
      //this.model.splitQty = 0;
      this.model.aftersplitQty = 0;

      $("input[name=splitType][value=0]").prop('checked', false);
      this.getBookingNo(this.model.blNo,'RET' );

    }

  }


  convertToNumberingScheme(number) {
    var baseChar = ("A").charCodeAt(0),
      letters  = "";

    do {
      number -= 1;
      letters = String.fromCharCode(baseChar + (number % 26)) + letters;
      number = (number / 26) >> 0;
    } while(number > 0);

    return letters.toUpperCase();
  }

  selectedListMod(value){
    this.gridQty.getSelectedValues().forEach(gs=>{
      if(value.split("-")[1] !=  gs.seqno){
        gs['select'] = '';
        this.gridQty.getSelectedValues().splice(this.gridQty.getSelectedValues().indexOf(gs),1);
      }
    });
    if(value.split(".")[1].split("-")[0] == "checked"){
      // console.log("size "+this.gridQty.getSelectedValues().length);
      // console.log("name "+this.gridQty.getSelectedValues()[0].portofLoadingName);
    }
  }

  generateBookingNumberByPrefix(callback?: (data) => void){

    var maxSeq = 0;
    //this.genericService.GET(this.configService.config.BASE_API.toString() + this.model.blNo.toUpperCase()).subscribe((kesp) =>{
    //this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingNoByPrefix/'+this.office.officeCode+'/'+this.modelBooking.bookingNo.toUpperCase()).subscribe((kesp) =>{
    this.genericService.GET(this.configService.config.BASE_API.toString() + '/MasterBookings/getBookingByNo/'+this.office.officeCode+'/'+this.model.blNo.toUpperCase()).subscribe((resp) =>{



      if(this.kesp.ok){


        var kesult = [];
        var totSeq = 0;

        // console.log('length'+Object.keys(this.kesp.json()['content']).length );

        if(Object.keys(this.kesp.json()['content']).length > 0){
          // console.log('wajkajja');
          // console.log(this.kesp.json()['content'][0].b_sequence_prefix);
          // console.log(this.kesp.json()['content'][1].b_sequence_prefix);

          for(var i =0;i<Object.keys(this.kesp.json()['content']).length;i++){
            // console.log('kenapa' + this.kesp.json()['content'][i].b_sequence_prefix);
            kesult.push(this.kesp.json()['content'][i].b_sequence_prefix);
          }

          // console.log(kesult);
          kesult = kesult.sort();

          var maxKesult = Math.max.apply(Math,kesult);
          // console.log('maxkesult'+maxKesult)
          var cKesult ;
          var lnKesult = cKesult.length;
          // console.log('len ckesult'+lnKesult);
          // console.log(cKesult);
          if(lnKesult > 0){
            //parseInt(this.model.splitQty);
            for(var j=0;j<this.model.splitQty;j++){
              // console.log('masuk yang pertama'+j);
              var addSeq = cKesult[j];

              if((j+1)>lnKesult){
                addSeq = ((j+1)-lnKesult)+maxKesult;

              }//end if

              this.splitDetails = new BLSplit();
              this.splitDetails.seqnoNew = j+1;
              //  this.splitDetails.bNoNew = this.modelBooking.bookingNo+this.convertToNumberingScheme(addSeq);//String.fromCharCode(j+65);
              this.splitDetails.blNo = this.kesp.json()['content'][0].beno+this.convertToNumberingScheme(addSeq);//String.fromCharCode(j+65);
              // this.splitDetails.containerTypeNew = this.typeSize[0];
              //this.splitDetails.containerSizeNew = this.cntSz[0];
              //this.splitDetails.qtyNew = 0;
              this.splitDetails.bSeqPrefix = addSeq;
              this.splitDetails.brootbno = this.kesp.json()['content'][0].beno;
              this.splitStore.push(this.splitDetails);


            }//end for


          }//end if lnkesult
          else{

            totSeq = maxKesult + 1;

            for(var j=0;j<this.model.splitQty;j++){

              this.splitDetails = new BLSplit();
              this.splitDetails.seqnoNew = j+1;
              //this.splitDetails.bNoNew = this.modelBooking.bookingNo+this.convertToNumberingScheme(totSeq);//String.fromCharCode(j+65);
              this.splitDetails.blNo = this.kesp.json()['content'][0].beno+this.convertToNumberingScheme(totSeq);//String.fromCharCode(j+65);
              // this.splitDetails.containerTypeNew = this.typeSize[0];
              //this.splitDetails.containerSizeNew = this.cntSz[0];
              // this.splitDetails.qtyNew = 0;
              //this.splitDetails.bSeqPrefix = totSeq;
              this.splitDetails.brootbno = this.kesp.json()['content'][0].beno;
              this.splitStore.push(this.splitDetails);
              totSeq++;


            }//end for
          }//end else


        }else{

          totSeq =1 ;
          //parseInt(this.model.splitQty);
          for(var j=0;j<this.model.splitQty;j++){
            // console.log(this.model.blNo+String.fromCharCode(j+65));

            // console.log('masuk else sini')
            this.splitDetails = new BLSplit();
            this.splitDetails.seqnoNew = j+1;
            this.splitDetails.blNo = this.model.blNo.toUpperCase()+this.convertToNumberingScheme(totSeq);//String.fromCharCode(j+65);
            //this.splitDetails.bNoNew = kesp.json()['content'][0].beno+this.convertToNumberingScheme(totSeq);//String.fromCharCode(j+65);
            // this.splitDetails.containerTypeNew = this.typeSize[0];
            // this.splitDetails.containerSizeNew = this.cntSz[0];
            //this.splitDetails.qtyNew = 0;
            // this.splitDetails.bSeqPrefix = totSeq;
            // this.splitDetails.brootbno = this.model.blNo;
            this.splitStore.push(this.splitDetails);
            totSeq++;
          }

        }

      }

      if(callback)
        callback('');




    });




  }
  eventGridMain(event){
    // console.log("kejadin "+event);


    switch (event.split(".")[0]) {
      //switch (event.split(".")) {


      case 'selected':

        //// console.log(" tetststts     ");
        break;

      case 'click' :

        //let data= this.gridMain.getSelectedValues()[0];
        //data.D20 = this.gridMain.getSelectedValues()[0]['D20'];
        //data.R20 = this.gridMain.getSelectedValues()[0];
        //// console.log("test D20 : "+data.R20);

        //// console.log(" tetststts   edsds  ");

        /* this.gridMain.getSelectedValues()[0]["D20"];
         // console.log("testtttt : "+this.gridMain.getSelectedValues()[0]["D20"]);*/
        break;

      case 'AfterEdit' :

        //// console.log("Hallo "+ event);
        let stress:string = "";
        stress= event;
        let R:string = "";
        let Kol:string="";
        let Vl:string="";
        let J:number;
        let PP=[];
        let Hasil:number;
        let HA:number;
        //// console.log("Akhirnya "+ stress);
        J = stress.length;

        // console.log (" Hahah "+J);

        var URL_Retrieve = "http://localhost:16000/blsplit/GetBlSplit/" + this.model.blNo.trim()+'/1/10/ASC/blno';
        this.isLoad = true;
        this.gridSplitDetail.url = URL_Retrieve;
        //this.model.aftersplitQty = qty+1;

        this.gridSplitDetail.loadData();
        this.model.gridheaderSplit = [];
        this.model.gridheaderSplit=this.gridSplitDetail.listStore.store;
        this.genericService.GET(URL_Retrieve).subscribe((resp) => {
          if (resp.ok) {
            this.modelA = new gridheaderSplit;
            this.modelA.blNo = resp.json()['content'][0]['blNo'];
            this.modelA.D20 = resp.json()['content'][0]['D20'];
            this.modelA.R20 = resp.json()['content'][0]['R20'];
            this.modelA.HC20 = resp.json()['content'][0]['HC20'];
            this.modelA.D40 = resp.json()['content'][0]['D40'];
            this.modelA.R40 = resp.json()['content'][0]['R40'];
            this.modelA.HC40 = resp.json()['content'][0]['HC40'];
            this.modelA.RH40 = resp.json()['content'][0]['RH40'];
            this.modelA.D45 = resp.json()['content'][0]['D45'];
            this.modelA.R45 = resp.json()['content'][0]['R45'];
            this.modelA.HC45 = resp.json()['content'][0]['HC45'];
            this.modelA.RH45 = resp.json()['content'][0]['RH45'];
            this.modelA.TK20 = resp.json()['content'][0]['TK20'];
            this.modelA.TK40 = resp.json()['content'][0]['TK40'];
            this.modelA.TK45 = resp.json()['content'][0]['TK45'];

          }

        })
        //// console.log("Nih data    " +this.modelA.HC40);
        //// console.log("GGGGGGGG   " +this.modelA.HC40);

        // console.log("DDD20   " + this.modelA.D20);
        // console.log("RRR20   " + this.modelA.R20);
        // console.log("HHCC20   " + this.modelA.HC20);
        // console.log("HCC40   " + this.modelA.HC40);
        // console.log("RRHH40   " + this.modelA.RH40);
        // console.log("RHHH45   " + this.modelA.RH45);

        if(J==18){
          Hasil =0;
          //// console.log("Akhirnya Nilai "+ Vl);
          R=event.substring(10,11);
          Kol=event.substring(12,16);
          Vl=event.substring(17);
          // console.log("Akhirnya Row "+ R);
          // console.log("Akhirnya Kolom "+ Kol);
          // console.log("Akhirnya Nilai "+ Vl);

          PP[R]=Vl;
          // console.log("HHHHHH "+PP[R]);
          //PP[R]=Number(this.modelA.HC40)- Number(Vl);
          Hasil= Hasil+Number(PP[R]);
          // console.log( "PP0 "+Hasil);
          HA = Number(this.modelA.HC40)- Hasil;
          // console.log( "Total "+HA);




        }else {
          Hasil = 0;
          //// console.log("Akhirnya Nilai " + Vl);
          R=event.substring(10,11);
          Kol=event.substring(12,15);
          Vl=event.substring(16);
          // console.log("Akhirnya Row "+ R);
          // console.log("Akhirnya Kolom "+ Kol);
          // console.log("Akhirnya Nilai "+ Vl);
          //PP=Number(this.modelA.D20)- Number(Vl);
          PP[R]=Vl;
          // console.log("HHHHHH "+PP[R]);
          Hasil= Hasil+Number(PP[R]);
          // console.log( "PP1 "+Hasil);
          HA = Number(this.modelA.D20)- Hasil;
          // console.log( "Total "+HA);

          this.gridSplitDetail.listStore.store[0]["D20"];
          this.modelA = new gridheaderSplit;

          this.modelA.D20 = HA;
          this.gridSplitDetail.url='';
          this.gridSplitDetail.loadData();
        }



        break;




      default:
        let strEvent:string = event;
        if(strEvent != ''){

          // console.log ("Haaaloo dapet "+strEvent);
          let arr:string[] = strEvent.split('-');
          switch(arr[0]){
            case 'edit':
              break;
            case 'dblClick':
              break;

            default:
              break;
          }

        }

        break;
    }
  }

  private getBookingNo(blNo: string|{rules}|any, s: string) {

  }


  private absent() {

  }

  private getSailingDetail(s: string, voyage, bound: string|{rules}|any|((lower: any, upper: any, lowerOpen?: boolean, upperOpen?: boolean) => IDBKeyRange), serviceCode: string|{rules}|any) {

  }
}






