//CR001 Added Code for Remove Allocation data from Grid
//CR002 Update Code done for ref pls check withCoreection No CR002

import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { element } from '@angular/core/src/render3/instructions';
import { Alert } from 'selenium-webdriver';
declare  var $:any;

@Component({
  selector: 'app-transaction-ts-container-movement-page',
  templateUrl: './transaction-ts-container-movement-page.component.html',
  styleUrls: ['./transaction-ts-container-movement-page.component.css']
})

export class TransactionTSContainerMovementPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingGrid;

  isError: Boolean = false;


 validatorRules = {}

  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Retrieve', event: 'search', icon: 'search'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Container Movement'
    }


     this.settingGrid = {
      url: '',
      page: 10,
      columns: [
        {header: 'Container Number', field: 'containerNo', width: 100},  
        {header: 'Vessel Name', field: 'vesselName', width: 200},     
        {header: 'Voyage', field: 'voyage', width: 100},     
        {header: 'POL', field: 'polName', width: 200},
        {header: 'ETA/ETD', field: 'polETAETD', width: 100},   
        {header: 'Through OBL No.', field: 'obl', width: 100},   
        {header: 'POT', field: 'podName', width: 200},
        {header: 'ETA/ETD', field: 'potETAETD', width: 100},   
        {header: 'Memo BL No.', field: 'memoBL', width: 100},   
        {header: 'Remarks (POT)', field: 'remark', width: 100},   
        {header: 'POD', field: 'podName', width: 200},
        {header: 'ETA/ETD', field: 'podETAETD', width: 100},   
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns:'locationName',
      sortingDirection: 'ASC'
    }

   

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'search' :
        break;
      case 'cancel' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  gridEvent(event){
     console.log(event);
    switch (event.split(".")[0]) {      
      case 'selected':
        break;
      case 'click' ://CR002  
      break;       
      default:          
        let strEvent:string = event;        
          if(strEvent != ''){
            let arr:string[] = strEvent.split('-');
            switch(arr[0]){               
              case 'afterLoad':
              
                break;       //CR002               
              case 'dblClick':                   
              
                  break; //End CR002
              default:
                break;
            }            
        }       
          
        break;
    }
  }

  infoGrid(event){}


  onCancel(){
  }


}
