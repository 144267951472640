import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { ReportPrint,officeModel } from '../shared/index';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $: any;

export class ReportParam {
  periodFrom = ''
  periodTo = ''
  releaseAt = '*';
  stuffAt = '*';
  constructor() { }
}

@Component({
  selector: 'app-report-daily-empty-releasing-order-page',
  templateUrl: './report-daily-empty-releasing-order-page.component.html',
  styleUrls: ['./report-daily-empty-releasing-order-page.component.css']
})

export class ReportDailyEmptyReleasingOrderPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbReleaseAt') cbReleaseAt: ComboPluginComponent;
  @ViewChild('cbStuffAt') cbStuffAt: ComboPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';

  modeToolbar = false;
  isError = false;
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingReleaseAt;
  settingStuffAt;

  validatorRules = {};

  param = new ReportParam();
  office = new officeModel;

  constructor(private _http: HttpClient,private auth: Authorize, private genericService: GenericService, private genericUtil: GenericUtil, private router: Router, private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Print', event: 'print', icon: 'print'},
       // {name: 'PrintV2', event: 'printV2', icon: 'print'},
        {name: 'Preview', event: 'preview', icon: 'search'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Daily Empty Releasing Order Report'
    }

    this.settingReleaseAt = {
      id          : 'cbReleaseAt',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Release At --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Depo Code', field: 'depoCode', width: 150},
        {header: 'Depo Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }
    this.settingStuffAt = {
      id          : 'cbStuffAt',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findGeneralDepo/'+this.cookieService.getDefaultLocationCode()+'/*/*/{query}/1/5/*/*',
      //url         : '',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Stuff At --',
      title       : 'depoName',
      description  : '',
      columns     : [
        {header: 'Depo Code', field: 'depoCode', width: 150},
        {header: 'Depo Name', field: 'depoName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.param = new ReportParam();
    $('[data-toggle="datepicker"]').datepicker({
      autoHide: true,
      format: 'yyyy-mm-dd',
      zIndex: 2048,
    });

    $("#prevx").dialog({
      autoOpen: false,
      modal: true,
      height: 600
  });

  }

  toolbarEvent(event) {
    // // console.log('event : ' + event);
    switch (event) {
      case 'print' :
        this.handlePrint('print');
        break;
      case 'printV2' :
          this.handlePrintV2('print');
          break;

      case 'preview' :
          this.handlePrint('preview');
         // window.open("https://view.officeapps.live.com/op/embed.aspx?src=http://sg.glossys.samudera.id/doc/daily.xlsx");
         //var win = window.open();

          break;
      case 'cancel' :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }





  handleCancel() {
    this.isError = false;
    $('[data-toggle="datepicker"]').datepicker('reset');
    this.param = new ReportParam();
    //this.cbVessel.setUrl('');
    // this.cbVessel.setForceValue('');
    //this.cbVoyage.setUrl('');
    // this.cbVoyage.setForceValue('');
    //this.cbLoadPort.setForceValue('');
  }
  handlePrintV2(actionX){
    

    var nowDate = new Date();
    
    var timeNow =nowDate.getFullYear()+''+(nowDate.getMonth()+1)+''+nowDate.getDate()+''
                + nowDate.getHours()+''
                + nowDate.getMinutes()+''
                + nowDate.getSeconds();

                this.isError = this.onValidate(this.param);
               this.loading = true;


               console.log('this loading')

   // const setUrl =   'http://localhost:14000/BookingReport/dailyEmptyReleasingOrderV2/'+this.cookieService.getDefaultLocationCode()+'/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val() + '/' + this.param.releaseAt + '/' + this.param.stuffAt + '/' + actionX + '/' + timeNow;
   const setUrl =   this.configService.config.BASE_API.toString()+'?q=/BookingReport/dailyEmptyReleasingOrderV2/'+this.cookieService.getDefaultLocationCode()+'/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val() + '/' + this.param.releaseAt + '/' + this.param.stuffAt + '/' + actionX + '/' + timeNow;
    this._http.get(setUrl,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token':localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{
            
      var hostname = window.location.hostname;
      var protocol = window.location.protocol+'//';
      console.log('musk');
            
            if(res.size > 4138)

               if(actionX == 'print'){
                    FileSaver.saveAs(res, 'DailyEmptyReleasingOrderReport.xlsx');
                }
            else{
                     // console.log("musk");
                console.log('https://view.officeapps.live.com/op/embed.aspx?src='+protocol+'//'+hostname+'/previewDaily/DailyEmptyReleasingOrderReport_'+timeNow+'.xlsx');          
                       //win.document.write('<iframe width="560" height="315" src="//www.youtube.com/embed/mTWfqi3-3qU" frameborder="0" allowfullscreen></iframe>')
                     $("#prevx").dialog({
                      autoOpen: false,
                      modal: true,
                      height: $(window).height(),
                      width :$(window).width(),
                      open: function(ev, ui){
                               $('#myIframe').attr('src','https://view.officeapps.live.com/op/embed.aspx?src='+protocol+hostname+'/cll/previewDaily/DailyEmptyReleasingOrderReport_'+timeNow+'.xlsx').height($(window).height()-80).width($(window).width()-50);
                            }
                      });
            
                      console.log("test");
            
                      $('#prevx').dialog('open');
            
                    }
                  else
                    this.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});
            
                  this.loading = false;
                });

  }
  
  handlePrint(actionP) {
    this.resetError();

    var currentdate = new Date();
    var datetime =currentdate.getFullYear()+''+(currentdate.getMonth()+1)+''+currentdate.getDate()+''
                + currentdate.getHours()+''
                + currentdate.getMinutes()+''
                + currentdate.getSeconds();



    this.isError = this.onValidate(this.param);
    this.loading = true;
    const urlHit =   this.configService.config.BASE_API.toString()+'?q=/BookingReport/dailyEmptyReleasingOrder/'+this.cookieService.getDefaultLocationCode()+'/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val() + '/' + this.param.releaseAt + '/' + this.param.stuffAt + '/' + actionP + '/' + datetime;

    this._http.get(urlHit,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token':localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{

      var hostname = window.location.hostname;
      var protocol = window.location.protocol+'//';
      console.log('musk');

      if(res.size > 4138)
        if(actionP == 'print'){
          FileSaver.saveAs(res, 'DailyEmptyReleasingOrderReport.xlsx');
        }
        else{
         // console.log("musk");
          console.log('https://view.officeapps.live.com/op/embed.aspx?src='+protocol+'//'+hostname+'/previewDaily/DailyEmptyReleasingOrderReport_'+datetime+'.xlsx');          
           //win.document.write('<iframe width="560" height="315" src="//www.youtube.com/embed/mTWfqi3-3qU" frameborder="0" allowfullscreen></iframe>')
         $("#prevx").dialog({
          autoOpen: false,
          modal: true,
          height: $(window).height(),
          width :$(window).width(),
          open: function(ev, ui){
                   $('#myIframe').attr('src','https://view.officeapps.live.com/op/embed.aspx?src='+protocol+hostname+'/cll/previewDaily/DailyEmptyReleasingOrderReport_'+datetime+'.xlsx').height($(window).height()-80).width($(window).width()-50);
                }
          });

          console.log("test");

          $('#prevx').dialog('open');

        }
      else
        this.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});

      this.loading = false;
    });

    //  }
  }

  resetError(){
    this.param['error-vesselId'] = '';
    this.param['error-voyage'] = '';
    this.param['error-bound'] = '';
    this.param['error-pol'] = '';

  }

  changeEventReleaseAt(event) {
    // console.log(event);
    if (event.depoCode != null && event.depoCode != '' && event.depoCode != undefined) {
      this.param.releaseAt = event.depoCode;
    } else {
      this.param.releaseAt = '*';
    }
    this.cbReleaseAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode()+'/depoName={query}');
  }
  changeEventStuffAt(event) {
    // console.log(event);
    if (event.depoCode != null && event.depoCode != '' && event.depoCode != undefined) {
      this.param.stuffAt = event.depoCode;
    } else {
      this.param.stuffAt = '*';
    }
    this.cbStuffAt.setUrl(this.configService.config.BASE_API.toString() + '/LocalMasterDepos/findByComboBoxControl/'+this.cookieService.getDefaultLocationCode() + '/depoName={query}');
  }
}
