import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Authorize, Validator, ComboPluginComponent, GenericService,GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { officeModel } from '../shared/index';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $: any;


export class Parameter {
  vesselId = '';
  vesselName = '';
  vesselPSAName = '';
  voyage = '';
  bound = 'O';
  cOwner = 'SOC';

  constructor() { }
}


@Component({
  selector: 'app-transaction-auto-creation-of-bl-qlec-page',
  templateUrl: './transaction-auto-creation-of-bl-qlec-page.component.html',
  styleUrls: ['./transaction-auto-creation-of-bl-qlec-page.component.css']
})
export class TransactionAutoCreationofBLQlecPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;

  @ViewChild('rSoc') radioSoc:ElementRef;
  @ViewChild('rCoc') radioCoc:ElementRef;
  @ViewChild('rSlot') radioSlot:ElementRef;
  @ViewChild('rAll') radioAll:ElementRef;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = '';
  disableToolbarButtons = '';
  autoCreationResultMesssage = '';

  modeToolbar = false;

  labelToolbar = "AUTO CREATION OF BL (VIA CLL)";
  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;
  settingPDF;

  // types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new Parameter();
  office = new officeModel;
  userId:string = "";
  postData:any = {};
  hitUrl:string = this.configService.config.BASE_API.toString();

  validatorRules = {};

  runAutoCreation:Boolean = false;

  prompterType:string = 'information';
  prompterHeader:string = 'Information';
  prompterContent:string = '';
  prompterButtons = [{name:"ok", event:""}];


  constructor(private auth: Authorize,
              private genericService: GenericService,
              private genericUtil: GenericUtil,
              private router: Router,
              private configService: ConfigService,
              private cookieService: CookieService,
              private _http: HttpClient) {
    super();
    var officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
    if(officeCode == "SGSIN"){
      this.labelToolbar = "AUTO CREATION OF BL (QLEC)";
    }

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Create', event: 'create', icon: 'upload'},
        {name: 'Print', event: 'print', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar
    };

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 130},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    };

    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : true,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.firstInit();
  }

  firstInit(){
    this.office.officeCode = this.cookieService.getDefaultLocationCode();//localStorage.getItem("defaultLocationCode");
    this.office.officeId = this.cookieService.getCookie("defaultLocation").split("|")[3];
    this.office.officeName = this.cookieService.getCookie("defaultLocation").split("|")[0];
    this.userId = this.cookieService.getName();
    this.disableToolbarButtons = 'print';
  }

  toolbarEvent(event) {
    switch (event) {
      case 'create' :
        if(this.model.vesselId != "" && this.model.voyage != "" && this.model.cOwner != ""){
          this.postData = {};
          this.postData["officeCode"] = this.office.officeCode;
          this.postData["userCreated"] = this.userId;
          this.postData["createdDate"] = this.genericUtil.getPSIDate();
          this.postData["vesselId"] = this.model.vesselId;
          this.postData["vesselPSA"] = this.model.vesselPSAName;
          this.postData["voyage"] = this.model.voyage;
          this.postData["cOwnerShip"] = this.model.cOwner;
          this.postData["bound"] = "O";
          this.loading = true;

          //console.log(this.postData);
          //this.autoCreationProcess();
          this.matchingProcess();
        } else {
          this.message("information","Information","please insert vessel & voyage","okonly",{ok:""});
        }
        break;
      case 'print':
        this.printDiscrepancyReport();

        break;
      case 'cancel' :
        this.model = new Parameter;
        this.cbVessel.setForceValue("");
        this.cbVoyage.setForceValue("");
        this.radioAll.nativeElement.checked = "";
        this.radioSoc.nativeElement.checked = "checked";
        this.radioCoc.nativeElement.checked = "";
        this.radioSlot.nativeElement.checked = "";
        this.autoCreationResultMesssage = '';
        this.disableToolbarButtons = 'print';
        this.postData = {};
        this.runAutoCreation = false;

        break;
      case 'close' :
        // handle close event
        this.autoCreationResultMesssage = '';
        this.router.navigateByUrl('/main/home');
        break;
    }
  }
  eventMessage(event) {}

  message(txtIcon: string, txtHeader: string, txtContent: string, btns: string, eve: any) {
    this.dialog = {icon: txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  changeEventVessel(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/distinctVoyage/' +
        event.vesselId + '/' + 'O' + '/{query}/' + this.cookieService.getDefaultLocationCode()
      );

      this.model.vesselId = event.vesselId;
      this.model.vesselName = event.vesselName;
      this.model.vesselPSAName = event.psaVesselCode;

      this.cbVoyage.setValue('');
    } else {
      this.cbVoyage.setUrl('');
      this.cbVoyage.setValue('');
    }
  }
  changeEventVoyage(event) {
    if (event != null && event !== '' && event !== undefined && String(event) !== 'NaN') {
      this.model.voyage = event.voyage;
    } else {
      this.model.voyage = '';
    }
  }

  radioEvent(check:boolean, type:string) {
    //console.log("redio event "+ check + " - type : "+type );
    if(check){
      this.model.cOwner = type;
    }
  }

  matchingProcess(){
    var hitUrl = this.hitUrl + "?q=/ContainerLoadList/matching/QLEC";
    this._http.post(hitUrl,this.postData, { headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      if(resp["status"] == "ok"){
        var reportDate = this.postData["createdDate"].split(" ")[0].split("-")[2] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[1] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[0] + "X" + this.postData["createdDate"].split(" ")[1];
        var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
        var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/QlecDiscrepancyReport.pdf?sessionId="+resp["session-id"]+"&Date="+reportDate+"&userId="+this.userId;
        var fileName = "QLEC-Discrepancy-Report-"+this.userId+".pdf";

        var postDataX = {};
        postDataX["fileName"] = fileName;
        postDataX["url"] = jasperURL;

        var passList = [];
        if(resp["pass-list"] != undefined){
          passList = resp["pass-list"];
          if(passList.length > 0){
            this.runAutoCreation = true;
          }
        }

        this.genericService.POST(hitUrl,postDataX).subscribe((resp)=>{
          if(resp.ok){
            var result = resp.json();
    
            if(result["message"] == "success"){
              this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
              this.pdfContainer.showPDF();
            }
                
          }

          this.loading = false;
        });

      } else {
        this.loading = false;
        //this.message("information","Information",resp["message"],"okonly",{ok:""});
        this.showPrompterDialog("information","Information",resp["message"],[{name:"OK",event:""}]);
      }
    }, error=>{
      console.log(error);
      //this.message("information","Information","An error occurred while running auto creation process, please contact your administrator!<br>error status : "+error.statusText,"okonly",{ok:""});
      this.showPrompterDialog("information","Information","An error occurred while running auto creation process, please contact your administrator!<br>error status : "+error.statusText,[{name:"OK",event:""}]);
      this.loading = false;
    });
  }

  autoCreationProcess(){
    var hitUrl2 = this.hitUrl + "?q=/AutoCreation/qlec/bl";
    this.loading = true;
    this.autoCreationResultMesssage = "";
    //this.genericService.POST(hitUrl, this.postData).subscribe((resp)=>{
    this._http.post(hitUrl2,this.postData, { headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      console.log(resp);
      if(resp["message"] == "success"){
        var blCreated:any[] = resp["bl-created"];
        var blUpdated:any[] = resp["bl-updated"];
        var blFailed:any[] = resp["bl_failed"];
        var blFinished:any[] = resp["bl-finished"];
        if(blCreated.length > 0){
          this.autoCreationResultMesssage = this.autoCreationResultMesssage + blCreated.length + " BL Created <br>";
        }
        if(blUpdated.length > 0){
          this.autoCreationResultMesssage = this.autoCreationResultMesssage + blUpdated.length + " BL Updated <br>";
        }

        if(blCreated.length == 0 && blUpdated.length == 0){
          this.autoCreationResultMesssage = this.autoCreationResultMesssage + "0 BL Created <br> 0 BL Updated <br>";
        }

        //this.message("information","Information",this.autoCreationResultMesssage,"okonly",{ok:""});
        this.showPrompterDialog("information","Information",this.autoCreationResultMesssage,[{name:"OK",event:""}]);
      } else if (resp["message"] == "no-matching-pass-found"){
        this.showPrompterDialog("information","Information","No booking has match with QLEC.",[{name:"OK",event:""}]);
      } else {
        //this.message("information","Information","No data found in Booking","okonly",{ok:''});
        this.showPrompterDialog("information","Information","No data found in Booking",[{name:"OK",event:""}]);
      }

      this.loading = false;
      this.runAutoCreation = false;      
    }, error=>{
      console.log(error);
      //this.message("information","Information","An error occurred while running auto creation process, please contact your administrator!<br>error status : "+error.statusText,"okonly",{ok:""});
      this.showPrompterDialog("information","Information","An error occurred while running auto creation process, please contact your administrator!<br>error status : "+error.statusText,[{name:"OK",event:""}]);
      this.loading = false;
    });
  }

  printDiscrepancyReport(){
    this.loading = true;
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/discReportCll.pdf?userCreated="+this.userId+"&vessel="+this.model.vesselId+"&voyage="+this.model.voyage+"&bound="+this.model.bound+"&office="+this.office.officeCode+"&cOwnerShip="+this.model.cOwner;
    var fileName = "DiscreapancyReportCll"+this.model.vesselName+"-"+this.model.voyage;

    var postData = {};
    postData["fileName"] = fileName+".pdf";
    postData["url"] = jasperURL;

    this.genericService.POST(hitUrl,postData).subscribe((resp)=>{
      if(resp.ok){
        var result = resp.json();

        if(result["message"] == "success"){
          this.pdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
          this.pdfContainer.showPDF();
        }

        this.loading = false;
      }
    });
  }

  eventPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      this.disableToolbarButtons = 'print';

      if(this.runAutoCreation){
        this.showPrompterDialog("information","Information","Continue to run BL Auto Creation?",[{name:"YES",event:"this.autoCreationProcess();"},{name:"NO", event:""}]);
      }
    }
  }

  openPrompterDialog(){
    $("#idPrompter").dialog({
      title:"Information",
      modal:true,
      width:400,
      height:'auto',
      open: function(event, ui) {
        //hide close button.
        //console.log($(this));
        $('.ui-dialog').css('z-index',103);
        $('.ui-widget-overlay').css('z-index',102);
        $(this).parent().children().children('.ui-dialog-titlebar-close').hide();
        $("body").first().css("overflow","hidden");
        $('.ui-widget-content').css('min-height','auto');
    },
    close: function(event, ui){
      $("body").first().css("overflow"," auto");
    }
    }).dialog("open");
  }

  closePrompter(id:string){
    $("#"+id).dialog("close");
    $("#"+id).dialog("destroy");
  }

  showPrompterDialog(icon:string, title:string, content:string, button:any){
    this.prompterType = icon;
    this.prompterHeader = title;
    this.prompterContent = content;
    this.prompterButtons = button;

    this.openPrompterDialog();
  }

  clickEvent(event){
    this.closePrompter("idPrompter");

    if(event != ""){
      eval(event);
    }
  }

}