import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent, GenericService, GenericUtil, ConfigService, CookieService, PdfPluginComponent } from 'sibego-ui-library';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { BLHeader, BLMarkNo, Booking } from '../shared/index';
import { Promise } from 'q';
import { config } from 'rxjs/internal/config';
import * as FileSaver from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReplaceSource } from '../../../node_modules/@types/webpack-sources';

declare  var $:any;



@Component({
  selector: 'app-report-booking-freetinme-page',
  templateUrl: './report-booking-freetime-page.component.html',
  styleUrls: ['./report-booking-freetime-page.component.css']
})

export class ReportBookingFreetimePageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog: any;

  @ViewChild('cbVessel') cbVessel:ComboPluginComponent;


  @ViewChild('cbVoyage') cbVoyage:ComboPluginComponent;
  @ViewChild('cbLoadPort') cbLoadPort:ComboPluginComponent;

  @ViewChild('pdfContainer') pdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;
//isLoadPort=false;

  /* Parameter settings */
  settingToolbar;
  settingDelivery;
  settingReceipt;
  settingVessel;
  settingVoyage;
  settingPOD;
  settingPOR;
  settingLoadPort;
  settingDischargePort;
  settingGrid;
  settingPDF;
  settingGridPDFPrev;
  newDialogOpt;

  // lock
  cButtonLock = true;
  dButtonLock = true;

 config:any = require('config.json');

 isError : Boolean = false;
 lock: Boolean = false;
 lockHeader:boolean = false;
 lockButtonRetrieve : Boolean = true;

 addRemoveButton : string  ="none";
 lengthArray : number=0;

 printCopy:number=1;
 activeTab:string="";

  officeCode = (this.cookieService.getDefaultLocationCode() == null) ?'*': this.cookieService.getDefaultLocationCode();
  officeId = (this.cookieService.getDefaultLocationId() == null) ?'*': this.cookieService.getDefaultLocationId();
  userId:string = "";
  userEmail:string = "";

  model = new Booking();


  validatorRules = {};


  constructor(private _http: HttpClient, private cdr: ChangeDetectorRef, private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private configService:ConfigService, private cookieService:CookieService, private location:Location) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'OK', event: 'ok', icon: 'print'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Freetime Report'
    }

     this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 150},
        {header: 'Vessel Name', field: 'vesselName', width: 300},
        {header: 'Valid', field: 'isValid', width: 50}
      ]
    }

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 100}
      ]
    }


    this.settingLoadPort = {
      id          : 'cbLoadPort',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterLocations/findByComboBoxControl/LocationName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : 'Search ...',
      title       : 'locationName',
      description  : '',
      columns     : [
       // {header: 'Location Id', field: 'locationId', width:100},
        {header: 'Location Name', field: 'locationName', width: 300},
        {header: 'Location Code', field: 'locationCode', width: 100},
        {header: 'Remarks', field: 'remarks', width: 100},
        {header: 'Valid?', field: 'isValid', width: 100},
      ]
    }


    this.settingGrid = {
      id : 'grid',
      url:'',//this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: '10',
      columns: [
        {header: 'B/L No', field: 'blNo', width: 200, editType: 'text'},
      ],
      buttons: [
        { name: 'Edit', event: 'edit', enabled: true }
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns:'blNo',
      sortingDirection: 'ASC',
      editable : true,
      height:500,
      minHeight: 500,
      autoSize:'auto'
    }

    this.settingGridPDFPrev = {
      id : 'gridPDFPrev',
      url:'',//this.configService.config.BASE_API.toString() + '/BLReport/findBLByVessel',
      page: '10',
      columns: [
        {header: 'B/L No', field: 'blNo', width: 200, editType: 'text'},
      ],
      buttons: [
      ],
      //store: this.listStore,
      enableSorting: true,
      enableSelectAll: false,
      enablePagination: false,
      sortingColumns:'blNo',
      sortingDirection: 'ASC',
      editable : false,
      height:500,
      minHeight: 500,
      autoSize:'auto'
    }

    this.settingPDF = {
      id : 'pdfContainer',
      url : '',
      hidePrint : false,
      tabOptio : false
    };
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
     this.afterViewMode();
    this.cdr.detectChanges();
     var fullUrl = window.location.href;
     var routeUrl = this.router.url;
     console.log("test");
     console.log(this.config.jasperUrl);
     this.model.bPolCode='*';
    //this.userId = this.cookieService.getName();

    $('.menu .item').tab();

  }

  toolbarEvent(event) {
    //console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'cancel' :
        this.handleCancel();
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case 'ok' :
      this.printFreetime();
        break;
    }
  }

  printFreetime(){
    let vesselCodes: String ;
    let vesselNames : String = '';
    let polNames : String = '';
    console.log("Print Freetime ON --> "+this.model.bOceanVesselCode);
    console.log("Print POL ON --> "+this.model.bOceanVesselName);
    this.setValidator();

    let arnet:string[] = this.model.bOceanVesselName.split(' ');
    
    arnet.forEach(dt => {
      vesselNames = vesselNames + dt + "_";
    });

    polNames= this.model.bPolName.replace(",","_");

    console.log("CEK bPOLNAME : "+this.model.bPolCode);
    
    this.isError = this.onValidate(this.model);
    if(!this.isError){
    vesselCodes= this.model.bOceanVesselCode;
    console.log("Print Freetime ON --> "+this.model.bPodCode);
    // console.log('this.iserror => ' + this.isError);
      this.loading = true;
      //const urlHit = 'http://glossys.samudera.com:14000' + '/BookingReport/bookingDeletedReport/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val()
      //const urlHit = this.configService.config.BASE_API.toString()+ '?q=/BookingReport/dailyEmptyReleasingOrder/' + this.cookieService.getDefaultLocationCode() + '/' + $('#datePeriodFrom').val()+'/'+$('#datePeriodTo').val() + '/' + this.param.releaseAt + '/' + this.param.stuffAt;

      const urlHit = this.configService.config.BASE_API.toString() +  '?q=/BookingReport/freetimeBookingReport/' + this.cookieService.getDefaultLocationCode()+"/"+this.model.bOceanVesselCode+'/'+this.model.bOceanVesselVoyage+'/'+this.model.bOceanVesselBound+"/"+this.model.bPolCode;
      //window.open(urlHit);

      this._http.get(urlHit,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token':localStorage.getItem('token')}), responseType: 'blob'}).subscribe((res)=>{

        console.log(res.size);

        if(res.size > 4101)
          if(this.model.bPolCode != '*' || this.model.bPodCode != ''){
            FileSaver.saveAs(res, 'Freetime Booking Report '+vesselNames+this.model.bOceanVesselVoyage+'_'+polNames+'.xlsx');
          }else{
            FileSaver.saveAs(res, 'Freetime Booking Report '+vesselNames+this.model.bOceanVesselVoyage+'.xlsx');
          }
        else
            this.message('information','Information','No data(s) found.','okonly',{ok: 'this.loading=false;'});

        this.loading = false;
    });
  }



  }

  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

 setValidator(){
    this.clearValidatorError();
    this.validatorRules = {
      bOceanVesselCode: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Vessel.'
        }]
      },
      bOceanVesselVoyage: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Voyage.'
        }]
      },
      bOceanVesselBound: {
        rules: [{
          type: 'empty',
          prompt: 'Please input Bound.'
        }]
      },
    }
  }

  changeEventVessel(event){
    console.log("Vessel -> :"+event.vesselCode);
      if(event.vesselId == null || event.vesselId == "" || event.vesselId == undefined){
        console.log("Vessel kosong");
        this.model.bOceanVesselId = '';
        this.model.bOceanVesselCode = '';

      }else{
        this.model.bOceanVesselId = event.vesselId;
        this.model.bOceanVesselCode = event.vesselCode;
        this.model.bOceanVesselName = event.vesselName;

        this.cbVoyage.disableCombo = false;
        //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+event.vesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
        this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + event.vesselId + "/" + this.model.bOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
        this.cbVoyage.setValue("");
      }

      this.cbVessel.setUrl(this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}');
  }





  disableLoadPort(){
    this.cbLoadPort.disableCombo = true;
    this.cbLoadPort.clearSelect;
    this.cbLoadPort.setValue("");
  }




  onRetrieve(){

  }


  changeEventLoadPort(event){

    console.log('event Load Port : ' + event.locationCode);

    if(event.locationCode == null || event.locationCode == "" || event.locationCode == undefined){
        this.model.bPolCode = '*';
        this.model.bPolName = '';

    }else{
       this.model.bPolCode = event.locationCode;
       this.model.bPolName = event.locationName;
    }
  }


  changeEventVoyage(event){
    console.log("Voyage -> "+event.voyage);
    if(event.voyage == null || event.voyage == "" || event.voyage == undefined){
        this.model.bOceanVesselVoyage = '';

      }else{
       this.model.bOceanVesselVoyage = event.voyage;
      }
  }



  gridEvent(event){
    var flgTool = 0;
    var dblFlag = false;
    var soso = [];
    console.log('eventgrid'+event);
    switch (event.split(".")[0]) {
      case 'selected':


        if(flgTool > 0)
          this.disableToolbarButtons = "";
          //console.log(flgTool);
        else
         // this.disableToolbarButtons = "unlock";
        //this.grid.getSelectedValues().splice(0,this.grid.getSelectedValues().length);
        break;
      case 'click' :


        if(flgTool > 0)
        console.log(flgTool)
         // this.disableToolbarButtons = "retrieve";
        else

          //this.disableToolbarButtons = "retrieve,unlock";
        break;

        case 'afterLoad' :
        /*this.lengthArray = this.grid.listStore.store.length;

        if(this.lengthArray == 0){
          console.log("LENGTH GRID --> "+this.grid.listStore.store.length);
          this.grid.listStore.store=[];
          this.message('information','Information','No record(s) found.','okonly',{ok: 'this.loading=true;'});
        }*/


        break;
      default:


        let strEvent:string = event;
        if(strEvent != ''){
          let arr:string[] = strEvent.split(';');
          console.log("before split4 -> " + strEvent);
          console.log("arr3 -> " + arr);
          console.log("arr-split1 -> " + arr[0]);
          console.log("arr-split2 -> " + arr[1]);
          switch(arr[0].split('.')[0] ){
            case 'AfterEdit':


              if(flgTool > 0)
                //this.disableToolbarButtons = "retrieve";
                console.log(flgTool)
              else
                this.disableToolbarButtons = "unlock";





              break;
            case 'dblClick':


            break;
            default:
              break;
          }

        }

        break;

    }
  }

  infoGrid(event){}
  changeSortBy(event){}



  afterViewMode(){
    this.model.bOceanVesselBound = 'O';

  }





   changeBound(event){
     console.log("Bound -> "+event.bound);
    this.model.bOceanVesselBound = event.target.value ;
    this.cbVoyage.disableCombo = false;
    //this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/distinctVoyage/"+this.param.bOceanVesselId+"/"+this.param.bOceanVesselBound+"/{query}/" + this.cookieService.getDefaultLocationCode());
    this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + "/MasterSailingSchedules/findByComboBoxControlVoyage/" + this.cookieService.getDefaultLocationCode() + "/" + this.model.bOceanVesselId + "/" + this.model.bOceanVesselBound.toUpperCase()  + "/voyage_like={query}");
    this.cbVoyage.setValue("");

    this.model.bOceanVesselVoyage= '';
  }

  handleCancel(){
    this.clearMode();
  }

  clearValidatorError(){
    this.model['error-blOceanVesselCode'] = '';
    this.model['error-blOceanVesselVoyage'] = '';
    this.model['error-blOceanVesselBound'] = '';
    this.model['error-blLoadPortCode'] = '';
    this.model['error-blPlaceOfDeliveryCode'] = '';
    this.model['error-blPlaceOfReceiptCode']= '';
    this.model['error-blDischargePortCode'] = '';
    this.model['error-blReceiptTypeCode'] = '';
  }

  clearMode(){
    this.cbVessel.setValue ('');
    this.cbVessel.clearSelect();
    this.cbVoyage.setValue('');
    this.cbVoyage.clearSelect();


    this.cbLoadPort.disableCombo = true;






    this.model.bOceanVesselBound = 'O';



  }



  eventPDFPlugin(){}
  gridEventPDFPrev(){}





}
