import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { autoCreationBLModel} from '../shared/index';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService, UploadPluginComponent, PdfPluginComponent } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
declare  var $:any;

@Component({
  selector: 'app-transaction-qlec-page',
  templateUrl: './transaction-qlec-page.component.html',
  styleUrls: ['./transaction-qlec-page.component.css']
})
export class TransactionQlecPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;
  @ViewChild('qlecFileUpload') qlecFileUpload:UploadPluginComponent;
  @ViewChild('qlecPM4FileUpload') qlecPM4FileUpload:UploadPluginComponent;

  @ViewChild('qLecPdfContainer') qlecPdfContainer: PdfPluginComponent;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;

  loading = false;
  labelToolbar = "QLEC";

  /* Parameter settings */
  settingToolbar;
  settingUploadQlecFile;
  settingUploadQlecPM4File;
  settingQlecPDF;
  //types


  // lock

  // model declaration
  model = new autoCreationBLModel;
  hitUrl:string = this.configService.config.BASE_API.toString();
  isQLECExist:boolean = false;
  isPM4Exist:boolean  = false;
  postData:any = {};    

  validatorRules = {};

  prompterType:string = 'information';
  prompterHeader:string = 'Information';
  prompterContent:string = '';
  prompterButtons = [{name:"ok", event:""}];

 
  constructor(
    private auth:Authorize, 
    private genericService:GenericService, 
    private genericUtil:GenericUtil, 
    private router: Router, 
    private configService:ConfigService, 
    private cookieService:CookieService,
    private _http:HttpClient) {

    super();
    var officeCode = (this.cookieService.getDefaultLocationCode() == null) ? '*' : this.cookieService.getDefaultLocationCode();
    if(officeCode == "SGSIN"){
      this.labelToolbar = "QLEC(Matching)";
    }

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'power'},
        {name: 'Cancel', event: 'cancel', icon: 'cancel'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: this.labelToolbar
    }

    this.settingUploadQlecFile = {
      id   : 'uploadQLEC',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'csv',
      showUploadButton: false,
      customButtons : [
      ]
    }
    this.settingUploadQlecPM4File = {
      id   : 'uploadQlecPM4',
      uploadDir : '/CLL',
      maxFiles : 1,
      filterFiles : 'csv',
      showUploadButton: false,
      customButtons : [
      ]
    }
    this.settingQlecPDF = {
      id : 'qlecPdfContainer',
      url : '',
      hidePrint : true,
    };
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initForm();
    var self = this;
    $("#myfile").change(function(e){
       console.log("file name : "+$(this).val());
      var fileName = $(this).val();
      if(fileName.split(".").pop().toLowerCase() == "csv"){
        self.model.fileName = $(this).val(); 
        self.disableToolbarButtons = '';    
      } else {
        self.message("information","Information", "Please choose CSV file!","okonly",{ok:""});   
      }
    });    
  }



  initModel(){
    this.model.fileName = "";
    this.model.pm4FileName = "";
    this.model.fileType = "QLEC";
    this.model.isCreateBL = false;
    this.model.messageValue = "TO BEGIN, PLEASE BROWSE FOR THE FILE TO BE PROCESSED.\nTHEN CHOOSE A FILE TYPE: QLEC AND CLICK ON THE PROCESS BUTTON.";
    this.disableToolbarButtons = 'process';
    this.model.userCreated = this.cookieService.getName();
    this.model.officeCode = this.cookieService.getDefaultLocationCode();

    this.postData["officeCode"] = this.model.officeCode;
    this.postData["userId"] = this.model.userCreated;
    this.postData["fileName"] = "";
    this.postData["pm4FileName"] = "";

    this.qlecFileUpload.store = [];
    this.qlecPM4FileUpload.store = [];

    this.isQLECExist = false;
    this.isPM4Exist  = false;
  }

  initForm(){
    this.initModel();    
  }  

  uploadProcess(type:string){
    switch(type){
      case "QLEC":
        this.qlecFileUpload.onUpload();
        break;
      case "PM4":
        this.qlecPM4FileUpload.onUpload();
        break;
    }
  }

  uploadEventQlecFile(event){
    console.log(event);   
    if(event.includes("-")){
      var temp = event.split("-");
      switch(temp[1].trim()){
        case "uploaded":
          this.disableToolbarButtons = "process";
          this.model.fileName = temp[0].trim();          
          this.isQLECExist = true;          
          this.uploadProcess("QLEC");          
          //this.uploadSastFileToServer();
          break;
        case "deleted":
          this.model.fileName = "";
          this.postData["fileName"] = "";
          this.isQLECExist = false;
          if(!this.isPM4Exist){
            this.disableToolbarButtons = "process";
          }
          break;
      }
    } else {
      switch(event){
        case "success":
          this.postData["fileName"] = this.model.fileName;
          this.disableToolbarButtons = "";
          //this.processingSASTFile(postData);

          break;
        default:
          break;
      }
    }
  }

  uploadEventQlecPM4File(event){
    if(event.includes("-")){
      var temp = event.split("-");
      switch(temp[1].trim()){
        case "uploaded":
          this.disableToolbarButtons = "process";
          this.model.pm4FileName = temp[0].trim(); 
          this.isPM4Exist = true;          
          this.uploadProcess("PM4");         
          //this.uploadSastFileToServer();
          break;
        case "deleted":
          this.model.pm4FileName = "";
          this.postData["pm4FileName"] = "";
          this.isPM4Exist = false;
          if(!this.isQLECExist){
            this.disableToolbarButtons = "process";
          }
          break;
      }
    } else {
      switch(event){
        case "success":
          this.postData["pm4FileName"] = this.model.pm4FileName;
          this.disableToolbarButtons = "";        
          //this.processingSASTFile(postData);

          break;
        default:
          break;
      }
    }
  }

  uploadQlecFileToServer(){    
    this.loading = true;
    this.postData["qlecFileExist"] = this.isQLECExist;   
    this.postData["pm4FileExist"] = this.isPM4Exist;
    this.postData["createdDate"] = this.genericUtil.getPSIDate();

    console.log("postData");
    console.log(this.postData);
    this.processionQLECFile();

  }

  processionQLECFile(){
    var uri = this.hitUrl + "?q=/ContainerLoadList/upload/Qlec";
    this._http.post(uri,this.postData,{ headers: new HttpHeaders({'Content-Type':  'application/json', 'Token': localStorage.getItem('token')}), responseType: 'json'}).subscribe((resp)=>{
      console.log(resp);
      var result = resp;
      if(result["status"] == "ok"){
        this.showQlecReport(result["session-id"]);
      } else {
        this.message("Information","information",result["message"],"okonly",{ok:""});
        this.loading = false;
      }
    });
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'process' :
       this.uploadQlecFileToServer();
       //this.message("information","Information","this function is still under maintenance","okonly",{ok:"this.showQlecReport();"});
       //this.showPrompterDialog("information","Information","this function is still under maintenance",[{name:"OK",event:"this.showQlecReport();"}]);
       break;
      case 'cancel' :
        this.initModel();
       break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){
    if(event != ''){
      eval(event);
    }
  }

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  uploadPM4(){}

  showQlecReport(sessionId){
    var reportDate = this.postData["createdDate"].split(" ")[0].split("-")[2] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[1] + "-" + this.postData["createdDate"].split(" ")[0].split("-")[0] + "X" + this.postData["createdDate"].split(" ")[1];
    var hitUrl = this.configService.config.getPDFUrl + "/edi-util/getFile";
    var jasperURL = this.configService.config.jasperUrl + "/jasperserver/rest_v2/reports/reports/GLOSSYS/DISCREPANCY/QlecDiscrepancyReport.pdf?sessionId="+sessionId+"&Date="+reportDate+"&userId="+this.model.userCreated;
    var fileName = "Qlec-Discrepancy-Report-"+this.model.userCreated+".pdf";

    var postDataX = {};
    postDataX["fileName"] = fileName;
    postDataX["url"] = jasperURL;

    this.genericService.POST(hitUrl,postDataX).subscribe((resp)=>{
      if(resp.ok){
        var result = resp.json();

        if(result["message"] == "success"){
          this.qlecPdfContainer.setPDFURL(this.configService.config.pdfUrl + result["filePath"]);
          this.qlecPdfContainer.showPDF();
        }
            
      }

      this.loading = false;
    });
  }

  openPrompterDialog(){
    $("#idPrompterQlec").dialog({
      title:"Information",
      modal:true,
      width:400,
      height:'auto',
      open: function(event, ui) {
        //hide close button.
        //console.log($(this));
        $('.ui-dialog').css('z-index',103);
        $('.ui-widget-overlay').css('z-index',102);
        $(this).parent().children().children('.ui-dialog-titlebar-close').hide();
        $("body").first().css("overflow","hidden");
        $('.ui-widget-content').css('min-height','auto');
    },
    close: function(event, ui){
      $("body").first().css("overflow"," auto");
    }
    }).dialog("open");
  }

  closePrompter(id:string){
    $("#"+id).dialog("close");
    $("#"+id).dialog("destroy");
  }

  showPrompterDialog(icon:string, title:string, content:string, button:any){
    this.prompterType = icon;
    this.prompterHeader = title;
    this.prompterContent = content;
    this.prompterButtons = button;

    this.openPrompterDialog();
  }

  clickEvent(event){
    this.closePrompter("idPrompterQlec");

    if(event != ""){
      eval(event);
    }
  }

  eventQlecPDFPlugin(event){
    console.log(event);
    if(event == "close-pdf"){
      this.initModel();
    }
  }
}