import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { routing, appRoutingProviders } from './app.routes';
import { SibegoUIModule, Authorize } from 'sibego-ui-library';

import { AgGridModule } from 'ag-grid-angular/main';
import { LicenseManager} from 'ag-grid-enterprise';
import { AutonumericModule } from '@angularfy/autonumeric';
import { HttpClientModule } from '@angular/common/http';
/*
import { AgmCoreModule} from '@agm/core';
import { AgmDirectionModule} from 'agm-direction';


import {
  GenericService,
  GenericUtil,
  Authorize,

  HeaderPluginComponent,
  FooterPluginComponent,
  ToolbarPluginComponent,
  MenuPluginComponent,
  LoginPluginComponent,
  WhatsNewsPluginComponent,
  GridPluginComponent,
  GridPluginComponent2,
  ComboPluginComponent,
  DialogPluginComponent,
  CalendarPluginComponent,
  HelpPluginComponent,
  TextareaPluginComponent
} from './shared/index';
*/

import {
  GridPluginComponent2,
  newDialogComponent,
} from './shared/index';


import { AppComponent } from './app.component';
import { MainComponent } from './main/main.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ChangePasswordPageComponent } from './change-password-page/change-password-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';

import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { LoginNewsPageComponent } from './login-news-page/login-news-page.component';
import { BlankPageComponent } from './blank-page/blank-page.component';
import { MachineReportPageComponent } from './machine-report-page/machine-report-page.component';

import { MasterPackageTypePageComponent } from './master-package-type-page/master-package-type-page.component';
import { MasterPerCodePageComponent } from './master-per-code-page/master-per-code-page.component';
import { MasterVesselPageComponent } from './master-vessel-page/master-vessel-page.component';
import { MasterVesselFormPageComponent } from './master-vessel-page/master-vessel-form-page.component';
import { MasterVesselSchedulePageComponent } from './master-vessel-schedule-page/master-vessel-schedule-page.component';
import { MasterServicePageComponent } from './master-service-page/master-service-page.component';
import { MasterCurrencyPageComponent } from './master-currency-page/master-currency-page.component';
import { MasterRegionPageComponent } from './master-region-page/master-region-page.component';
import { MasterCommodityPageComponent } from './master-commodity-page/master-commodity-page.component';
import { MasterPrincipalPageComponent } from './master-principal-page/master-principal-page.component';
import { MasterRecDelTypePageComponent } from './master-recdel-type-page/master-recdel-type-page.component';
import { MasterReceiptTypePageComponent } from './master-receipt-type-page/master-receipt-type-page.component';
import { MasterDeliveryTypePageComponent } from './master-delivery-type-page/master-delivery-type-page.component';
import { MasterClausePageComponent } from './master-clause-page/master-clause-page.component';
import { MasterSpecialDetailPageComponent} from './master-special-detail-page/master-special-detail-page.component';
import { MasterCustomerPageComponent} from './master-customer-page/master-customer-page.component';
import { MasterCustomerChinaPageComponent} from './master-customer-china-page/master-customer-china-page.component';
import { MasterCustomerNewPageComponent} from './master-customer-new-page/master-customer-new-page.component';
import { MasterCountryPageComponent } from './master-country-page/master-country-page.component';
import { MasterLocationPageComponent } from './master-location-page/master-location-page.component';
import { MasterChargePageComponent } from './master-charge-page/master-charge-page.component';
import { MasterExchangeRatePageComponent } from './master-exchange-rate-page/master-exchange-rate-page.component';
import { MasterContainerIsoMappingPageComponent } from './master-container-iso-mapping-page/master-container-iso-mapping-page.component';
import { MasterLocationGroupPageComponent } from './master-location-group-page/master-location-group-page.component';
import { MasterContainerTypePageComponent } from './master-container-type-page/master-container-type-page.component';
import { MasterSurchargePageComponent } from './master-surcharge-page/master-surcharge-page.component';
import {MasterNewsPageComponent} from './master-news-page/master-news-page.component';
import { LocalMasterExchangeRatePageComponent } from './local-master-exchange-rate-page/local-master-exchange-rate-page.component';
import { LocalMasterChargeCodePageComponent } from './local-master-charge-code-page/local-master-charge-code-page.component';
import { LocalMasterGstPageComponent } from './local-master-gst-page/local-master-gst-page.component';
import { LocalMasterBankPageComponent } from './local-master-bank-page/local-master-bank-page.component';
import { MasterCustomerPicPageComponent} from  './master-customer-pic-page/master-customer-pic-page.component';


import { TransactionBookingFlagPageComponent } from './transaction-booking-maintenance-page/transaction-booking-flag-page.component';
import { TransactionBookingMaintenancePageComponent } from './transaction-booking-maintenance-page/transaction-booking-maintenance-page.component';
import { TransactionBookingMaintenanceLatestPageComponent } from './transaction-booking-maintenance-latest-page/transaction-booking-maintenance-latest-page.component';
import { TransactionBookingMaintenanceCoparnPageComponent } from './transaction-booking-maintenance-coparn-page/transaction-booking-maintenance-coparn-page.component';
import { TransactionVesselAllocationPageComponent } from './transaction-vessel-allocation-page/transaction-vessel-allocation-page.component';
import { TransactionVesselListingByportofcallPageComponent } from './transaction-vessel-listing-byportofcall-page/transaction-vessel-listing-byportofcall-page.component';
import { TransactionSailingSchedulePageComponent} from './transaction-sailing-schedule-page/transaction-sailing-schedule-page.component';
import { TransactionBookingSplitPageComponent } from './transaction-booking-split-page/transaction-booking-split-page.component';
import { TransactionBoookingTransferPageComponent } from './transaction-booking-transfer-page/transaction-booking-transfer-page.component';
import { TransactionSASTPageComponent } from './transaction-sast-page/transaction-sast-page.component';
import { TransactionOBOPPageComponent } from './transaction-obop-page/transaction-obop-page.component';
import { TransactionDiscrepanciesPageComponent } from './transaction-discrepancies-page/transaction-discrepancies-page.component';

import { TransactionCloseVesselPageComponent } from './transaction-close-vessel-page/transaction-close-vessel-page.component';
import { TransactionUnlockBookingPageComponent } from './transaction-unlock-booking-page/transaction-unlock-booking-page.component';

import { TransactionBLFlagStatusPageComponent } from './transaction-bl-flag-status-page/transaction-bl-flag-status-page.component';
import { TransactionBLMaintenancePageComponent } from './transaction-bl-maintenance-page/transaction-bl-maintenance-page.component';
import { TransactionBLSplitPageComponent } from './transaction-bl-split-page/transaction-bl-split-page.component';
import { TransactionBLTransferPageComponent } from './transaction-bl-transfer-page/transaction-bl-transfer-page.component';
import { TransactionUnlockBLPageComponent } from './transaction-unlock-bl-page/transaction-unlock-bl-page.component';
import { TransactionBLFreightMatchPageComponent } from './transaction-bl-freight-match/transaction-bl-freight-match-page.component';
import { TransactionBLLockedMonitoringPageComponent } from './transaction-bl-locked-monitoring-page/transaction-bl-locked-monitoring-page.component';

import { TransactionCLLPageComponent } from './transaction-cll-page/transaction-cll-page.component';
import { TransactionBLCrossReferenceByBookingPageComponent } from './transaction-bl-cross-reference-by-booking-page/transaction-bl-cross-reference-by-booking-page.component';
import { TransactionBLCrossReferenceByContainerPageComponent } from './transaction-bl-cross-reference-by-container-page/transaction-bl-cross-reference-by-container-page.component';
import { TransactionBLTransshipmentPageComponent } from './transaction-bl-transshipment-page/transaction-bl-transshipment-page.component';
import { TransactionStandingInstructionPageComponent } from './transaction-standing-instruction-page/transaction-standing-instruction-page.component';
import { TransactionDebitCreditNotePageComponent } from './transaction-debit-credit-note-page/transaction-debit-credit-note-page.component';
import { TransactionReceiptPageComponent } from './transaction-receipt-page/transaction-receipt-page.component';
import { TransactionChargeTabPageComponent } from './transaction-charge-tab-page/transaction-charge-tab-page.component';
import { TransactionAutoCreationBLViaCLLPageComponent } from './transaction-auto-creation-bl-via-cll-page/transaction-auto-creation-bl-via-cll-page.component';
import { TransactionBlReinstatementPageComponent } from './transaction-bl-reinstatement-page/transaction-bl-reinstatement-page.component';
import { TransactionFreightManagementPageComponent} from './transaction-freight-management-page/transaction-freight-management-page.component';
import { TransactionFreightApprovalPageComponent} from './transaction-freight-approval-page/transaction-freight-approval-page.component';
import { TransactionContractRatePageComponent} from './transaction-contract-rate-page/transaction-contract-rate-page.component';
import { TransactionBoookingReinstatementPageComponent} from './transaction-booking-reinstatement-page/transaction-booking-reinstatement-page.component';

import { TransactionBlFlagStatusLatestPageComponent} from './transaction-bl-flag-status-latest-page/transaction-bl-flag-status-latest-page.component';
import { TransactionBlMaintenanceLatestPageComponent} from './transaction-bl-maintenance-latest-page/transaction-bl-maintenance-latest-page.component';
import { TransactionBlFreightMatchLatestPageComponent } from './transaction-bl-freight-match-latest-page/transaction-bl-freight-match-latest-page.component';
import { TransactionBLSplitLatestPageComponent} from './transaction-bl-split-latest-page/transaction-bl-split-latest-page.component';
import { TransactionBlTransferLatestPageComponent} from './transaction-bl-transfer-latest-page/transaction-bl-transfer-latest-page.component';
import { TransactionBLCombineLatestPageComponent} from './transaction-bl-combine-latest-page/transaction-bl-combine-latest-page.component';
import { TransactionBLUncombineLatestPageComponent} from './transaction-bl-uncombine-latest-page/transaction-bl-uncombine-latest-page.component';
import { TransactionUnlockBlLatestPageComponent} from './transaction-unlock-bl-latest-page/transaction-unlock-bl-latest-page.component';

import { PFCBulkUpdateContractRatePageComponent } from './pfc-bulk-update-contract-rate-page/pfc-bulk-update-contract-rate-page.component';
import { PFCBulkUpdateExistingApprovedFreightPageComponent } from './pfc-bulk-update-existing-approved-freight-page/pfc-bulk-update-existing-approved-freight-page.component';
import { PFCRequestHistoryPageComponent } from './pfc-request-history-page/pfc-request-history-page.component';

import { ReportBlPrintSinPageComponent} from './report-bl-print-sin-page/report-bl-print-sin-page.component';
import { ReportBlDraftPageComponent} from './report-bl-draft-page/report-bl-draft-page.component';
import { ReportBlCopyPageComponent} from './report-bl-copy-page/report-bl-copy-page.component';
import { ReportBlOriginalPageComponent} from './report-bl-original-page/report-bl-original-page.component';
import { ReportBlVgmPageComponent } from './report-bl-vgm-page/report-bl-vgm-page.component';
import { ReportMonthlyLiftingPageComponent} from './report-monthly-lifting-page/report-monthly-lifting-page.component';
import { ReportMonthlyLiftingNormalPageComponent} from './report-monthly-lifting-normal-page/report-monthly-lifting-normal-page.component';
import { ReportMonthlyLiftingTankPageComponent} from './report-monthly-lifting-tank-page/report-monthly-lifting-tank-page.component';
import { ReportMonthlyLiftingReeferPageComponent} from './report-monthly-lifting-reefer-page/report-monthly-lifting-reefer-page.component';
import { ReportMonthlyLiftingPortPageComponent} from './report-monthly-lifting-port-page/report-monthly-lifting-port-page.component';
import { ReportFinanceActualFreightBillPageComponent} from './report-finance-actual-freight-bill-page/report-finance-actual-freight-bill-page.component';
import { ReportFinanceRePrintFreightBillPageComponent} from './report-finance-re-print-freight-bill-page/report-finance-re-print-freight-bill-page.component';
import { ReportFinancePerformaFreightBillPageComponent} from './report-finance-performa-freight-bill-page/report-finance-performa-freight-bill-page.component';
import { ReportFinanceApprovedListSummaryPageComponent} from './report-finance-approved-list-summary-page/report-finance-approved-list-summary-page.component';
import { ReportFinanceFreightListPageComponent} from './report-finance-freight-list-page/report-finance-freight-list-page.component';
import { ReportFinanceCargoManifestPageComponent} from './report-finance-cargo-manifest-page/report-finance-cargo-manifest-page.component';
import { ReportOperationPreliminaryContainerLoadingListPageComponent} from './report-operation-preliminary-container-loading-list-page/report-operation-preliminary-container-loading-list-page.component';
import { ReportOperationContainerLoadingListPageComponent} from './report-operation-container-loading-list-page/report-operation-container-loading-list-page.component';
import { ReportOperationContainerLoadingListCocPageComponent} from './report-operation-container-loading-list-coc-page/report-operation-container-loading-list-coc-page.component';
import { ReportOperationContainerLoadingListCocProformaPageComponent} from './report-operation-container-loading-list-coc-proforma-page/report-operation-container-loading-list-coc-proforma-page.component';
import { ReportOperationContainerLoadingListBlPageComponent} from './report-operation-container-loading-list-bl-page/report-operation-container-loading-list-bl-page.component';
import { ReportOperationFinalContainerLoadingListPageComponent} from './report-operation-final-container-loading-list-page/report-operation-final-container-loading-list-page.component';
import { ReportOperationCargoManifestPageComponent} from './report-operation-cargo-manifest-page/report-operation-cargo-manifest-page.component';
import { ReportOperationDailyBookingProgressPageComponent} from './report-operation-daily-booking-progress-page/report-operation-daily-booking-progress-page.component';
import { ReportOutboundPageComponent} from './report-outbound-page/report-outbound-page.component';
import { ReportSocCustomerRatePageComponent} from './report-soc-customer-rate-page/report-soc-customer-rate-page.component';

import { EdiVesselSchedulePageComponent } from './edi-vessel-schedule-page/edi-vessel-schedule-page.component';
import { EDIProcessEDIBookingPageComponent} from './edi-process-edi-booking-page/edi-process-edi-booking-page.component';
import { EDIPortMappingPageComponent} from './edi-port-mapping-page/edi-port-mapping-page.component';
import { EDIReceiveBayPlanPageComponent} from './edi-receive-bay-plan-page/edi-receive-bay-plan-page.component';
import { EDIReceiveBLDataFromPenavicoPageComponent} from './edi-receive-bl-data-from-penavico-page/edi-receive-bl-data-from-penavico-page.component';
import { EDIReceiveTallyListPageComponent} from './edi-receive-tally-list-page/edi-receive-tally-list-page.component';
import { EDIReceiveBookingFromAgentPageComponent} from './edi-receive-booking-from-agent-page/edi-receive-booking-from-agent-page.component';
import { EDITransmitBookingDataToPSAPageComponent} from './edi-transmit-booking-data-to-psa-page/edi-transmit-booking-data-to-psa-page.component';
import { EDITransmitCLLToFISSIPageComponent} from './edi-transmit-cll-to-fissi-page/edi-transmit-cll-to-fissi-page.component';
import { EDITransmitFinalManifestPageComponent} from './edi-transmit-final-manifest-page/edi-transmit-final-manifest-page.component';
import { EDITransmitToDataCenterPageComponent} from './edi-transmit-to-data-center-page/edi-transmit-to-data-center-page.component';
import { EDITransmitToEMASPageComponent} from './edi-transmit-to-emas-page/edi-transmit-to-emas-page.component';
import { EDITransmitToPlanMasterPageComponent} from './edi-transmit-to-plan-master-page/edi-transmit-to-plan-master-page.component';
import { EDITransmitUnpostedCreditDebitToCodaPageComponent} from './edi-transmit-unposted-credit-debit-to-coda-page/edi-transmit-unposted-credit-debit-to-coda-page.component';
import { EDITransmitUnpostedInvoiceToCodaPageComponent} from './edi-transmit-unposted-invoice-to-coda-page/edi-transmit-unposted-invoice-to-coda-page.component';

import { SettingLockPeriodPageComponent} from './setting-lock-period-page/setting-lock-period-page.component';
import { SettingBookingEDIPortMappingPageComponent} from './setting-booking-edi-port-mapping-page/setting-booking-edi-port-mapping-page.component';
import { SettingSilentPrintingPageComponent} from './setting-silent-printing-page/setting-silent-printing-page.component';

import { ExperimentalBookingRequisitionPageComponent} from './experimental-page/experimental-booking-requisition-page/experimental-booking-requisition-page.component';
import { ExperimentalMessagesPageComponent} from './experimental-page/experimental-messages-page/experimental-messages-page.component';
import { ExperimentalProfilePageComponent} from './experimental-page/experimental-profile-page/experimental-profile-page.component';
import { ExperimentalRegisterPageComponent} from './experimental-page/experimental-register-page/experimental-register-page.component';
import { ExperimentalTemplatePageComponent} from './experimental-page/experimental-template-page/experimental-template-page.component';
import { ExperimentalVesselLookupPageComponent} from './experimental-page/experimental-vessel-lookup-page/experimental-vessel-lookup-page.component';
import { ExperimentalMfoPageComponent} from './experimental-page/experimental-mfo-page/experimental-mfo-page.component';
import { ExperimentalContainerTrackingPageComponent} from './experimental-page/experimental-container-tracking-page/experimental-container-tracking-page.component';
import { HSCodeToolkitPageComponent } from './hscode-toolkit-page/hscode-toolkit-page.component';

import { MasterDepoPageComponent } from './local-master-depo-page/local-master-depo-page.component';
import { LocalMasterTerminalClosingTimePageComponent } from './local-master-terminal-closing-time-page/local-master-terminal-closing-time-page.component';
import { TransactionCLLDGPageComponent } from './transaction-cll-dg-page/transaction-cll-dg-page.component';

import {ReportCllOogListPageComponent} from './report-cll-oog-list-page/report-cll-oog-list-page.component';
import {ReportCllReeferListPageComponent} from './report-cll-reefer-list-page/report-cll-reefer-list-page.component';
import {ReportCllRecapitulationListPageComponent} from './report-cll-recapitulation-list-page/report-cll-recapitulation-list-page.component';
import {ReportCllMultipleDGListPageComponent} from './report-cll-multiple-dg-list-page/report-cll-multiple-dg-list-page.component';
import { SafePipe } from './shared/safePipe';
import {ReportCllDGListPageComponent} from './report-cll-dg-list-page/report-cll-dg-list-page.component';
import {ReportBookingForecastPageComponent} from './report-booking-forecast-page/report-booking-forecast-page.component';
import {ReportBookingDeletedPageComponent} from './report-booking-deleted-page/report-booking-deleted-page.component';
import {ReportBookingFreetimePageComponent} from './report-booking-freetime-page/report-booking-freetime-page.component';


import { TransactionFreightManagementMRGPageComponent} from './transaction-freight-management-mrg-page/transaction-freight-management-mrg-page.component';

import {TransactionBookingOrphanagePageComponent} from './transaction-booking-orphanage-page/transaction-booking-orphanage-page.component';
import {ReportMatchingCllBlPageComponent} from './report-matching-cll-bl-page/report-matching-cll-bl-page.component';
import { CustomPinnedRowRenderer } from './app.custom-pinned';
import { MasterUserManagementPageComponent } from './master-user-management-page/master-user-management-page.component';
import {ReportDailyEmptyReleasingOrderPageComponent} from './report-daily-empty-releasing-order-page/report-daily-empty-releasing-order-page.component';
import {ReportContainerGateInSummaryPageComponent} from './report-container-gate-in-summary-page/report-container-gate-in-summary-page.component';
import { MasterDgClassPageComponent } from './master-dg-class-page/master-dg-class-page.component';

import { MasterQlecPageComponent } from './master-qlec-page/master-qlec-page.component';
import { TransactionQlecPageComponent } from './transaction-qlec-page/transaction-qlec-page.component';
import { TransactionAutoCreationofBLQlecPageComponent } from './transaction-auto-creation-of-bl-qlec-page/transaction-auto-creation-of-bl-qlec-page.component';
import { TransactionAutoCreationofBLSastPageComponent } from './transaction-auto-creation-of-bl-sast-page/transaction-auto-creation-of-bl-sast-page.component';
import { TransactionAutoCreationofBLObopPageComponent } from './transaction-auto-creation-of-bl-obop-page/transaction-auto-creation-of-bl-obop-page.component';
import { MasterCustomerViewPageComponent} from './master-customer-view-page/master-customer-view-page.component';

import { TransactionTSPortPairAllocationPageComponent } from './transaction-ts-port-pair-allocation-page/transaction-ts-port-pair-allocation-page.component';
import { TransactionTSContainerMovementPageComponent } from './transaction-ts-container-movement-page/transaction-ts-container-movement-page.component';
import { ReportOperationBookingForecastDailyVesselPageComponent } from './report-operation-booking-forecast-daily-vessel-page/report-operation-booking-forecast-daily-vessel-page.component';

import { TransactionBlSameContainerListPageComponent } from './transaction-bl-same-container-list-page/transaction-bl-same-container-list-page.component';
import { TransactionInvoiceFlagStatusPageComponent } from './transaction-invoice-flag-status-page/transaction-invoice-flag-status-page.component';
import { MasterReasonPageComponent } from './master-reason-page/master-reason-page.component';
import { ReportFinanceProformaFreightBillLatestPageComponent } from './report-finance-proforma-freight-bill-latest-page/report-finance-proforma-freight-bill-latest-page.component';
import { ReportFinanceActualFreightBillLatestPageComponent } from './report-finance-actual-freight-bill-latest-page/report-finance-actual-freight-bill-latest-page.component';

import { EDITransmitToDataCenterSinConverterPageComponent} from './edi-transmit-to-data-center-sin-converter-page/edi-transmit-to-data-center-sin-converter-page.component';
import { EDITransmitToDataCenterShaConverterPageComponent} from './edi-transmit-to-data-center-sha-converter-page/edi-transmit-to-data-center-sha-converter-page.component';

import { ReportFinanceProformaFreightListPageComponent} from './report-finance-proforma-freight-list-page/report-finance-proforma-freight-list-page.component';

import { TransactionBlMaintenanceLatestProtoPageComponent } from './transaction-bl-maintenance-latest-proto-page/transaction-bl-maintenance-latest-proto-page.component';
import { TransactionBlMaintenanceLatestProto2PageComponent } from './transaction-bl-maintenance-latest-proto-2-page/transaction-bl-maintenance-latest-proto-2-page.component';

import { ReportLiftingCustomerPageComponent } from './report-lifting-customer-page/report-lifting-customer-page.component';
import { MasterHSCodePageComponent } from './master-hs-code-page/master-hs-code-page.component';
import { EdiTransmitToCodaConfigComponent } from './edi-transmit-to-coda-config/edi-transmit-to-coda-config.component';
import { TransactionReceiptPageExportCodaComponent } from './transaction-receipt-page-export-coda/transaction-receipt-page-export-coda.component';
import {MasterAdhocChargePageComponent} from './master-adhoc-charge-page/master-adhoc-charge-page.component';
import { FourDigitDecimalNumberDirective } from './tools/four-digit-decimal-number.directive';
import { FourDigitDecimalNumberWithCommasSeparatorDirective } from './tools/four-digit-decimal-number-with-commas-separator.directive';
import { EdiTransmitEdifactPageComponent} from './edi-transmit-edifact-page/edi-transmit-edifact-page.component';
import { LimitDecimalsDirective } from './shared/directives/limitDecimals.directive';
import { EdiGenerationToDepotComponent } from './edi-generation-to-depot/edi-generation-to-depot.component';
import { TransactionTSPortPairAllocationPageComponentV2 } from './transaction-ts-port-home/transaction-ts-port-pair-allocation-page-v2/transaction-ts-port-pair-allocation-page-v2.component';
import { TransactionBlSameSealNoPageComponent } from './transaction-bl-same-seal-no-page/transaction-bl-same-seal-no-page.component';
import { TransactionTsPortHomeComponent } from './transaction-ts-port-home/transaction-ts-port-home.component';
import { TransactionProspectBookingComponent } from './transaction-ts-port-home/transaction-prospect-booking/transaction-prospect-booking.component';
import { ReportFinanceCodeTransmissionLogComponent } from './report-finance-code-transmission-log/report-finance-code-transmission-log.component';
import { CommonModule, DatePipe } from '@angular/common';
import { TransactionTsConfigComponent } from './transaction-ts-config/transaction-ts-config.component';
import { TransactionTsExceptionComponent } from './transaction-ts-port-home/transaction-ts-exception/transaction-ts-exception.component';
import { TransactionTsExceptionHandlingComponent } from './transaction-ts-port-home/transaction-ts-exception/transaction-ts-exception-handling/transaction-ts-exception-handling.component';
import { TransactionTsRenominationComponent } from './transaction-ts-port-home/transaction-ts-renomination/transaction-ts-renomination.component';
import { RenominateDialogComponent } from './transaction-ts-port-home/transaction-ts-renomination/dialog/renominate-dialog/renominate-dialog.component';
import { TransactionBookingCocReleasePageComponent } from './transaction-booking-coc-release-page/transaction-booking-coc-release-page.component';
import {LocalMasterTareWeightPageComponent} from "./local-master-tare-weight-page/local-master-tare-weight-page.component";
import {TransactionBookingUploadContainerPageComponent} from "./transaction-booking-upload-container-page/transaction-booking-upload-container-page.component";
import {TransactionTSSummaryPortPairAllocationPageComponent} from "./transaction-ts-port-home/transaction-ts-summary-port-pair-allocation-page/transaction-ts-summary-port-pair-allocation-page.component";
import { TransactionUnlockInvoicePageComponent } from './transaction-unlock-invoice-page/transaction-unlock-invoice-page.component';



LicenseManager.setLicenseKey('Samudera_Shipping_Line_Ltd__Global_Operation_and_Documentation_System_1Devs13_February_2020__MTU4MTU1MjAwMDAwMA==80d3c302af8c7b0c181bdd42151f95ec');


@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    NotFoundPageComponent,
    GridPluginComponent2,
    LoginPageComponent,
    LoginNewsPageComponent,
    MainComponent,
    BlankPageComponent,
    ChangePasswordPageComponent,
    ResetPasswordPageComponent,

    HomePageComponent,
    DashboardPageComponent,

    TransactionBookingFlagPageComponent,
    TransactionBookingMaintenanceLatestPageComponent,
    TransactionBookingMaintenancePageComponent,
    TransactionBookingMaintenanceCoparnPageComponent,
    TransactionBookingSplitPageComponent,
    TransactionBoookingTransferPageComponent,
    TransactionBoookingReinstatementPageComponent,
    TransactionVesselAllocationPageComponent,
    HSCodeToolkitPageComponent,

    TransactionCloseVesselPageComponent,
    TransactionUnlockBookingPageComponent,
    TransactionSASTPageComponent,
    TransactionOBOPPageComponent,
    TransactionDiscrepanciesPageComponent,
    TransactionQlecPageComponent,

    TransactionBLFlagStatusPageComponent,
    TransactionBLMaintenancePageComponent,
    TransactionBLSplitPageComponent,
    TransactionBLTransferPageComponent,
    TransactionUnlockBLPageComponent,
    TransactionBLFreightMatchPageComponent,

    TransactionCLLPageComponent,
    TransactionBLCrossReferenceByBookingPageComponent,
    TransactionBLCrossReferenceByContainerPageComponent,
    TransactionBLTransshipmentPageComponent,
    TransactionStandingInstructionPageComponent,
    TransactionDebitCreditNotePageComponent,
    TransactionReceiptPageComponent,
    TransactionChargeTabPageComponent,

    TransactionTSPortPairAllocationPageComponent,
    TransactionTSContainerMovementPageComponent,

    TransactionAutoCreationBLViaCLLPageComponent,
    TransactionFreightManagementPageComponent,
    TransactionFreightApprovalPageComponent,
    TransactionContractRatePageComponent,
    TransactionBlReinstatementPageComponent,
    TransactionAutoCreationofBLSastPageComponent,
    TransactionAutoCreationofBLQlecPageComponent,
    TransactionAutoCreationofBLObopPageComponent,

    TransactionBlFlagStatusLatestPageComponent,
    TransactionBlMaintenanceLatestPageComponent,
    TransactionBlMaintenanceLatestProtoPageComponent,
    TransactionBlMaintenanceLatestProto2PageComponent,

    TransactionBlFreightMatchLatestPageComponent,
    TransactionBLSplitLatestPageComponent,
    TransactionBlTransferLatestPageComponent,
    TransactionBLCombineLatestPageComponent,
    TransactionBLUncombineLatestPageComponent,
    TransactionUnlockBlLatestPageComponent,
    TransactionBLLockedMonitoringPageComponent,

    MachineReportPageComponent,
    LocalMasterExchangeRatePageComponent,
    LocalMasterBankPageComponent,
    LocalMasterChargeCodePageComponent,
    LocalMasterGstPageComponent,

    MasterVesselFormPageComponent,
    MasterServicePageComponent,
    MasterPackageTypePageComponent,
    MasterVesselPageComponent,
    MasterPerCodePageComponent,
    MasterVesselSchedulePageComponent,
    MasterCurrencyPageComponent,
    // tslint:disable-next-line:indent
	  MasterRegionPageComponent,
    MasterCommodityPageComponent,
    MasterPrincipalPageComponent,
    MasterRecDelTypePageComponent,
    MasterReceiptTypePageComponent,
    MasterDeliveryTypePageComponent,
    MasterClausePageComponent,
    MasterSpecialDetailPageComponent,
    MasterCustomerPageComponent,
    MasterCustomerNewPageComponent,
    MasterCustomerChinaPageComponent,
    MasterCountryPageComponent,
    MasterLocationPageComponent,
    MasterChargePageComponent,
    MasterExchangeRatePageComponent,
    MasterContainerIsoMappingPageComponent,
    MasterLocationGroupPageComponent,
    MasterNewsPageComponent,
    MasterContainerTypePageComponent,
    MasterSurchargePageComponent,
    LocalMasterTerminalClosingTimePageComponent,
    MasterUserManagementPageComponent,
    MasterCustomerPicPageComponent,
    MasterDgClassPageComponent,
    MasterQlecPageComponent,
    MasterReasonPageComponent,
    MasterHSCodePageComponent,
    MasterAdhocChargePageComponent,

    ReportBlPrintSinPageComponent,
    ReportBlDraftPageComponent,
    ReportBlCopyPageComponent,
    ReportBlOriginalPageComponent,
    ReportBlVgmPageComponent,
    ReportMonthlyLiftingPageComponent,
    ReportMonthlyLiftingNormalPageComponent,
    ReportMonthlyLiftingReeferPageComponent,
    ReportMonthlyLiftingTankPageComponent,
    ReportMonthlyLiftingPortPageComponent,
    ReportFinanceActualFreightBillPageComponent,
    ReportFinanceActualFreightBillLatestPageComponent,
    ReportFinanceRePrintFreightBillPageComponent,
    ReportFinancePerformaFreightBillPageComponent,
    ReportFinanceProformaFreightBillLatestPageComponent,
    ReportFinanceApprovedListSummaryPageComponent,
    ReportFinanceFreightListPageComponent,
    ReportFinanceCargoManifestPageComponent,
    ReportOperationPreliminaryContainerLoadingListPageComponent,
    ReportOperationFinalContainerLoadingListPageComponent,
    ReportOperationContainerLoadingListPageComponent,
    ReportOperationContainerLoadingListCocPageComponent,
    ReportOperationContainerLoadingListCocProformaPageComponent,
    ReportOperationContainerLoadingListBlPageComponent,
    ReportOperationDailyBookingProgressPageComponent,
    ReportOperationCargoManifestPageComponent,
    ReportOperationBookingForecastDailyVesselPageComponent,
    ReportOutboundPageComponent,
    ReportSocCustomerRatePageComponent,
    ReportLiftingCustomerPageComponent,

    EdiVesselSchedulePageComponent,
    EDIProcessEDIBookingPageComponent,
    EDIPortMappingPageComponent,
    EDIReceiveBayPlanPageComponent,
    EDIReceiveBLDataFromPenavicoPageComponent,
    EDIReceiveTallyListPageComponent,
    EDIReceiveBookingFromAgentPageComponent,
    EDITransmitBookingDataToPSAPageComponent,
    EDITransmitCLLToFISSIPageComponent,
    EDITransmitFinalManifestPageComponent,
    EDITransmitToDataCenterPageComponent,
    EDITransmitToEMASPageComponent,
    EDITransmitToPlanMasterPageComponent,
    EDITransmitUnpostedCreditDebitToCodaPageComponent,
    EDITransmitUnpostedInvoiceToCodaPageComponent,


    TransactionVesselListingByportofcallPageComponent,
    TransactionSailingSchedulePageComponent,

    PFCBulkUpdateContractRatePageComponent,
    PFCBulkUpdateExistingApprovedFreightPageComponent,
    PFCRequestHistoryPageComponent,
    SettingLockPeriodPageComponent,
    SettingBookingEDIPortMappingPageComponent,
    SettingSilentPrintingPageComponent,

    ExperimentalBookingRequisitionPageComponent,
    ExperimentalRegisterPageComponent,
    ExperimentalProfilePageComponent,
    ExperimentalMessagesPageComponent,
    ExperimentalVesselLookupPageComponent,
    ExperimentalTemplatePageComponent,
    ExperimentalContainerTrackingPageComponent,
    ExperimentalMfoPageComponent,
    MasterDepoPageComponent,
    TransactionCLLDGPageComponent,
    ReportCllOogListPageComponent,
    ReportCllReeferListPageComponent,
    ReportCllRecapitulationListPageComponent,
    ReportCllMultipleDGListPageComponent,
    ReportCllDGListPageComponent,
    ReportBookingForecastPageComponent,
    ReportBookingDeletedPageComponent,

    ReportBookingFreetimePageComponent,
    TransactionFreightManagementMRGPageComponent,

    TransactionBookingOrphanagePageComponent,
    ReportMatchingCllBlPageComponent,
    ReportDailyEmptyReleasingOrderPageComponent,
    ReportContainerGateInSummaryPageComponent,
    CustomPinnedRowRenderer,
    MasterCustomerViewPageComponent,
    TransactionBlSameContainerListPageComponent,
    newDialogComponent,
    TransactionInvoiceFlagStatusPageComponent,

    EDITransmitToDataCenterSinConverterPageComponent,
    EDITransmitToDataCenterShaConverterPageComponent,
    ReportFinanceProformaFreightListPageComponent,
    TransactionReceiptPageExportCodaComponent,
    EdiTransmitToCodaConfigComponent,
    FourDigitDecimalNumberDirective,
    FourDigitDecimalNumberWithCommasSeparatorDirective,
    EdiTransmitEdifactPageComponent,
    LimitDecimalsDirective,
    EdiGenerationToDepotComponent,
    TransactionTSPortPairAllocationPageComponentV2,
    TransactionBlSameSealNoPageComponent,
    TransactionTsPortHomeComponent,
    TransactionProspectBookingComponent,
    ReportFinanceCodeTransmissionLogComponent,
    TransactionTsConfigComponent,
    TransactionTsExceptionComponent,
    TransactionTsExceptionHandlingComponent,
    TransactionTsRenominationComponent,
    RenominateDialogComponent,
    TransactionBookingCocReleasePageComponent,
    LocalMasterTareWeightPageComponent,
    TransactionBookingUploadContainerPageComponent,
    TransactionTSSummaryPortPairAllocationPageComponent,
    TransactionUnlockInvoicePageComponent
   ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpModule,
    routing,
    SibegoUIModule,
    AgGridModule.withComponents([CustomPinnedRowRenderer]),
    AutonumericModule.forRoot(),
    ReactiveFormsModule,
    // AllocationsModule
    /*
    AgmCoreModule.forRoot({apiKey: 'AIzaSyD33Xh2aPUCPpd_5b1V3CnwVAioO-kmnME'}),
    AgmDirectionModule,
    console.log
    */
    HttpClientModule

  ],
  providers: [appRoutingProviders, Authorize, DatePipe ],
  bootstrap: [AppComponent]
})
export class AppModule { }
