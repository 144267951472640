import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil, ConfigService, CookieService } from 'sibego-ui-library';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare  var $:any;

export class InvoiceFlagStatusParam {
  invoiceOfficeCode = '';
  invoiceVesselId = '';
  invoiceVesselCode = '';
  invoiceVesselName = '';
  invoiceVoyage = '';
  invoiceBound = 'O';
  constructor() {}
}

@Component({
  selector: 'app-transaction-receipt-page',
  templateUrl: './transaction-receipt-page.component.html',
  styleUrls: ['./transaction-receipt-page.component.css']
})
export class TransactionReceiptPageComponent extends Validator implements OnInit, AfterViewInit {

  @ViewChild('cbVessel') cbVessel: ComboPluginComponent;
  @ViewChild('cbVoyage') cbVoyage: ComboPluginComponent;

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'sailingScheduleFlagStatus';

  loading = false;

  /* Parameter settings */
  settingToolbar;
  settingVessel;
  settingVoyage;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model = new InvoiceFlagStatusParam();

  validatorRules = {};

  isExportContent = false;
  settingSocOPR = {
    id: 'cbSocOPR',
    type: 'select', // search | select | select input
    url: '',
    urlType: 'dynamic',
    placeholder: '',
    code: 'opr',
    title: 'opr',
    allowRemove: false
  }

  receiptForm: FormGroup;
  rowDataCNDN: any;
  columnDefsCNDN: any;
  pinnedBottomRowDataCNDN;
  gridApi;


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router, private fb: FormBuilder, private configService: ConfigService, private cookieService: CookieService) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        { name: 'Confirm', event: 'confirm', icon: '' },
        { name: 'Over payment', event: 'close', icon: '' },
        { name: 'Close', event: 'close', icon: 'power' },
        { name: 'Export to CODA', event: 'export', icon: '' },
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Receipt(Knock Off)'
    }

    this.receiptForm = this.fb.group(
      {
        paymentMode: ['cash', [Validators.required]],
        cheque: ['', [Validators.required]],

        date: ['', [Validators.required]],
        currency: ['SGD', [Validators.required]],
        account: ['cash', [Validators.required]],
        amount: ['', [Validators.required]],
        bankCharges: ['', [Validators.required]],
        invoice: ['', [Validators.required]],
        blNo: ['', [Validators.required]],
        vesselName: ['', [Validators.required]],
        customerName: [''],
        voyageNo: ['', [Validators.required]],
        customerName2: ['', [Validators.required]],
        totalRecord: ['', [Validators.required]],
      }
    )

    this.settingVessel = {
      id          : 'cbVessel',
      type        : 'search enter', // search | select | select input
      url         : this.configService.config.BASE_API.toString() + '/MasterVessels/findByComboBoxControl/vesselName={query}',
      maxChars    : 3,
      template    : 'grid', // default
      placeholder : '-- Vessel --',
      title       : 'vesselName',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Vessel Code', field: 'vesselCode', width: 175},
        {header: 'Vessel Name', field: 'vesselName', width: 200},
        {header: 'Valid', field: 'isValid', width: 50}
      ],
      maxlength: 50
    };

    this.settingVoyage = {
      id          : 'cbVoyage',
      type        : 'search enter', // search | select | select input
      url         : '',
      maxChars    : 0,
      template    : 'grid', // default
      placeholder : '-- Voyage --',
      title       : 'voyage',
      description  : '',
      isMark      : true,
      columns     : [
        {header: 'Voyage', field: 'voyage', width: 100},
        {header: 'Bound', field: 'bound', width: 100},
        {header: 'Service', field: 'serviceCode', width: 150},
        {header: 'Local ETA', field: 'localEta', width: 300}
      ],
      maxLength: 20,
    };

  }
  ngOnInit() {
  }

  ngAfterViewInit() {

  }

  toolbarEvent(event) {
    console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
      case 'export':
        this.handleExport();
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

  handleExport(){
   this.isExportContent = true;
  }

  closeExportContent(val) {
    this.isExportContent = false;
  }

  onGridReadyCNDN(params) {
    this.gridApi = params.api;
    this.gridApi.hideOverlay();
  }

  // * Field Validation On Paste
  onPasteValidation(
    event: any,
    field: string,
    regexName: RegExp,
    isAlphabet: boolean = false,
    validSPChar: string = ''
  ) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData("text");

    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets and space' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  backspaceValue(event: any, regexName: RegExp, field: string, isAlphabet: boolean, validSPChar: string) {
    switch (event.code) {
      case "Backspace":
      case "ControlLeft":
        this.showErrorMessage(regexName, event.target.value, field, isAlphabet, validSPChar);
        break;
      default:
        return
    }
  }

  showErrorMessage(regexName, pastedText, field, isAlphabet = false, validSPChar) {
    if (regexName.test(pastedText) == false) {
      this.model[`error-${field}`] = `${isAlphabet ? 'Only accept alphabets' : 'Only accept alphanumeric'} ${validSPChar}`;
    } else {
      this.model[`error-${field}`] = "";
    }
  }

  changeEventVessel(event) {
    if (event.vesselId != null && event.vesselId !== '' && event.vesselId !== undefined) {
      this.model.invoiceVesselId = event.vesselId;
      this.model.invoiceVesselName = event.vesselName;
      this.model.invoiceVesselCode = event.vesselCode;
      this.model.invoiceVoyage = '';
      this.cbVoyage.setValue('');
      this.cbVoyage.setUrl(this.configService.config.BASE_API.toString() + '/MasterSailingSchedules/findByComboBoxControlVoyageInvoice/' +
        this.cookieService.getDefaultLocationCode() + '/' + event.vesselId + '/' + this.model.invoiceBound  + '/voyage_like={query}');
    } else {
      this.model.invoiceVesselId = '';
      this.model.invoiceVesselName = '';
      this.model.invoiceVesselCode = '';
      this.model.invoiceVoyage = '';
      this.cbVoyage.setValue('');
      this.cbVoyage.setUrl('');
    }
  }

  changeEventVoyage(event) {
    if (event.voyage != null) {
      this.model.invoiceVoyage = event.voyage;
    } else {
      this.model.invoiceVoyage = '';
    }
  }

}
