import {
  Component,
  OnInit,
  EventEmitter,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2
} from "@angular/core";
import { CustomerChina,CustomerChinaDetail} from "../shared/index";
import FormValidation from "../../utils/formValidation";
import {
  ListStore,
  Authorize,
  Validator,
  GridPluginComponent,
  ComboPluginComponent,
  GenericService,
  GenericUtil,
  ConfigService,
  CookieService,
  ToolbarPluginComponent,
} from "sibego-ui-library";
import * as FileSaver from "file-saver";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { InternalNgModuleRef } from "@angular/core/src/linker/ng_module_factory";
import {
  ColumnPinnedEvent,
  ColumnResizedEvent,
  ColumnApi,
  GridApi,
  ICellRenderer,
  ICellRendererParams
} from "ag-grid-community";
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from "rxjs/operators";
declare var $: any;

@Component({
  selector: "app-master-customer-china-page",
  templateUrl: "./master-customer-china-page.component.html",
  styleUrls: ["./master-customer-china-page.component.css"],
})
export class MasterCustomerChinaPageComponent
  extends Validator
  implements OnInit, AfterViewInit
{
  @ViewChild("grid") grid: GridPluginComponent;
  @ViewChild("gridDetails") gridDetails: GridPluginComponent;
  @ViewChild("cbCountry") cbCountry: ComboPluginComponent;
  @ViewChild("cbCustomerName") cbCustomerName: ComboPluginComponent;
  @ViewChild("cbCustomerHistroy") cbCustomerHistory: ComboPluginComponent;
  @ViewChild("gridHistory") gridHistory: GridPluginComponent;
  @ViewChild("toolbarSetting") toolbarSetting : ToolbarPluginComponent;
  @ViewChild("txtCustomerCode")txtCustomerCode:Text;

  /* Parameter for information into Footer */
  info = "";

  /* Parameter for dialog */
  dialog: any;

  dataGrid: any;

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = "";

  /* Parameter modeForm (Default : false) */
  modeForm = false;
  modeToolbar = false;
  form = "";

  /* Parameter settings */
  settingToolbar;
  settingGrid;
  settingGridDetails;
  settingCountry;
  settingCustomerName;
  settingCustomerHistory;
  settingGridHistory;
  settingContOpr;
  settingAuCustName;
  settingAuDisName;
  settingAuCountry;
  checkboxAdvanceSearch;
  getRowStyle;
  rowData: any;
  rowData2 = [];
  pinnedBottomRowData;
  columnDefs;
  columnFinanceDefs;
  columnGeneralDefs;
  columnPartyDefs;
  columnAuditLogDefs;
  gridApi;
  columnApi;
  rowMultiSelectWithClickP = true;
  rowSelectionP = 'multiple';
  searchValid;

  typeCustomerLogStartDate = "calendar";
  typeCustomerLogEndDate = "calendar";

  // column changes
  pinnedColumns = [];
  sortedColumns: any = undefined;
  filterColumns: any = undefined;
  resizedColumns: any = undefined;

  /* Parameter model */
  model = new CustomerChina();
  modelDetail = new CustomerChinaDetail();
  formValidation = new FormValidation();
  paramRetrieve = {"customerName":"","displayName":"","country":"","isValid":"*","isDeleted":"*","category":"*","search":"*","advanceSearch": {}};
  allowRetrieve = false;


  loading = false;
  isUpdate = false;

  lock = false;
  lockCreate = false;
  isCustomerCodeEnabled: boolean = false;
  customerIdHistory: string = "";
  customerCodeHistory: string = "";
  valHistoryStartDate: string = "";
  valHistoryEndDate: string = "";

  isSOC: boolean = false;
  isCOC: boolean = false;
  isSLOT: boolean = false;

  appStatusLabel: String = "";
  tabStatus: String = "tab1";
  tabStatus1: string = "active";
  tabStatus2: string = "active";
  tabStatus3: string = "active";
  tabStatus4: string = "active";

  hitUrl: string = this.configService.config.BASE_API.toString();

  viewOnlyStatus = false;
  currentMenuID;
  currentMenu;

  /* validatorRules */
  validatorRules = {};

  custNameSearch = '/*/*/*';
  paramRetrieveEncode = '';

  pageOnScroll = 2;
  totalPages = 0;
  isLoadPageDone = true;
  isMsgShow = true;

  selectedItem: any;
  rowPredictive: any;
  showList: boolean = false;
  noRecord: boolean = true;
  filterRows: any;
  loadingCustName: boolean = false;
  lastLengthSearch: any;


  constructor(
    private auth: Authorize,
    private genericService: GenericService,
    private genericUtil: GenericUtil,
    private configService: ConfigService,
    private cookieService: CookieService,
    private _http: HttpClient,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer
  ) {
    super();

    this.settingToolbar = {
      buttonsFront:   [
        { name : 'Create',  event: 'create', icon : 'plus' },
        { name : 'Update',  event: 'update', icon : 'write' },
        { name : 'Delete',  event: 'delete', icon : 'minus circle' },
        { name : 'Print' ,  event: 'print' , icon : 'file outline' },
        { name : 'History',  event: 'history' , icon : 'history' },
        { name : 'Cancel',  event: 'cancel', icon : 'remove' },
      ],
      buttonsDetail:  [
        { name : 'Save'   ,  event: 'save'  , icon : 'checkmark' },
        { name : 'Delete',  event: 'delete', icon : 'minus circle' },
        { name : 'Cancel' ,  event: 'cancel', icon : 'remove' },

      ],
      createDefaultFront: false,
      createDefaultDetail: false,
      searchBy: [
        { display: "Customer Code", value: "customerCode" },
        { display: "Customer Name (Master)", value: "customerName" },
        { display: "User Created", value: "userCreated" },
        { display: "Date Created", value: "dateCreated" },
        { display: "User Modified", value: "userModified" },
        { display: "Date Modified", value: "dateModified" },
        { display: "User Invalid", value: "userInvalid" },
        { display: "Date Invalid", value: "dateInvalid" },
        { display: "User Deleted", value: "userDeleted" },
        { display: "Date Deleted", value: "dateDeleted" },
      ],
      urlhelp: "assets/pdf/panduan.pdf",
    };



    this.settingGrid = {
      url:this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findGeneralSha",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {header: "Customer Code",field: "customerCode",width: 250 },
        {header: "Customer Name (Master)",field: "customerName", width: 170},
        { header: "User Created", field: "userCreated",},
        { header: "Date Created", field: "dateCreated",},
        { header: "User Modified", field: "userModified",},
        { header: "Date Modified", field: "dateModified",},
        { header: "User Invalid", field: "userInvalid",},
        { header: "Date Invalid", field: "dateInvalid",},
        { header: "Valid", field: "isValid", width: 80 },
        { header: "User Deleted", field: "userDeleted",},
        { header: "Date Deleted", field: "dateDeleted",},
        { header: "Deleted", field: "isDeleted",},

      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,
      enableSelectAll: true,
      enablePagination: true,
      sortingColumns: "customerName",
      sortingDirection: "ASC",
    };

    this.settingGridHistory = {
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findGeneralHistorySHAByCustomerId",
      page: 10,
      columns: [
        { header: "No", field: "no", width: 50 },
        {header: "Customer Code",field: "customerCode",width: 250 },
        {header: "Customer Name (Master)",field: "customerName", width: 170},
        { header: "User Created", field: "userCreated",},
        { header: "Date Created", field: "dateCreated",},
        { header: "User Modified", field: "userModified",},
        { header: "Date Modified", field: "dateModified",},
        { header: "User Invalid", field: "userInvalid",},
        { header: "Date Invalid", field: "dateInvalid",},
        { header: "Valid", field: "isValid", width: 80 },
        { header: "User Deleted", field: "userDeleted",},
        { header: "Date Deleted", field: "dateDeleted",},
        { header: "Deleted", field: "isDeleted",},
        { header: "Action Log", field: "action", width: 110 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: true,

      enablePagination: true,
    };

    this.settingGridDetails = {
      url: "",
      page: 5,
      columns: [
        { header: "Seq", field: "seqNo", width: 50 },
        { header: "Display Name", field: "customerDisplayName", width: 150 },
        { header: "Address", field: "fullAddress", width: 200 },
        { header: "Contact Person", field: "contactPerson", width: 150 },
        { header: "Tel. No(s)", field: "telno", width: 100 },
        { header: "Email", field: "email", width: 100 },
        { header: "Fax. No(s)", field: "fax", width: 100 },
        { header: "Valid", field: "isValidDetail", width: 100 },
      ],
      buttons: [{ name: "Edit", event: "edit", enabled: true }],
      enableSorting: false,
      enableSelectAll: true,
      enablePagination: false,
      sortingColumns: "customerDisplayName",
      sortingDirection: "ASC",
    };


    this.settingCustomerName = {
      id: "cbCustomerName",
      type: "search enter", // search | select | select input
      url: this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlAll/customerName={query}",
      maxChars: 3,
      template: "grid", // default
      placeholder : 'Search Customer Name',
      title       : 'customerName',
      description: "",
      isMark: true,
      columns: [
        { header: "Customer ID", field: "customerId", width: 100 },
        { header: "Customer Code", field: "customerCode", width: 100 },
        { header: "Customer Name", field: "customerName", width: 300 },
        { header: "Valid?", field: "isValid", width: 125 },
      ],
    };


    this.settingCountry = {
      id: "cbCountry",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCountries/findGeneral/*/*/{query}/1/5/*/*",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search Country",
      title: "countryName",
      description: "",
      isMark: true,
      columns: [
        { header: "Country Code", field: "countryCode", width: 100 },
        { header: "Country Name", field: "countryName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    };

    this.settingCustomerHistory = {
      id: "cbCustomerHistory",
      type: "search enter", // search | select | select input
      url:
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/findGeneralHistorySHA/{query}",
      maxChars: 3,
      template: "grid", // default
      placeholder: "Search ...",
      title: "customerCode",
      description: "",
      isMark: true,
      columns: [
        { header: "Customer Code ID", field: "customerId", width: 250 },
        {
          header: "Customer Code",
          field: "customerCode",
          width: 200,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 50 },
      ],
    };


    this.settingContOpr = {
      id: "cbContOpr",
      type: "select",
      url: '[ { "contOprCode" : "ALL", "contOprName" : "All" }, { "contOprCode" : "SOC", "contOprName" : "SOC" }, { "contOprCode" : "COC", "contOprName" : "COC" }, { "contOprCode" : "SLOT", "contOprName" : "SLOT" }]',
      urlType: "inject",
      placeholder: "All",
      code: "contOprCode",
      title: "contOprName",
    };


    this.searchValid = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted|Y' },
    ];

    this.settingAuCustName = {
      id          : 'auCustName',
      url         :this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlAll/customerName={query}",
      maxChars    : 3,
      placeholder : 'Search Customer Name',
      title       : 'customerName',
      valueSelect : 'customerName',
      // boxStyle    : 'width:240px !important;',
      columns     : [
        {
          header: "Customer Name",
          field: "customerName",
          width: 300,
          type: "string",
        },
        { header: "Valid?", field: "isValid", width: 125 },
      ]
    }

    this.settingAuDisName = {
      id          : 'auDisName',
      url         : this.configService.config.BASE_API.toString() +"/MasterCustomers/findByComboBoxControlDisplayName/customerDisplayName={query}",
      maxChars    : 3,
      placeholder : 'Search Display Name',
      title       : 'customerDisplayName',
      valueSelect : 'customerDisplayName',
      // boxStyle    : 'width:240px !important;',
      columns     : [
        {header: 'Seq No', field: 'seqNo', width: 150},
        {header: 'Display Name', field: 'customerDisplayName', width: 300},
        {header: 'Customer Id', field: 'customerId', width: 75}
      ]
    }

    this.settingAuCountry = {
      id          : 'auCountry',
      url         : this.configService.config.BASE_API.toString() +"/MasterCountries/findGeneral/*/*/{query}/1/5/*/*",
      maxChars    : 3,
      placeholder : "Search Country",
      title       : "countryName",
      valueSelect : "countryName",
      // boxStyle    : 'width:240px !important;',
      columns: [
        { header: "Country Code", field: "countryCode", width: 100 },
        { header: "Country Name", field: "countryName", width: 300 },
        { header: "Valid", field: "isValid", width: 50 },
      ],
    }
    /**
         * This events get called by all clicks on the page
         */
     this.renderer.listen('window', 'click',(e:Event)=>{
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      // if (this.inputSearch != null && this.listSelect != null) {
      //   if(e.target !== this.inputSearch.nativeElement && e.target!==this.listSelect.nativeElement){
      //     this.showList=false;
      //   }
      // }

      // if (this.inputSearchDN != null && this.listSelectDN != null) {
      //   if(e.target !== this.inputSearchDN.nativeElement && e.target!==this.listSelectDN.nativeElement){
      //     this.showListDN=false;
      //   }
      // }

      // if (this.inputSearchCN != null && this.listSelectCN != null) {
      //   if(e.target !== this.inputSearchCN.nativeElement && e.target!==this.listSelectCN.nativeElement){
      //     this.showListCN=false;
      //   }
      // }
    });
  }

  ngOnInit() {
    $(".ui.accordion").accordion();
  }

  firstInit() {
    this.currentMenuID = this.cookieService
      .getCookie("currentMenu")
      .split("|")[0];
    this.currentMenu = this.cookieService
      .getCookie("currentMenu")
      .split("|")[1];
    if (this.currentMenu.includes("(View Only)")) {
      this.viewOnlyStatus = true;
    }

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

     this.grid.search = "*/*/*";
     this.grid.onFirst();
  }

  ngAfterViewInit() {
    $(".test.checkbox").checkbox();
    $(".special.cards .image").dimmer({
      on: "hover",
    });
    this.firstInit();

    this.setValidatorHeader();

    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";


    this.genericUtil.setBreadcrumb(
      "Master,Entity,Master-Customer:/main/master-customer"
    );
    this.grid.search = "*/*/*";
    this.grid.onFirst();
    this.toolbarSetting.searchBy2 = [
      { display: 'Invalid', value: 'N' },
      { display: 'Valid', value: 'Y' },
      { display: 'Deleted', value: 'isDeleted-Y' },
    ];


   /*  $(".menu .item").tab();

    setTimeout(() => {
      $('#rightmenu').hide();
      // this.initTab2();
      // console.log(this.grid);
    }, 100); */
  }



  replaceNbsps(str) {
    var re = new RegExp(String.fromCharCode(160), "g");
    return str.replace(re, " ");
  }


  sanitize(style: string) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }


  message(
    txtIcon: string,
    txtHeader: string,
    txtContent: string,
    btns: string,
    eve: any
  ) {
    this.dialog = {
      icon: txtIcon,
      header: txtHeader,
      content: txtContent,
      buttons: btns,
      event: eve,
    };
    $("#dialog").modal("show");
  }

  eventMessage(event: any) {
    if (event != "") {
      eval(event);
    }
  }

  infoGrid(event: any) {
    this.info = event;
  }

  infoGridDetails(event: any) {
    this.info = event;
  }

  gridEvent(event:any) {
    switch (event.split(".")[0]) {
       case 'selected':
         if(this.grid.getSelectedValues().length > 0){
           if(this.viewOnlyStatus){
             this.disableToolbarButtons = "create,update,delete";
           } else {
             this.disableToolbarButtons = "";
           }
         }else{
           if(this.viewOnlyStatus){
             this.disableToolbarButtons = "create,update,delete";
           } else {
             this.disableToolbarButtons = "update,delete";
           }
         }
         break;
       case 'click' :
         if(this.grid.getSelectedValues().length > 0){
           if(this.viewOnlyStatus){
             this.disableToolbarButtons = "create,update,delete";
           } else {
             this.disableToolbarButtons = "";
           }
         }else{
           if(this.viewOnlyStatus){
             this.disableToolbarButtons = "create,update,delete";
           } else {
             this.disableToolbarButtons = "update,delete";
           }
         }
       case "afterLoad":
         let self = this;
         setTimeout(() => {
           var idx = 0;
           this.grid.listStore.store.forEach((element) => {
             if(element["isDeleted"] == 'Y'){
               self.markRedRow(idx); //element.no-1
             } else {
               self.markDefaultRow(idx); //element.no-1
             }
             idx++;
           });
         }, 1000);
       default:
         let strEvent:string = event;
         if(strEvent != ''){
           let arr:string[] = strEvent.split('-');
           if(arr[0] == "edit"){
             this.handleUpdate();

           }else if(arr[0] == "dblClick"){

             let dt = this.grid.getSelectedValues()[0];
             if (dt.isDeleted == "Y") {
                this.message(
                 "warning",
                 "Error Input",
                 "The record has been deleted",
                 "okonly",
                 { ok: "this.loading=false;" }
                );
               return false;
             }
             else
             {

              this.modeForm = true;
              this.modeToolbar = true;
              this.setData(dt);

              this.lock=true;
              this.isUpdate = true;
              // Lock all fields initially
              this.isCustomerCodeEnabled = true;

              // Unlock only the customer code field
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "";
              }
              this.invisibleToolbarButtons = "create,print";


             }
           }
         }

         break;

     }
   }



  gridEventDetails(event: any) {
    //// console.log(event);
    switch (event.split(".")[0]) {
      case "selected":
        break;

      case "click":
        break;

      default:
        let strEvent: string = event;
        if (strEvent != "") {
          let arr: string[] = strEvent.split("-");
          switch (arr[0]) {
            case "edit":

              break;
            case "dblClick":
              //this.setValidatorDetails();
              if (this.viewOnlyStatus) {
                this.disableToolbarButtons = "create,update,delete";
              } else {
                this.disableToolbarButtons = "save,cancel";
              }

              if (this.model["error-customerName"] != null) {
                this.model["error-customerName"] = "";
              }
              this.modelDetail = new CustomerChinaDetail();
              this.modelDetail.no = this.gridDetails.getSelectedValues()[0].no;
              this.modelDetail.seqNo = this.gridDetails.getSelectedValues()[0].seqNo;
              this.modelDetail.customerDisplayName = this.gridDetails.getSelectedValues()[0].customerDisplayName;
              this.modelDetail.contactPerson = this.gridDetails.getSelectedValues()[0].contactPerson;
              this.modelDetail.fullAddress = this.gridDetails.getSelectedValues()[0].fullAddress;
              this.modelDetail.telno = this.gridDetails.getSelectedValues()[0].telno;
              this.modelDetail.email = this.gridDetails.getSelectedValues()[0].email;
              this.modelDetail.fax = this.gridDetails.getSelectedValues()[0].fax;
              if(this.gridDetails.getSelectedValues()[0].isValidDetail == "Y"){
                this.modelDetail.isValidD = true;
              }else{
                this.modelDetail.isValidD = false;
              }
              this.modelDetail.isValidDetail = this.gridDetails.getSelectedValues()[0].isValidDetail;



              $("#display").transition();
              $("#displayGrid").transition("hide");

              break;

            default:
              break;
          }
        }

        break;
    }
  }

  toolbarEvent(event: any) {
    //// console.log('event : ' + event);
    switch (event) {
      case "create":
        this.handleCreate();
        break;
      case "update":
        this.handleUpdate();
        break;
      case "delete":
        this.handleDelete();
        break;
      case "print":
        this.handlePrint();
        break;
      case "save":
        if (this.model.customerId != "") {
          this.model.userModified = this.cookieService.getName();
        } else {
          this.model.userCreated = this.cookieService.getName();
        }
        this.handleSave();
        break;
      case "cancel":
        this.handleCancel();
        break;
      case "history":
        this.handleHistory();
        break;

      default:
        let str: string = event;
        if (str.indexOf("search") > -1) {
          this.grid.search = str.replace("search:", "");
          this.grid.clearSelectedValues();
          this.grid.onFirst();
          this.handleCancel();
        } else {
          this.handleCancel();
        }
        break;
    }
  }


  setDataUpdate(){
    //SET MODEL DATA FOR UPDATE PROCESS
let data = this.grid.getSelectedValues()[0];
// console.log(data);
this.model = new CustomerChina();

this.model.customerId = String(data.customerId).trim().toUpperCase();
this.model.customerCode = String(data.customerCode).trim();
this.model.customerName = String(data.customerName).trim().toUpperCase();
console.log(this.model.customerName)
this.cbCustomerName.setValue(this.model.customerName.trim().toUpperCase());
this.model.userCreated = String(data.userCreated).trim().toUpperCase();
this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
this.model.userModified = String(data.userModified).trim().toUpperCase();
this.model.dateModified = String(data.dateModified).trim().toUpperCase();
this.model.countryCode = String(data.countryCode).trim().toUpperCase();
this.model.countryName = String(data.countryName).trim().toUpperCase();
this.cbCountry.setValue(this.model.countryName.trim().toUpperCase());
this.model.companyRegNo = String(data.companyRegNo).trim().toUpperCase();
this.model.remarks = String(data.remarks).trim().toUpperCase();

    if (String(data.isValid).trim().toUpperCase() == "Y") {
      this.model.isValid = true;
    } else {
      this.model.isValid = false;
    }

    if (String(data.isShipper).trim().toUpperCase() == "Y") {
      this.model.isShipper = true;
    } else {
      this.model.isShipper = false;
    }

    if (String(data.isConsignee).trim().toUpperCase() == "Y") {
      this.model.isConsignee = true;
    } else {
      this.model.isConsignee = false;
    }

    if (String(data.isNotifyParty).trim().toUpperCase() == "Y") {
      this.model.isNotifyParty = true;
    } else {
      this.model.isNotifyParty = false;
    }

    if (String(data.isPayer).trim().toUpperCase() == "Y") {
      this.model.isPayer = true;
    } else {
      this.model.isPayer = false;
    }

    if (String(data.isAgency).trim().toUpperCase() == "Y") {
      this.model.isAgency = true;
    } else {
      this.model.isAgency = false;
    }

    if (String(data.isBookingParty).trim().toUpperCase() == "Y") {
      this.model.isBookingParty = true;
    } else {
      this.model.isBookingParty = false;
    }

    if (String(data.isHaulier).trim().toUpperCase() == "Y") {
      this.model.isHaulier = true;
    } else {
      this.model.isHaulier = false;
    }

    if (String(data.isNvocc).trim().toUpperCase() == "Y") {
      this.model.isNvocc = true;
    } else {
      this.model.isNvocc = false;
    }

    if (String(data.isSupplier).trim().toUpperCase() == "Y") {
      this.model.isSupplier = true;
    } else {
      this.model.isSupplier = false;
    }

    if (String(data.isFreightParty).trim().toUpperCase() == "Y") {
      this.model.isFreightParty = true;
    } else {
      this.model.isFreightParty = false;
    }

    if (String(data.isCoc).trim().toUpperCase() == "Y") {
      this.model.isCoc = true;
      this.isCOC = true;
    } else {
      this.model.isCoc = false;
      this.isCOC = false;
    }

    if (String(data.isSoc).trim().toUpperCase() == "Y") {
      this.model.isSoc = true;
      this.isSOC = true;
    } else {
      this.model.isSoc = false;
      this.isSOC = false;
    }

    if (String(data.isSlot).trim().toUpperCase() == "Y") {
      this.model.isSlot = true;
      this.isSLOT = true;
    } else {
      this.model.isSlot = false;
      this.isSLOT = false;
    }

    this.lock = false;
    this.gridDetails.enable();

    if (data.cocOperatorCode != null) {
      this.model.cocOperatorCode = String(data.cocOperatorCode)
        .trim()
        .toUpperCase();
    }

    if (data.cocOperatorCodeNew != null) {
      this.model.cocOperatorCodeNew = String(data.cocOperatorCodeNew)
        .trim()
        .toUpperCase();
    }

    if (data.socOperatorCode != null) {
      this.model.socOperatorCode = String(data.socOperatorCode)
        .trim()
        .toUpperCase();
    }

    if (data.socOperatorCodeNew != null) {
      this.model.socOperatorCodeNew = String(data.socOperatorCodeNew)
        .trim()
        .toUpperCase();
    }

    if (data.slotOperatorCode != null) {
      this.model.slotOperatorCode = String(data.slotOperatorCode)
        .trim()
        .toUpperCase();
    }


    if (data.slotOperatorCodeNew != null) {
      this.model.slotOperatorCodeNew = String(data.slotOperatorCodeNew)
        .trim()
        .toUpperCase();
    }

    if (data.cocAccountCode != null) {
      this.model.cocAccountCode = String(data.cocAccountCode)
        .trim()
        .toUpperCase();
    }
    if (data.socAccountCode != null) {
      this.model.socAccountCode = String(data.socAccountCode)
        .trim()
        .toUpperCase();
    }
    if (data.slotAccountCode != null) {
      this.model.slotAccountCode = String(data.slotAccountCode)
        .trim()
        .toUpperCase();
    }

    if (data.cocCreditDays != null) {
      this.model.cocCreditDays = Number.parseInt(data.cocCreditDays);
    }
    if (data.socCreditDays != null) {
      this.model.socCreditDays = Number.parseInt(data.socCreditDays);
    }
    if (data.slotCreditDays != null) {
      this.model.slotCreditDays = Number.parseInt(data.slotCreditDays);
    }

    if (data.cocCreditLimit != null) {
      this.model.cocCreditLimit = data.cocCreditLimit;
    }

    if (data.cocTolerance != null) {
      this.model.cocTolerance = Number.parseInt(data.cocTolerance);
    }

    if (data.socCreditLimit != null) {
      this.model.socCreditLimit = data.socCreditLimit;
    }

    if (data.socTolerance != null) {
      this.model.socTolerance = Number.parseInt(data.socTolerance);
    }

    if (data.slotCreditLimit != null) {
      this.model.slotCreditLimit = data.slotCreditLimit;
    }

    if (data.slotTolerance != null) {
      this.model.slotTolerance = Number.parseInt(data.slotTolerance);
    }

    // console.log(data);
    if (data["masterCustomerDetails"] != null) {
      //this.settingGridDetails.listStore.store = [];
      this.model.masterCustomerDetails = [];
      this.model.masterCustomerDetails = data["masterCustomerDetails"];
      //this.gridDetails.listStore.store = this.model.masterCustomerDetails;
      this.model.masterCustomerDetails.forEach((fe)=>{
        this.gridDetails.listStore.addData(fe);
      });

      this.gridDetails.loadData();
    }

    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
  }



  buildParamEncode() {
    var paramCustom = '';
    paramCustom += "customerName:"+this.paramRetrieve.customerName+"|";
    paramCustom += "displayName:"+this.paramRetrieve.displayName+"|";
    paramCustom += "countryName:"+this.paramRetrieve.country+"|";
    paramCustom += "isValid:"+this.paramRetrieve.isValid+"|";
    paramCustom += "isDeleted:"+this.paramRetrieve.isDeleted+"|";
    paramCustom += "category:"+this.paramRetrieve.category+"|";
    paramCustom += "search:"+this.paramRetrieve.search;
    // console.log(this.paramRetrieve);
    this.paramRetrieveEncode = btoa(paramCustom);
  }

  handleRetrieve() {
    // ag-grid load
    this.gridApi.showLoadingOverlay();
    this.buildParamEncode();
    // customerName:|countryName:|isValid:*|category:*|search:*
    // "/MasterCustomers/findCustomGeneral/1/100/ASC/CustomerName/"+this.paramRetrieveEncode
    this.genericService
      .GET(this.configService.config.BASE_API.toString() +
      "/MasterCustomers/findCustomGeneral/1/100/ASC/CustomerName/"+this.paramRetrieveEncode
      // "/MasterCustomers/findGeneral/"+this.custNameSearch+"/1/100/ASC/CustomerName"
      )
      .subscribe((resp) => {
        this.dataGrid = resp.json();
        // console.log(resp.json()["content"]);
        this.rowData = resp.json()["content"];
        this.totalPages = resp.json()["totalPages"] * 1;
        this.pageOnScroll = 2;
        this.isMsgShow = false;
        this.gridApi.hideOverlay();
      });
  }

  onSelectionChanged(params) {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        if (selectedRows.length > 1) {
          this.disableToolbarButtons = "create,update,print,history";
          return;
        }
        this.disableToolbarButtons = "";
      }
    } else {
      if(this.viewOnlyStatus){
        this.disableToolbarButtons ="create,update,delete";
      } else {
        this.disableToolbarButtons ="update,delete";
      }
    }
  }



  handleRowDataChanged(params) {
    // console.log(params);
  }

  sortChanged(params: any) {
    // console.log("** sortChanged **");
    // console.log(params.api.getSortModel());
    const selectedColumns = params.api.getSortModel()[0];
    // console.log(selectedColumns);

    this.sortedColumns = selectedColumns;
  }

  handleCreate() {
    // handle create event

    this.modeForm = true;
    this.modeToolbar = true;
    this.invisibleToolbarButtons = "";
    this.isCustomerCodeEnabled = true;
    this.model = new CustomerChina();
    this.cbCustomerName.setValue("");
    this.cbCountry.setValue("");

    this.model.isValid = true;
    this.modelDetail = new CustomerChinaDetail();
    this.gridDetails.listStore.store = [];
    this.gridDetails.loadData();

    this.lockCreate = true;
    this.isUpdate = false;

    this.genericUtil.setBreadcrumb(
      "EDI,China Toolkit,master-customer-china:/main/master-customer-china,Form"
    );

    this.invisibleToolbarButtons = "delete";
  }

  handleUpdate() {
    // handle update event

    let dt = this.grid.getSelectedValues()[0];
    if (dt.isDeleted == "Y") {
      this.message(
        "warning",
        "Error Input",
        "The record has been deleted",
        "okonly",
        { ok: "this.loading=false;" }
      );
      return false;
    } else {
      this.modeForm = true;
      this.modeToolbar = true;
      this.setData(dt);

      this.lock=true;
      // Lock all fields initially
      this.isCustomerCodeEnabled = true;
      this.isUpdate = true;


      // Unlock only the customer code field
      if (this.viewOnlyStatus) {
        this.disableToolbarButtons = "create,update,delete";
      } else {
        this.disableToolbarButtons = "";
      }
      this.invisibleToolbarButtons = "create,print";

      // let dt = this.grid.getSelectedValues()[0];
      //let customerId: string = String(dt.customerId).trim();
      //this.onRetrieve(customerId);

    }
  }
/*
  disableCustomerCode() {
    this.txtCustomerCode. = true;
    this.txtCustomerCode.clearSelect;
    this.txtCustomerCode.setValue("");
  } */




  handleDelete()
  {
    // handle delete event

    this.loading = true;
    let deleteList = [];
    var haveDeleteRow = false;

    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.customerId);
      if (element.isDeleted == "Y")
        {
          haveDeleteRow = true;
        }
    });

    if (!haveDeleteRow)
      {
        this.loading = false;
        this.message(
                "delete",
                "Deletion Record",
                "Confirm to delete record(s)?",
                "yesno",
                { yes: "this.deleteEvent()", no: "this.loading = false;" }
              );
      }
      else
      {
        this.message(
          "warning",
          "Error Input",
          "The record has been deleted",
          "okonly",
          { ok: "this.loading=false;" }
        );
      }
  }



  handlePrint() {
    // handle print event
    var formatName = "report-China-Customers-Reports.xls";
   var uri = "";
   // var uri = this.configService.config.BASE_API.toString()+
    //"?q=" +
     // "/Mreport/runReport/80/Customers-Export-Reports/*" + this.cookieService.getDefaultLocationCode().toString().trim();

     if (this.configService.config.standalone) {
      const mreportConfig = this.configService.config.standalone.find(item => item.endpoint === "/Mreport");

      if (mreportConfig) {
        uri = mreportConfig.target +
                    "/Mreport/runReport/80/Customers-Export-Reports/*" +
                    this.cookieService.getDefaultLocationCode().toString().trim();
      } else {
        uri = this.configService.config.BASE_API.toString() +

                    "/Mreport/runReport/80/Customers-Export-Reports/*" +
                    this.cookieService.getDefaultLocationCode().toString().trim();
      }
    } else {
      uri = this.configService.config.BASE_API.toString() +

                  "/Mreport/runReport/80/Customers-Export-Reports/*" +
                  this.cookieService.getDefaultLocationCode().toString().trim();
    }

     var self = this;
    this.loading = true;
    $.ajax({
      url: uri,
      method: "GET",
      beforeSend: function(request) {
        request.setRequestHeader("Token", localStorage.getItem("token"));
      },
      xhrFields: {
        responseType: "blob",
      },
      success: function (data) {
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = formatName;
        a.click();
        window.URL.revokeObjectURL(url);
        self.loading = false;
      },
    });
    // var splitUrl = this.configService.config.getPDFUrl.split(":");
    // window.open(
    //   this.configService.config.getPDFUrl.replace(splitUrl[2], "10000") +
    //     "/Mreport/runReport/39/Customers-Export-Reports/*"
    // );
  }

  validationOperatorCode(opCode) {
    const splitCode = this.model[opCode].split(",");
    splitCode.forEach((item) => {
      if (item.length < 2 || item.length > 4) {
        this.model[`error-${opCode}`] =
          "OP character should be max has 4 char min has 2 char.";
      }
    });
  }

 checkValidGridDetails():number{
  let x:number;
  x=0;
  this.gridDetails.listStore.store.forEach((element) => {


      if(element["isValidDetail"] == 'Y'){
        x+=1;
      }
  });
  return x;
 }

 resetErrorOPnewCode(){
    this.model["error-cocOperatorCodeNew"] = "";
    this.model["error-socOperatorCodeNew"] = "";
    this.model["error-slotOperatorCodeNew"] = "";
 }

  handleSave() {
    // handle save event
    this.model.dateCreated = this.genericUtil.getPSIDate();
    this.model.masterCustomerDetails = [];
    this.model.masterCustomerDetails = this.gridDetails.listStore.store;


    let isValid: boolean = this.onValidate(this.model);

    /*
    //additional validation
    if (this.model.isBookingParty) {
      if (this.model.isCoc) {
        // this.validationOperatorCode("cocOperatorCode");
        if (this.model.cocOperatorCode.trim() == "") {
          this.model["error-cocOperatorCode"] = "Please input OP Code(s).";
          isValid = true;
        }
        if (this.model.cocOperatorCodeNew.trim() == "") {
          this.model["error-cocOperatorCodeNew"] = "Please input OP Code(s) (3 Chars).";
          isValid = true;
        }
      }

      if (this.model.isSoc) {
        // this.validationOperatorCode("socOperatorCode");
        if (this.model.socOperatorCode.trim() == "") {
          this.model["error-socOperatorCode"] = "Please input OP Code(s).";
          isValid = true;
        }
        if (this.model.socOperatorCodeNew.trim() == "") {
          this.model["error-socOperatorCodeNew"] = "Please input OP Code(s) (3 Chars).";
          isValid = true;
        }
      }

      if (this.model.isSlot) {
        // this.validationOperatorCode("slotOperatorCode");
        if (this.model.slotOperatorCode.trim() == "") {
          this.model["error-slotOperatorCode"] = "Please input OP Code(s).";
          isValid = true;
        }
        if (this.model.slotOperatorCodeNew.trim() == "") {
          this.model["error-slotOperatorCodeNew"] = "Please input OP Code(s) (3 Chars).";
          isValid = true;
        }
      }
    }else{
      this.resetErrorOPnewCode();
    }


    // GOS-699  Additional Data Field for OP code (3-Chars)
    // Do not duplicate operator codes
    if (this.model.isCoc && this.model.cocOperatorCode && this.model.cocOperatorCodeNew) {
      if (this.model.cocOperatorCode.toUpperCase() === this.model.cocOperatorCodeNew.toUpperCase()) {
        this.model["error-cocOperatorCodeNew"] = "OP Code and OP Code (3 Chars) cannot be identical.";
        isValid = true;
      }else{
        this.model["error-cocOperatorCodeNew"] = "";
      }
    }

    if (this.model.isSoc && this.model.socOperatorCode && this.model.socOperatorCodeNew) {
      if (this.model.socOperatorCode.toUpperCase() === this.model.socOperatorCodeNew.toUpperCase()) {
        this.model["error-socOperatorCodeNew"] = "OP Code and OP Code (3 Chars) cannot be identical.";
        isValid = true;
      }else{
        this.model["error-socOperatorCodeNew"] = "";
      }
    }

    if (this.model.isSlot && this.model.slotOperatorCode && this.model.slotOperatorCodeNew) {
      if (this.model.slotOperatorCode.toUpperCase() === this.model.slotOperatorCodeNew.toUpperCase()) {
        this.model["error-slotOperatorCodeNew"] = "OP Code and OP Code (3 Chars) cannot be identical.";
        isValid = true;
      }else{
        this.model["error-slotOperatorCodeNew"] = "";
      }
    }
    */

    if (!isValid) {
      if (
        !this.model.isShipper &&
        !this.model.isConsignee &&
        !this.model.isNotifyParty &&
        !this.model.isPayer &&
        !this.model.isAgency &&
        !this.model.isBookingParty &&
        !this.model.isHaulier &&
        !this.model.isNvocc &&
        !this.model.isSupplier &&
        !this.model.isFreightParty
      ) {
        this.message(
          "warning",
          "Error Input",
          "Please select at least one (1) option for Customer Type.",
          "okonly",
          { ok: "this.loading=false;" }
        );
      } else if (!this.model.isSoc && !this.model.isCoc && !this.model.isSlot) {
        this.message(
          "warning",
          "Error Input",
          "Please select at least one (1) option for Container Ownership.",
          "okonly",
          { ok: "this.loading=false;" }
        );
        /*
      }else if(this.model.masterCustomerDetails.length == 0){
        this.message('warning','Error Input', 'Please insert customer detail.', 'okonly',{ok:'this.loading=false;'});
      */
      }else if(this.checkValidGridDetails() < 1){
        this.message(
          "warning",
          "Error Input",
          "There must be at least 1 Set of Valid Details. No saving is allowed.",
          "okonly",
          { ok: "this.loading=false;" }
        );

      } else {
        this.message(
          "save",
          "Saving data",
          "Do you want to save the record? ",
          "yesno",
          { yes: "this.saveEvent()", no: "this.loading = false;" }
        );
      }
    } else {
      this.loading = false;
    }
  }

  handleCancel() {
    // handle cancel event
    this.model = new CustomerChina();
    this.modelDetail = new CustomerChinaDetail();
    this.gridDetails && this.gridDetails.onClear();

    this.modeForm = false;
    this.modeToolbar = false;
    this.form = "";
    // if (this.viewOnlyStatus) {
    //   this.disableToolbarButtons = "create,update,delete";
    // } else {
    //   this.disableToolbarButtons = "update,delete";
    // }

    if(this.viewOnlyStatus){
      this.disableToolbarButtons ="create,update,delete";
    } else {
      this.disableToolbarButtons ="";
    }
    this.invisibleToolbarButtons = "cancel";
    this.lock = false;

    this.cbCustomerHistory && this.cbCustomerHistory.setValue("");
    this.cbCustomerHistory && this.cbCustomerHistory.close();

    this.customerCodeHistory = "";
    this.gridHistory && this.gridHistory.onClear();
    this.gridHistory && this.gridHistory.onHistory("");
    if(this.viewOnlyStatus){
      this.cancelDetail();
    }
    this.gridApi.deselectAll();
  }

  handleHistory() {
    // handle history event
    this.modeForm = true;
    this.modeToolbar = true;
    this.form = "history";
    this.invisibleToolbarButtons = "save";

    this.gridHistory && this.gridHistory.onClear();
  }

  onRetrieve(customerId: string) {
    console.log('OnRetrieve : ' + customerId)
    this.gridDetails.onClear();
    this.genericService
      .GET(
        this.configService.config.BASE_API.toString() +
          "/MasterCustomers/findByIdSHA/" + customerId
      )
      .subscribe((resp) => {
        let data = resp.json();
        this.setData(data);
      });
  }

  setData(data: any, isLoad?) {
    console.log(data)
    let isValidVal:boolean =  false;
    let customerCodeVal:string = '';
    let customerNameVal:string = '';
    let oldCustomerIdVal:string = '';
    let oldCustomerCodeVal:string = '';

    if(isLoad != undefined){
      isValidVal = this.model['isValid'];
      customerCodeVal = this.model['customerCode'];
      customerNameVal = this.model['customerName'];
      oldCustomerIdVal = this.model['oldCustomerId'];
      oldCustomerCodeVal = this.model['oldCustomerCode'];
    }

    this.model = new CustomerChina();
    if(isLoad == undefined){
      this.model.customerId = String(data.customerId).trim().toUpperCase();
      this.model.customerCode = String(data.customerCode).trim();
      this.model.customerName = String(data.customerName).trim().replace(/&NBSP;/g, " ");
      this.cbCustomerName.setValue(this.model.customerName.trim().toUpperCase());

      this.model.oldCustomerId = String(data.oldCustomerId).trim().toUpperCase();
      this.model.oldCustomerCode = String(data.oldCustomerCode).trim();
    }else{
      this.model.isValid = isValidVal;
      this.model.customerCode = customerCodeVal;
      this.model.customerName =  customerNameVal;
      this.model.oldCustomerId = oldCustomerIdVal;
      this.model.oldCustomerCode = oldCustomerCodeVal;
    }
    this.model.countryCode = String(data.countryCode).trim().toUpperCase();
    this.model.countryName = String(data.countryName).trim().toUpperCase();
    this.cbCountry.setValue(this.model.countryName.trim().toUpperCase());
    this.model.companyRegNo = String(data.companyRegNo).trim().toUpperCase();
    this.model.remarks = String(data.remarks).trim().toUpperCase();

    if(isLoad == undefined){
      if (String(data.isValid).trim().toUpperCase() == "Y") {
        this.model.isValid = true;
      } else {
        this.model.isValid = false;
      }
    }

    if (String(data.isShipper).trim().toUpperCase() == "Y") {
      this.model.isShipper = true;
    } else {
      this.model.isShipper = false;
    }

    if (String(data.isConsignee).trim().toUpperCase() == "Y") {
      this.model.isConsignee = true;
    } else {
      this.model.isConsignee = false;
    }

    if (String(data.isNotifyParty).trim().toUpperCase() == "Y") {
      this.model.isNotifyParty = true;
    } else {
      this.model.isNotifyParty = false;
    }

    if (String(data.isPayer).trim().toUpperCase() == "Y") {
      this.model.isPayer = true;
    } else {
      this.model.isPayer = false;
    }

    if (String(data.isAgency).trim().toUpperCase() == "Y") {
      this.model.isAgency = true;
    } else {
      this.model.isAgency = false;
    }

    if (String(data.isBookingParty).trim().toUpperCase() == "Y") {
      this.model.isBookingParty = true;
    } else {
      this.model.isBookingParty = false;
    }

    if (String(data.isHaulier).trim().toUpperCase() == "Y") {
      this.model.isHaulier = true;
    } else {
      this.model.isHaulier = false;
    }

    if (String(data.isNvocc).trim().toUpperCase() == "Y") {
      this.model.isNvocc = true;
    } else {
      this.model.isNvocc = false;
    }

    if (String(data.isSupplier).trim().toUpperCase() == "Y") {
      this.model.isSupplier = true;
    } else {
      this.model.isSupplier = false;
    }

    if (String(data.isFreightParty).trim().toUpperCase() == "Y") {
      this.model.isFreightParty = true;
    } else {
      this.model.isFreightParty = false;
    }

    if (String(data.isCoc).trim().toUpperCase() == "Y") {
      this.model.isCoc = true;
      this.isCOC = true;
    } else {
      this.model.isCoc = false;
      this.isCOC = false;
    }

    if (String(data.isSoc).trim().toUpperCase() == "Y") {
      this.model.isSoc = true;
      this.isSOC = true;
    } else {
      this.model.isSoc = false;
      this.isSOC = false;
    }

    if (String(data.isSlot).trim().toUpperCase() == "Y") {
      this.model.isSlot = true;
      this.isSLOT = true;
    } else {
      this.model.isSlot = false;
      this.isSLOT = false;
    }

    //this.lock = false; commented on 3/9/2024
    if(isLoad == undefined){
      this.gridDetails.enable();
    }else{
      this.gridDetails.disable();
    }

    if (data.cocOperatorCode != null) {
      this.model.cocOperatorCode = String(data.cocOperatorCode)
        .trim()
        .toUpperCase();
    }

    if (data.cocOperatorCodeNew != null) {
      this.model.cocOperatorCodeNew = String(data.cocOperatorCodeNew)
        .trim()
        .toUpperCase();
    }

    if (data.socOperatorCode != null) {
      this.model.socOperatorCode = String(data.socOperatorCode)
        .trim()
        .toUpperCase();
    }

    if (data.socOperatorCodeNew != null) {
      this.model.socOperatorCodeNew = String(data.socOperatorCodeNew)
        .trim()
        .toUpperCase();
    }

    if (data.slotOperatorCode != null) {
      this.model.slotOperatorCode = String(data.slotOperatorCode)
        .trim()
        .toUpperCase();
    }


    if (data.slotOperatorCodeNew != null) {
      this.model.slotOperatorCodeNew = String(data.slotOperatorCodeNew)
        .trim()
        .toUpperCase();
    }

    if (data.cocAccountCode != null) {
      this.model.cocAccountCode = String(data.cocAccountCode)
        .trim()
        .toUpperCase();
    }
    if (data.socAccountCode != null) {
      this.model.socAccountCode = String(data.socAccountCode)
        .trim()
        .toUpperCase();
    }
    if (data.slotAccountCode != null) {
      this.model.slotAccountCode = String(data.slotAccountCode)
        .trim()
        .toUpperCase();
    }

    if (data.cocCreditDays != null) {
      this.model.cocCreditDays = Number.parseInt(data.cocCreditDays);
    }
    if (data.socCreditDays != null) {
      this.model.socCreditDays = Number.parseInt(data.socCreditDays);
    }
    if (data.slotCreditDays != null) {
      this.model.slotCreditDays = Number.parseInt(data.slotCreditDays);
    }

    if (data.cocCreditLimit != null) {
      this.model.cocCreditLimit = data.cocCreditLimit;
    }

    if (data.cocTolerance != null) {
      this.model.cocTolerance = Number.parseInt(data.cocTolerance);
    }

    if (data.socCreditLimit != null) {
      this.model.socCreditLimit = data.socCreditLimit;
    }

    if (data.socTolerance != null) {
      this.model.socTolerance = Number.parseInt(data.socTolerance);
    }

    if (data.slotCreditLimit != null) {
      this.model.slotCreditLimit = data.slotCreditLimit;
    }

    if (data.slotTolerance != null) {
      this.model.slotTolerance = Number.parseInt(data.slotTolerance);
    }

    this.gridDetails.listStore.store = [];
    // console.log(data);
    if (data["masterCustomerDetails"] != null) {
      //this.settingGridDetails.listStore.store = [];
      this.model.masterCustomerDetails = [];
      this.model.masterCustomerDetails = data["masterCustomerDetails"];
      //this.gridDetails.listStore.store = this.model.masterCustomerDetails;
      this.model.masterCustomerDetails.forEach((fe)=>{
        this.gridDetails.listStore.addData(fe);
      });

      this.gridDetails.loadData();
    }

    this.model.userCreated = String(data.userCreated).trim().toUpperCase();
    this.model.dateCreated = String(data.dateCreated).trim().toUpperCase();
    this.model.userModified = String(data.userModified).trim().toUpperCase();
    this.model.dateModified = String(data.dateModified).trim().toUpperCase();
    this.model.userInvalid = String(data.userInvalid).trim().toUpperCase();
    this.model.dateInvalid = String(data.dateInvalid).trim().toUpperCase();
  }

  changeEventOwnership(event: any) {
    //// console.log(event);
    let e: string = event;
    let ee: string[] = e.split("-");
    switch (ee[0]) {
      case "COC":
        if (ee[1] == "true") {
          this.isCOC = true;
        } else {
          this.isCOC = false;
          this.model.cocOperatorCode = "";
          this.model["error-cocOperatorCode"] = "";
          this.model.cocOperatorCodeNew = "";
          this.model["error-cocOperatorCodeNew"] = "";
          /*
          this.model.cocOperatorCode = '';
          this.model.cocAccountCode = '';
          this.model.cocCreditDays = 0;
          */
        }

        break;

      case "SOC":
        if (ee[1] == "true") {
          this.isSOC = true;
        } else {
          this.isSOC = false;
          this.model.socOperatorCode = "";
          this.model["error-socOperatorCode"] = "";
          this.model.socOperatorCodeNew = "";
          this.model["error-socOperatorCodeNew"] = "";
          /*
          this.model.socOperatorCode = '';
          this.model.socAccountCode = '';
          this.model.socCreditDays = 0;
          */
        }
        break;

      case "SLOT":
        if (ee[1] == "true") {
          this.isSLOT = true;
        } else {
          this.isSLOT = false;
          this.model.slotOperatorCode = "";
          this.model["error-slotOperatorCode"] = "";
          this.model.slotOperatorCodeNew = "";
          this.model["error-slotOperatorCodeNew"] = "";
          /*
          this.model.slotOperatorCode = '';
          this.model.slotAccountCode = '';
          this.model.slotCreditDays = 0;
          */
        }
        break;

      default:
        break;
    }
  }

  changeEventCustomer(event: any) {
    this.model["error-customerName"] = null;

    if (event["customerName"] != null) {
      this.model.customerName = event["customerName"];
      this.model.oldCustomerId = event["customerId"]
      this.model.oldCustomerCode = event["customerCode"]
      if(this.isUpdate == false){
        this.genericService.GET(
          this.configService.config.BASE_API.toString() +
            "/MasterCustomers/findById/" + this.model.oldCustomerId
        )
        .subscribe((resp) => {
          let data = resp.json();
          this.setData(data,true);
        });
      }

    } else {
      this.model.customerName = "";

      let isValidVal = this.model['isValid'];
      let  customerCodeVal = this.model['customerCode'];
      let  customerNameVal = this.model['customerName'];
      let  oldCustomerIdVal = this.model['oldCustomerId'];
      let  oldCustomerCodeVal = this.model['oldCustomerCode'];

      this.model = new CustomerChina();
      this.model.isValid = isValidVal;
      this.model.customerCode = customerCodeVal;
      this.model.customerName = customerNameVal;
      this.model.oldCustomerId = oldCustomerIdVal;
      this.model.oldCustomerCode = oldCustomerCodeVal;
      this.cbCountry.setValue("");
      this.gridDetails.listStore.store = [];
      this.gridDetails.loadData();

    }

    console.log(event);

  }

  changeEventCountry(event: any) {
    this.model["error-countryName"] = null;

    if (event["countryCode"] != null) {
      this.model.countryCode = event["countryCode"];
    } else {
      this.model.countryCode = "";
    }

    if (event["countryName"] != null) {
      this.model.countryName = event["countryName"];
    } else {
      this.model.countryName = "";
    }
  }

  changeEventCustomerHistory(event: any) {

    this.customerCodeHistory = "";

    this.customerCodeHistory = String(event.customerId).trim();

    this.gridHistory.onHistory(this.customerCodeHistory);
    // }
  }

  afterSave() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;
    this.isSOC = false;
    this.isCOC = false;
    this.isSLOT = false;


    //this.handleRetrieve();
    //this.gridApi.deselectAll();

    if (this.grid.getSelectedValues().length >= 1) {
      this.grid.onSortDir("dateModified", "DESC");
    } else {
      this.grid.onSortDir("dateCreated", "DESC");
    }

    this.grid.clearSelectedValues();
  }

  afterDelete() {
    this.modeForm = false;
    this.modeToolbar = false;
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "update,delete";
    }
    this.invisibleToolbarButtons = "cancel";

    this.form = "";
    this.lock = false;
    this.isSOC = false;
    this.isCOC = false;
    this.isSLOT = false;

    // this.grid.clearSelectedValues();
    // this.grid.loadData();
    //this.handleRetrieve();
    this.grid.loadData();
    this.grid.clearSelectedValues();
  }

  saveEvent() {
    //// console.log(this.model);
    console.log (this.model);

    this.loading = true;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() + "/MasterCustomers/chinaCustomersSave",
        this.model
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200 && resp.json()["message"] == "OK") {
            //clear all
            this.model = new CustomerChina();
            this.cbCountry.setValue("");
            this.modelDetail = new CustomerChinaDetail();
            this.gridDetails.listStore.store = [];
            this.gridDetails.loadData();

            this.loading = false;
            this.info = "save successfully";

            this.message(
              "information",
              "Information",
              "Record saved successfully.",
              "okonly",
              { ok: "this.loading=false;this.afterSave();" }
            );
          } else {
            this.loading = false;
            this.message(
              "warning",
              "Warning",
              resp.json()["content"],
              "okonly",
              { ok: "this.loading=false;" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );

  }

  deleteEvent() {
    //// console.log('delete event');
    this.loading = true;
    let deleteList = [];
    this.grid.getSelectedValues().forEach((element) => {
      deleteList.push(element.customerId);
    });
    // this.grid.getSelectedValues().forEach((element) => {
    //   deleteList.push(element.customerId);
    // });

    var currentdate = new Date();
    var datetime =  + currentdate.getFullYear() + "-"
                + ("0" + (currentdate.getMonth() + 1)).slice(-2)  + "-"
                + ("0" + (currentdate.getDate())).slice(-2) + " "
                + ("0" + (currentdate.getHours())).slice(-2) + ":"
                + ("0" + (currentdate.getMinutes())).slice(-2) + ":"
                + ("0" + (currentdate.getSeconds())).slice(-2);

    let paramsDelete = {"userDeleted": this.cookieService.getName(),"dateDeleted":datetime,"customerId":[]}
    paramsDelete.customerId = deleteList;

    this.genericService
      .POST(
        this.configService.config.BASE_API.toString() +
        "/MasterCustomers/softDeleteSHA",paramsDelete
      )
      .subscribe(
        (resp) => {
          if (resp.status == 200) {
            this.model = new CustomerChina();
            this.cbCountry.setValue("");
            this.modelDetail = new CustomerChinaDetail();
            this.gridDetails.listStore.store = [];
            this.gridDetails.loadData();

            this.loading = false;
            let data = resp.json();
            if (data.status == 'NOK') {
              this.message(
                "information",
                "Information",
                data.message,
                "okonly",
                { ok: "this.loading=false;" }
              );
              return;
            }
            this.message(
              "information",
              "Information",
              "Record deleted successfully. ",
              "okonly",
              { ok: "this.loading=false;this.afterDelete();" }
            );
          }
        },
        (error) => {
          this.loading = false;
          // console.log(error.message);
          this.info = error.message;
        }
      );
  }

  setValidatorHeader() {
    this.validatorRules = {
      customerName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Customer Name.",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9-'@,^&*(\)\+[\]/:;"\.  ]*$/,
            prompt: "Invalid Customer Name",
          },
        ],
      },

      companyRegNo: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9]*$/,
            prompt: "Invalid Company Registration Number",
          },
        ],
      },

      countryName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Country.",
          },
        ],
      },

      cocOperatorCode: {
        rules: [
          {
            type: "operatorCode",
            prompt: "",
          },
        ],
      },

      socOperatorCode: {
        rules: [
          {
            type: "operatorCode",
            prompt: "",
          },
        ],
      },

      slotOperatorCode: {
        rules: [
          {
            type: "operatorCode",
            prompt: "",
          },
        ],
      },

      cocCreditDays: {
        rules: [
          {
            type: "positiveInteger",
            prompt: "No negative value!",
          },
        ],
      },

      socCreditDays: {
        rules: [
          {
            type: "positiveInteger",
            prompt: "No negative value!",
          },
        ],
      },

      slotCreditDays: {
        rules: [
          {
            type: "positiveInteger",
            prompt: "No negative value!",
          },
        ],
      },
    };
  }

  setValidatorDetails() {
    this.validatorRules = {
      customerDisplayName: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Display Name.",
          },
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9-'@,^&*(\)\+[\]/:;"\. ]*$/,
            prompt: "Invalid Customer Display Name",
          },
        ],
      },
      contactPerson: {
        rules: [
          {
            type: "modifiedPattern",
            pattern: /^[a-zA-Z0-9-'&(\.) ]*$/,
            prompt: "Invalid Contact Person",
          },
        ],
      },
      fullAddress: {
        rules: [
          {
            type: "empty",
            prompt: "Please input Display Address.",
          },
          {
            type: "modifiedPattern",
            pattern: this.formValidation.regAllChar,
            prompt:
              "Please input valid characters (alphanumeric and -#&@^*()_+';:\"\\/,.)",
          },
        ],
      },
      email: {
        rules: [
          {
            type: "modifiedPattern",
            // pattern: /^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([;.] (([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,
            pattern: /^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+(([/.] |[;.] |[;.]||[/.])(([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/,

            prompt: "Invalid email format.",
          },
        ],
      },
    };
  }

  addDetail() {
    this.setValidatorDetails();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "save,cancel";
    }

    if (this.model["error-customerName"] != null) {
      this.model["error-customerName"] = "";
    }

    this.modelDetail = new CustomerChinaDetail();
    this.modelDetail.isValidD = true;
    $("#display").transition();
    $("#displayGrid").transition("hide");
  }

  removeDetail() {
    if (this.gridDetails.getSelectedValues().length > 0) {
      this.message(
        "delete",
        "Deletion Record",
        "Confirm to delete record(s)?",
        "yesno",
        { yes: "this.removeDetailEvent()", no: "" }
      );
    } else {
      if (this.gridDetails.listStore.store.length > 0) {
        this.message(
          "delete",
          "Deletion Record",
          "Please select at least one (1) option.",
          "okonly",
          { ok: "" }
        );
      }
    }
  }

  removeDetailEvent() {
    this.gridDetails.getSelectedValues().forEach((element) => {
      this.gridDetails.listStore.removeData(element);
    });
    this.gridDetails.loadData();
    this.gridDetails.clearSelectedValues();
    this.updateSeqNo();
  }

  cancelDetail() {
    this.gridDetails.clearSelectedValues();
    this.setValidatorHeader();
    if (this.viewOnlyStatus) {
      this.disableToolbarButtons = "create,update,delete";
    } else {
      this.disableToolbarButtons = "";
    }
    $("#display").transition("hide");
    $("#displayGrid").transition();
  }

  saveDetail() {
    if (!this.onValidate(this.modelDetail)) {
      if (this.modelDetail.no == 0) {
        let data = new CustomerChinaDetail();
        data.customerDisplayName = String(this.modelDetail.customerDisplayName)
          .toUpperCase()
          .trim();
        data.fullAddress = String(this.modelDetail.fullAddress)
          .toUpperCase()
          .trim();
        data.contactPerson = String(this.modelDetail.contactPerson)
          .toUpperCase()
          .trim();
        data.telno = String(this.modelDetail.telno);
        data.email = String(this.modelDetail.email).trim();
        data.fax = String(this.modelDetail.fax);
        if(this.modelDetail.isValidD){
          data.isValidDetail = 'Y';
        }else{
          data.isValidDetail = 'N';
        }
        this.gridDetails.listStore.addData(data);
        this.gridDetails.loadData();
        this.updateSeqNo();

        this.gridDetails.clearSelectedValues();
        this.modelDetail = new CustomerChinaDetail();

        this.setValidatorHeader();
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "";
        }
        $("#display").transition("hide");
        $("#displayGrid").transition();
      } else {
        let data = new CustomerChinaDetail();
        data.no = this.modelDetail.no;
        data.seqNo = this.modelDetail.seqNo;
        data.customerDisplayName = String(this.modelDetail.customerDisplayName)
          .toUpperCase()
          .trim();
        data.fullAddress = String(this.modelDetail.fullAddress)
          .toUpperCase()
          .trim();
        data.contactPerson = String(this.modelDetail.contactPerson)
          .toUpperCase()
          .trim();
        data.telno = String(this.modelDetail.telno);
        data.email = String(this.modelDetail.email).trim();
        data.fax = String(this.modelDetail.fax);
        if(this.modelDetail.isValidD){
          data.isValidDetail = 'Y';
        }else{
          data.isValidDetail = 'N';
        }

        this.gridDetails.listStore.updateData(this.modelDetail.no - 1, data);
        this.gridDetails.loadData();
        this.gridDetails.clearSelectedValues();
        this.modelDetail = new CustomerChinaDetail();

        this.setValidatorHeader();
        if (this.viewOnlyStatus) {
          this.disableToolbarButtons = "create,update,delete";
        } else {
          this.disableToolbarButtons = "";
        }
        $("#display").transition("hide");
        $("#displayGrid").transition();
      }
    }
  }

  updateSeqNo() {
    let index: number = 0;
    this.gridDetails.listStore.store.forEach((element) => {
      element["seqNo"] = element["no"];
      this.gridDetails.listStore.updateData(index, element);
      index++;
    });
  }

  generateCustomerHistoryReport(event: any) {
    // console.log("customerIdHistory => " + this.customerIdHistory);
    if (this.customerCodeHistory == "" || this.customerCodeHistory == "undefined") {
      this.message(
        "warning",
        "Warning",
        "Please select (1) historical data before exporting.",
        "okonly",
        { ok: "" }
      );
    } else {
      // if(this.gridHistory.listStore.store.length > 0){
      //window.open(this.configService.config.BASE_API.toString().replace('9090','10000') + '/Mreport/runReport/40/History-Customer-' + this.customerIdHistory +'/'+this.customerIdHistory);
      var path =
        "40/History-Customer-" +
        this.customerCodeHistory +
        "/" +
        this.customerCodeHistory;
      var filename = "History-Customer-" + this.customerCodeHistory;
      this.downloadFileFromServer(path, filename, "xls", true);
      // }
    }
  }


  markRedRow(dataNo){
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return this.style.cssText + ';background: lightcoral !important;';
        });
      }
    });
  }

  markDefaultRow(dataNo) {
    var i = this.grid.id;
    $('#' + i + '_dataTables_scrollBody').find('table').find('tbody').find('tr').each(function (index, value) {
      if(index === dataNo){
        $(this).css('cssText', function(i, v) {
          return 'cursor:pointer;';
        });
      }
    });
  }

  gridEventHistory(event: any) {
    //do nothing
  }

  retrieveHistory(event: any) {
    if (this.customerCodeHistory != "" && this.customerCodeHistory != "undefined") {
      this.gridHistory.loadData();
    }
  }

  infoGridHistory(event: any) {
    // this.info=event;
  }

  none(event: any) {
    if (event.keyCode == 13) {
      event.preventDefault();
    }
  }

  noneNum(event: any) {
    const regexPattern = /[0-9]/g;
    if (regexPattern.test(event.key) == false) {
      event.preventDefault();
    }
  }

  downloadFileFromServer(
    path: string,
    fileName: string,
    fileExt: string,
    loading: boolean
  ) {
    loading = true;
    var dwnldUrl = this.hitUrl + "?q=/Mreport/runReport/" + path;
    this._http
      .get(dwnldUrl, {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Token: localStorage.getItem("token"),
        }),
        responseType: "blob",
      })
      .subscribe(
        (res) => {
          var docName = fileName + "." + fileExt;
          FileSaver.saveAs(res, docName);
          loading = false;
        },
        (error) => {
          this.message(
            "Information",
            "information",
            "An Error occured, please contact your administrator!",
            "okonly",
            { ok: "" }
          );
          loading = false;
        }
      );
  }



  initTab4() {
    this.tabStatus = "tab4";
    this.tabStatus1 = "";
    this.tabStatus2 = "";
    this.tabStatus3 = "";
    this.tabStatus4 = "active";
    this.gridApi.setColumnDefs(this.columnAuditLogDefs);
    // this.gridApi.sizeColumnsToFit();
  }

  handleSync() {
    this.genericUtil.showLoader();
    this.genericService
      .GET(this.hitUrl +
        "/MasterCustomers/transmitToSVM"
      ).pipe(
        finalize(() => this.genericUtil.hideLoader())
      )
      .subscribe((resp) => {
        this.message(
          "information",
          "Information",
          "Sync to SVM successfully.",
          "okonly",
          { ok: "this.loading=false;this.afterDelete();" }
        );
      }, () => {
        this.message(
          "information",
          "Information",
          "An error has occurred.",
          "okonly",
          { ok: "this.loading=false;this.afterDelete();" }
        );
      });
  }
}

var hashValueGetter = function (params) {
  return params.node ? params.node.rowIndex + 1 : null;
};

class CountryIndicator implements ICellRenderer {
  private eGui!: HTMLElement;
  init(params: ICellRendererParams) {
      const element = document.createElement('span');
      const elementIcon = document.createElement('i');
      var cntryName= params.data.countryCode.trim().toLowerCase();

      elementIcon.className=cntryName+' flag';
      element.appendChild(elementIcon);
      element.appendChild(document.createTextNode(' '+params.value.trim()));
      this.eGui = element;
  }
  getGui() {
      return this.eGui;
  }
  refresh() { return false; }


}


