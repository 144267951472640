import { Component, OnInit, EventEmitter, AfterViewInit, ViewChild } from '@angular/core';
import { ListStore, Authorize, Validator, GridPluginComponent, ComboPluginComponent,GenericService, GenericUtil } from 'sibego-ui-library';
import { Router } from '@angular/router';
declare  var $:any;

@Component({
  selector: 'app-edi-receive-bl-data-from-penavico-page',
  templateUrl: './edi-receive-bl-data-from-penavico-page.component.html',
  styleUrls: ['./edi-receive-bl-data-from-penavico-page.component.css']
})
export class EDIReceiveBLDataFromPenavicoPageComponent extends Validator  implements OnInit, AfterViewInit {

  dialog:any;

  /* Parameter for information into Footer */
  info = '';

  /* Parameter for enable/disable Toolbar buttons */
  invisibleToolbarButtons = "";
  disableToolbarButtons = '';

  modeToolbar = false;
  modeForm = 'sailingScheduleFlagStatus';

  loading = false;

  /* Parameter settings */
  settingToolbar;

  //types
  typeLocalETA = 'calendarETA';
  typeLocalETD = 'calendarETD';
  typeBlDate = 'calendarBL';
  typeExchangeRateDate = 'calendarEX';


  // lock
  cButtonLock = true;
  dButtonLock = true;

  model ='';

  validatorRules = {};


  constructor(private auth:Authorize, private genericService:GenericService, private genericUtil:GenericUtil, private router: Router) {
    super();

    this.settingToolbar = {
      buttonsFront: [
        {name: 'Process', event: 'process', icon: 'shuffle'},
        {name: 'Close', event: 'close', icon: 'power'},
      ],
      buttonsDetail: [],
      createDefaultFront: false,
      createDefaultDetail: false,
      toolbarType: 'label',
      label: 'Receive BL From Penavico'
    }
  }
  ngOnInit() {
  }

  ngAfterViewInit() {
    this.eventBrowse();


  }

  eventBrowse(){
    var fileExtentionRange = '.zip .rar .tar .pdf .doc .docx .xls .xlsx .ppt .pptx';
    var MAX_SIZE = 30; // MB

    $(document).on('change', '.btn-file :file', function() {
        var input = $(this);

        if (navigator.appVersion.indexOf("MSIE") != -1) { // IE
            var label = input.val();

            input.trigger('fileselect', [ 1, label, 0 ]);
        } else {
            var label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            var numFiles = input.get(0).files ? input.get(0).files.length : 1;
            var size = input.get(0).files[0].size;

            input.trigger('fileselect', [ numFiles, label, size ]);
        }
    });

    $('.btn-file :file').on('fileselect', function(event, numFiles, label, size) {
        $('#attachmentName').attr('name', 'attachmentName'); // allow upload.

        var postfix = label.substr(label.lastIndexOf('.'));
        if (fileExtentionRange.indexOf(postfix.toLowerCase()) > -1) {
            if (size > 1024 * 1024 * MAX_SIZE ) {
                alert('max size：<strong>' + MAX_SIZE + '</strong> MB.');

                $('#attachmentName').removeAttr('name'); // cancel upload file.
            } else {
                $('#_attachmentName').val(label);
            }
        } else {
            alert('file type：<br/> <strong>' + fileExtentionRange + '</strong>');

            $('#attachmentName').removeAttr('name'); // cancel upload file.
        }
    });
  }

  toolbarEvent(event) {
    //// console.log('event : ' + event);
    switch (event) {
      case 'retrieve' :
        break;
      case 'close':
        // handle close event
        this.router.navigateByUrl("/main/home");
        break;
    }
  }
  eventMessage(event){}

  message(txtIcon:string, txtHeader:string, txtContent:string, btns:string, eve:any){
    this.dialog = {icon:txtIcon, header: txtHeader, content: txtContent, buttons: btns, event : eve };
    $('#dialog').modal('show');
  }

}
